import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('menuButton') botonMenu?: ElementRef<HTMLButtonElement>;

  menuOpen: boolean = false;
  modalSearchOpen: boolean = false;
  search: string = '';
  requiredPrivateConnection: boolean = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.menuOpen = false;
      this.changeDetectorRef.detectChanges();
    });

    this.globalService.getDeporwinStatus().subscribe(status => {
      this.requiredPrivateConnection = status;
    });
  }

  // Escuchar evento click fuera del menu para cerrarlo
  @HostListener('document:click', ['$event'])
  handleClick(event: Event) {
    if (this.menuOpen && !this.botonMenu?.nativeElement.contains(event.target as HTMLElement)) {
      this.menuOpen = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  closeModal() {
    this.search = '';
    this.modalSearchOpen = false;
    this.changeDetectorRef.detectChanges();
  }

  openMenu(e: MouseEvent) {
    const menu = document.getElementById('menu');
    if (!menu) return;
    if (this.menuOpen) return;
    let x = e.pageX,
      y = e.pageY,
      winWidth = window.innerWidth,
      winHeight = window.innerHeight,
      mWidth = menu?.offsetWidth,
      mHeight = menu?.offsetHeight;

    x = x > winWidth - mWidth ? winWidth - mWidth - 5 : x;
    y = y > winHeight - mHeight ? winHeight - mHeight - 5 : y;

    menu.style.left = `${x}px`;
    menu.style.top = `${y}px`;
    this.menuOpen = true;
  }
  navigateToHome() {
    this.router.navigate(['/']);
  }
}
