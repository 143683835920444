import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-line',
  templateUrl: './user-line.component.html',
  styleUrls: ['./user-line.component.css'],
})
export class UserLineComponent {
  @Input() usuario: any;
}
