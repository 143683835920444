import { style } from '@angular/animations';
import { Injectable } from '@angular/core';
import { text } from 'pdfkit';
import { margins } from 'pdfkit/js/page';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

@Injectable({
  providedIn: 'root',
})
export class ExportPdfService {
  constructor() {}

  generatePdf(data: any) {
    const pdfMake = require('pdfmake/build/pdfmake.js');
    const pdfFonts = require('pdfmake/build/vfs_fonts.js');

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    function generatePDFContent(data: any) {
      return data.map((element: any) => {
        const content = [];

        content.push({ text: element.Titulo + ':', style: 'header' });

        for (const key in element) {
          if (element.hasOwnProperty(key) && key !== 'Titulo') {
            content.push({
              text: [{ text: key + ': ', style: 'subheader' }, element[key] || 'No asignado'],
            });
          }
        }

        return content;
      });
    }

    const docDefinition = {
      content: generatePDFContent(data),
      defaultStyle: {
        font: 'Roboto',
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          alignment: 'left',
          margin: [10, 10, 10, 10],
        },
        subheader: {
          fontSize: 15,
          bold: true,
          margin: [5, 5, 5, 5],
        },
        quote: {
          italics: true,
        },
        small: {
          fontSize: 8,
        },
      },
    };

    pdfMake.createPdf(docDefinition).open();
  }
}
