import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-pages-loader',
  templateUrl: './pages-loader.component.html',
  styleUrls: ['./pages-loader.component.css'],
})
export class PagesLoaderComponent implements OnInit, OnDestroy {
  @Input() Apierror: boolean = false;
  @HostListener('window:resize', ['$event'])
  @Input()
  condition: boolean = false;
  onResize() {
    this.heightLoader();
  }

  ngOnInit() {
    this.heightLoader();
    document.getElementsByTagName('footer')[0]?.classList.add('hidden');
  }

  ngOnDestroy() {
    document.getElementsByTagName('footer')[0]?.classList.remove('hidden');
  }

  heightLoader() {
    const header = document.getElementsByTagName('header')[0]?.offsetHeight;

    const height = window.innerHeight - header - 250;

    const img = document.getElementById('page-loader');
    if (img) img.style.height = `${height}px`;
  }
}
