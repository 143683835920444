<main *ngIf="isModalOpen">
    <section>
        <h2 *ngIf="title">{{ title }}</h2>
        <p *ngIf="message">{{ message }}</p>
        <footer class="flex justify-end">
            <button *ngIf="confirm" class="btn btn-primary" (click)="onConfirm()">{{confirm}}</button>
            <button *ngIf="cancel" class="btn btn-secondary" (click)="onCancel()">{{cancel}}</button>
        </footer>
    </section>
</main>
