<main>
  <header class="flex w-full justify-between">
    <div id="desplegable-listado" class="w-full flex flex-row my-auto items-center text-black font-bold text-lg">
      <img class="h-6 w-6 transition-all" src="/assets/icons/flecha.svg" alt="Flecha">
      Rutas coincidentes
    </div>
    <div class="flex justify-end w-1/6">
      <button id="button-vista-grid" (click)="vista='grid'">
        <img src="/assets/icons/grid.svg" alt="Tarjeta" class="w-8">
      </button>
      <button id="button-vista-list" (click)="vista='list'">
        <img src="/assets/icons/list.svg" alt="Lista" class="w-8">
      </button>
    </div>
  </header>
  <footer>
    <ng-template [ngIf]="rutas !== null">
      <app-route-card *ngFor="let ruta of rutas" [ruta]="ruta" [vista]="vista" />
    </ng-template>
  </footer>
</main>