<div class="full-container">
  <div class="container">
    <div>
      <h5>{{ title }}</h5>
      <div class="text-area-container">
        <textarea [(ngModel)]="observaciones"></textarea>
      </div>
    </div>
    <div class="buttons">
      <app-button label="Cancelar" (onClick)="cancel()" class="button-observacion-action cancel" />
      <app-button label="Aceptar" (onClick)="save()" class="button-observacion-action save" />
    </div>
  </div>
</div>
