import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserProfileSwitchService } from 'src/app/user/services/user-profile-switch.service';

@Component({
  selector: 'app-user-profile-switch',
  templateUrl: './user-profile-switch.component.html',
  styleUrls: ['./user-profile-switch.component.css'],
})
export class UserProfileSwitchComponent {
  @Input() initialState = false;
  state = this.initialState;
  show: boolean = false;

  constructor(private userProfileSwitchService: UserProfileSwitchService) {}

  ngOnInit() {
    this.userProfileSwitchService.state().subscribe(state => {
      this.state = state;
    });
    this.userProfileSwitchService.isMounted().subscribe(mounted => {
      this.show = mounted;
    });
  }

  switch() {
    this.userProfileSwitchService.switch();
  }
}
