import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserProfileSwitchService {
  private mounted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private switched: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  mount() {
    this.mounted.next(true);
  }

  unmount() {
    this.mounted.next(false);
  }

  switch() {
    this.switched.next(!this.switched.value);
  }

  state() {
    return this.switched.asObservable();
  }

  isMounted() {
    return this.mounted.asObservable();
  }
}
