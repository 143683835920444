import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InscriptionsService {
  constructor(private httpClient: HttpClient) {}

  getInscriptions() {
    return this.httpClient.get(`${environment.apiBackGetUrl}/api/v1/drupal/inscripciones`);
  }
  getAtachedFiles(path: string) {
    return `${environment.apiBackGetUrl}/api/v1/drupal/content/file?url=${path}`;
  }
}
