<div class="w-full h-full">
  <div *ngIf="avisos.length!=0 || convocatorias.length!=0" class="w-full bg-gray-800 flex justify-center">
    <section id="banner" class="w-8/12 flex flex-row justify-between">
      <app-avisos [incidencias]="incidencias" [avisos]="avisos" class="w-1/2 h-20 hidden lg:block"></app-avisos>
      <app-convocatorias [convocatorias]="convocatorias" class="w-1/2 h-20  hidden lg:block"></app-convocatorias>
      <a routerLink="/noticias" class="w-full flex justify-center items-center lg:hidden text-white font-semibold text-lg">
        <img src="assets/icons/alerta.svg" alt="Alerta" class="h-6">
        <p class="mx-2 text-center">AVISOS IMPORTANTES</p>
        <img src="assets/icons/alerta.svg" alt="Alerta" class="h-6">
      </a>
    </section>
  </div>
  <div class="overflow-hidden m-auto">
    <img id="imagen-home-wide" src="assets/img/home-wide.webp" alt="Imagen de Inicio" class="object-center object-cover m-auto hidden md:block">
    <img id="imagen-home" src="assets/img/home.webp" alt="Imagen de Inicio" class="object-center object-cover m-auto md:hidden">
  </div>
  <div *ngIf="noticias.length!==0" class="w-full flex justify-center py-4">
    <section id="banner-noticia" class="w-11/12 md:w-8/12">
      <app-banner-noticias [noticias]="noticias.slice(0,7)"/>
    </section>
  </div>
  <div>
    <app-enlaces-interes></app-enlaces-interes>
  </div>
</div>

