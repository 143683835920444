import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info-floating-button',
  templateUrl: './info-floating-button.component.html',
  styleUrls: ['./info-floating-button.component.css'],
})
export class InfoFloatingButtonComponent {
  constructor(private router: Router) {}

  handleClick() {
    this.router.navigate(['/inscripciones'], {
      queryParams: { section: 1 },
      queryParamsHandling: 'merge',
    });
  }
}
