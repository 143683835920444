<main *ngIf="show">
    <h2>{{convocatoria?.titulo}}</h2>
    <p [innerHTML]="convocatoria?.descripcion?.value"></p>
    <article>
        <span *ngIf="convocatoria?.desde">
            <b>Desde: </b>
            {{fromDate.format('DD/MM/YYYY')}}
        </span>
        <span *ngIf="convocatoria?.hasta">
            <b>Hasta: </b>
            {{toDate.format('DD/MM/YYYY')}}
        </span>
    </article>
    <section>
        <ng-container *ngFor="let file of convocatoria?.archivos_enlazados">
            <app-file-downloader [url]="file.uri?.url || null" [filename]="file.filename" [description]="file.meta?.description || null" />
        </ng-container>
    </section>
</main>
