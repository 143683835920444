import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/services/service.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-book-facility-step-one',
  templateUrl: './book-facility-step-one.component.html',
  styleUrls: ['./book-facility-step-one.component.css'],
})
export class BookFacilityStepOneComponent implements OnInit {
  showloader: boolean = true;
  IdFamilia: number;
  resources: any;

  constructor(private serviceService: ServiceService, private route: ActivatedRoute) {
    this.IdFamilia = this.route.snapshot.params['id'];
  }

  ngOnInit() {
    this.serviceService.getActivities(this.IdFamilia).subscribe((data: any) => {
      this.resources = data.SubFamilias;
      this.showloader = false;
    });
  }
}
