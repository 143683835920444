import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  OnChanges,
  AfterViewInit,
  SimpleChanges,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css'],
})
export class IconComponent implements OnChanges, AfterViewInit {
  @ViewChild('iconContainer') iconContainer!: ElementRef;
  @Input() customClass: string = '';
  @Input() hoverFill: string = 'none';
  @Input() hover: boolean = false;
  @Input() fill: string = 'none';
  @Input() stroke: string = 'none';
  @Input() hoverStroke: string = 'none';
  @Input() src: string = 'none';
  fillValue: string = 'none';
  strokeValue: string = 'none';

  constructor(private http: HttpClient) {}

  ngAfterViewInit() {
    this.fillValue = this.fill;
    this.strokeValue = this.stroke;
    this.setSrc();
  }

  setSrc() {
    this.http.get(`assets/icons/${this.src}.svg`, { responseType: 'text' }).subscribe(data => {
      const iconContainerNative = this.iconContainer.nativeElement;
      while (iconContainerNative.firstChild) {
        iconContainerNative.removeChild(iconContainerNative.firstChild);
      }
      const parser = new DOMParser();
      const svg = parser.parseFromString(data, 'image/svg+xml');
      const elements = svg.querySelectorAll('*:not(svg)');
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('fill', 'var(--fill, black)');
        elements[i].setAttribute('stroke', 'var(--stroke, black)');
      }
      this.iconContainer.nativeElement.appendChild(svg.documentElement);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fillValue = this.fill;
    const hasChangeSrc =
      changes['src'] &&
      !changes['src']?.firstChange &&
      changes['src']?.currentValue !== changes['src']?.previousValue;
    if (hasChangeSrc) {
      this.setSrc();
    }
    if (this.hover) this.onMouseEnter();
    else this.onMouseLeave();
  }

  onMouseEnter() {
    if (this.hoverFill !== 'none') this.fillValue = this.hoverFill;
    if (this.hoverStroke !== 'none') this.strokeValue = this.hoverStroke;
  }

  onMouseLeave() {
    if (this.hoverFill !== 'none') this.fillValue = this.fill;
    if (this.hoverStroke !== 'none') this.strokeValue = this.stroke;
  }
}
