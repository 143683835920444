import { Component, Input } from '@angular/core';
import { AccessorForCardView } from '../list/icon-list/icon-list.component';
import { Router } from '@angular/router';
import { ListListerColumn, ListListerColumnType } from '../list/list-lister/list-lister.component';

@Component({
  selector: 'app-mini-card-view',
  templateUrl: './mini-card-view.component.html',
  styleUrls: ['./mini-card-view.component.css'],
})
export class MiniCardViewComponent {
  @Input() data!: any;
  @Input() accessorKeys!: AccessorForCardView;
  @Input() destination!: string;

  currentPageData: any[] = [];
  handleEmitData(data: any[]) {
    this.currentPageData = data;
  }

  constructor(private router: Router) {}

  navigateTo(id: string) {
    this.router.navigate([this.destination, id]);
  }

  ngOnChange() {}
}
