import { Component, Input } from '@angular/core';
import { ModalService } from 'src/app/services/modal/modal.service';
import { UsersTableModalComponent } from '../../users-table-modal/users-table-modal.component';
import { tap } from 'rxjs';

@Component({
  selector: 'app-tabla-bundle',
  templateUrl: './tabla-bundle.component.html',
  styleUrls: ['./tabla-bundle.component.css'],
})
export class TablaBundleComponent {
  @Input() rutinas: any;

  constructor(private modalService: ModalService) {}

  onTableUsersClick(tableId: string | number) {
    this.modalService
      .showComponent(
        UsersTableModalComponent,
        {
          rutina: this.rutinas.find((rutina: any) => rutina.tabla.id === tableId),
        },
        ['cerrar']
      )
      .pipe(
        tap((output: any) => {
          if (output.cerrar) {
            this.modalService.removeComponent();
          }
        })
      )
      .subscribe();
  }
}
