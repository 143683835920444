import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-monitor-profile-form',
  templateUrl: './monitor-profile-form.component.html',
  styleUrls: ['./monitor-profile-form.component.css'],
})
export class MonitorProfileFormComponent {
  @Input() user: any;
  @Output() cerrar = new EventEmitter<boolean>();
  monitorFormGroup: UntypedFormGroup;

  onClose() {
    this.cerrar.emit(true);
  }
}
