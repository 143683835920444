import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { TablaFormConverterService } from '../../converters/tabla-form/tabla-form.converter.service';
import { combineLatest, map, Observable, of, Subscription, timer } from 'rxjs';
import {
  RutinaApi,
  TablasConverterService,
} from '../../converters/tablas/tablas.converter.service';
import { TablasService } from '../tablas/tablas.service';

@Injectable({
  providedIn: 'root',
})
export class TablaFormService implements OnDestroy {
  rutinaForm: UntypedFormGroup;

  private subscriptions = new Subscription();

  constructor(
    private tablaFormConverterService: TablaFormConverterService,
    private tablaConverterService: TablasConverterService,
    private tablaService: TablasService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  intialize() {
    //TODO: Eliminar rutina Mock cuando ya se traigan todos los datos
    const rutinaMock = { tabla: null, usuarios: [] };
    this.rutinaForm = this.tablaFormConverterService.fromRutina(rutinaMock);
  }

  saveRutina(): Observable<any> {
    this.rutinaForm.updateValueAndValidity();
    this.rutinaForm.markAllAsTouched();
    this.rutinaForm.statusChanges.subscribe();
    Object.keys(this.rutinaForm.controls).forEach(key => {
      const control = this.rutinaForm.get(key);
      control.statusChanges.subscribe();
    });
    let saveRutina$: Observable<any> = of();
    const commonValidationErrors = this.getCommonValidationErrors();
    if (commonValidationErrors.length > 0) {
      return of(commonValidationErrors);
    } else {
      saveRutina$ = this.performSaveRutina();
    }

    return saveRutina$.pipe(
      map(_ => ({
        response: 200,
      }))
    );
  }

  updateRutina(): Observable<any> {
    this.rutinaForm.updateValueAndValidity();
    this.rutinaForm.markAllAsTouched();
    this.rutinaForm.statusChanges.subscribe();
    Object.keys(this.rutinaForm.controls).forEach(key => {
      const control = this.rutinaForm.get(key);
      control.statusChanges.subscribe();
    });
    let updateRutina$: Observable<any> = of();
    const commonValidationErrors = this.getCommonValidationErrors();
    if (commonValidationErrors.length > 0) {
      return of(commonValidationErrors);
    } else {
      updateRutina$ = this.perfomUpdateRutina();
    }

    return updateRutina$.pipe(
      map(_ => ({
        response: 200,
      }))
    );
  }

  perfomUpdateRutina() {
    const rutinaApi = this.tablaFormConverterService.toRutina(this.rutinaForm);
    const rutinaDto = this.tablaConverterService.toDto(rutinaApi);

    const updateRutina$ = this.tablaService.updateRutina(rutinaDto);
    //   .pipe
    //   //switchMap(() => {
    //   //return this.printEntryRecord();
    //   //})
    //   ();
    return combineLatest([timer(2000), updateRutina$]);
  }

  getCommonValidationErrors(): { message: string }[] {
    const validationMessages = [];
    let tablaData = this.rutinaForm.get('tabla') as UntypedFormGroup;
    let usuarioData = this.rutinaForm.get('usuarios') as UntypedFormGroup;
    if (this.rutinaForm?.invalid || usuarioData.getRawValue().length === 0) {
      if (!tablaData.valid) {
        if (tablaData?.controls['title']?.invalid) {
          validationMessages.push({
            message: 'Tabla: Falta Nombre',
          });
        }
        if (tablaData?.controls['observaciones']?.invalid) {
          validationMessages.push({
            message: 'Tabla: Error en Observaciones',
          });
        }
        if (tablaData?.controls['lineasEjercicio']?.invalid) {
          validationMessages.push({
            message: 'Tabla: Error en una linea de ejercicio',
          });
        }
        if (tablaData?.controls['lineasEjercicio']?.value?.length < 1) {
          validationMessages.push({
            message: 'Tabla: No hay ninguna línea de ejercicio',
          });
        }
      }
      if (!usuarioData.valid || usuarioData.getRawValue().length === 0) {
        if (usuarioData.getRawValue().length === 0) {
          validationMessages.push({ message: 'El listado de usuario no puede estar vacío' });
        } else {
          validationMessages.push({ message: 'Errores en los usuarios' });
        }
      }
    }

    return validationMessages;
  }

  loadRutina(rutina: RutinaApi): Observable<any> {
    this.rutinaForm = this.tablaFormConverterService.fromRutina(rutina);
    return of(null);
  }

  private performSaveRutina(): Observable<any> {
    const rutinaApi = this.tablaFormConverterService.toRutina(this.rutinaForm);
    const rutinaDto = this.tablaConverterService.toDto(rutinaApi);

    const savedRutina$ = this.tablaService
      .saveRutina(rutinaDto)
      .pipe
      //switchMap(() => {
      //return this.printEntryRecord();
      //})
      ();
    return combineLatest([timer(2000), savedRutina$]);
  }

  deleteUsuario(index: number) {
    const userFormGroupToDelete = this.usuariosFormArray.at(index) as FormGroup;
    if (userFormGroupToDelete.getRawValue()?.idRutina)
      this.usuariosDeleteFormArray.push(userFormGroupToDelete);
    this.usuariosFormArray.removeAt(index);
  }

  get usuariosFormArray(): UntypedFormArray {
    return this.rutinaForm.get('usuarios') as UntypedFormArray;
  }

  get usuariosDeleteFormArray(): UntypedFormArray {
    return this.rutinaForm.get('usuariosDelete') as UntypedFormArray;
  }
}
