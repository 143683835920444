<section>
    <header>
        <span>Vista de usuarios de una tabla</span>
        <span>{{rutina.usuarios.length}} PERSONAS</span>
    </header>

    <article>
        <ng-container *ngFor="let usuario of rutina.usuarios">
            <app-user-line [usuario]="usuario" />
        </ng-container>
    </article>
</section>
<main (click)="onClose()">
</main>
