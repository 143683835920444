import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css'],
})
export class ProgressBarComponent {
  @Input() numberSteps: number = 0;
  @Input() step: number = 0;

  calcPercent(): number {
    return ((this.step - 1) / this.numberSteps) * 100;
  }
}
