import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of, tap } from 'rxjs';
import { RutasResponse } from '../../interfaces/RutasResponse';

@Injectable({
  providedIn: 'root',
})
export class RutasService {
  rutas?: RutasResponse;

  constructor(private http: HttpClient) {}

  getRutas(
    limit: number = 200,
    url: string = `${environment.apiBackGetUrl}/api/v1/drupal/rutas`
  ): Observable<RutasResponse> {
    let params = new HttpParams();
    params = params.append('page[limit]', limit);
    if (this.rutas) {
      return of(this.rutas);
    } else {
      return this.http.get<RutasResponse>(url, { params }).pipe(
        tap(data => {
          this.rutas = data;
        })
      );
    }
  }

  filtradoRutas(params: HttpParams, limit: number = 200): Observable<RutasResponse> {
    params = params.append('page[limit]', limit);
    return this.http.get<RutasResponse>(`${environment.apiBackGetUrl}/api/v1/drupal/rutas`, {
      params,
    });
  }
}
