import { Component } from '@angular/core';
import { UserProfileSwitchService } from 'src/app/user/services/user-profile-switch.service';

@Component({
  selector: 'app-user-profile-switcher',
  templateUrl: './user-profile-switcher.component.html',
  styleUrls: ['./user-profile-switcher.component.css'],
})
export class UserProfileSwitcherComponent {
  switched: boolean = false;

  constructor(private userProfileSwitchService: UserProfileSwitchService) {}

  handleSwitch(state: boolean) {
    this.switched = state;
  }

  ngOnInit() {
    this.userProfileSwitchService.mount();
    this.userProfileSwitchService.state().subscribe(state => {
      this.switched = state;
    });
  }

  ngOnDestroy() {
    this.userProfileSwitchService.unmount();
  }
}
