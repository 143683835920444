import { Component } from '@angular/core';
import { DeporteEscolarService } from '../../../services/deporte-escolar.service';
import { getTitle } from 'src/app/utils/breadcrumbs';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css'],
})
export class InfoComponent {
  convocatorias = [];

  constructor(
    private deporteEscolarService: DeporteEscolarService,
    private breadCrumbs: BreadcrumbService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(getTitle('Información y convocatorias'));
    this.breadCrumbs.addCurrentBreadcrumb();
    this.deporteEscolarService.getConvocatorias().subscribe((data: any) => {
      this.convocatorias = data.data;
    });
  }
}
