import { Component, OnInit } from '@angular/core';
import { PreciosServices } from '../../services/precios.service';
import { catchError, of, switchMap, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PreciosPublicos } from '../../../interfaces/PreciosPublicosResponse';
import { ApiStatus } from '../../../interfaces';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { Title } from '@angular/platform-browser';
import { ActivityDetailsComponent } from '../../../components/book-facility/activity-details/activity-details.component';

@Component({
  selector: 'app-precio-id',
  templateUrl: './precio-id.component.html',
  styleUrls: ['./precio-id.component.css'],
})
export class PrecioIdComponent implements OnInit {
  precio: PreciosPublicos | null = null;
  apiStatus: ApiStatus = { loading: false, error: true };

  constructor(
    private preciosService: PreciosServices,
    private route: ActivatedRoute,
    private breadCrumbService: BreadcrumbService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap(params => {
          this.apiStatus = { loading: true, error: false };
          this.preciosService
            .getPrecioPublicoById(params['id'])
            .pipe(
              catchError(err => {
                this.apiStatus = { loading: false, error: true };
                return of();
              }),
              tap(value => {
                this.precio = value.data[0];
                this.apiStatus = { loading: false, error: false };
                this.titleService.setTitle(this.precio.title);
                this.breadCrumbService.addCurrentBreadcrumb();
              })
            )
            .subscribe();
          return of(params);
        })
      )
      .subscribe();
  }
}
