import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Ejercicio, LineaEjercicio, UsuarioRutina } from '../../models/ejercicios.model';
import { TablaFormConverterService } from '../../converters/tabla-form/tabla-form.converter.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { EjercicioDetailsComponent } from '../ejercicio-details/ejercicio-details.component';
import { ModalService } from '../../../services/modal/modal.service';
import { tap } from 'rxjs';
import { AgregarUsuariosComponent } from '../agregar-usuarios/agregar-usuarios.component';
import { TablaFormService } from '../../services/tabla-form/tabla-form.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tabla-ejercicios-form-group',
  templateUrl: './tabla-ejercicios-form-group.component.html',
  styleUrls: ['./tabla-ejercicios-form-group.component.css'],
  providers: [ModalService],
})
export class TablaEjerciciosFormGroupComponent {
  @Input() tablaFormGroup: UntypedFormGroup;

  @Input() allEjercicios: Ejercicio[];

  @Input() isSavingOrLoading: boolean = false;

  @Output() saveRutina = new EventEmitter();

  constructor(
    private tablaFormService: TablaFormService,
    private tablaFormConverterService: TablaFormConverterService,
    private modalService: ModalService,
    private router: Router
  ) {}

  lineaEjercicioFormGroup(lineaEjercicioFormGroup: AbstractControl): FormGroup {
    return lineaEjercicioFormGroup as FormGroup;
  }

  showEjercicio(index: number) {
    const inputs = { index: index, ejercicios: this.AllEjerciciosAsObjects };
    const outputs = ['cerrar'];

    this.modalService
      .showComponent(EjercicioDetailsComponent, inputs, outputs)
      .pipe(
        tap(res => {
          if (res?.cerrar) {
            this.modalService.removeComponent();
          }
        })
      )
      .subscribe();
  }

  deleteLinea(index: number) {
    const linea = this.lineasEjerciciosFormGroup?.at(index)?.getRawValue();
    const lineaFormGroup = this.lineasEjerciciosFormGroup.at(index) as FormGroup;
    if (linea?.id) {
      this.lineasEjerciciosDeleteFormGroup.push(lineaFormGroup);
      this.lineasEjerciciosFormGroup.removeAt(index);
    } else {
      this.lineasEjerciciosFormGroup.removeAt(index);
    }
  }

  onClickSaveRutina() {
    this.saveRutina.emit();
  }

  cancel() {
    this.router.navigate(['/user-profile']);
  }

  openModalUsers() {
    this.modalService
      .showComponent(
        AgregarUsuariosComponent,
        { usuariosAgregados: this.usuariosFormArray.getRawValue() },
        ['onSave']
      )
      .pipe(
        tap(res => {
          if (res?.onSave) {
            this.updateListUsuarios(res?.onSave);
          }
          this.modalService.removeComponent();
        })
      )
      .subscribe();
  }

  drop(event: CdkDragDrop<any>) {
    if (event?.container?.id == event?.previousContainer?.id) {
      const element = this.lineasEjerciciosFormGroup?.at(event?.previousIndex);
      this.lineasEjerciciosFormGroup?.removeAt(event?.previousIndex);
      this.lineasEjerciciosFormGroup?.insert(event?.currentIndex, element);
    } else if (event?.item?.data) {
      if (event?.container?.id != event?.previousContainer?.id) {
        this.lineasEjerciciosFormGroup?.insert(
          event?.currentIndex,
          this.getLineaEjercicioFormFromData(event?.item?.data)
        );
      }
    }
  }

  getLineaEjercicioFormFromData(ejercicio: Ejercicio): UntypedFormGroup {
    const lineaEjercicio: LineaEjercicio = {
      id: null,
      peso: null,
      tiempo: null,
      title: null,
      series: null,
      repeticiones: null,
      observaciones: null,
      ejercicio: ejercicio,
    };
    return this.tablaFormConverterService.buildLineaEjercicioFormGroup(lineaEjercicio);
  }

  updateListUsuarios(usuarios: UsuarioRutina[]): void {
    const usuariosTabla = this.usuariosFormArray.getRawValue();
    this.usuariosFormArray.clear();
    const usuariosToDelete = usuariosTabla.filter(
      user => !usuarios.some(userAdd => userAdd?.Id === user?.id) && user?.idRutina
    );

    const usuariosAdd = usuarios.map(user => {
      const alreadyAdd = usuariosTabla.find(us => us?.id === user?.Id);
      if (alreadyAdd) return alreadyAdd;
      else return user;
    });

    usuariosToDelete.forEach(user => {
      const newForm = this.tablaFormConverterService.buildUsuarioFormGroup(user);
      this.usuariosDeleteFormArray.push(newForm);
    });

    usuariosAdd.forEach(user => {
      const newForm = this.tablaFormConverterService.buildUsuarioFormGroup(user);
      this.usuariosFormArray.push(newForm);
    });
  }

  get AllEjerciciosAsObjects(): any[] {
    const lineas = this.lineasEjerciciosFormGroup.getRawValue().map(linea => ({
      observaciones: linea?.observaciones,
      ...linea?.ejercicio,
    }));

    // Solo si se desean mostrar un ejercicio una única vez
    const lineasUnicas = lineas.reduce((acc, linea) => {
      acc[linea?.title] = linea;
      return acc;
    }, {});

    return Object.values(lineasUnicas);
  }

  get lineasEjerciciosFormGroup(): UntypedFormArray {
    return this.tablaFormGroup.get('lineasEjercicio') as UntypedFormArray;
  }

  get lineasEjerciciosDeleteFormGroup(): UntypedFormArray {
    return this.tablaFormGroup.get('lineasDelete') as UntypedFormArray;
  }

  get usuariosFormArray(): UntypedFormArray {
    return this.tablaFormService.rutinaForm.get('usuarios') as UntypedFormArray;
  }
  get usuariosDeleteFormArray(): UntypedFormArray {
    return this.tablaFormService.rutinaForm.get('usuariosDelete') as UntypedFormArray;
  }
}
