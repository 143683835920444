import { Component, Input } from '@angular/core';
import { Clase } from '../../services/clases/clases.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-clases-bundle',
  templateUrl: './clases-bundle.component.html',
  styleUrls: ['./clases-bundle.component.css'],
})
export class ClasesBundleComponent {
  @Input() clases: Clase[] = [];

  getDatesForCalendar() {
    return this.clases.map((clase, index) => {
      const date = dayjs(clase.fecha.value);
      return {
        year: date.year(),
        month: date.month(),
        date: date.date(),
        colorIndex: index,
      };
    });
  }
}
