<main (click)="onCardClick(data.id)" *ngIf="isCardView; else listView">
    <h2>{{title}}</h2>
    <section >
        <ng-container *ngFor="let column of cardColumns">
            <ng-container [ngSwitch]="column.type">
                <b>{{column.name}}</b>
                <ng-container *ngSwitchCase="ListListerColumnType.String">
                    <p>{{data[column.prop]}}</p>
                </ng-container>
                <ng-container *ngSwitchCase="ListListerColumnType.Boolean">
                    <p>{{data[column.prop] ? 'Sí' : 'No'}}</p>
                </ng-container>
                <ng-container *ngSwitchCase="ListListerColumnType.Array">
                    <aside>
                        <ng-container *ngFor="let item of data[column.prop]">
                                <span>{{item.title}}</span>
                        </ng-container>
                    </aside>
                </ng-container>
                <ng-container *ngSwitchCase="ListListerColumnType.Date">
                    <p>{{data[column.prop] | date}}</p>
                </ng-container>
                <ng-container *ngSwitchCase="ListListerColumnType.Number">
                    <p>{{data[column.prop] | number}}</p>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <span>Error: columna no implementada</span>
                </ng-container>
            </ng-container>
        </ng-container>
    </section>

</main>
<ng-template #listView>
        <td>
            <h2 ngClass="title">{{title}}</h2>
        </td>
        <ng-container *ngFor="let column of listColumns">
            <td>
                <ng-container [ngSwitch]="column.type">
                    <ng-container *ngSwitchCase="ListListerColumnType.String">
                        {{data[column.prop]}}
                    </ng-container>
                    <ng-container *ngSwitchCase="ListListerColumnType.Boolean">
                        {{data[column.prop] ? 'Sí' : 'No'}}
                    </ng-container>
                    <ng-container *ngSwitchCase="ListListerColumnType.Array">
                        <ng-container *ngIf="data[column.prop].length > 0">
                            <ng-container *ngFor="let item of data[column.prop]">
                                <span>{{item.title}}</span>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="ListListerColumnType.Date">
                        {{data[column.prop] | date}}
                    </ng-container>
                    <ng-container *ngSwitchCase="ListListerColumnType.Number">
                        {{data[column.prop] | number}}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <span>Error: columna no implementada</span>
                    </ng-container>
                </ng-container>
            </td>
        </ng-container>
</ng-template>