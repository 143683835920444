<main>
  <ul>
    <ng-container *ngFor="let tab of tabs; let i = index">
      <li [ngClass]="i === selectedIndex ? 'selected' : ''" (click)="setSelectedIndex(i)">
        {{ tab }}
      </li>
    </ng-container>
  </ul>

  <ng-container [ngSwitch]="selectedIndex">
    <ng-container *ngSwitchCase="0">
      <app-clases-bundle [clases]="clases" />
    </ng-container>
    <ng-container *ngSwitchCase="1">
      <app-tabla-bundle [rutinas]="rutinas" />
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <app-users-bundle [users]="users" />
    </ng-container>
  </ng-container>
</main>