<app-page-with-breadcrumb>
  <app-pages-loader *ngIf="apiStatus.loading" [Apierror]="apiStatus.error"></app-pages-loader>
  <div *ngIf="!apiStatus.loading && !apiStatus.error">
    <section *ngFor="let actividad of actividades">
      <div class="pb-3" *ngIf="actividad.length > 0">
        <h2 class="text-2xl font-aldo text-primary">{{ actividad[0].actividad.name | uppercase }}</h2>
        <hr />
        <h6
          [routerLink]="'/precios-publicos/' + precio.id"
          class="ml-2 p-2 hover:border hover:cursor-pointer"
          *ngFor="let precio of actividad"
        >
          {{ precio.title | uppercase }}
        </h6>
      </div>
    </section>
  </div>
</app-page-with-breadcrumb>
