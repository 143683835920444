export const menu = [
  {
    title: 'Inicio',
    path: '/',
    icon: 'inicio',
    stroke: 'none',
    fill: 'var(--wine-red)',
  },
  {
    title: 'Instalaciones',
    path: '/instalaciones',
    icon: 'instalaciones',
    stroke: 'none',
    fill: 'var(--wine-red)',
  },
  {
    title: 'Deportes',
    path: '/deportes',
    icon: 'deporte',
    stroke: 'none',
    fill: 'var(--wine-red)',
  },
  {
    title: 'Deporte escolar',
    path: '/deporte-escolar',
    icon: 'escuelas',
    fill: 'none',
    stroke: 'var(--wine-red)',
  },
  {
    title: 'Noticias',
    path: '/noticias',
    icon: 'noticias',
    stroke: 'var(--wine-red)',
    fill: 'none',
  },
  {
    title: 'Convocatorias',
    path: '/convocatorias',
    icon: 'convocatorias',
    stroke: 'none',
    fill: 'var(--wine-red)',
  },
  {
    title: 'Inscripciones',
    path: '/inscripciones',
    icon: 'lapiz',
    stroke: 'none',
    fill: 'var(--wine-red)',
  },
  {
    title: 'Rutas',
    path: '/rutas',
    icon: 'running',
    stroke: 'none',
    fill: 'var(--wine-red)',
  },
  {
    title: 'Precios Públicos',
    path: '/precios-publicos',
    icon: 'money',
    stroke: 'none',
    fill: 'var(--wine-red)',
  },
  {
    title: 'Monitor',
    path: '/monitor/perfil',
    icon: '/ejercicios/pecho',
    stroke: 'none',
    fill: 'var(--wine-red)',
  },
];
