import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablasEjerciciosPage } from './pages/tablas-ejercicios/tablas-ejercicios.page';
import { NuevaTablaEjerciciosPage } from './pages/nueva-tabla-ejercicios/nueva-tabla-ejercicios.page';
import { EditarTablaEjerciciosPage } from './pages/editar-tabla-ejercicios/editar-tabla-ejercicios.page';
import { MonitorRoutingModule } from './monitor-routing.module';
import { LineaEjercicioComponent } from './components/linea-ejercicio/linea-ejercicio.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BusquedaEjerciciosComponent } from './components/busqueda-ejercicios/busqueda-ejercicios.component';
import { LayoutsModule } from '../layouts/layouts.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TablaFormGroupComponent } from './components/tabla-form-group/tabla-form-group.component';
import { LineaUsuarioComponent } from './components/linea-usuario/linea-usuario.component';
import { TablaEjerciciosFormGroupComponent } from './components/tabla-ejercicios-form-group/tabla-ejercicios-form-group.component';
import { TablaUsuariosFormGroupComponent } from './components/tabla-usuarios-form-group/tabla-usuarios-form-group.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { PerfilResumenComponent } from './components/perfil-resumen/perfil-resumen.component';
import { PerfilTableComponent } from './components/perfil-table/perfil-table.component';
import { TablaLineaComponent } from './components/perfil/tabla-linea/tabla-linea.component';
import { TablaBundleComponent } from './components/perfil/tabla-bundle/tabla-bundle.component';
import { EjercicioDetailsComponent } from './components/ejercicio-details/ejercicio-details.component';
import { ReproductorVideoComponent } from './components/reproductor-video/reproductor-video.component';
import { ReproductorVideoSlideComponent } from './components/reproductor-video-slide/reproductor-video-slide.component';
import { NgxMaskDirective, IConfig, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { BusquedaUsuariosComponent } from './components/busqueda-usuarios/busqueda-usuarios.component';
import { AgregarUsuariosComponent } from './components/agregar-usuarios/agregar-usuarios.component';
import { AgregarUsuariosLineaUsuarioComponent } from './components/agregar-usuarios-linea-usuario/agregar-usuarios-linea-usuario.component';
import { UsersTableModalComponent } from './components/users-table-modal/users-table-modal.component';
import { UserLineComponent } from './components/user-line/user-line.component';
import { MonitorProfileFormComponent } from './components/monitor-profile-form/monitor-profile-form.component';
import { ClasesBundleComponent } from './components/clases-bundle/clases-bundle.component';
import { ClasesLineaComponent } from './components/clases-linea/clases-linea.component';
import { ToacronymPipe } from '../pipes/toacronym.pipe';
import { CalendarSelectorComponent } from './components/calendar-selector/calendar-selector.component';
import { AgregarObservacionComponent } from './components/agregar-observacion/agregar-observacion.component';
import { PlanningCalendarModalComponent } from './components/planning-calendar-modal/planning-calendar-modal.component';
import { PlanningRoutineLineComponent } from './components/planning-routine-line/planning-routine-line.component';
import { PlanningRoutineInfoComponent } from './components/planning-routine-info/planning-routine-info.component';
import { UsersBundleComponent } from './components/perfil/users-bundle/users-bundle.component';
import { UsersMonitorLineComponent } from './components/perfil/users-monitor-line/users-monitor-line.component';
import { PerfilModalComponent } from './components/perfil/perfil-modal/perfil-modal.component';

const maskConfig: Partial<IConfig> = {
  validation: true,
};

@NgModule({
  declarations: [
    TablasEjerciciosPage,
    NuevaTablaEjerciciosPage,
    EditarTablaEjerciciosPage,
    LineaEjercicioComponent,
    BusquedaEjerciciosComponent,
    TablaFormGroupComponent,
    LineaUsuarioComponent,
    TablaEjerciciosFormGroupComponent,
    TablaUsuariosFormGroupComponent,
    PerfilComponent,
    PerfilResumenComponent,
    PerfilTableComponent,
    TablaLineaComponent,
    TablaBundleComponent,
    EjercicioDetailsComponent,
    ReproductorVideoComponent,
    ReproductorVideoSlideComponent,
    BusquedaUsuariosComponent,
    AgregarUsuariosComponent,
    AgregarUsuariosLineaUsuarioComponent,
    UsersTableModalComponent,
    UserLineComponent,
    MonitorProfileFormComponent,
    ClasesBundleComponent,
    ClasesLineaComponent,
    ToacronymPipe,
    CalendarSelectorComponent,
    AgregarObservacionComponent,
    PlanningCalendarModalComponent,
    PlanningRoutineLineComponent,
    PlanningRoutineInfoComponent,
    UsersBundleComponent,
    UsersMonitorLineComponent,
    PerfilModalComponent,
  ],
  imports: [
    CommonModule,
    MonitorRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    LayoutsModule,
    DragDropModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [PerfilComponent],
  providers: [provideNgxMask(maskConfig)],
})
export class MonitorModule {}
