<app-page-with-breadcrumb>
    <app-tab-slider [(activeIndex)]="activeIndex" [tabs]="tabs" />
    <ng-container [ngSwitch]="activeIndex">
      <main *ngSwitchCase="0">
        <app-escuelas-deportivas />
      </main>
      <main *ngSwitchCase="1">
        <app-competiciones />
      </main>
      <main *ngSwitchCase="2">
          <app-info />
      </main>
        <main *ngSwitchCase="3">
          <app-memoria />
        </main>
        <main *ngSwitchCase="4">
            <app-concursos />
        </main>
        <main *ngSwitchDefault>
            <app-descargas />
        </main>
    </ng-container>
</app-page-with-breadcrumb>
