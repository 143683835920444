import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RememberPasswordService {
  constructor(private http: HttpClient) {}

  rememberPasswordStepOne(data: any) {
    return this.http.post(
      environment.apiBackGetUrl + '/api/v1/deporwin/auth/rememberPasswordStepOne',
      data,
      { withCredentials: true }
    );
  }

  rememberPasswordStepTwo(data: any) {
    return this.http.post(
      environment.apiBackGetUrl + '/api/v1/deporwin/auth/rememberPasswordStepTwo',
      data,
      { withCredentials: true }
    );
  }
}
