import { Component, Input } from '@angular/core';
import { User } from '../perfil-modal/perfil-modal.component';

@Component({
  selector: 'app-users-bundle',
  templateUrl: './users-bundle.component.html',
  styleUrls: ['./users-bundle.component.css'],
})
export class UsersBundleComponent {
  @Input() users: User[] = [];

  constructor() {}
}
