import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreciosPublicosComponent } from './pages/precios-publicos/precios-publicos.component';
import { SharedModule } from '../shared/shared.module';
import { PrecioIdComponent } from './pages/precio-id/precio-id.component';
import { PreciosPublicosRoutingModule } from './precios-publicos-routing.module';
import { LayoutsModule } from '../layouts/layouts.module';
import { DeporteEscolarModule } from '../deporte-escolar/deporte-escolar.module';

@NgModule({
  declarations: [PreciosPublicosComponent, PrecioIdComponent],
  imports: [
    CommonModule,
    SharedModule,
    PreciosPublicosRoutingModule,
    LayoutsModule,
    DeporteEscolarModule,
  ],
})
export class PreciosPublicosModule {}
