import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { DatetimeType } from 'src/app/form/datetime-picker/datetime-picker.component';
import { ModalSuccessService } from 'src/app/services/modal-success.service';
import { RememberPasswordService } from 'src/app/services/remember-password.service';

@Component({
  selector: 'app-remember-password',
  templateUrl: './remember-password.component.html',
  styleUrls: ['./remember-password.component.css'],
})
export class RememberPasswordComponent {
  protected readonly dayjs = dayjs;
  protected readonly DatetimeType = DatetimeType;
  currentStep = 1;

  constructor(
    private formBuilder: FormBuilder,
    private rememberPasswordService: RememberPasswordService,
    private router: Router,
    private modalService: ModalSuccessService
  ) {}
  formularioRecuperarContrasena = this.formBuilder.group({
    DocumentoIdentidad: [null, Validators.required],
    Email: [null, Validators.required],
    FechaNacimiento: [null, Validators.required],
  });

  formularioPIN = this.formBuilder.group({
    PIN: [null, Validators.required],
    NuevaContrasena: [null, Validators.required],
    ConfirmarContrasena: [null, Validators.required],
  });

  submitRecuperarContrasena() {
    this.rememberPasswordService
      .rememberPasswordStepOne(this.formularioRecuperarContrasena.value)
      .subscribe(
        response => {
          this.nextStep();
        },
        error => {
          console.error(error);
        }
      );
  }

  submitPIN() {
    this.rememberPasswordService
      .rememberPasswordStepTwo({
        Contraseña: this.formularioPIN.value.NuevaContrasena,
        PIN: this.formularioPIN.value.PIN,
      })
      .subscribe(
        (response: any) => {
          if (response.PasswordChanged) {
            this.modalService.showModal('Contraseña recuperada', 2500);
            this.router.navigate(['/login']);
          }
        },
        error => {
          console.error(error);
        }
      );
  }

  nextStep() {
    this.currentStep++;
  }

  prevStep() {
    this.currentStep--;
  }
}
