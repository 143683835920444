import { Component, Input } from '@angular/core';
import { Aviso } from '../../../interfaces/AvisoResponse';
import { ApiStatus } from '../../../interfaces';
import { getDescripcion } from '../../../utils/text';

@Component({
  selector: 'app-avisos',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.css'],
})
export class AvisosComponent {
  @Input() avisos: Aviso[] = [];
  @Input() apiStatus?: ApiStatus;

  currentPageData: any[] = [];
  handleEmitData(data: any[]) {
    this.currentPageData = data;
  }
  protected readonly getDescripcion = getDescripcion;
}
