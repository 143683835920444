import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Ruta, RutasResponse, rutaToCSVConverter } from '../../../interfaces/RutasResponse';
import { RutasService } from '../../service/rutas.service';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { ActivatedRoute } from '@angular/router';
import { catchError, of, switchMap } from 'rxjs';
import { ApiStatus } from '../../../interfaces';
import { LngLatLike } from 'mapbox-gl';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as turf from '@turf/turf';
import { Title } from '@angular/platform-browser';
import { getTitle } from 'src/app/utils/breadcrumbs';
import { ExportCsvService } from 'src/app/services/export-csv.service';

// TODO Usar el FilterForm para tipar el formulario
interface FilterForm {
  distance: string;
  route: string;
  difficulty: string;
}

type GeoJSONFeature = {
  type: 'Feature';
  properties: Record<string, unknown>;
  geometry: {
    coordinates: number[] | number[][];
    type: 'Point' | 'LineString';
  };
  id?: number;
};

@Component({
  selector: 'app-rutas',
  templateUrl: './rutas.component.html',
  styleUrls: ['./rutas.component.css'],
})
export class RutasComponent implements OnInit {
  type = {
    urbano: 'urbano',
    monte: 'monte',
    huerta: 'huerta',
    otro: 'otro',
  };

  radioOptions = [
    { label: 'Novel', value: 'novel' },
    { label: 'Fácil', value: 'facil' },
    { label: 'Medio', value: 'medio' },
    { label: 'Difícil', value: 'dificil' },
  ];

  center: LngLatLike = [-1.13004, 37.98704];
  zoom: number = 14;
  searchInput: string = '';
  routeList: Ruta[] = [];
  originalRouteList: Ruta[] = [];
  apiStatus: ApiStatus = { loading: false, error: false };
  open: string = '';
  loading = true;
  length = 0;
  distancias: { label: string; value: any }[] = [
    { label: 'Por debajo de 0.5 Km', value: { min: 0, max: 500 } },
    { label: 'Entre 0.5 y 1 Km', value: { min: 500, max: 1000 } },
    { label: 'Entre 1 y 2.5 Km', value: { min: 1000, max: 2500 } },
    { label: 'Más de 2.5 Km', value: { min: 2500, max: 1000000 } },
  ];

  currentPageData: any[] = [];
  handleEmitData(data: any[]) {
    this.currentPageData = data;
  }

  constructor(
    private rutasService: RutasService,
    private breadCrumbService: BreadcrumbService,
    private titleService: Title,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private exportCsvService: ExportCsvService
  ) {}

  filterForm: FormGroup = this.fb.group({
    distance: [{ min: 0, max: 1000000 }],
    route: [''],
    difficulty: [''],
    urbano: [false],
    monte: [false],
    huerta: [false],
    otro: [false],
  });

  ngOnInit() {
    this.titleService.setTitle(getTitle('Rutas'));
    this.filterForm.valueChanges.subscribe(() => {
      this.filterRoutes();
      this.cdr.detectChanges();
    });
    this.breadCrumbService.addCurrentBreadcrumb();
    this.apiStatus = { loading: true, error: false };

    this.getRoutes();

    this.route.queryParams
      .pipe(
        switchMap(params => {
          if (params['tipo']) {
            this.open = params['tipo'];
          } else {
            this.open = '';
          }
          return of();
        })
      )
      .subscribe();
  }

  getRoutes() {
    this.rutasService
      .getRutas(100)
      .pipe(
        catchError(err => {
          this.apiStatus = { loading: false, error: true };
          this.routeList = [];
          return of();
        }),
        switchMap((data: RutasResponse) => {
          this.apiStatus = { loading: false, error: false };
          const routes = data.data.map(route => {
            const length = this.calculateLength(route);
            return { ...route, length };
          });
          this.routeList = routes;
          this.originalRouteList = routes;
          return of();
        })
      )
      .subscribe();
  }

  calculateLength(ruta: Ruta): number {
    let length = 0;
    if (ruta.geojson) {
      const lineString = ruta.geojson?.features.filter(
        feature => feature.geometry.type === 'LineString'
      )[0];

      if (lineString?.geometry.type === 'LineString') {
        const lineFeature: GeoJSONFeature = {
          type: 'Feature',
          properties: {},
          geometry: lineString.geometry,
        };
        length = Math.round(turf.length(lineFeature, { units: 'meters' }));
      }
    }
    return length;
  }

  filterRoutes() {
    this.routeList = this.originalRouteList.filter(
      route =>
        this.filterByType(route) &&
        this.filterByDifficulty(route) &&
        this.filterByName(route) &&
        this.filterByDistance(route)
    );
  }

  filterByDistance(route: Ruta) {
    if (this.filterForm.get('distance')?.value === null) {
      return true;
    }
    return route.length
      ? route.length >= this.filterForm.value.distance.min &&
          route.length <= this.filterForm.value.distance.max
      : true;
  }

  filterByName(route: Ruta) {
    const searchParameter = [
      route.titleslug,
      route.dificultad,
      route.tipo_recorrido,
      route.inicio,
      route.tipo_suelo,
    ];
    return searchParameter.some(parameter =>
      parameter.toLowerCase().includes(this.filterForm.value.route.toLowerCase())
    );
  }

  filterByDifficulty(route: Ruta) {
    return route.dificultad.toLowerCase().includes(this.filterForm.value.difficulty.toLowerCase());
  }

  filterByType(route: Ruta) {
    const { urbano, monte, huerta, otro } = this.filterForm.value;
    const routeToFilter: string = route.tipo_recorrido.toLowerCase();

    if (!(urbano || monte || huerta || otro)) return true;

    return (
      routeToFilter.includes(urbano) ||
      routeToFilter.includes(monte) ||
      routeToFilter.includes(huerta) ||
      routeToFilter.includes(otro)
    );
  }

  clearSelection() {
    this.filterForm.reset({
      distance: null,
      route: '',
      difficulty: '',
      urbano: false,
      monte: false,
      huerta: false,
      otro: false,
    });
  }

  CSVdownload() {
    this.exportCsvService.generateXLSX(rutaToCSVConverter(this.routeList), 'rutas.xlsx');
  }
}
