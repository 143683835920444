import { Component, Input } from '@angular/core';
import { CompeticionData } from '../../../services/deporte-escolar.service';

@Component({
  selector: 'app-competicion-detail',
  templateUrl: './competicion-detail.component.html',
  styleUrls: ['./competicion-detail.component.css'],
})
export class CompeticionDetailComponent {
  @Input() competicion!: CompeticionData;
}
