import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FacilitiesService } from 'src/app/services/facilities.service';
import { getTitle } from 'src/app/utils/breadcrumbs';
import { SharedDataService } from 'src/app/components/book-facility/service/book-facility.service';

@Component({
  selector: 'app-book-facility',
  templateUrl: './book-facility.component.html',
  styleUrls: ['./book-facility.component.css'],
})
export class BookFacilityComponent implements OnInit {
  resources: any;
  numberSteps: number = 0;
  currentStep: number = 1;

  facilityId: string | null = null;
  facility: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private facilitiesService: FacilitiesService,
    private sharedDataService: SharedDataService
  ) {
    this.titleService.setTitle(getTitle('Reserva de instalaciones'));
    this.facilityId = this.route.snapshot.params['id'];
    this.sharedDataService.currentStep.subscribe(step => {
      this.currentStep = step;
    });
  }
  ngOnInit() {
    this.currentStep = 1;
    this.facilitiesService.getFacilityResource().subscribe((data: any) => {
      this.facility = data.find(
        (resource: any) => resource.Id.toString() === this.facilityId
      ).Descripcion;
    });
  }
}
