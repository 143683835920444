import { Component, OnInit } from '@angular/core';
import { Ejercicio } from '../../models/ejercicios.model';
import { ActivatedRoute, Router } from '@angular/router';
import { TablaFormService } from '../../services/tabla-form/tabla-form.service';
import { UntypedFormGroup } from '@angular/forms';
import { catchError, of, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { Title } from '@angular/platform-browser';
import { ModalSuccessService } from '../../../services/modal-success.service';

export const DEFAULT_VALUE_REPEATS: number = 15;
export const DEFAULT_VALUE_SERIES: number = 3;
export const DEFAULT_VALUE_LOAD = 10;

@Component({
  selector: 'app-nueva-tabla-ejercicios',
  templateUrl: './nueva-tabla-ejercicios.page.html',
  styleUrls: ['./nueva-tabla-ejercicios.page.css'],
  providers: [TablaFormService],
})
export class NuevaTablaEjerciciosPage implements OnInit {
  allEjercicios: Ejercicio[] = [];

  overTabsErrorMessages: { message: string }[] = [];
  savingOrLoading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private breadCrumbService: BreadcrumbService,
    private titleService: Title,
    private modalSuccessService: ModalSuccessService,
    private tablaFormService: TablaFormService
  ) {
    this.tablaFormService.intialize();
  }

  ngOnInit() {
    this.titleService.setTitle('Nueva Tabla');
    this.breadCrumbService.addCurrentBreadcrumb();
    this.activatedRoute.data.subscribe(({ initialData }) => {
      this.allEjercicios = initialData?.ejercicios || [];
    });
  }

  saveRutina() {
    this.overTabsErrorMessages = [];

    this.modalSuccessService.showModal('Guardando...', 0);
    this.savingOrLoading = true;

    this.tablaFormService
      .saveRutina()
      .pipe(
        tap((value: any) => {
          if (Array.isArray(value)) {
            this.overTabsErrorMessages = value;
          } else {
            this.router.navigate(['/monitor/perfil']);
          }
        }),
        catchError((err: HttpErrorResponse) => {
          return this.handleCommonErrors(err);
        })
      )
      .subscribe({
        complete: () => {
          this.modalSuccessService.hideModal();
          this.savingOrLoading = false;
        },
      });
  }

  handleCommonErrors(error: HttpErrorResponse) {
    this.overTabsErrorMessages = [];
    if (this.ejercicioNotFound(error)) {
      this.overTabsErrorMessages.push({
        message: 'No existe uno de los ejercicios seleccionados.',
      });
    }

    return of(null);
  }

  ejercicioNotFound(err: HttpErrorResponse): boolean {
    return err.status === 404 && err.error && err.error.message === 'Not Found';
  }

  get rutinaFormGroup(): UntypedFormGroup {
    return this.tablaFormService.rutinaForm as UntypedFormGroup;
  }
}
