<div class="video-container">
  <video #videoPlayer>
    <source [src]="src" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  <div *ngIf="hasValidSource">
    <div *ngIf="videoError" class="error-message">Error cargando el video, inténtelo luego</div>
    <div *ngIf="!videoError && !videoLoaded" class="loading-message">Cargando video...</div>

    <div class="controls" *ngIf="!videoError && videoLoaded">
      <app-reproductor-video-slide [value]="currentTimeValue" (valueChange)="seek($event)" />
      <div class="buttons-container">
        <div class="play-button">
          <app-button (onClick)="playPause()" [icon]="playing ? 'pause' : 'play'" fillColors="white"
            class="controlButton" />
        </div>
        <div class="play-button">
          <app-button (onClick)="mute()" [icon]="isMute ? 'mute' : 'volumen'" strokeColors="white" fillColors="white"
            class="controlButton" />
        </div>
        <app-reproductor-video-slide [value]="volume" (valueChange)="changeVolume($event)" [canMoveSlide]="true" />
      </div>
    </div>
  </div>
  <div *ngIf="!hasValidSource" class="error-message">Sin fuente de video</div>
</div>