<div class="w-full h-full m-auto flex flex-row py-2">
  <div class="flex flex-col w-full h-full m-auto">
    <div id="slider" class="whitespace-nowrap overflow-hidden w-full h-full">
      <div [id]="'convocatoria-'+i" *ngFor="let convocatoria of convocatorias; let i = index" class="inline-block overflow-hidden h-full text-white m-auto transition-all duration-300 ease-in text-end"
           [ngClass]="i!=indexSelected?'w-0':'w-full'">
        <div class="flex flex-col w-full h-full m-auto align-middle">
          <h2 class="line-clamp-1 overflow-ellipsis">{{convocatoria.title}}</h2>
          <div class="flex flex-row space-x-1 justify-end" >
            <p *ngIf="convocatoria.descripcion" class="overflow-hidden text-xs h-5 w-2/3" [innerHTML]="convocatoria.descripcion|safeHtml"></p>
            <a [routerLink]="'/noticias/'+convocatoria.id" class="text-primary text-xs font-extrabold">+ info</a>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col justify-end w-full h-[10%] m-auto">
      <app-dot-navigation [length]="convocatorias.length" [current]="0" (changeIndex)="indexChange($event)" estilos="justify-end items-end align-bottom"></app-dot-navigation>
    </div>
  </div>
</div>
