import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { InscriptionsService } from 'src/app/services/inscriptions.service';
import { getTitle } from 'src/app/utils/breadcrumbs';

@Component({
  selector: 'app-inscriptions-info',
  templateUrl: './inscriptions-info.component.html',
  styleUrls: ['./inscriptions-info.component.css'],
})
export class InscriptionsInfoComponent implements OnInit {
  inscriptionsList: any;
  attachedFiles: string[] = [];
  AtachedFilesLinks: string[] = [];

  constructor(
    private inscriptionsService: InscriptionsService,
    private breadCrumbs: BreadcrumbService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.inscriptionsService.getInscriptions().subscribe(inscriptions => {
      this.inscriptionsList = (inscriptions as any).data;
      for (let inscription of this.inscriptionsList) {
        for (let archivo of inscription.archivos_adjuntos) {
          this.attachedFiles.push(archivo.uri.url);
        }
      }
      this.AtachedFilesLinks = this.attachedFiles.map(path =>
        this.inscriptionsService.getAtachedFiles(path)
      );
    });
  }
}
