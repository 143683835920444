import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { map, merge, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private componentRef: ComponentRef<any>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  showComponent<T>(component: Type<T>, inputs: any = {}, outputs: string[] = []): Observable<any> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.componentRef = componentFactory.create(this.injector);

    if (inputs) {
      Object.keys(inputs).forEach(key => {
        this.componentRef.instance[key] = inputs[key];
      });
    }

    const outputSubjects: { [key: string]: Subject<any> } = {};

    if (outputs) {
      outputs.forEach(key => {
        outputSubjects[key] = new Subject<any>();
        this.componentRef.instance[key].subscribe(outputSubjects[key]);
      });
    }

    this.appRef.attachView(this.componentRef.hostView);
    const domElem = (this.componentRef.hostView as any).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    return merge(
      ...Object.keys(outputSubjects).map(key =>
        outputSubjects[key].pipe(map(value => ({ [key]: value })))
      )
    );
  }

  removeComponent() {
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
    }
  }
}
