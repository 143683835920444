import { Injectable } from '@angular/core';
import { Ejercicio, LineaEjercicio, UsuarioRutina } from '../../models/ejercicios.model';
import { usuarios } from '../../mock/tabla.mock';
import { getMostUsedIcon } from '../../utils/icons';

export interface RutinaApi {
  tabla: TablaApi;
  usuarios: UsuarioRutina[];
  usuariosDelete?: UsuarioRutina[];
}

export interface TablaApi {
  id?: string;
  rutinaId?: number;
  title: string;
  observaciones: string;
  plantilla: boolean;
  idMonitor: string;
  icon?: string;
  lineasEntrada: LineaEjercicio[];
  lineasDelete?: LineaEjercicio[];
}

@Injectable({
  providedIn: 'root',
})
export class TablasConverterService {
  constructor() {}

  fromDto(rutinaDto: any, ejercicios: Ejercicio[]): RutinaApi {
    const tablaModelo = rutinaDto[0]?.tabla;
    const tablaApi: TablaApi = {
      id: tablaModelo?.id,
      rutinaId: rutinaDto[0]?.id,
      title: tablaModelo?.title,
      observaciones: tablaModelo?.observaciones,
      plantilla: tablaModelo?.plantilla,
      icon: getMostUsedIcon(tablaModelo?.lineas),
      idMonitor: tablaModelo?.idMonitor,
      lineasEntrada: tablaModelo?.lineas
        ? tablaModelo.lineas.map(linea => this.lineaEjercicioFromDto(linea, ejercicios))
        : [],
    };

    const usuariosRutina: UsuarioRutina[] = [];
    rutinaDto.forEach((rutina: any) => {
      //TODO: Esto se supone que venga de Deporwin, Aqui me los estoy inventando
      const usuarioRutina = rutina?.usuario
        ? usuarios.find(usuario => usuario?.Id === rutina?.usuario)
        : null;
      if (usuarioRutina) {
        usuariosRutina.push({
          ...usuarioRutina,
          idRutina: rutina.id,
          observaciones: rutina?.observaciones,
          fechas: rutina?.fechas,
        });
      }
      //TODO: Termina
    });

    return { tabla: tablaApi, usuarios: usuariosRutina };
  }

  lineaEjercicioFromDto(linea: any, ejercicios: Ejercicio[]): LineaEjercicio {
    const ejercicio = ejercicios.find(ej => ej?.id === linea?.ejercicio?.id);
    return {
      id: linea?.id,
      observaciones: linea?.observaciones,
      peso: linea?.peso,
      series: linea?.series,
      repeticiones: linea?.repeticiones,
      tiempo: linea?.tiempo,
      ejercicio: ejercicio,
    };
  }

  toDto(rutinaApi: RutinaApi): any {
    return {
      tabla: {
        id: rutinaApi?.tabla?.id,
        title: rutinaApi?.tabla?.title,
        observaciones: rutinaApi?.tabla?.observaciones,
        plantilla: rutinaApi?.tabla?.plantilla,
        idMonitor: rutinaApi?.tabla?.idMonitor,
        lineasEntrada: rutinaApi?.tabla?.lineasEntrada,
        lineasDelete: rutinaApi?.tabla?.lineasDelete,
      },
      usuarios: rutinaApi?.usuarios || [],
      rutinasDelete: rutinaApi?.usuariosDelete || [],
    };
  }
}
