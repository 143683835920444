import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FacilitiesService {
  constructor(private http: HttpClient) {}

  getFacilityResource(): any {
    return this.http.get(environment.apiBackGetUrl + '/api/v1/deporwin/servicios/familias');
  }
}
