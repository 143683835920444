import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcordeonComponent } from './acordeon.component';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [AcordeonComponent],
  imports: [CommonModule, RouterLink],
  exports: [AcordeonComponent],
})
export class AcordeonModule {}
