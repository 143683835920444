import { Component, Input } from '@angular/core';
import { Clase } from '../../services/clases/clases.service';
import dayjs, { Dayjs } from 'dayjs';
import { bgColors } from '../../utils/colors';

@Component({
  selector: 'app-clases-linea',
  templateUrl: './clases-linea.component.html',
  styleUrls: ['./clases-linea.component.css'],
})
export class ClasesLineaComponent {
  @Input() clase: Clase;
  @Input() index: number;

  startingTime: Dayjs;
  finishingTime: Dayjs;
  bgColor;

  ngOnInit() {
    this.bgColor = bgColors[this.index % bgColors.length];
    this.parseTimes();
  }

  parseTimes() {
    this.startingTime = dayjs(
      `${this.clase.fecha.value} ${this.clase.fecha.hora}`,
      'YYYY-MM-DD HH:mm'
    );
    this.startingTime.add(parseInt(this.clase.fecha.duracion), 'minute');

    this.finishingTime = dayjs(
      `${this.clase.fecha.value} ${this.clase.fecha.hora}`,
      'YYYY-MM-DD HH:mm'
    ).add(parseInt(this.clase.fecha.duracion), 'minute');
  }
}
