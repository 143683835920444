import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-agregar-observacion',
  templateUrl: './agregar-observacion.component.html',
  styleUrls: ['./agregar-observacion.component.css'],
})
export class AgregarObservacionComponent implements OnInit {
  @Input() observacion: string;

  @Input() title: string = 'Observación Individual';
  @Output() observacionChange: EventEmitter<string | null> = new EventEmitter<string | null>();

  observaciones: string = '';

  ngOnInit() {
    this.observaciones = this.observacion;
  }

  save() {
    this.observacionChange.emit(this.observaciones);
  }

  cancel() {
    this.observacionChange.emit(null);
  }
}
