import {
  Component,
  Output,
  EventEmitter,
  HostListener,
  Renderer2,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-horizontal-range-slider',
  templateUrl: './horizontal-range-slider.component.html',
  styleUrls: ['./horizontal-range-slider.component.css'],
})
export class HorizontalRangeSliderComponent {
  @ViewChild('rangeSlider') rangeSlider!: ElementRef;

  @Input() min: number = 0;
  @Input() max: number = 100;
  currentValue: number = 0;
  private dragging = false;
  private rangeWidth = 0;
  private rangeSlideBackground = undefined;

  @Output() valueChange = new EventEmitter<number>();

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.renderer.setStyle(this.rangeSlider.nativeElement, 'left', `${4 + this.currentValue}px`);
    this.rangeWidth = this.rangeSlider.nativeElement.parentElement.offsetWidth - 24;
    this.rangeSlideBackground = this.rangeSlider.nativeElement.parentElement.querySelector('aside');
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.dragging = true;
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.dragging = false;
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.dragging) {
      this.currentValue += (event.movementX * 100) / this.rangeWidth;
      if (this.currentValue < 0) this.currentValue = 0;
      if (this.currentValue > 100) this.currentValue = 100;
      this.renderer.setStyle(
        this.rangeSlider.nativeElement,
        'left',
        `${4 + this.currentValue * (this.rangeWidth / 100)}px`
      );
      this.renderer.setStyle(
        this.rangeSlideBackground,
        'width',
        `${this.currentValue * ((this.rangeWidth + 24) / 100)}px`
      );
      this.valueChange.emit(this.currentValue);
    }
  }
}
