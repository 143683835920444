<main>
  <article (click)="open=!open">
    <img src="/assets/icons/flecha.svg" alt="Flecha" [ngClass]="open?'rotate-90':'rotate-0'">
    <h1>{{deporte?.title | uppercase}}</h1>
  </article>
  <section [ngClass]="open ? 'open' : 'closed'">
    <p *ngIf="deporte?.descripcion" [innerHtml]="deporte?.descripcion!"></p>
    <aside *ngIf="deporte?.contacto">
      <h3>Contacto</h3>
      <p>{{deporte?.contacto}}</p>
    </aside>
    <span *ngIf="deporte?.telefono">
      <h3>Telefono/Correo</h3>
      <p>{{deporte?.telefono}}</p>
    </span>
    <span>
      <h3>Edades</h3>
      <p>{{deporte?.edad_minima}}-{{deporte?.edad_maxima}}</p>
    </span>
    <span *ngIf="!deporte?.precios?.hasOwnProperty('data')">
      <h3>Precios</h3>
      <ul>
        <li *ngFor="let precio of deporte?.precios">
          {{precio.title}} - {{precio.precio | number:'1.2-2'}} euros/mes
        </li>
      </ul>
    </span>
    <footer>
      <app-button (click)="onSeeMoreClick()" label="Más info" classList="btn btn-primary" />
    </footer>
  </section>
</main>