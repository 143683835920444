import { Component, Input } from '@angular/core';
import { getDescripcion } from '../../../utils/text';
import { Convocatoria } from '../../../interfaces/ConvocatoriaResponse';
import { ApiStatus } from '../../../interfaces';

@Component({
  selector: 'app-convocatorias',
  templateUrl: './convocatorias.component.html',
  styleUrls: ['./convocatorias.component.css'],
})
export class ConvocatoriasComponent {
  @Input() convocatorias: Convocatoria[] = [];
  @Input() apiStatus?: ApiStatus;

  currentPageData: any[] = [];
  handleEmitData(data: any[]) {
    this.currentPageData = data;
  }

  protected readonly getDescripcion = getDescripcion;
}
