<app-page-with-breadcrumb>

    <header>
        <h2>{{facility}}</h2>
        <h3>Paso {{currentStep}}</h3>
        <app-progress-bar [step]="currentStep" [numberSteps]="2" />
    </header> 
    <main>
        <ng-container [ngSwitch]="currentStep" >
            <app-book-facility-step-one *ngSwitchCase="1"  (step)="currentStep"  />
            <app-book-facility-step-two *ngSwitchCase="2" />
        </ng-container>
    </main>


</app-page-with-breadcrumb>