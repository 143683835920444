import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { primaryColor } from '../../utils/globalColors';

@Component({
  selector: 'app-avisos',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.css'],
})
export class AvisosComponent implements OnChanges {
  @Input() incidencias: any[] = [];
  @Input() avisos: any[] = [];
  contadorIncidencias: { piscina: number; pista: number } = { piscina: 0, pista: 0 };

  ngOnChanges(changes: SimpleChanges) {
    this.incidencias.forEach((element: any) => {
      if (!element?.vaso) this.contadorIncidencias.pista += 1;
      else this.contadorIncidencias.piscina += 1;
    });
  }

  protected readonly primaryColor = primaryColor;
}
