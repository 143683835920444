<div id="busqueda" class="flex flex-col space-y-3 mt-4">
  <button
    id="desplegable-busqueda"
    class="flex flex-row my-auto items-center text-black font-bold text-lg"
    (click)="open = !open"
  >
    <img
      [class]="open ? 'rotate-90' : 'rotate-0'"
      class="h-6 w-6 transition-all duration-500"
      src="/assets/icons/flecha.svg"
      alt="Flecha"
    />
    BÚSQUEDA
  </button>
  <div
    id="formulario-busqueda"
    [ngClass]="open ? 'max-h-full' : 'max-h-0 hidden'"
    class="relative transition-all ease-out"
  >
    <!-- Formulario -->
    <div class="flex flex-col">
      <!--      Busqueda por palabra-->
      <div class="w-full md:w-1/3 space-y-3">
        <h2 class="font-semibold">POR PALABRA</h2>
        <input
          name="input-search"
          id="input-search"
          type="text"
          placeholder="Futbol | Nombre del sitio"
          class="w-full bg-footer mt-1 p-2 rounded-3xl outline-none"
          [(ngModel)]="searchInput"
          (keyup.enter)="filtradoInstalaciones()"
        />
      </div>
      <!--      Busqueda por Deporte-->
      <div class="w-full flex flex-col md:flex-row space-y-3 md:space-y-0 justify-between mt-4">
        <div class="w-full flex flex-col space-y-5 items-start">
          <div class="w-full md:w-4/5">
            <app-select-input
              label="POR DEPORTE"
              id="select-deporte"
              firstOptionLabel="Cualquier deporte"
              [size]="5"
              [options]="deportesSelectOptions"
              [(ngModel)]="deporteSelector"
            />
          </div>
        </div>
        <div class="w-full flex flex-col space-y-5 items-start md:items-end">
          <app-slide-checkbox
            title="Tipo de instalación"
            label="Instalaciones por concesión administrativa"
            [id]="'checkbox-concesion-administrativa'"
            [(ngModel)]="concesionAdministrativa"
          />
          <app-select-input
            id="select-tipo-instalacion"
            firstOptionLabel="Cualquier Tipo"
            [options]="tipoInstalacionSelectOptions"
            [(ngModel)]="tipoInstalacionSelector"
          />
        </div>
        <!--        Busqueda por instlacion-->
        <div class="w-full flex flex-col space-y-5 items-start md:items-end">
          <div class="w-full md:w-4/5">
            <h2 class="font-semibold">POR INSTALACIÓN</h2>
            <input
              name="input-instalacion"
              id="input-instalacion"
              type="text"
              placeholder="Campo de ..."
              class="w-full bg-footer mt-1 p-2 rounded-3xl outline-none"
              [(ngModel)]="instalacionInput"
              (keyup.enter)="filtradoInstalaciones()"
            />
          </div>
          <button
            id="button-buscar"
            (click)="filtradoInstalaciones()"
            class="btn btn-primary"
            [disabled]="cargandoDatos"
            [ngClass]="cargandoDatos ? 'hover:cursor-not-allowed' : ''"
          >
            Buscar
          </button>
        </div>
      </div>
    </div>
    <!--    Resultados -->
    <!--    Loader-->
    <div id="loader-busqueda" *ngIf="cargandoDatos" class="w-full text-center mt-8">
      <h4 class="text-3xl text-primary font-aldo m-auto">Buscando</h4>
      <svg
        id="L5"
        class="w-20 m-auto justify-center flex"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
        xml:space="preserve"
      >
        <circle fill="#aa0400" stroke="none" cx="6" cy="50" r="6">
          <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 15 ; 0 -15; 0 15"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle fill="#aa0400" stroke="none" cx="30" cy="50" r="6">
          <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 10 ; 0 -10; 0 10"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle fill="#aa0400" stroke="none" cx="54" cy="50" r="6">
          <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 5 ; 0 -5; 0 5"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
    </div>
    <!--    lista de resultados-->
    <div
      id="resultados-busqueda"
      *ngIf="instalaciones.length != 0 && !cargandoDatos"
      class="w-full space-y-3 mt-7"
    >
      <h4 class="text-primary font-aldo text-xl">Resultados</h4>
      <div id="grid" class="grid md:grid-cols-4 gap-3">
        <a
          [id]="'resultado-' + i"
          [routerLink]="'/instalaciones/' + instalacion.id"
          *ngFor="let instalacion of instalaciones; let i = index"
          class="flex flex-col space-y-2"
        >
          <div class="flex flex-row space-x-2">
            <img src="/assets/icons/mas.svg" alt="Mas" class="h-10 w-10" />
            <div class="flex flex-col">
              <h3 class="font-semibold">{{ instalacion.title | titlecase }}</h3>
              <small>{{ instalacion.direccion }}</small>
            </div>
          </div>
          <hr class="md:hidden w-full" />
        </a>
      </div>
    </div>
    <!--    Si no hay datos-->
    <div id="noData-busqueda" *ngIf="hasData != null && !hasData && !cargandoDatos" class="w-full">
      <h4 class="text-primary font-aldo text-xl mt-8">
        La búsqueda no coincide con ningún elemento en específico
      </h4>
    </div>
  </div>
</div>
