import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, of, tap } from 'rxjs';
import { AvisoResponse } from '../interfaces/AvisoResponse';
import { ConvocatoriaResponse } from '../interfaces/ConvocatoriaResponse';
import { Deporte, DeportesResponse } from '../interfaces/DeportesResponse';
import { InstalacionesResponse } from '../interfaces/InstalacionesResponse';
import { EscuelasResponse } from '../interfaces/EscuelasResponse';
import { NoticiaResponse } from '../interfaces/NoticiaResponse';
import { InfoResponse } from '../interfaces/InfoInstitucionalResponse';
import { RutasResponse } from '../interfaces/RutasResponse';
import { environment } from '../../environments/environment';

export enum Accesibilidad {
  'Ninguna',
  'BN',
  'TextoGrande',
  'NoCSS',
}

@Injectable({
  providedIn: 'root',
})
export class MainService {
  accesibilidad: Accesibilidad = Accesibilidad.Ninguna;

  deportes?: DeportesResponse;
  avisos?: AvisoResponse;
  convocatorias?: ConvocatoriaResponse;
  escuelas?: EscuelasResponse;

  constructor(private http: HttpClient) {}

  setAccesibilidad(tipo: Accesibilidad) {
    this.accesibilidad = tipo;
  }

  getAccesibilidad(): Accesibilidad {
    return this.accesibilidad;
  }

  getAvisos(): Observable<AvisoResponse> {
    if (this.avisos) {
      return of(this.avisos);
    } else {
      return this.http.get<AvisoResponse>(`${environment.apiBackGetUrl}/api/v1/drupal/avisos`).pipe(
        tap(data => {
          this.avisos = data;
        })
      );
    }
  }

  getConvocatorias(): Observable<ConvocatoriaResponse> {
    if (this.convocatorias) {
      return of(this.convocatorias);
    } else {
      return this.http
        .get<ConvocatoriaResponse>(`${environment.apiBackGetUrl}/api/v1/drupal/convocatorias`)
        .pipe(
          tap(data => {
            this.convocatorias = data;
          })
        );
    }
  }

  getDeportes(params: HttpParams): Observable<DeportesResponse> {
    if (this.deportes) {
      return of(this.deportes);
    } else {
      params = params.append('page[limit]', 200);
      return this.http
        .get<DeportesResponse>(`${environment.apiBackGetUrl}/api/v1/drupal/deportes`, { params })
        .pipe(
          tap(data => {
            data.data = data.data.filter(deporte => deporte.title != 'NO DEPORTE');
            this.deportes = data;
          })
        );
    }
  }

  getDeporte(id: string): Observable<Deporte> {
    return this.http.get<Deporte>(`${environment.apiBackGetUrl}/api/v1/drupal/deporte?id=${id}`);
  }

  getEscuelas(): Observable<EscuelasResponse> {
    let params = new HttpParams();
    params = params.append('page[limit]', 200);
    if (this.escuelas) {
      return of(this.escuelas);
    } else {
      params = params.append('page[limit]', 200);
      return this.http
        .get<EscuelasResponse>(
          `${environment.apiBackGetUrl}/api/v1/drupal/deporte_escolar/escuelas`,
          { params }
        )
        .pipe(
          tap(data => {
            data.data.map(escuela => {
              if (escuela.deportes![0]?.title == 'NO DEPORTE') escuela.deportes = undefined;
              if (escuela.instalacion![0]?.title == 'NO INSTALACION')
                escuela.instalacion = undefined;
            });
            data.data = data.data.filter(escuela => escuela.title != 'NO ESCUELA');
            this.escuelas = data;
          })
        );
    }
  }

  busquedaGeneral(params: HttpParams, limit: number = 200): Observable<any[]> {
    params = params.append('page[limit]', limit);
    const deportes = this.http
      .get<DeportesResponse>(`${environment.apiBackGetUrl}/api/v1/drupal/deportes`, { params })
      .pipe(
        tap(data => {
          data.data = data.data.filter(deporte => deporte.title != 'NO DEPORTE');
        })
      );
    const instalaciones = this.http
      .get<InstalacionesResponse>(`${environment.apiBackGetUrl}/api/v1/drupal/instalaciones`)
      .pipe(
        tap(data => {
          data.data = data.data.filter(instalacion => instalacion.title != 'NO INSTALACION');
        })
      );
    const escuelas = this.http
      .get<EscuelasResponse>(
        `${environment.apiBackGetUrl}/api/v1/drupal/deporte_escolar/escuelas`,
        { params }
      )
      .pipe(
        tap(data => {
          data.data = data.data.filter(escuela => escuela.title != 'NO ESCUELA');
        })
      );
    const noticias = this.http.get<NoticiaResponse>(
      `${environment.apiBackGetUrl}/api/v1/drupal/noticias`,
      {
        params,
      }
    );
    const avisos = this.http.get<AvisoResponse>(
      `${environment.apiBackGetUrl}/api/v1/drupal/avisos`,
      { params }
    );
    const convocatorias = this.http.get<ConvocatoriaResponse>(
      `${environment.apiBackGetUrl}/api/v1/drupal/convocatorias`,
      { params }
    );
    const rutas = this.http.get<RutasResponse>(`${environment.apiBackGetUrl}/api/v1/drupal/rutas`, {
      params,
    });

    return forkJoin([deportes, instalaciones, escuelas, noticias, avisos, convocatorias, rutas]);
  }

  filtradoEscuelas(params: HttpParams, limit: number = 200): Observable<EscuelasResponse> {
    params = params.append('page[limit]', limit);
    return this.http
      .get<EscuelasResponse>(
        `${environment.apiBackGetUrl}/api/v1/drupal/deporte_escolar/escuelas`,
        { params }
      )
      .pipe(
        tap(data => {
          data.data.map(escuela => {
            if (escuela.deportes![0]?.title == 'NO DEPORTE') escuela.deportes = undefined;
            if (escuela.instalacion![0]?.title == 'NO INSTALACION') escuela.instalacion = undefined;
          });
          data.data = data.data.filter(escuela => escuela.title != 'NO ESCUELA');
        })
      );
  }

  getBanners(): Observable<InfoResponse> {
    const params = new HttpParams().set('filter[tipo]', 'BANNER');
    return this.http.get<InfoResponse>(
      `${environment.apiBackGetUrl}/api/v1/drupal/info_institucional`,
      { params }
    );
  }

  getInfoById(params: HttpParams) {
    return this.http.get<InfoResponse>(
      `${environment.apiBackGetUrl}/api/v1/drupal/info_institucional`,
      { params }
    );
  }
}
