import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-users-table-modal',
  templateUrl: './users-table-modal.component.html',
  styleUrls: ['./users-table-modal.component.css'],
})
export class UsersTableModalComponent {
  @Input() rutina: any;
  @Output() cerrar = new EventEmitter<boolean>();

  onClose() {
    this.cerrar.emit(true);
  }
}
