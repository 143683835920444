import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CargandoCirularIconComponent } from './cargando-cirular-icon/cargando-cirular-icon.component';
import { GuardarIconComponent } from './guardar-icon/guardar-icon.component';
import { IconComponent } from './icon/icon.component';
import { PoolIconComponent } from './pool-icon/pool-icon.component';
import { TenisIconComponent } from './tenis-icon/tenis-icon.component';

@NgModule({
  declarations: [
    CargandoCirularIconComponent,
    GuardarIconComponent,
    IconComponent,
    PoolIconComponent,
    TenisIconComponent,
  ],
  exports: [CargandoCirularIconComponent, GuardarIconComponent, IconComponent],
  imports: [CommonModule],
})
export class IconosModule {}
