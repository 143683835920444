<div>
    <app-menu-curtain [navOpen]="navOpen" (navOpenChange)="onNavOpenChange(null)" />
    <main>
        <a [routerLink]="getRoute()" class="h-8 flex justify-center text-xs items-center text-white"
            *ngIf="!user; else userLogged">
            <span class="md:block hidden mr-2">Iniciar<br />sesión</span>
            <aside>
                <img src="../../../assets/icons/login-icon-user.svg" alt="Boton Login" />
            </aside>
        </a>
        <ng-template #userLogged>
            <div (click)="toggleNav()">
                <app-image-renderer *ngIf="user?.profileImage; else noImage"
                    [classList]="'flex justify-center items-center w-10'" [src]="'/favicon.ico'" [altString]="'Logo'"
                    [routeAddress]="'/'" [imgClass]="'h-10'" />
                <ng-template #noImage>
                    <app-no-image [data]="{
                            letterOne: user?.name?.charAt(0),
                            letterTwo: user?.lastName1?.charAt(0)
                        }" size="40" [fontSize]="1.2" borderColor="#fff" />
                </ng-template>
            </div>
        </ng-template>
        <nav *ngIf="navOpen">
            <ul>
                <li>
                    <span routerLink="/user-profile" (click)="toggleNav()">Perfil</span>
                </li>
                <li>
                    <span (click)="toggleNav()">Configuración</span>
                </li>
                <li>
                    <span (click)="logout()">Cerrar sesión</span>
                </li>
            </ul>
        </nav>
    </main>
</div>