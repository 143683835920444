<div>
  <div id="noticias" *ngIf="noticias.length != 0 && !apiStatus?.loading">
    <div class="my-14">
      <h1 class="md:text-start text-center text-5xl font-aldo text-primary">Notas de Prensa</h1>
    </div>
    <div class="w-full flex flex-col space-y-20">
      <app-pagination-container [data]="noticias"
      (emitData)="handleEmitData($event)" [pageSize]="5">
        <article *ngFor="let noticia of currentPageData" class="w-full flex flex-col shadow-2xl">
          <div class="flex flex-col md:flex-row p-3 md:p-8 pb-0">
            <div *ngIf="noticia.img.uri" class="md:w-1/3 overflow-hidden flex md:items-center">
              <img
                [src]="environment.imgUrl + noticia.img.uri.url"
                alt="Imagen de la Noticia"
                class="w-full h-56"
              />
            </div>
            <div class="w-full md:w-2/3 h-full flex flex-col space-y-2 p-3 m-auto">
              <small class="md:text-lg">{{ noticia.changed | date : 'dd/MM/yyyy' }}</small>
              <h3 class="text-xl md:text-3xl font-bold">{{ noticia.title }}</h3>
              <p
                class="md:text-lg line-clamp-4"
                [innerHtml]="getDescripcion(noticia.body) | safeHtml"
              ></p>
            </div>
          </div>
          <div class="w-full flex justify-center md:justify-end">
            <a
              [routerLink]="'/noticias/' + noticia.id"
              class="bg-primary hover:bg-secondary w-1/3 md:w-[20%] text-sm md:text-lg button-keep-reading text-white text-center font-semibold rounded-t-2xl p-1.5 md:mr-20"
            >
              Seguir leyendo
            </a>
          </div>
        </article>
      </app-pagination-container>
    </div>
  </div>
  <div
    id="noData-noticias"
    *ngIf="!apiStatus?.loading && noticias.length == 0"
    class="text-5xl text-primary text-center w-full"
  >
    No se encontro ninguna noticia
  </div>
</div>
