import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteCardComponent } from './route-card/route-card.component';
import { RoutesListComponent } from './routes-list/routes-list.component';
import { RouterModule, Routes } from '@angular/router';
import { InstalacionesModule } from 'src/app/instalaciones/instalaciones.module';
import { RouteAcordeonComponent } from './route-acordeon/route-acordeon.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouteMapComponent } from './route-map/route-map.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

const routes: Routes = [
  {
    path: '',
    component: RoutesListComponent,
  },
  {
    path: ':title',
    component: RouteCardComponent,
  },
];

@NgModule({
  declarations: [
    RouteCardComponent,
    RoutesListComponent,
    RouteAcordeonComponent,
    RouteMapComponent,
  ],
  imports: [
    CommonModule,
    [RouterModule.forChild(routes)],
    InstalacionesModule,
    ReactiveFormsModule,
    NgxMapboxGLModule,
  ],
  exports: [RouteCardComponent, RoutesListComponent, RouterModule, RouteAcordeonComponent],
})
export class RoutesModule {}
