import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Noticia } from '../../interfaces/NoticiaResponse';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-banner-noticias',
  templateUrl: './banner-noticias.component.html',
  styleUrls: ['./banner-noticias.component.css'],
})
export class BannerNoticiasComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() noticias: Noticia[] = [];
  @ViewChild('scrollableDiv') private myScrollContainer?: ElementRef<HTMLDivElement>;
  private isDown = false;
  private startX = 0;
  private scrollLeft = 0;
  current: number = 0;
  dropIzq: boolean = false;
  dropDer: boolean = true;
  timeOutSlide?: any;
  intervalSlide?: any;

  ngAfterViewInit(): void {
    const div = this.myScrollContainer?.nativeElement;
    div?.addEventListener('mousedown', this.mouseDownHandler.bind(this));
    div?.addEventListener('mouseleave', this.mouseLeaveHandler.bind(this));
    div?.addEventListener('mouseup', this.mouseUpHandler.bind(this));
    div?.addEventListener('mousemove', this.mouseMoveHandler.bind(this));
  }

  ngOnDestroy(): void {
    const div = this.myScrollContainer?.nativeElement;
    div?.removeEventListener('mousedown', this.mouseDownHandler.bind(this));
    div?.removeEventListener('mouseleave', this.mouseLeaveHandler.bind(this));
    div?.removeEventListener('mouseup', this.mouseUpHandler.bind(this));
    div?.removeEventListener('mousemove', this.mouseMoveHandler.bind(this));
  }

  ngOnInit() {
    this.initTimeOutSlide();
  }

  initTimeOutSlide() {
    clearInterval(this.intervalSlide);
    clearTimeout(this.timeOutSlide);
    // this.timeOutSlide = setTimeout(()=>this.initIntervalSlide(), 10000)
  }
  private initIntervalSlide() {
    this.intervalSlide = setInterval(() => {
      const div = this.myScrollContainer?.nativeElement!;
      const article = this.myScrollContainer?.nativeElement.getElementsByTagName('article')[0];
      if (!article) return;
      if (div.scrollLeft + div.clientWidth >= div.scrollWidth - 20) div.scrollLeft = 0;
      else div.scrollLeft = div.scrollLeft + article.clientWidth / 0.5;
    }, 4000);
  }

  private mouseDownHandler(e: MouseEvent): void {
    const div = this.myScrollContainer?.nativeElement!;
    this.isDown = true;
    div.style.cursor = 'grabbing';
    this.startX = e.pageX - div?.offsetLeft;
    this.scrollLeft = div.scrollLeft;
  }

  private mouseLeaveHandler(): void {
    const div = this.myScrollContainer?.nativeElement!;
    this.isDown = false;
    div.style.cursor = 'grab';
  }

  private mouseUpHandler(): void {
    const div = this.myScrollContainer?.nativeElement!;
    this.isDown = false;
    div.classList.add('scroll-smooth');
    div.style.cursor = 'grab';
  }

  mouseMoveHandler(e: MouseEvent): void {
    const div = this.myScrollContainer?.nativeElement!;
    if (!this.isDown) return;
    div.classList.remove('scroll-smooth');
    e.preventDefault();
    const y = e.pageX - div?.offsetLeft;
    const walk = y - this.startX;
    div.scrollLeft = this.scrollLeft - walk;
    this.initTimeOutSlide();
  }

  onClickRight() {
    this.initTimeOutSlide();
    const div = this.myScrollContainer?.nativeElement!;
    const article = this.myScrollContainer?.nativeElement.getElementsByTagName('article')[0];
    if (!article) return;
    div.scrollTo(div.scrollLeft + article.clientWidth / 0.5, 0);
  }
  onClickLeft() {
    this.initTimeOutSlide();
    const div = this.myScrollContainer?.nativeElement!;
    const article = this.myScrollContainer?.nativeElement.getElementsByTagName('article')[0];
    if (!article) return;
    div.scrollTo(div.scrollLeft - article.clientWidth / 0.5, 0);
  }

  changeIndex(index: number) {
    const div = this.myScrollContainer?.nativeElement!;
    const noticia = document.getElementById(`noticia-${index}`);
    noticia!.style.outline = '1px red solid';
    div.scrollLeft =
      ((div.scrollWidth - div.clientWidth) * index) / this.noticias.length +
      (((div.scrollWidth - div.clientWidth) / this.noticias.length) * index) / this.noticias.length;
    setTimeout(() => {
      noticia!.style.outline = '';
    }, 500);
  }

  onScroll() {
    const div = this.myScrollContainer?.nativeElement!;
    this.current = Math.floor(
      (div.scrollLeft / (div.scrollWidth - div.clientWidth)) * this.noticias.length
    );
    this.current = this.current == this.noticias.length ? this.noticias.length - 1 : this.current;
    this.dropIzq = !(div.scrollLeft === 0);
    this.dropDer = !(div.scrollLeft + div.clientWidth >= div.scrollWidth - 20);
  }

  getDescripcion(texto: string): string {
    return texto.replaceAll(/<img [^>]+\>/gi, '');
  }

  protected readonly environment = environment;
}
