<main>
    <ng-container *ngIf="url; else notUrl">
        <a target="_blank" [href]="baseFilesUrl + '/api/v1/drupal/content/file?url=' + url">
            <app-icon [src]="'file-icons/'+ type"
                stroke="black"
                customClass="file-downloader-icon"
            />
            <span>
                {{filename}}
            </span>
        </a>
        <h3>
            {{description}}
        </h3>
    </ng-container>
    <ng-template #notUrl>
        <section>
            <app-icon [src]="'file-icons/'+ type"
                stroke="black"
                customClass="file-downloader-icon"
            />
            <span>
                {{filename}}
            </span>
            <h3>
                {{description}}
            </h3>
        </section>
    </ng-template>
</main>