import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, tap } from 'rxjs';
import { environment } from '../../../../environments/environment.development';
import { Ejercicio } from '../../models/ejercicios.model';
import { EjerciciosConverterService } from '../../converters/ejercicios/ejercicios.converter.service';

@Injectable({
  providedIn: 'root',
})
export class EjerciciosService {
  constructor(
    private http: HttpClient,
    private ejerciciosConverterService: EjerciciosConverterService
  ) {}

  getAllEjercicios(): Observable<Ejercicio[]> {
    return this.http
      .get(environment.apiBackGetUrl + '/api/v1/drupal/ejercicios')
      .pipe(
        map((exercise: any[]) =>
          exercise
            ? exercise?.map((ejercicio: any) => this.ejerciciosConverterService.fromDto(ejercicio))
            : []
        )
      );
  }
}
