import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TablasService } from 'src/app/monitor/services/tablas/tablas.service';
import { getIcon } from 'src/app/monitor/utils/icons';

@Component({
  selector: 'app-tabla-linea',
  templateUrl: './tabla-linea.component.html',
  styleUrls: ['./tabla-linea.component.css'],
})
export class TablaLineaComponent {
  constructor(private router: Router, private tablasService: TablasService) {}

  @Input() rutina?: any;
  @Output() tableId = new EventEmitter<string | number>();

  getIcon = getIcon;

  onEditClick() {
    this.router.navigate(['/monitor/editar-tabla'], {
      queryParams: { tablaId: this.rutina.tabla.id },
    });
  }

  onUsersClick() {
    this.tableId.emit(this.rutina.tabla.id);
  }

  onNewTableClick() {
    this.router.navigate(['/monitor/nueva-tabla']);
  }

  handleDownload(routineId: number) {
    this.tablasService.getPdfFromRutinaId(routineId).subscribe();
  }
}
