import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalSuccessService {
  private modalVisibleSubject = new BehaviorSubject<boolean>(false);
  modalVisible$ = this.modalVisibleSubject.asObservable();

  private successMessageSubject = new BehaviorSubject<string>('');
  successMessage$ = this.successMessageSubject.asObservable();

  private closeTimeoutSource = new BehaviorSubject<number>(2500);
  closeTimeout$ = this.closeTimeoutSource.asObservable();

  showModal(message: string, closeTimeout: number = 2500) {
    this.successMessageSubject.next(message);
    if (closeTimeout > 0) this.closeTimeoutSource.next(closeTimeout);
    this.modalVisibleSubject.next(true);
  }

  hideModal() {
    this.modalVisibleSubject.next(false);
    this.successMessageSubject.next('');
  }
}
