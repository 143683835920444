import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-image',
  templateUrl: './no-image.component.html',
  styleUrls: ['./no-image.component.css'],
})
export class NoImageComponent {
  @Input() data: { letterOne: string | undefined; letterTwo: string | undefined } | undefined;
  @Input() size: string = '150';
  @Input() fontSize: number = 4;
  @Input() borderColor: string = 'white';
}
