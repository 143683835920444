<nav
  id="menu"
  class="absolute xl:w-1/6 flex flex-col justify-evenly border rounded-2xl border-primary border-opacity-40 p-4 bg-footer shadow-xl z-[8000]"
  [ngClass]="!open ? 'hidden' : ''"
>
  <div *ngFor="let menItem of menu" class="w-full">
    <a
      [id]="menItem.title"
      [routerLink]="menItem.path"
      (click)="cleanBreadCrumbs()"
      class="text-lg flex space-x-3 hover:bg-gray-300 hover:bg-opacity-60 p-1.5 rounded-2xl"
    >
      <app-icon
        [src]="menItem.icon"
        class="w-7 h-7"
        [fill]="menItem.fill"
        [stroke]="menItem.stroke"
      />
      <p>
        {{ menItem.title }}
      </p>
    </a>
  </div>
</nav>
