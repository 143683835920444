import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Instalacion, InstalacionesResponse } from '../../../interfaces/InstalacionesResponse';
import { HttpParams } from '@angular/common/http';
import { InstalacionService } from '../../services/instalacion.service';
import { environment } from '../../../../environments/environment';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { catchError, of, switchMap } from 'rxjs';
import { ApiStatus } from '../../../interfaces';
import { Title } from '@angular/platform-browser';
import { getTitle } from 'src/app/utils/breadcrumbs';
import { GlobalService } from 'src/app/services/global.service';
import {
  ListListerColumn,
  ListListerColumnType,
} from 'src/app/components/shared/list/list-lister/list-lister.component';

@Component({
  selector: 'app-instalacion',
  templateUrl: './instalacion.component.html',
  styleUrls: ['./instalacion.component.css'],
})
export class InstalacionComponent implements OnInit {
  instalacion?: Instalacion;
  openModalImg: boolean = false;
  imgSelected: string = '';
  apiStatus: ApiStatus = { loading: false, error: false };
  requiredPrivateConnection = false;
  id: string = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private instalacionService: InstalacionService,
    private changeDetectorRef: ChangeDetectorRef,
    private breadCrumbService: BreadcrumbService,
    private titleService: Title,
    private globalService: GlobalService
  ) {}

  cardColumns: ListListerColumn[] = [
    { name: 'Dirección', prop: 'direccion', type: ListListerColumnType.String },
    { name: 'Transporte público', prop: 'lineas', type: ListListerColumnType.String },
    { name: 'Horario', prop: 'horario', type: ListListerColumnType.Array },
  ];

  listColumns: ListListerColumn[] = [
    { name: 'Dirección', prop: 'direccion', type: ListListerColumnType.String },
    { name: 'Transporte público', prop: 'lineas', type: ListListerColumnType.String },
    { name: 'Horario', prop: 'horario', type: ListListerColumnType.Array },
  ];

  ngOnInit() {
    this.globalService.getDeporwinStatus().subscribe(status => {
      this.requiredPrivateConnection = status;
    });
    this.route.params.subscribe(params => {
      const httpp = new HttpParams().set('filter[id]', params['id']);
      this.apiStatus = { loading: true, error: false };
      this.instalacionService
        .filtradoInstalaciones(httpp, 1)
        .pipe(
          catchError(_ => {
            this.apiStatus = { loading: false, error: true };
            return of();
          }),
          switchMap((data: InstalacionesResponse) => {
            this.instalacion = data.data[0];
            this.titleService.setTitle(getTitle(data.data[0].title));
            this.breadCrumbService.addCurrentBreadcrumb();
            this.apiStatus = { loading: false, error: false };
            return of(data);
          })
        )
        .subscribe();
    });
  }

  abrirModalImagen(uid: string) {
    this.imgSelected = uid;
    this.openModalImg = true;
  }

  closeModalImagen(open: boolean) {
    this.openModalImg = open;
    this.changeDetectorRef.detectChanges();
  }

  onBookFacility() {
    this.router.navigate(['/reservar-instalacion', this.instalacion?.reference_id]);
  }
  onSportClick(id: string) {
    this.id = id;
    this.router.navigate(['/deportes', this.id]);
  }

  onCardClick(id: string) {
    this.id = id;
    this.router.navigate(['/instalacion', this.id]);
  }

  protected readonly environment = environment;
}
