<article>
    <section>
        <ng-container *ngFor="let planning of planningsFromApi; let i = index">
            <app-planning-routine-line [routine]="planning" [index]="i" (click)="setSelectedPlanningId(i)" />
        </ng-container>
        <app-planning-routine-info *ngIf="selectedPlanningIndex" [routine]="planningsFromApi[selectedPlanningIndex]" />
    </section>
    <section>
        <app-calendar-selector [dates]="returnDatesFromPlannings()" (clickEvent)="onClickDay($event)" />
        <footer>
            <app-button (click)="cancelChanges()" label="Cancelar" classList="cancel-button" />
            <app-button (click)="saveChanges()" label="Guardar cambios" classList="save-button" />
        </footer>
    </section>
</article>
<main (click)="onClose()">
</main>