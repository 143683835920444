export const getIcon = (ejercicioIcon: string): string => {
  if (!ejercicioIcon) return 'ejercicios/espalda';
  return 'ejercicios/' + ejercicioIcon;
};

export const getMostUsedIcon = lines => {
  let exerciseTypeCount = [];

  lines &&
    lines.forEach(line => {
      const exerciseIconString =
        line.ejercicio.grupoMuscular.name?.toLowerCase() ||
        line.ejercicio.grupoMuscular[0].name.toLowerCase() ||
        'espalda';

      const found = exerciseTypeCount.find(exercise => exercise.icon === exerciseIconString);

      if (found) {
        found.count++;
      } else {
        exerciseTypeCount.push({
          icon: exerciseIconString,
          count: 1,
        });
      }
    });

  return (
    (exerciseTypeCount.length > 0 &&
      exerciseTypeCount.reduce((prev, current) => (prev.count > current.count ? prev : current))
        .icon) ||
    'espalda'
  );
};
