<app-page-with-breadcrumb>
    <div id="noticia" *ngIf="noticia" >
        <h1 class="text-center md:text-start mb-8 text-5xl font-aldo text-primary font-bold">Noticia</h1>
        <div class="flex flex-col space-y-8">
          <h2 class="text-4xl font-semibold">{{noticia.title | uppercase}}</h2>
          <img *ngIf="noticia.img.uri" [src]="environment.imgUrl + noticia.img.uri.url" alt="Imagen de la noticia" class="max-w-md">
          <small class="text-xl">{{noticia.changed|date:'medium'}}</small>
          <p class="text-xl text-justify leading-relaxed md:leading-loose" [innerHtml]="noticia ? noticia.body : ''|safeHtml"></p>
          <p *ngIf="noticia.url">Fuente:
          <a class="hover:text-secondary" [href]="noticia.url" target="_blank"> {{noticia.url}} </a>
          </p>
      </div>
    </div>
    <div id="aviso" *ngIf="aviso">
      <h1 class="text-center md:text-start mb-8 text-5xl font-aldo text-primary font-bold">Aviso</h1>
      <div class="flex flex-col space-y-8">
        <h2 class="text-4xl font-semibold">{{aviso.title | uppercase}}</h2>
        <small class="text-xl">{{aviso.changed|date:'medium'}}</small>
        <p class="text-xl text-justify leading-relaxed md:leading-loose" [innerHtml]="aviso ? aviso.descripcion : ''|safeHtml"></p>
      </div>
    </div>
    <div id="convocatoria" *ngIf="convocatoria">
      <h1 class="text-center md:text-start mb-8 text-5xl font-aldo text-primary font-bold">Convocatoria</h1>
      <div class="flex flex-col space-y-8">
        <h2 class="text-4xl font-semibold">{{convocatoria.title | uppercase}}</h2>
        <div *ngIf="convocatoria.fecha_inicio || convocatoria.fecha_fin">
          <div *ngIf="convocatoria.fecha_inicio">
            <h3>Abierta desde</h3>
            <small class="text-xl">{{convocatoria.fecha_inicio|date:'medium'}}</small>
          </div>
          <div *ngIf="convocatoria.fecha_fin">
            <h3>Hasta</h3>
            <small class="text-xl">{{convocatoria.fecha_fin|date:'medium'}}</small>
          </div>
        </div>
        <p class="text-xl text-justify leading-relaxed md:leading-loose" [innerHtml]="convocatoria ? convocatoria.descripcion : ''|safeHtml"></p>
        <p *ngIf="!convocatoria.fichero1.hasOwnProperty('data')">Mas detalles:
          <a class="hover:text-secondary underline underline-offset-2" [href]="environment.imgUrl+convocatoria.fichero1.uri?.url" target="_blank"> Descargue aqui el fichero </a>
        </p>
      </div>
    </div>
    <div id="cargando-noticia" *ngIf="loading && (!noticia&&!aviso&&!convocatoria)" class="text-5xl text-primary text-center w-full">
      Buscando
    </div>
    <div id="noData-noticia" *ngIf="(!noticia&&!aviso&&!convocatoria) && !loading" class="text-5xl text-primary text-center w-full">
      No se encontro ninguna noticia con esa ID o no se pudo recuperar la información
    </div>
</app-page-with-breadcrumb>
