import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-file-downloader',
  templateUrl: './file-downloader.component.html',
  styleUrls: ['./file-downloader.component.css'],
})
export class FileDownloaderComponent {
  @Input() url?: string | null = null;
  @Input() filename: string | null = null;
  @Input() description: string | null = null;
  @Input() forceType?: string | null = null;
  type: string | null = null;

  ngOnInit() {
    this.type = this.forceType ? this.forceType : this.url?.split('.').pop() || null;
  }

  baseFilesUrl = environment.apiBackGetUrl;
}
