import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-planning-routine-info',
  templateUrl: './planning-routine-info.component.html',
  styleUrls: ['./planning-routine-info.component.css'],
})
export class PlanningRoutineInfoComponent {
  @Input() routine: any;
}
