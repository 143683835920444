<main>
    <header (click)="toggleOpen()">
        <app-icon
            src="flecha"
            fill="var(--wine-red)"
            [customClass]="'accordion-icon' + (isOpen ? ' accordion-open' : '')"
        />
        <h2>{{title}}</h2>
    </header>
    <section [ngClass]="isOpen ? '' : 'closed'">
        <ng-content />
    </section>
</main>