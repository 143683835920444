import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { getIcon } from '../../utils/icons';
import { Subscription, tap } from 'rxjs';
import { validatorTimeField } from '../../converters/tabla-form/tabla-form.converter.service';
import { ModalService } from '../../../services/modal/modal.service';
import { AgregarObservacionComponent } from '../agregar-observacion/agregar-observacion.component';

export const tiempoRepet = [
  { label: 'min.', value: 1 },
  { label: 'rep.', value: 2 },
];

@Component({
  selector: 'app-linea-ejercicio',
  templateUrl: './linea-ejercicio.component.html',
  styleUrls: ['./linea-ejercicio.component.css'],
})
export class LineaEjercicioComponent implements OnInit, OnDestroy {
  @Input()
  lineaEjercicioFormGroup: UntypedFormGroup;

  @Input() isPreview: boolean = false;
  @Input() index: number = 0;

  @Output() deleteLinea = new EventEmitter<any>();
  @Output() showEjercicio = new EventEmitter<number>();

  patern = { '0': { pattern: new RegExp('\\d') }, '5': { pattern: new RegExp('[0-5]') } };

  tiempoRepetOpt: { label: string; value: number }[] = [];

  subsrciptions: Subscription = new Subscription();

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    if (this.ejercicio?.admiteTiempo) {
      this.tiempoRepetOpt.push(tiempoRepet[0]);
    }
    if (this.ejercicio?.admiteRepeticiones) {
      this.tiempoRepetOpt.push(tiempoRepet[1]);
    }

    const obs$ = this.lineaEjercicioFormGroup
      .get('tipoRepeticion')
      .valueChanges.pipe(
        tap(value => {
          if (value == 1) {
            this.lineaEjercicioFormGroup.patchValue({ repeat: null });
            this.lineaEjercicioFormGroup?.get('repeat').removeValidators(Validators.required);
            this.lineaEjercicioFormGroup?.get('repeat').updateValueAndValidity();
            this.lineaEjercicioFormGroup?.get('time').addValidators(validatorTimeField);
          } else {
            this.lineaEjercicioFormGroup.patchValue({ time: null });
            this.lineaEjercicioFormGroup?.get('time').removeValidators(validatorTimeField);
            this.lineaEjercicioFormGroup?.get('time').updateValueAndValidity();
            this.lineaEjercicioFormGroup?.get('repeat').addValidators(Validators.required);
          }
        })
      )
      .subscribe();

    if (this.lineaEjercicioFormGroup.get('repeat').value) {
      this.lineaEjercicioFormGroup?.get('repeat').addValidators(Validators.required);
    } else if (this.lineaEjercicioFormGroup.get('time').value) {
      this.lineaEjercicioFormGroup?.get('time').addValidators(validatorTimeField);
    } else {
      this.lineaEjercicioFormGroup?.get('repeat').addValidators(Validators.required);
    }
    this.subsrciptions.add(obs$);
  }

  ngOnDestroy() {
    this.subsrciptions.unsubscribe();
  }

  showExercise() {
    this.showEjercicio.emit(this.index);
  }

  deleteExercise() {
    if (this.deleteLinea) this.deleteLinea.emit();
  }

  addComment() {
    this.modalService
      .showComponent(
        AgregarObservacionComponent,
        {
          observacion: this.lineaEjercicioFormGroup.getRawValue()?.observaciones,
          title: 'Observación Ejercicio',
        },
        ['observacionChange']
      )
      .pipe(
        tap(res => {
          if (res?.observacionChange) {
            this.lineaEjercicioFormGroup.get('observaciones').patchValue(res?.observacionChange);
          }
          this.modalService.removeComponent();
        })
      )
      .subscribe();
  }

  get icon(): string {
    return getIcon(this.lineaEjercicioFormGroup.getRawValue()?.ejercicio?.icon);
  }

  get ejercicioTitle(): string {
    return (
      this.lineaEjercicioFormGroup.getRawValue()?.ejercicio?.title || 'No puedo recuperar el nombre'
    );
  }

  get ejercicio(): any {
    return this.lineaEjercicioFormGroup.getRawValue()?.ejercicio;
  }

  get typeRepet(): number {
    return this.lineaEjercicioFormGroup.getRawValue()?.tipoRepeticion;
  }
}
