import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreciosPublicosComponent } from './pages/precios-publicos/precios-publicos.component';
import { PrecioIdComponent } from './pages/precio-id/precio-id.component';

const routes: Routes = [
  {
    path: '',
    component: PreciosPublicosComponent,
    data: { animation: 'precios-publicos-index' },
  },
  {
    path: ':id',
    component: PrecioIdComponent,
    data: { animation: 'precios-publicos-id' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PreciosPublicosRoutingModule {}
