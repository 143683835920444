<div class="full-container">
  <div class="busqueda">
    <app-busqueda-usuarios (addUsuario)="addUser($event)" [usuarios]="usuariosMonitor" />
  </div>
  <div class="lista-agregados">
    <div class="container">
      <h6>Añadidos a la tabla</h6>
      <div
        class="list-users"
        cdkDropList
        id="usersAdded"
        #usersAdded="cdkDropList"
        [cdkDropListConnectedTo]="['users']"
        [cdkDropListData]="usuariosAdded"
        (cdkDropListDropped)="drop($event)"
        cdkDropListSortingDisabled
      >
        <ng-container *ngFor="let usuario of usuariosAdded">
          <div cdkDrag [cdkDragData]="usuario">
            <app-agregar-usuarios-linea-usuario
              (selectElement)="removeUser($event)"
              [usuario]="usuario"
              [isAdd]="false"
            />
            <app-agregar-usuarios-linea-usuario
              *cdkDragPreview
              [usuario]="usuario"
              [isAdd]="false"
              class="custom-drag-preview"
            />
            <app-agregar-usuarios-linea-usuario
              *cdkDragPlaceholder
              [usuario]="usuario"
              [isAdd]="false"
            />
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="space"></div>
  <div class="buttons">
    <app-button classList="cancel button-action" label="Cancelar" (onClick)="cancel()" />
    <app-button classList="save button-action" label="Aceptar" (onClick)="saveUsers()" />
  </div>
</div>
