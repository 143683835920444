<form [formGroup]="rutinaFormGroup">
  <h2 *ngIf="usuariosFormGroup.length !== 0">Usuarios para tabla: {{ nombreTabla }}</h2>
  <div class="lista-usuarios">
    <app-linea-usuario
      *ngFor="let usuario of usuariosFormGroup.controls; let i = index"
      [usuarioFormGroup]="usuarioFormGroup(usuario)"
      [indice]="i"
      (openCalendar)="handleOpenCalendar($event)"
    />
  </div>
</form>
