import { Component, Input } from '@angular/core';
import { menu } from './menu';
import { BreadcrumbService } from '../../services/breadcrumb.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent {
  @Input() open: boolean = false;

  menu: any[] = menu;

  constructor(private breadCrumbs: BreadcrumbService) {}

  cleanBreadCrumbs() {
    this.breadCrumbs.clearBreadcrumbs();
  }
}
