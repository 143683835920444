import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit {
  user: User | null = null;
  borderColor: string = '';

  constructor(private changeDetectorRef: ChangeDetectorRef, private globalService: GlobalService) {}

  ngOnInit() {
    this.globalService.getLoggedUser().subscribe(user => {
      this.user = user;
      this.changeDetectorRef.detectChanges();
    });

    this.borderColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--wine-red')
      .trim();
  }
}
