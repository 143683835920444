import { Component, Input } from '@angular/core';
import { Instalacion } from '../../../interfaces/InstalacionesResponse';

@Component({
  selector: 'app-acordeon',
  templateUrl: './acordeon.component.html',
  styleUrls: ['./acordeon.component.css'],
})
export class AcordeonComponent {
  @Input() instalacion?: Instalacion;
  @Input() open?: boolean = false;
}
