import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Noticia } from '../../../interfaces/NoticiaResponse';
import { NoticiasService } from '../../services/noticias.service';
import { environment } from '../../../../environments/environment';
import { Aviso } from '../../../interfaces/AvisoResponse';
import { Convocatoria } from '../../../interfaces/ConvocatoriaResponse';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { Title } from '@angular/platform-browser';
import { getTitle } from 'src/app/utils/breadcrumbs';

@Component({
  selector: 'app-noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.css'],
})
export class NoticiaComponent implements OnInit {
  noticia?: Noticia;
  aviso?: Aviso;
  convocatoria?: Convocatoria;
  loading: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private noticiaService: NoticiasService,
    private breadCrumbService: BreadcrumbService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.loading = true;
      this.noticiaService.getNoticiaById(params['id']).subscribe(
        ([noticia, aviso, convocatoria]) => {
          if (noticia.data.length != 0) this.noticia = noticia.data[0];
          if (aviso.data.length != 0) this.aviso = aviso.data[0];
          if (convocatoria.data.length != 0) this.convocatoria = convocatoria.data[0];
          if (this.noticia?.type == 'noticias' && this.noticia?.body) {
            this.titleService.setTitle(getTitle(this.noticia.title));
            this.breadCrumbService.addCurrentBreadcrumb();
            this.noticia.body = this.noticia.body.replaceAll('src="', `src="${environment.imgUrl}`);
          }
          if (this.aviso?.type == 'avisos' && this.aviso?.descripcion) {
            this.titleService.setTitle(getTitle(this.aviso.title));
            this.breadCrumbService.addCurrentBreadcrumb();
            this.aviso.descripcion = this.aviso.descripcion.replaceAll(
              'src="',
              `src="${environment.imgUrl}`
            );
          }
          if (this.convocatoria?.type == 'convocatorias') {
            this.titleService.setTitle(getTitle(this.convocatoria.title));
            this.breadCrumbService.addCurrentBreadcrumb();
            this.convocatoria.descripcion &&
              (this.convocatoria.descripcion = this.convocatoria.descripcion.replaceAll(
                'src="',
                `src="${environment.imgUrl}`
              ));
          }

          const label: string =
            this.noticia?.type == 'noticias'
              ? 'Noticias'
              : this.aviso?.type == 'avisos'
              ? 'Avisos'
              : this.convocatoria?.type == 'convocatorias'
              ? 'Convocatorias'
              : '';
          const title: string =
            this.noticia?.type == 'noticias'
              ? this.noticia.title
              : this.aviso?.type == 'avisos'
              ? this.aviso.title
              : this.convocatoria?.type == 'convocatorias'
              ? this.convocatoria.title
              : '';
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    });
  }

  protected readonly environment = environment;
}
