import { Component, Input, OnInit } from '@angular/core';
import { Ejercicio, LineaEjercicio } from '../../models/ejercicios.model';
import { UntypedFormGroup } from '@angular/forms';
import { getIcon } from '../../utils/icons';
import { TablaFormConverterService } from '../../converters/tabla-form/tabla-form.converter.service';

interface EjerciciosAgrupados {
  groupName: string;
  ejercicios: Ejercicio[];
}

@Component({
  selector: 'app-busqueda-ejercicios',
  templateUrl: './busqueda-ejercicios.component.html',
  styleUrls: ['./busqueda-ejercicios.component.css'],
})
export class BusquedaEjerciciosComponent implements OnInit {
  @Input() ejercicios: Ejercicio[];

  ejerciciosFiltrados: Ejercicio[];

  ejerciciosAgrupados: {
    [key: string]: EjerciciosAgrupados;
  } = {};

  grupoSeleccionado: EjerciciosAgrupados | null = null;

  search: string = '';

  draggIndex: number = null;
  draggContainer: string = null;

  constructor(private tablaFormConverterService: TablaFormConverterService) {}

  ngOnInit() {
    this.filtrar();
  }

  filtrar() {
    this.grupoSeleccionado = null;
    this.filtrarEjercicios();
    this.agruparEjercicios(this.ejerciciosFiltrados);
  }

  agruparEjercicios(ejercicios: Ejercicio[]) {
    const groupedExercises: {
      [key: string]: EjerciciosAgrupados;
    } = {};

    ejercicios.forEach(ejercicio => {
      ejercicio?.grupoMuscular.forEach(grupo => {
        if (!groupedExercises[grupo.name]) {
          groupedExercises[grupo.name] = { groupName: grupo.name, ejercicios: [] };
        }
        groupedExercises[grupo.name]?.ejercicios?.push(ejercicio);
      });
    });

    this.ejerciciosAgrupados = groupedExercises;
  }

  selectGrupoMuscular(grupoName: string) {
    this.grupoSeleccionado = this.ejerciciosAgrupados[grupoName];
  }

  deselectGrupo() {
    this.grupoSeleccionado = null;
  }

  filtrarEjercicios(): void {
    this.ejerciciosFiltrados = this.ejercicios.filter(ejercicio => {
      const searchTerm = this.search.trim().toLowerCase();
      const matchGroupName =
        Array.isArray(ejercicio?.grupoMuscular) &&
        ejercicio?.grupoMuscular?.some(grupo =>
          grupo?.name.trim().toLowerCase().includes(searchTerm)
        );
      return (
        (ejercicio?.title && ejercicio?.title.trim().toLowerCase().includes(searchTerm)) ||
        matchGroupName
      );
    });
  }

  dragStarted(item: Ejercicio, index) {
    this.draggIndex = index;
    this.draggContainer = 'allExercise';
  }

  dragEnd() {
    this.draggIndex = null;
    this.draggContainer = null;
  }

  getLineaEjercicioFormGroup(ejercicio: Ejercicio): UntypedFormGroup {
    const lineaEjercicio: LineaEjercicio = {
      id: null,
      tiempo: null,
      title: null,
      peso: null,
      series: null,
      repeticiones: null,
      observaciones: null,
      ejercicio: ejercicio,
    };
    return this.tablaFormConverterService.buildLineaEjercicioFormGroup(lineaEjercicio);
  }

  protected readonly getIcon = getIcon;
  protected readonly Object = Object;
}
