import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-slide-checkbox',
  templateUrl: './slide-checkbox.component.html',
  styleUrls: ['./slide-checkbox.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideCheckboxComponent),
      multi: true,
    },
  ],
})
export class SlideCheckboxComponent implements ControlValueAccessor {
  @Input() checked: boolean = false;
  @Input() title: string | null = null;
  @Input() label: string | null = null;
  @Input() id: string | null = null;
  @Output() change: EventEmitter<null> = new EventEmitter<null>();
  @Input() errores: any = null;
  @Input() image?: string;
  @Input() checkedValue: string | null = null;
  value: boolean = this.checked;
  disabled: boolean = false;

  onChange: any = (value: boolean) => {
    if (this.change) {
      this.change.emit();
    }
  };
  onTouch: any = () => {};

  writeValue(value: any) {
    this.value = value;
    this.onChange(value);
    this.onTouch(value);
    if (this.change) this.change.emit();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  updateParent() {
    if (!this.disabled) {
      this.value = !this.value;
      this.onChange(this.value ? (this.checkedValue ? this.checkedValue : this.value) : false);
      this.onTouch();
      if (this.change) this.change.emit();
    }
  }
}
