import { Injectable } from '@angular/core';
import { User } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthConverterService {
  constructor() {}
  fromDto(dto: any): User {
    return {
      userId: dto.userId,
      token: dto.token,
      tokenRefresh: {
        tokenLife: dto.tokenRefresh.tokenLife,
        generationDate: new Date(),
      },
    };
  }
}
