<mgl-map #mapComponent class="w-full h-full overflow-hidden"
         [style]="'mapbox://styles/estevez8891/clnefmm5i03rs01pbagxxdnec'"
         [zoom]="[zoom]"  [center]="center"
         [maxZoom]="18"
         [attributionControl]="false"
         [cooperativeGestures]="true"
>
  <mgl-control mglNavigation></mgl-control>
  <mgl-control  mglGeolocate [showUserLocation]="true" [trackUserLocation]="true"></mgl-control>
  <span>
    <mgl-geojson-source
    [id]="uuid"
    [data]="geojson">

    </mgl-geojson-source>
    <mgl-layer
    [source]="uuid"
      type="line"
      [layout]="{ 'line-join': 'round', 'line-cap': 'round' }"
      [paint]="{
        'line-color': '#aa0400',
        'line-width': 5
      }"
      ></mgl-layer>
  </span>
</mgl-map>

