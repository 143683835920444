import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcordeonDeportesComponent } from './acordeon-deportes.component';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'src/app/form/button/button.module';

@NgModule({
  declarations: [AcordeonDeportesComponent],
  exports: [AcordeonDeportesComponent],
  imports: [CommonModule, RouterModule, ButtonModule],
})
export class AcordeonDeportesModule {}
