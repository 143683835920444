import { Component } from '@angular/core';
import { TabInterface } from 'src/app/components/shared/tab-slider/tab-slider.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { Title } from '@angular/platform-browser';
import { getTitle } from 'src/app/utils/breadcrumbs';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
})
export class IndexComponent {
  tabs: TabInterface[] = [
    { title: 'Escuelas deportivas', active: true },
    { title: 'Competiciones', active: true },
    { title: 'Información y convocatorias', active: true },
    { title: 'Memoria', active: true },
    { title: 'Concursos', active: false },
    { title: 'Descargas', active: false },
  ];
  activeIndex: number = 0;
  constructor(
    private route: ActivatedRoute,
    private breadCrumbService: BreadcrumbService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(getTitle('Deporte escolar'));
    this.breadCrumbService.addCurrentBreadcrumb();
    this.route.queryParams.subscribe(params => {
      this.activeIndex = Number(params['section']) || 0;
    });
  }
}
