import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-menu-curtain',
  templateUrl: './menu-curtain.component.html',
  styleUrls: ['./menu-curtain.component.css'],
})
export class MenuCurtainComponent {
  @Input() navOpen: boolean = false;
  @Output() modalOpenChange = new EventEmitter<null>();
  constructor() {}

  toggleNav() {
    this.modalOpenChange.emit(null);
  }
}
