import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-geolocalizacion',
  templateUrl: './geolocalizacion.component.html',
  styleUrls: ['./geolocalizacion.component.css'],
})
export class GeolocalizacionComponent {
  @Input() data: any[] = [];

  open: boolean = true;
}
