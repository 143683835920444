import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { catchError, of, tap } from 'rxjs';
import { ConvertersService } from '../../../services/converters.service';
import { ApiStatus } from '../../../interfaces';
import { PreciosServices } from '../../services/precios.service';
import { getTitle } from 'src/app/utils/breadcrumbs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-precios-publicos',
  templateUrl: './precios-publicos.component.html',
  styleUrls: ['./precios-publicos.component.css'],
})
export class PreciosPublicosComponent implements OnInit {
  actividades: any[] = [];
  apiStatus: ApiStatus = { error: false, loading: false };

  constructor(
    private preciosService: PreciosServices,
    private breadCrumbService: BreadcrumbService,
    private converterService: ConvertersService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(getTitle('Precios públicos'));
    this.breadCrumbService.addCurrentBreadcrumb();
    this.apiStatus = { error: false, loading: true };
    this.preciosService
      .getPreciosPublicos()
      .pipe(
        catchError(err => {
          this.apiStatus = { loading: false, error: true };
          return of();
        }),
        tap(values => {
          this.actividades = this.converterService.convertPreciosFromApi(values.data);
          this.actividades.sort((a: any, b: any) => {
            if (a[0].actividad.orden === null) return 1;
            if (b[0].actividad.orden === null) return -1;
            return a[0].actividad.orden - b[0].actividad.orden;
          });
          this.apiStatus = { loading: false, error: false };
        })
      )
      .subscribe();
  }
}
