<div *ngIf="banners.length>0" class="w-11/12 md:w-8/12 mx-auto my-14">
<!--  <h3 class="text-3xl font-aldo text-primary mb-4">Enlaces de Interés</h3>-->
  <div class="w-full flex flex-row flex-wrap gap-8 justify-center">
    <div class="w-full md:w-auto" *ngFor="let banner of banners">
      <a *ngIf="!banner.url?.includes('/info')" [href]="!banner.url?.includes('/info')?banner.url:banner.url+banner.id" [target]="!banner.url?.includes('/info')?'_blank':''"
         class="w-full h-full relative flex flex-row-reverse justify-center align-middle px-3.5 py-1.5 md:flex-col shadow-lg rounded-xl  overflow-hidden text-center md:min-w-[160px] md:max-w-[180px] md:space-y-3">
        <h3 class="text-gray-500 font-semibold text-xl m-auto">{{banner.title}}</h3>
        <img *ngIf="banner.img?.uri" [src]="environment.imgUrl+banner.img?.uri?.url" alt="Imagen de la Noticia" class="min-h-[70px] max-w-[100px] my-auto md:h-20 md:mx-auto p-1.5" />
      </a>
      <a *ngIf="banner.url?.includes('/info')" [routerLink]="banner.url?.includes('/info')?banner.url+banner.id:''"
         class="w-full h-full relative flex flex-row-reverse justify-center align-middle px-3.5 py-1.5 md:flex-col shadow-lg rounded-xl  overflow-hidden text-center md:min-w-[160px] md:max-w-[180px] md:space-y-3">
        <h3 class="text-gray-500 font-semibold text-xl m-auto">{{banner.title}}</h3>
        <img *ngIf="banner.img?.uri" [src]="environment.imgUrl+banner.img?.uri?.url" alt="Imagen de la Noticia" class="min-h-[50px] w-1/4 my-auto md:h-20 md:w-auto md:mx-auto" />
      </a>
    </div>
  </div>
</div>
