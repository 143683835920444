<div class="w-full relative">
  <button id="buttonSliderIzq" *ngIf="dropDer" class="hidden md:block h-20 w-20 absolute -right-10 translate-y-[100%] z-30 bg-white rounded-full p-3 border border-secondary" (click)="onClickRight()">
    <app-icon 
    src="chevron"
    customClass="rotate-180"
    stroke="var(--wine-red)"
    />
  </button>
  <button id="buttonSliderDer" *ngIf="dropIzq" class="hidden md:block h-20 w-20 absolute -left-10 translate-y-[100%] z-30 bg-white rounded-full p-3 border border-secondary" (click)="onClickLeft()">
    <app-icon 
    src="chevron"
    stroke="var(--wine-red)"
    />
  </button>
  <div id="noticias" class="w-full relative overflow-hidden rounded-sm">
      <div *ngIf="dropDer" id="fadeToLeft" class="h-52 xl:h-72 min-w-[20px] absolute right-0 top-0 z-20"></div>
      <div *ngIf="dropIzq" id="fadeToRight" class="h-52 xl:h-72 min-w-[20px] absolute left-0 top-0 z-20"></div>
      <div class="w-full flex gap-10 overflow-scroll snap-x scroll-pl-3 rounded-md px-4 hover:cursor-grab scroll-smooth" #scrollableDiv (scroll)="onScroll()" (touchmove)="initTimeOutSlide()" >
          <article *ngFor="let noticia of noticias, let i = index"
                   [id]="'noticia-'+i"
                   class="h-52 w-8/12 lg:w-52 lg:h-52 xl:w-72 xl:h-72 relative flex flex-col items-center justify-center shadow-md rounded-xl  overflow-hidden flex-shrink-0">
              <img *ngIf="noticia.img.uri" [src]="environment.imgUrl+noticia.img.uri.url" alt="Imagen de la Noticia"
                   class="absolute top-0 left-0 w-full h-full opacity-40">
              <div
                class="w-full h-full z-10 flex flex-col justify-between backdrop-saturate-50 backdrop-sepia-0 bg-gray-200 bg-opacity-40 xl:p-6 p-1">
                  <div class="shrink"></div>
                  <div class="p-2">
                      <div class="text-center h-full flex flex-col justify-center space-y-2 p-3 m-auto ">
                          <h3 class="text-xl xl:text-3xl font-bold font-aldo line-clamp-2">{{noticia.title}}</h3>
                          <p *ngIf="noticia.body" class="md:text-lg line-clamp-2" [innerHtml]="getDescripcion(noticia.body) |safeHtml"></p>
                      </div>
                  </div>
                  <div class="w-full flex justify-center">
                      <a [routerLink]="'/noticias/'+noticia.id"
                         class="w-5/6 bg-primary hover:bg-secondary text-sm xl:text-lg button-keep-reading text-white text-center font-semibold rounded-full p-1.5">
                          Seguir leyendo
                      </a>
                  </div>
              </div>
          </article>
      </div>
    <app-dot-navigation [current]="current" [length]="noticias.length" (changeIndex)="changeIndex($event)" estilos="justify-center pt-4"/>
  </div>
</div>
