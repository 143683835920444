import { Injectable } from '@angular/core';
import { PreciosPublicosResponse } from '../../interfaces/PreciosPublicosResponse';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PreciosServices {
  private precios$: PreciosPublicosResponse | null = null;
  constructor(private http: HttpClient) {}

  getPreciosPublicos() {
    if (this.precios$) return of(this.precios$);
    else
      return this.http.get<PreciosPublicosResponse>(
        `${environment.apiBackGetUrl}/api/v1/drupal/precios-publicos`
      );
  }

  getPrecioPublicoById(id: string) {
    let params = new HttpParams();
    params = params.append('filter[id]', id);
    return this.http.get<PreciosPublicosResponse>(
      `${environment.apiBackGetUrl}/api/v1/drupal/precios-publicos`,
      {
        params,
      }
    );
  }
}
