import { Component } from '@angular/core';

@Component({
  selector: 'app-menu-accesibilidad',
  templateUrl: './menu-accesibilidad.component.html',
  styleUrls: ['./menu-accesibilidad.component.css'],
})
export class MenuAccesibilidadComponent {
  modalOpen: boolean = false;
  highContrast: boolean = false;
  stylesEnabled: boolean = true;
  lastToggle: number = 0;
  minSliderValue: number = 0;
  maxSliderValue: number = 100;
  minFontSize: number = 16;
  maxFontSize: number = 40;
  fontSize = this.minFontSize;

  constructor() {}

  toggleModal() {
    this.modalOpen = !this.modalOpen;
    return this.modalOpen;
  }

  onModalOpenChange(_: null) {
    this.modalOpen = !this.modalOpen;
  }

  toggleHighContrast() {
    if (this.lastToggle && Date.now() - this.lastToggle < 10) {
      return;
    }

    document.documentElement.classList.toggle('contrast');
    this.highContrast = !this.highContrast;

    this.lastToggle = Date.now();
  }

  modifyFontSize(value: number) {
    this.fontSize =
      ((value - this.minSliderValue) / (this.maxSliderValue - this.minSliderValue)) *
        (this.maxFontSize - this.minFontSize) +
      this.minFontSize;

    if (this.fontSize >= this.minFontSize && this.fontSize <= this.maxFontSize) {
      document.documentElement.style.setProperty('--body-font-size', `${this.fontSize}px`);
    }
  }

  toggleStyles() {
    Array.from(document.styleSheets).forEach(stylesheet => {
      (stylesheet as CSSStyleSheet).disabled = this.stylesEnabled;
      this.stylesEnabled = !this.stylesEnabled;
    });
  }
}
