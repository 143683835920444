import { Injectable } from '@angular/core';
import { of } from 'rxjs';

export interface Clase {
  id: number;
  monitorId: number;
  title: string;
  fecha: { value: string; hora: string; duracion: string };
  alumnos: { id: number }[];
  aforo: number;
  sala?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClasesService {
  constructor() {}

  getClasesByMonitorId(monitorId: number) {
    const clasesMock: { data: Clase[] } = {
      data: [
        {
          id: 0,
          monitorId: 61,
          title: 'Body Pump',
          fecha: { value: '2024-07-01', hora: '10:00', duracion: '60' },
          alumnos: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }],
          aforo: 10,
          sala: 'Sala 1',
        },
        {
          id: 1,
          monitorId: 61,
          title: 'Body Pump',
          fecha: { value: '2024-07-02', hora: '12:00', duracion: '60' },
          alumnos: [{ id: 1 }, { id: 2 }],
          aforo: 10,
          sala: 'Sala 1',
        },
        {
          id: 2,
          monitorId: 61,
          title: 'Pilates',
          fecha: { value: '2024-07-05', hora: '14:00', duracion: '60' },
          alumnos: [{ id: 1 }, { id: 2 }],
          aforo: 10,
        },
        {
          id: 3,
          monitorId: 61,
          title: 'Pilates',
          fecha: { value: '2024-07-24', hora: '16:00', duracion: '60' },
          alumnos: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
          aforo: 10,
        },
        {
          id: 3,
          monitorId: 61,
          title: 'Pilates',
          fecha: { value: '2024-08-16', hora: '16:00', duracion: '60' },
          alumnos: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
          aforo: 10,
        },
        {
          id: 3,
          monitorId: 61,
          title: 'Pilates',
          fecha: { value: '2024-08-24', hora: '16:00', duracion: '60' },
          alumnos: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
          aforo: 10,
        },
      ],
    };

    return of(clasesMock);
  }
}
