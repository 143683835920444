import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-reproductor-video-slide',
  templateUrl: './reproductor-video-slide.component.html',
  styleUrls: ['./reproductor-video-slide.component.css'],
})
export class ReproductorVideoSlideComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('sliderProgress') sliderProgress: ElementRef;
  @ViewChild('slider') slider: ElementRef;

  @Input() value: number = 0;
  @Input() canMoveSlide: boolean = false;
  @Input() max: number = 100;
  @Input() step: number = 1;

  @Output() valueChange: EventEmitter<number> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value'] && !changes['value'].firstChange) {
      this.updateSliderProgressFromValue();
    }
    if (
      changes['canMoveSlide'] &&
      !changes['canMoveSlide'].firstChange &&
      changes['canMoveSlide'].currentValue !== changes['canMoveSlide'].previousValue
    ) {
      if (this.canMoveSlide) {
        this.removeListeners();
        this.updateSliderProgress();
        this.addListeners();
      }
    }
  }

  ngOnDestroy() {
    this.removeListeners();
  }

  ngAfterViewInit(): void {
    if (this.canMoveSlide) {
      this.updateSliderProgress();
      this.addListeners();
    }
    this.updateSliderProgressFromValue();
  }

  removeListeners() {
    if (this.slider?.nativeElement) {
      this.slider?.nativeElement?.removeEventListener('click', this.updateSliderProgress);
      this.slider?.nativeElement?.removeEventListener('mousemove', this.updateSliderProgress);
    }
  }

  addListeners() {
    this.slider?.nativeElement?.addEventListener('click', this.updateSliderProgress.bind(this));
    // this.slider.nativeElement.addEventListener('mousemove', this.updateSliderProgress.bind(this));
  }

  updateSliderProgressFromValue() {
    const progress = 100 - (this.value / this.max) * 100;
    this.sliderProgress.nativeElement.style.left = `-${progress}%`;
  }

  updateSliderProgress() {
    const progress = (this.slider?.nativeElement?.value / this.max) * 100;
    this.sliderProgress.nativeElement.style.left = `-${progress}%`;
    this.valueChange.emit(progress);
  }
}
