<app-page-with-breadcrumb>
  <app-pages-loader [condition]="precio == null" [Apierror]="apiStatus.error">
    <main *ngIf="!apiStatus.loading && !apiStatus.error">
      <h1>{{ precio?.title | uppercase }}</h1>
      <header *ngIf="precio?.actividad?.descripcion">
        <h2>Descripción</h2>
        <p [innerHTML]="precio?.actividad?.descripcion"></p>
      </header>
      <article>
        <h3>{{ precio?.actividad?.name | uppercase }}</h3>
        <table>
          <tr>
            <th>Concepto</th>
            <th>
              {{ precio?.actividad?.name == 'BONIFICACIONES' ? 'Porcentaje' : 'Precio' }}
            </th>
          </tr>
          <tr *ngFor="let concepto of precio?.conceptos">
            <td>{{ concepto.title }}</td>
            <td ngClass='percentages'>
              {{ concepto.precio | number : '1.2-2' }}
              {{ precio?.actividad?.name == 'BONIFICACIONES' ? '%' : '€' }}
            </td>
          </tr>
        </table>
      </article>
      <footer *ngIf="precio?.archivos_adjuntos?.length !=0">
        <ng-container *ngFor="let file of precio?.archivos_adjuntos">
          <h3>DESCARGAS</h3>
          <app-file-downloader [url]="file.url || null" [filename]="file.filename"
            [description]="file.description || null" />
        </ng-container>
      </footer>
    </main>
  </app-pages-loader>
</app-page-with-breadcrumb>