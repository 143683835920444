import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NoticiaResponse } from '../../interfaces/NoticiaResponse';
import { AvisoResponse } from '../../interfaces/AvisoResponse';
import { ConvocatoriaResponse } from '../../interfaces/ConvocatoriaResponse';

@Injectable({
  providedIn: 'root',
})
export class NoticiasService {
  constructor(private http: HttpClient) {}

  getNoticias(): Observable<NoticiaResponse> {
    return this.http.get<NoticiaResponse>(`${environment.apiBackGetUrl}/api/v1/drupal/noticias`, {
      params: {
        'page[limit]': 100,
      },
    });
  }

  getNoticiaById(id: string): Observable<any[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('page[limit]', 100).append('filter[id]', id);
    const noticias = this.http.get<NoticiaResponse>(
      `${environment.apiBackGetUrl}/api/v1/drupal/noticias`,
      {
        params,
      }
    );
    const avisos = this.http.get<AvisoResponse>(
      `${environment.apiBackGetUrl}/api/v1/drupal/avisos`,
      { params }
    );
    const convocatorias = this.http.get<ConvocatoriaResponse>(
      `${environment.apiBackGetUrl}/api/v1/drupal/convocatorias`,
      { params }
    );

    return forkJoin([noticias, avisos, convocatorias]);
  }
}
