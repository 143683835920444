import { Component, Input } from '@angular/core';
import { Ruta } from 'src/app/interfaces/RutasResponse';

@Component({
  selector: 'app-route-card',
  templateUrl: './route-card.component.html',
  styleUrls: ['./route-card.component.css'],
})
export class RouteCardComponent {
  @Input() cargando: boolean = false;
  @Input() ruta: Ruta = {} as Ruta;
  @Input() vista: string = 'grid';
  @Input() zoom: number = 14;
}
