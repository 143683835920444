import dayjs from 'dayjs';

export function convertTimestampToStringMinSec(timestamp: number): null | string {
  const totalSeconds = Math.floor(timestamp / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  if (isNaN(minutes) || isNaN(seconds) || !minutes || !seconds) {
    return null;
  }
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export function convertStringMinSecToTimestamp(time: string): number {
  const timeDivider = time.split(':');
  if (timeDivider.length !== 2) return null;
  const min = parseInt(timeDivider[0]);
  const seg = parseInt(timeDivider[1]);
  if (isNaN(seg) || isNaN(min) || !seg || !min) return null;
  return (min * 60 + seg) * 1000;
}

export function calculateAge(birthDay: Date): number {
  const birth = dayjs(birthDay);
  const today = dayjs();
  return today.diff(birth, 'year');
}
