import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { Breadcrumb } from '../../models/breadcrumb/breadcrumb';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css'],
})
export class BreadcrumbsComponent implements OnInit {
  breadcrumbs: Breadcrumb[] = [];
  currentUrl: string = '';
  encodedUrl: string = '';

  constructor(private breadcrumbService: BreadcrumbService, private chdect: ChangeDetectorRef) {}

  ngOnInit() {
    this.currentUrl = window.location.pathname;
    setTimeout(() => {
      this.breadcrumbs = this.breadcrumbService.breadcrumbs;
      this.chdect.detectChanges();
    }, 50);
  }

  decode(url: string): string {
    return decodeURI(url);
  }

  removeFutureBreadcrumbs(index: number) {
    this.breadcrumbService.removeBreadcrumbs(index);
  }
}
