import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination-container',
  templateUrl: './pagination-container.component.html',
  styleUrls: ['./pagination-container.component.css'],
})
export class PaginationContainerComponent {
  @Input() data!: any[];
  @Input() pageSize!: number;
  currentPage: number = 1;
  maxPages: number = 0;
  currentPageData: any[] = [];
  @Output() emitData = new EventEmitter<any[]>();
  @Input() showUpperButtons: boolean = false;
  @Input() showLowerButtons: boolean = true;

  ngOnChanges() {
    if (this.data) {
      this.maxPages = Math.ceil(this.data.length / this.pageSize);
      this.currentPage = 1;
      this.showCurrentPageData();
    }
  }

  showCurrentPageData() {
    const start = (this.currentPage - 1) * this.pageSize;
    const end = start + this.pageSize;
    this.currentPageData = this.data.slice(start, end);
    this.emitData.emit(this.currentPageData);
  }

  nextPage() {
    if (this.currentPage < this.maxPages) {
      this.currentPage++;
      this.showCurrentPageData();
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.showCurrentPageData();
    }
  }
}
