<div id="listado" class="w-full flex flex-col space-y-7 pb-6">
  <div class="flex w-full justify-between">
    <button
      id="desplegable-listado"
      class="w-full flex flex-row my-auto items-center cursor-pointer text-black font-bold text-lg"
      (click)="open = !open"
    >
      <img
        [class]="open ? 'rotate-90' : 'rotate-0'"
        class="h-6 w-6 transition-all"
        src="/assets/icons/flecha.svg"
        alt="Flecha"
      />
      LISTADO
    </button>
    <div *ngIf="open && instalaciones.length != 0" class="flex justify-end w-1/6">
      <button id="button-vista-grid" class="w-8" (click)="vista = 'grid'">
        <img src="/assets/icons/grid.svg" alt="Tarjeta" />
      </button>
      <button id="button-vista-list" class="w-8" (click)="vista = 'list'">
        <img src="/assets/icons/list.svg" alt="Lista" />
      </button>
    </div>
  </div>
  <!--  Resultados Vista Tarjetas-->
  <div id="resultado-listado" class="flex flex-col" [ngClass]="open ? '' : 'hidden'">
    <div
      *ngIf="vista === 'grid'"
      id="tarjetas"
      class="grid grid-cols-2 md:grid-cols-3 gap-3 w-full"
    >
      <article
        *ngFor="let instalacion of instalaciones"
        class="p-4 border hover:cursor-pointer"
        [routerLink]="instalacion.url ? null : '/instalaciones/' + instalacion.id"
        (click)="instalacion.url ? navigate(instalacion.url) : null"
      >
        <h2 class="text-lg mb-2 text-primary font-semibold">{{ instalacion.title }}</h2>
        <!--        <div *ngIf="instalacion.tipo[0].name">-->
        <!--          <h3 class="font-semibold">Tipo de Instalación:</h3>-->
        <!--          <p>{{ instalacion.tipo[0].name }}</p>-->
        <!--        </div>-->
        <div *ngIf="instalacion.direccion">
          <h3 class="font-semibold">Dirección:</h3>
          <p>{{ instalacion.direccion }}</p>
        </div>
        <div *ngIf="instalacion.telefono" class="hidden md:block">
          <h3 class="font-semibold">Telefono:</h3>
          <p>{{ instalacion.telefono }}</p>
        </div>
        <div *ngIf="instalacion.lineas" class="hidden md:block">
          <h3 class="font-semibold">Lineas de Autobuses:</h3>
          <p>{{ instalacion.lineas }}</p>
        </div>
        <div *ngIf="instalacion.horario" class="hidden md:block">
          <h3 class="font-semibold">Horario:</h3>
          <p>{{ instalacion.horario }}</p>
        </div>
      </article>
    </div>
    <!--    Resultados Vista Tabla-->
    <div *ngIf="vista === 'list'" class="w-full">
      <table id="lista" class="table-fixed w-auto hidden lg:block">
        <tr class="text-center">
          <th class="w-1/6">Nombre Instalación</th>
          <th class="w-1/6">Tipo de Instalación</th>
          <th class="w-1/6">Dirección</th>
          <th class="w-1/6">Teléfono</th>
          <th class="w-1/6">Líneas de Autobuses</th>
          <th class="w-1/6">Horario</th>
        </tr>
        <tr
          *ngFor="let instalacion of instalaciones"
          class="border cursor-pointer"
          [routerLink]="'/instalaciones/' + instalacion.id"
        >
          <td class="w-1/6 text-lg text-center p-2 text-primary font-semibold">
            {{ instalacion.title }}
          </td>
          <td class="w-1/6 p-2 text-center">
            {{ instalacion.tipo[0] ? instalacion!.tipo[0]!.name : '' }}
          </td>
          <td class="w-1/6 p-2">{{ instalacion?.direccion }}</td>
          <td class="w-1/6 p-2 text-center">{{ instalacion?.telefono }}</td>
          <td class="w-1/6 p-2 text-center">{{ instalacion?.lineas }}</td>
          <td class="w-1/6 p-2">{{ instalacion?.horario }}</td>
        </tr>
      </table>

      <app-acordeon
        class="lg:hidden"
        [id]="instalacion.titleslug"
        *ngFor="let instalacion of instalaciones"
        [instalacion]="instalacion"
      />
    </div>
    <div class="w-full flex flex-row justify-evenly mt-3 text-xl text-center">
      <button
        id="paginacion-prev"
        *ngIf="paginacion?.prev"
        class="m-auto hover:text-primary"
        (click)="prevInstalaciones()"
      >
        « Anterior
      </button>
      <button
        id="paginacion-next"
        *ngIf="paginacion?.next"
        class="m-auto hover:text-primary"
        (click)="nextInstalaciones()"
      >
        Siguiente »
      </button>
    </div>
  </div>
</div>
