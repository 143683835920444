import { Injectable } from '@angular/core';
import { PreciosPublicos } from '../interfaces/PreciosPublicosResponse';

@Injectable({
  providedIn: 'root',
})
export class ConvertersService {
  constructor() {}

  convertPreciosFromApi(precios: PreciosPublicos[]) {
    let newData: any = [];
    precios.forEach(value => {
      if (!newData[value.actividad.name]) {
        newData[value.actividad.name] = [];
      }
      newData[value.actividad.name].push(value);
    });
    return Object.values(newData);
  }
}
