import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Instalacion } from '../../interfaces/InstalacionesResponse';
import { HttpParams } from '@angular/common/http';
import { MainService } from '../../services/main.service';
import { Deporte } from '../../interfaces/DeportesResponse';
import { Escuela } from '../../interfaces/EscuelasResponse';
import { Noticia } from '../../interfaces/NoticiaResponse';
import { Convocatoria } from '../../interfaces/ConvocatoriaResponse';
import { Aviso } from '../../interfaces/AvisoResponse';
import { Ruta } from '../../interfaces/RutasResponse';
import { ApiStatus } from '../../interfaces';
import { catchError, of, switchMap } from 'rxjs';
import { sanitize } from '../../utils/text';

@Component({
  selector: 'app-modal-busqueda',
  templateUrl: './modal-busqueda.component.html',
  styleUrls: ['./modal-busqueda.component.css'],
})
export class ModalBusquedaComponent implements OnInit, OnDestroy {
  @ViewChild('modalBusqueda') content?: ElementRef<HTMLElement>;
  @Output() closeModal = new EventEmitter<boolean>();
  @Input() search: string = '';

  apiStatus: ApiStatus = { loading: false, error: false };

  instalaciones: Instalacion[] = [];
  deportes: Deporte[] = [];
  escuelas: Escuela[] = [];
  noticias: Noticia[] = [];
  avisos: Aviso[] = [];
  convocatorias: Convocatoria[] = [];
  rutas: Ruta[] = [];

  constructor(private mainService: MainService) {}

  @HostListener('click', ['$event'])
  handleClick(event: Event) {
    if (!this.content?.nativeElement.contains(event.target as HTMLElement)) {
      this.close();
    }
  }
  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  ngOnInit() {
    document.getElementById('search-input')?.focus();
    document.body.classList.add('overflow-hidden');
    this.buscar();
  }

  ngOnDestroy() {
    document.body.classList.remove('overflow-hidden');
  }

  close() {
    this.search = '';
    this.closeModal.emit(false);
  }

  hasFoundMatch() {
    return (
      this.instalaciones.length == 0 &&
      this.deportes.length == 0 &&
      this.escuelas.length == 0 &&
      this.avisos.length == 0 &&
      this.convocatorias.length == 0 &&
      this.noticias.length == 0
    );
  }

  buscar() {
    const sanitizeString = sanitize(this.search);
    if (this.search === '') return;

    this.avisos =
      this.instalaciones =
      this.deportes =
      this.escuelas =
      this.noticias =
      this.convocatorias =
      this.rutas =
        [];

    let params: HttpParams = new HttpParams();
    params = params
      .append('filter[search-filter][condition][path]', 'titleslug')
      .append('filter[search-filter][condition][operator]', 'CONTAINS')
      .append('filter[search-filter][condition][value]', sanitizeString);
    this.apiStatus = { loading: true, error: false };
    this.mainService
      .busquedaGeneral(params)
      .pipe(
        catchError(_ => {
          this.apiStatus = { loading: false, error: true };
          return of();
        }),
        switchMap(([deportes, instalaciones, escuelas, noticias, avisos, convocatorias, rutas]) => {
          this.deportes = deportes.data;
          this.instalaciones = instalaciones.data;
          this.escuelas = escuelas.data;
          this.noticias = noticias.data;
          this.avisos = avisos.data;
          this.convocatorias = convocatorias.data;
          this.rutas = rutas.data;
          this.apiStatus = { loading: false, error: false };
          this.filtrarInstalaciones(sanitizeString);
          return of();
        })
      )
      .subscribe();

    document.getElementById('search-input')?.focus();
  }

  filtrarInstalaciones(searchSanitized: string) {
    this.instalaciones = this.instalaciones.filter(instalacion => {
      if (instalacion.titleslug == searchSanitized) {
        return true;
      } else if (instalacion?.tipo && Array.isArray(instalacion?.tipo)) {
        return instalacion?.tipo?.some(tipo =>
          sanitize(tipo?.name || '').includes(searchSanitized)
        );
      } else return false;
    });
  }
}
