import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Instalacion } from '../../interfaces/InstalacionesResponse';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-imagen-modal',
  templateUrl: './imagen-modal.component.html',
  styleUrls: ['./imagen-modal.component.css'],
})
export class ImagenModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() instalacion!: Instalacion;
  @Input() selected: string = '';
  currentIndex: number = 0;
  images: any[] = [];
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.close.emit(false);
    }
  }

  ngOnInit() {
    document.body.classList.add('overflow-hidden');
    if (this.instalacion.img?.hasOwnProperty('type'))
      this.images.push({ url: this.instalacion.img?.uri?.url, id: this.instalacion.img.id });
    if (this.instalacion.img_orig?.hasOwnProperty('type'))
      this.images.push({
        url: this.instalacion.img_orig?.uri?.url,
        id: this.instalacion.img_orig.id,
      });
    if (this.instalacion.img2?.hasOwnProperty('type'))
      this.images.push({ url: this.instalacion.img2?.uri?.url, id: this.instalacion.img2.id });
    if (this.instalacion.img2_orig?.hasOwnProperty('type'))
      this.images.push({
        url: this.instalacion.img2_orig?.uri?.url,
        id: this.instalacion.img2_orig.id,
      });
  }

  ngAfterViewInit() {
    this.currentIndex = this.images.findIndex(obj => obj.id === this.selected);
    document.querySelector(`#imagen-${this.currentIndex}`)?.scrollIntoView({ block: 'nearest' });
  }

  ngOnDestroy() {
    document.body.classList.remove('overflow-hidden');
  }
  anterior() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.images.length - 1;
    }
    document.querySelector(`#imagen-${this.currentIndex}`)?.scrollIntoView({ block: 'nearest' });
  }

  siguiente() {
    if (this.images.length - 1 > this.currentIndex) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }
    document.querySelector(`#imagen-${this.currentIndex}`)?.scrollIntoView({ block: 'nearest' });
  }

  protected readonly environment = environment;
}
