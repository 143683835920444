<div class="full-container">
  <div class="info">
    <div class="title">
      <div class="title-icon">
        <app-icon [src]="getIcon(actualEjercicio?.icon)" fill="white" />
      </div>
      <h5>{{ actualEjercicio?.title }}</h5>
    </div>
    <!--    <div *ngIf="actualEjercicio?.observaciones" class="observaciones-actualEjercicio">-->
    <!--      <h6>OBSERVACIONES GENERALES</h6>-->
    <!--      <p>{{ actualEjercicio?.observaciones }}</p>-->
    <!--    </div>-->
    <!--      TODO: Cambiar esto a observaciones de línea de actualEjercicio-->
    <div *ngIf="actualEjercicio?.observaciones" class="observaciones-individuales">
      <h6>OBSERVACIONES INDIVIDUALES</h6>
      <p>{{ actualEjercicio?.observaciones }}</p>
    </div>
    <div class="descripcion">
      <h6>FUNCIÓN</h6>
      <p>{{ actualEjercicio?.descripcion }}</p>
    </div>
    <div class="buttons-navigate">
      <button [disabled]="isDisabledClickPrev" (click)="onPrevClick()">Volver atrás</button>
      <div class="divider"></div>
      <button [disabled]="isDisabledClickNext" (click)="onNextClick()">Siguiente</button>
    </div>
  </div>
  <div class="video">
    <app-reproductor-video [src]="srcVideo" [posterSrc]="srcImg" />
    <div class="close">
      <app-button icon="menos" strokeColors="black" (click)="cerrar.emit(true)" />
    </div>
  </div>
</div>
