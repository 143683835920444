import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { primaryColor } from '../../utils/globalColors';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css'],
})
export class ButtonComponent implements OnChanges {
  @Input() icon: string | null = null;
  @Input() iconPosition: string = 'left';
  @Input() label?: string;
  @Input() loading: boolean = false;
  @Input() disabled: boolean = false;
  @Input() fillColors: string[] | string = [primaryColor, 'white'];
  @Input() strokeColors: string[] | string;
  @Output() onClick? = new EventEmitter<any>();
  @Input() type?: string;
  @Input() classList: string | null = null;
  isHover: boolean = false;
  fillColor: string = primaryColor;
  strokeColor: string = 'none';
  hoverStrokeColor: string = 'none';
  hoverFillColor: string = 'white';

  ngOnChanges(changes: SimpleChanges) {
    if (typeof this.fillColors == 'string') {
      this.fillColor = this.fillColors;
      this.hoverFillColor = this.fillColors;
    } else {
      this.fillColor = this.fillColors[0];
      this.hoverFillColor = this.fillColors[1];
    }
    if (this.strokeColors) {
      if (typeof this.strokeColors == 'string') {
        this.strokeColor = this.strokeColors;
        this.hoverStrokeColor = this.strokeColors;
      } else {
        this.strokeColor = this.strokeColors[0];
        this.hoverStrokeColor = this.strokeColors[1];
      }
    }
  }

  click() {
    if (this.onClick && !this.disabled) {
      this.onClick.emit();
    }
  }
}
