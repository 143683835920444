import { Injectable } from '@angular/core';
import { Breadcrumb } from '../models/breadcrumb/breadcrumb';
import { Title } from '@angular/platform-browser';
import { initial_title } from '../utils/breadcrumbs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  breadcrumbs: Breadcrumb[] = [];
  previousUrl: string | null = null;
  currentUrl: string | null = null;

  constructor(private titleService: Title) {
    this.breadcrumbs = this.loadBreadcrumbs(
      JSON.parse(sessionStorage.getItem('breadcrumbs') || '[]')
    );
  }

  loadBreadcrumbs(array: { label: string; url: string; params: any }[]): Breadcrumb[] {
    return array.map(
      ({ label, url, params }) => new Breadcrumb({ label: label, url: url, params: params })
    );
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  getCurrentTitle(): string {
    let title = this.titleService.getTitle().split(' - ')[0];
    if (title === initial_title) return 'Inicio';
    if (title.length > 23) title = title.slice(0, 20) + '...';
    return title;
  }

  trimBreadcrumbsOnCurrentUrl() {
    let url = window.location.pathname;
    this.breadcrumbs.forEach((breadcrumb, index) => {
      if (url === breadcrumb.url) this.breadcrumbs = this.breadcrumbs.slice(0, index + 1);
    });
  }

  getParams() {
    let params = new URLSearchParams(window.location.search);
    let urlParams: { [key: string]: string } = {};
    params.forEach((value, key) => {
      urlParams[key] = value;
    });
    return urlParams || {};
  }

  breadcrumbAlreadyExists(bread: Breadcrumb) {
    return this.breadcrumbs.some(breadcrumb => {
      return (
        (breadcrumb.url === bread.url || encodeURI(breadcrumb.url) === bread.url) &&
        JSON.stringify(breadcrumb.params) === JSON.stringify(bread.params)
      );
    });
  }

  public addCurrentBreadcrumb() {
    this.trimBreadcrumbsOnCurrentUrl();
    const breadcrumb = new Breadcrumb({
      label: this.getCurrentTitle(),
      url: window.location.pathname,
      params: this.getParams(),
    });
    if (this.breadcrumbAlreadyExists(breadcrumb)) return;
    this.breadcrumbs.push(breadcrumb);
    this.storeBreadcrumbs(this.breadcrumbs);
  }

  storeBreadcrumbs(breadcrumbs: Breadcrumb[]) {
    sessionStorage.setItem('breadcrumbs', JSON.stringify(breadcrumbs));
  }

  removeBreadcrumbs(index: number) {
    this.breadcrumbs = this.breadcrumbs.slice(0, index + 1);
    this.storeBreadcrumbs(this.breadcrumbs);
  }

  clearBreadcrumbs() {
    this.previousUrl = null;
    this.breadcrumbs = [];
    sessionStorage.removeItem('breadcrumbs');
  }
}
