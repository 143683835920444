import { ChangeDetectorRef, Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Deporte, DeportesResponse, deportesForDownload } from '../../interfaces/DeportesResponse';
import { MainService } from '../../services/main.service';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { catchError, of, tap } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { getTitle } from '../../utils/breadcrumbs';
import { SelectInputOptions } from 'src/app/form/select-input/select-input.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ExportCsvService } from 'src/app/services/export-csv.service';
import { ExportPdfService } from 'src/app/services/export-pdf.service';

interface filterFormInterface {
  sport: FormControl;
  edadSelector: FormControl;
}

@Component({
  selector: 'app-deportes',
  templateUrl: './deportes.component.html',
  styleUrls: ['./deportes.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeportesComponent implements OnInit {
  deportes: Deporte[] = [];
  deportesFiltrados: Deporte[] = [];
  currentFragment: string = '';
  Apierror: boolean = false;
  deportesSelect: SelectInputOptions[] = [];
  initialValues = {
    sport: '',
    administrativeConcession: false,
    edadSelector: 0,
  };

  currentPageData: any[] = [];
  handleEmitData(data: any[]) {
    this.currentPageData = data;
  }

  filterForm = this.fb.group({
    sport: new FormControl(this.initialValues.sport),
    edadSelector: new FormControl(this.initialValues.edadSelector),
  }) as FormGroup<filterFormInterface>;

  constructor(
    private deportesService: MainService,
    private route: ActivatedRoute,
    private router: Router,
    private breadCrumbService: BreadcrumbService,
    private cdRef: ChangeDetectorRef,
    private title: Title,
    private fb: FormBuilder,
    private exportCsvService: ExportCsvService,
    private exportPdfService: ExportPdfService
  ) {
    this.filterForm.valueChanges.subscribe(values => {
      this.filtrar();
      this.newSelectInfo();
    });
  }

  ngOnInit() {
    this.title.setTitle(getTitle('Deportes'));
    this.breadCrumbService.addCurrentBreadcrumb();
    const params: HttpParams = new HttpParams();
    this.deportesService
      .getDeportes(params)
      .pipe(
        catchError(() => {
          return of();
        }),
        tap((data: DeportesResponse) => {
          this.Apierror = false;
          this.deportes = this.deportesFiltrados = data.data;
          this.newSelectInfo();
          this.cdRef.detectChanges();
        }),
        tap(() => {
          this.route.fragment.subscribe(fragment => {
            if (fragment && document.getElementById(fragment)) {
              this.currentFragment = fragment;
              setTimeout(() => {
                document.getElementById(fragment)?.scrollIntoView({ behavior: 'smooth' });
              }, 300);
            }
          });
        })
      )
      .subscribe();
  }

  newSelectInfo() {
    this.deportesSelect = this.deportesFiltrados.map(deporte => ({
      label: deporte.title,
      value: deporte.titleslug,
    }));
  }

  onError() {
    this.Apierror = true;
  }

  trackByDeportes(index: number, deporte: Deporte) {
    return deporte.id;
  }

  filtrar() {
    this.deportesFiltrados = [...this.deportes];
    this.deportesFiltrados = this.deportesFiltrados.filter(
      deporte =>
        this.filterBySport(deporte) && this.filterByAge(deporte, this.filterForm.value.edadSelector)
    );
  }

  filterBySport(deporte: Deporte) {
    return deporte.titleslug?.toLowerCase().includes(this.filterForm.value.sport.toLowerCase());
  }

  filterByAge(deporte: Deporte, edad: number) {
    if (edad === 0) {
      return true;
    }
    return (
      (deporte.edad_minima ?? 0) <= this.filterForm.value.edadSelector &&
      (deporte.edad_maxima ?? Infinity) >= this.filterForm.value.edadSelector
    );
  }

  clearSelection() {
    this.filterForm.reset(this.initialValues);
  }

  onSeeMoreClick(id: string) {
    this.router.navigate([id], { relativeTo: this.route });
  }

  CSVdownload() {
    this.exportCsvService.generateXLSX(deportesForDownload(this.currentPageData), 'deportes.xlsx');
  }
  PDFdownload() {
    this.exportPdfService.generatePdf(deportesForDownload(this.currentPageData));
  }
}
