import { Component, Input } from '@angular/core';
import { Ruta } from 'src/app/interfaces/RutasResponse';

@Component({
  selector: 'app-route-acordeon',
  templateUrl: './route-acordeon.component.html',
  styleUrls: ['./route-acordeon.component.css'],
})
export class RouteAcordeonComponent {
  rutas: Ruta[] = [];
  open: boolean = false;
}
