import { Component, HostListener, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';
import { Convocatoria, ConvocatoriaResponse } from '../../interfaces/ConvocatoriaResponse';
import { NoticiasService } from '../../noticias/services/noticias.service';
import { Noticia, NoticiaResponse } from '../../interfaces/NoticiaResponse';
import { Title } from '@angular/platform-browser';
import { getTitle } from 'src/app/utils/breadcrumbs';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { IncidenciasService } from '../../incidencias/services/incidencias.service';
import { IncidenciasResponse } from '../../interfaces/IncidenciasResponse';
import { AvisoResponse } from 'src/app/interfaces/AvisoResponse';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  avisos: any[] = [];
  incidencias: any[] = [];
  convocatorias: Convocatoria[] = [];
  noticias: Noticia[] = [];

  height: number = 0;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.heightImagenHome();
  }

  constructor(
    public mainService: MainService,
    private noticiaService: NoticiasService,
    private incidenciasServices: IncidenciasService,
    private breadcrumbService: BreadcrumbService,
    private titleService: Title
  ) {
    this.titleService.setTitle(getTitle());
  }

  ngOnInit() {
    this.breadcrumbService.addCurrentBreadcrumb();
    this.mainService.getAvisos().subscribe((data: AvisoResponse) => {
      this.avisos = data.data;
    });
    this.incidenciasServices.getAllIncidencias().subscribe((data: IncidenciasResponse) => {
      this.incidencias = data.data.filter(incidence => !incidence['archivada']);
    });

    this.mainService.getConvocatorias().subscribe((data: ConvocatoriaResponse) => {
      this.convocatorias = data.data;
    });

    this.noticiaService.getNoticias().subscribe((data: NoticiaResponse) => {
      this.noticias = data.data;
    });

    this.heightImagenHome();
  }

  heightImagenHome() {
    const footer = document.getElementsByTagName('footer')[0]?.offsetHeight;
    const header = document.getElementsByTagName('header')[0]?.offsetHeight;
    const banner = document.getElementById('banner')?.offsetHeight || 0;

    this.height = window.innerHeight - (footer + header + banner!);

    const img = document.getElementById('imagen-home');
    const imgWide = document.getElementById('imagen-home-wide');

    img!.style.minHeight = `${this.height}px`;
    imgWide!.style.minHeight = `${this.height}px`;
  }
}
