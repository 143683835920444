<ng-template #paginator>
    <ng-container *ngIf="currentPage > 1">
        <button (click)="prevPage()">Anterior</button>
    </ng-container>
    <span *ngIf="maxPages > 1">{{currentPage}} - {{maxPages}}</span>
    <ng-container *ngIf="currentPage < maxPages">
        <button (click)="nextPage()">Siguiente</button>
    </ng-container>
</ng-template>
<main>
    <section *ngIf="showUpperButtons">
        <ng-container *ngTemplateOutlet="paginator"></ng-container>
    </section>
    <ng-content></ng-content>
    <section *ngIf="showLowerButtons">
        <ng-container *ngTemplateOutlet="paginator"></ng-container>
    </section>
</main>
