<div class="fixed top-0 left-0 z-50 w-full h-screen flex justify-center bg-black bg-opacity-20">
  <div
    id="modal-busqueda"
    #modalBusqueda
    class="w-11/12 md:w-1/3 h-2/3 bg-white mt-28 rounded-lg overflow-auto"
  >
    <div class="w-full flex flex-row justify-between items-center p-4">
      <img
        src="/assets/icons/lupa.svg"
        alt="Buscar"
        class="w-10 cursor-pointer"
        (click)="buscar()"
      />
      <input
        id="search-input"
        type="text"
        [(ngModel)]="search"
        (keydown.enter)="buscar()"
        placeholder="Instalación | Deporte | Escuela"
        class="w-full h-full p-3 outline-none placeholder-transparent md:placeholder-primary"
      />
      <button
        id="cerrar-modal"
        class="w-10 p-1 border-gray-400 border rounded-lg m-auto"
        (click)="close()"
      >
        ESC
      </button>
    </div>
    <hr class="w-full" />
    <!--    Si no hay datos-->
    <div id="noData-busqueda" *ngIf="hasFoundMatch() && !apiStatus.loading" class="w-full">
      <h4 class="w-full text-primary font-aldo text-xl mt-8 text-center">
        La búsqueda no coincide con ningún elemento en específico
      </h4>
    </div>
    <!--    Loader-->
    <div id="loader-busqueda-general" *ngIf="apiStatus.loading" class="w-full text-center mt-8">
      <h4 class="text-3xl text-primary font-aldo m-auto">Buscando</h4>
      <svg
        id="L5"
        class="w-20 m-auto justify-center flex"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <circle fill="#aa0400" stroke="none" cx="6" cy="50" r="6">
          <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 15 ; 0 -15; 0 15"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle fill="#aa0400" stroke="none" cx="30" cy="50" r="6">
          <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 10 ; 0 -10; 0 10"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle fill="#aa0400" stroke="none" cx="54" cy="50" r="6">
          <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 5 ; 0 -5; 0 5"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
    </div>
    <!--    Resultados-->
    <div class="w-full p-4 pt-0">
      <div *ngIf="instalaciones.length != 0">
        <h2 class="text-2xl mb-2 font-aldo text-primary pt-4">Instalaciones</h2>
        <div>
          <article
            [routerLink]="'/instalaciones/' + instalacion.id"
            *ngFor="let instalacion of instalaciones"
            class="m-auto p-2 cursor-pointer hover:bg-secondary hover:bg-opacity-20"
            (click)="close()"
          >
            <p>{{ instalacion.title }}</p>
          </article>
        </div>
        <hr class="w-full mt-3" />
      </div>
      <div *ngIf="deportes.length != 0">
        <h2 class="text-2xl mb-2 font-aldo text-primary pt-4">Deportes</h2>
        <div>
          <article
            routerLink="/deportes"
            [fragment]="deporte.titleslug"
            *ngFor="let deporte of deportes"
            class="m-auto p-2 cursor-pointer hover:bg-secondary hover:bg-opacity-20"
            (click)="close()"
          >
            <p>{{ deporte.title }}</p>
          </article>
        </div>
        <hr class="w-full mt-3" />
      </div>
      <div *ngIf="escuelas.length != 0">
        <h2 class="text-2xl mb-2 font-aldo text-primary pt-4">Escuelas</h2>
        <div>
          <article
            [routerLink]="'/escuelas/' + escuela.titleslug"
            *ngFor="let escuela of escuelas"
            class="m-auto p-2 cursor-pointer hover:bg-secondary hover:bg-opacity-20"
            (click)="close()"
          >
            <p>{{ escuela.title }}</p>
          </article>
        </div>
        <hr class="w-full mt-3" />
      </div>
      <div *ngIf="noticias.length != 0">
        <h2 class="text-2xl mb-2 font-aldo text-primary pt-4">Noticias</h2>
        <div>
          <article
            [routerLink]="'/noticias/' + noticia.id"
            *ngFor="let noticia of noticias"
            class="m-auto p-2 cursor-pointer hover:bg-secondary hover:bg-opacity-20"
            (click)="close()"
          >
            <p>{{ noticia.title }}</p>
          </article>
        </div>
        <hr class="w-full mt-3" />
      </div>
      <div *ngIf="avisos.length != 0">
        <h2 class="text-2xl mb-2 font-aldo text-primary pt-4">Avisos</h2>
        <div>
          <article
            [routerLink]="'/noticias/' + aviso.id"
            *ngFor="let aviso of avisos"
            class="m-auto p-2 cursor-pointer hover:bg-secondary hover:bg-opacity-20"
            (click)="close()"
          >
            <p>{{ aviso.title }}</p>
          </article>
        </div>
        <hr class="w-full mt-3" />
      </div>
      <div *ngIf="convocatorias.length != 0">
        <h2 class="text-2xl mb-2 font-aldo text-primary pt-4">Convocatorias</h2>
        <div>
          <article
            [routerLink]="'/noticias/' + convocatoria.id"
            *ngFor="let convocatoria of convocatorias"
            class="m-auto p-2 cursor-pointer hover:bg-secondary hover:bg-opacity-20"
            (click)="close()"
          >
            <p>{{ convocatoria.title }}</p>
          </article>
        </div>
        <hr class="w-full mt-3" />
      </div>
      <div *ngIf="rutas.length != 0">
        <h2 class="text-2xl mb-2 font-aldo text-primary pt-4">Rutas</h2>
        <div>
          <article
            [routerLink]="'/rutas/' + ruta.id"
            *ngFor="let ruta of rutas"
            class="m-auto p-2 cursor-pointer hover:bg-secondary hover:bg-opacity-20"
            (click)="close()"
          >
            <p>{{ ruta.title }}</p>
          </article>
        </div>
        <hr class="w-full mt-3" />
      </div>
    </div>
  </div>
</div>
