import { Escuela } from './EscuelasResponse';
import { Competiciones, DatumLinks } from './DeportesResponse';
import { InstalacionLinks, UidMeta } from './IncidenciasResponse';

export interface InstalacionesResponse {
  jsonapi: Jsonapi;
  data: Instalacion[];
  meta: InstalacionesResponseMeta;
  links: InstalacionesResponseLinks;
}

export interface Instalacion {
  drupal_internal__nid: number;
  type: string;
  id: string;
  links: DeporteLinks;
  reference_id: number | null;
  title: string;
  titleslug?: string;
  created: Date;
  instalaciones: Instalacion[];
  changed: Date;
  direccion: string;
  direccionslug?: string;
  horario: null | string;
  latitud: number;
  lineas: null | string;
  longitud: number;
  orden: number;
  otras: null | string;
  permite_alquiler: boolean;
  telefono: null | string;
  url: null | string;
  validado: boolean;
  validado_oficina_virtual: boolean;
  uid: Uid;
  deportes?: Deporte[];
  escuela?: Escuela[];
  fichero1: Fichero1;
  fichero1_original: Fichero1;
  img?: ImgElement;
  img_orig?: Img2Class;
  img2?: Img2Class;
  img2_orig?: Img2Class;
  tipo: Tipo[];
  concesion_administrativa: boolean;
}

export const instalacionesForDownloader = (instalaciones: Instalacion[]) => {
  return instalaciones.map(instalacion => {
    return {
      Titulo: instalacion.title,
      Direccion: instalacion.direccion,
      Horario: instalacion.horario || '',
      Lineas: instalacion.lineas || '',
      Telefono: instalacion.telefono || '',
      Escuelas:
        instalacion.escuela && instalacion.escuela?.length > 0
          ? instalacion.escuela?.map(escuela => escuela.title).join(', ')
          : '',
      Deportes:
        instalacion.deportes && instalacion.deportes?.length > 0
          ? instalacion.deportes?.map(deportes => deportes.title).join(', ')
          : '',
      Instalaciones:
        instalacion.instalaciones && instalacion.instalaciones?.length > 0
          ? instalacion.instalaciones?.map(instalaciones => instalaciones.title).join(', ')
          : '',
      'Tipo de instalacion':
        instalacion.tipo && instalacion.tipo?.length > 0
          ? instalacion.tipo?.map(tipo => tipo.name).join(', ')
          : '',
    };
  });
};

export interface Tipo {
  type?: string;
  id?: string;
  links?: InstalacionLinks;
  name?: string;
  meta?: UidMeta;
}

export interface Deporte {
  type: string;
  id: string;
  links: DatumLinks;
  title: string;
  created?: Date;
  changed?: Date;
  descripcion?: string | null;
  edad_maxima?: number;
  edad_minima?: number;
  mas_informacion?: null | string;
  orden?: number;
  titleslug?: string;
  validado?: boolean;
  competiciones?: Competiciones;
  img?: Competiciones;
  img_bn?: Competiciones;
  instalacion_practicar?: Instalacion[];
}

export interface DeportesClass {
  data: any[] | null;
  links: DeportesLinks;
}

export interface DeportesLinks {
  related?: Last;
  self: Last;
}

export interface Last {
  href: string;
}

export interface DeporteLinks {
  self: Last;
}

export interface DeporteMeta {
  drupal_internal__target_id: number;
}

export interface EscuelaElement {
  type: string;
  id: string;
  links: DeporteLinks;
  title: string;
  created: Date;
  changed: Date;
  contacto: string;
  orden: null;
  telefono: string;
  validado: boolean;
  meta: DeporteMeta;
  img: DeportesClass;
  instalacion: DeportesClass;
}

export interface Fichero1 {
  type?: string;
  id?: string;
  links: DeportesLinks;
  drupal_internal__fid?: number;
  langcode?: string;
  filename?: string;
  uri?: URI;
  filemime?: string;
  filesize?: number;
  status?: boolean;
  created?: Date;
  changed?: Date;
  meta?: Fichero1Meta;
  uid?: Uid;
  data?: null;
}

export interface Fichero1Meta {
  display: boolean;
  description: string;
  drupal_internal__target_id: number;
}

export interface Uid {
  type: string;
  id: string;
  meta: DeporteMeta;
}

export enum UidType {
  UserUser = 'user--user',
}

export interface URI {
  value: string;
  url: string;
}

export interface ImgElement {
  type: string;
  id: string;
  links: DeporteLinks;
  drupal_internal__fid: number;
  langcode: string;
  filename: string;
  uri: URI;
  filemime: string;
  filesize: number;
  status: boolean;
  created: Date;
  changed: Date;
  meta: ImgMeta;
  uid: Uid;
}

export interface ImgMeta {
  alt: string;
  title: string;
  width: number;
  height: number;
  drupal_internal__target_id: number;
}

export interface Img2Class {
  type?: string;
  id?: string;
  links: DeportesLinks;
  drupal_internal__fid?: number;
  langcode?: string;
  filename?: string;
  uri?: URI;
  filemime?: string;
  filesize?: number;
  status?: boolean;
  created?: Date;
  changed?: Date;
  meta?: ImgMeta;
  uid?: Uid;
  data?: null;
}

export interface Path {
  alias: null;
  pid: null;
  langcode: string;
}

export interface Jsonapi {
  version: string;
  meta: JsonapiMeta;
  parsed: boolean;
}

export interface JsonapiMeta {
  links: DeporteLinks;
}

export interface InstalacionesResponseLinks {
  last: Last;
  next?: Last;
  prev?: Last;
  self: Last;
}

export interface InstalacionesResponseMeta {
  count: number;
}
