<mgl-map #mapComponent class="w-full h-full overflow-hidden"
  [style]="'mapbox://styles/estevez8891/clnefmm5i03rs01pbagxxdnec'" [zoom]="[zoom]" [center]="center" [maxZoom]="16"
  [attributionControl]="false" [cooperativeGestures]="true">
  <mgl-control mglNavigation></mgl-control>
  <mgl-control mglGeolocate [showUserLocation]="true" [trackUserLocation]="true"></mgl-control>
  <span *ngFor="let item of data">
    <mgl-marker #marker [lngLat]="item.longitud?[item.longitud, item.latitud]:undefined">
      <img alt="{{item.title}}" [src]="getImagePath(item.field_marcador)" class="hover-group w-6 cursor-pointer">
    </mgl-marker>
    <mgl-popup [marker]="marker" [closeButton]="false">
      <a [routerLink]="'/' + item.type + '/' + item.titleslug" class="p-3 flex flex-col text-lg">
        <h3 class="text-xl text-primary font-aldo">{{item.title}}</h3>
        <p>{{item.direccion}}</p>
        <div class="grid grid-cols-3">
          <!--TODO: Verificar que instalacion.deportes sea un iterable. Se hace verificando que no tenga la propiedad 'data' (queda feo) -->
          <div *ngIf="!item.deportes?.hasOwnProperty('data')">
            <p *ngFor="let depor of item.deportes">
              {{depor.title}}
            </p>
          </div>
        </div>
      </a>
    </mgl-popup>
  </span>
</mgl-map>
