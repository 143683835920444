<main *ngFor="let inscription of inscriptionsList" class="w-full bg-gradient-to-t from-blue-400 to-gray-200 to-45%">
    <section class="w-11/12 xl:w-8/12 h-full m-auto justify-center flex flex-col py-9">
        <aside class="2/3 flex flex-col items-center text-center">
            <header class="2/3 flex flex-col items-center text-center">
                <div class="rounded-full bg-secondary p-3 w-20 h-20 flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"
                        class="w-full">
                        <path fill="white"
                            d="M190 873.5h30l436-438-30-30-436 438v30ZM793.5 396 667 269l19.5-20.5q27.5-27.5 64.75-28t64.75 26l16.5 16.5q22 21.5 19.25 49.5t-21.75 47L793.5 396ZM755 434.5 247 942H120.5V815.5l507-506.5L755 434.5Zm-113-14-16-15 30 30-14-15Z" />
                    </svg>
                </div>
                <h3 class="text-secondary font-aldo text-3xl">{{inscription.title}}</h3>
                <p class=" font-aldo text-xl" [innerHTML]="inscription.descripcion"></p>
            </header>
            <article ngClass="infoSchedule" class="my-6">
                <h4 class=" text-lg">Desde <span>{{inscription.fecha_inicio}}</span> hasta
                    <span>{{inscription.fecha_fin}}</span></h4>
                <p [innerHTML]=" 'Horario telefónico:'+ inscription.horario_telefonico.value"></p>
                <p [innerHTML]=" 'Horario oficina:'+ inscription.horario_de_oficina.value"></p>
            </article>
            <p [innerHTML]="inscription.horario_web.value"></p>
            <button class="bg-secondary px-8 py-2 rounded-full text-white text-xl">INSCRÍBETE</button>
        </aside>
        <aside class="w-full my-14 flex flex-col items-center font-aldo">
            <article
                class="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 w-full justify-center items-center">
                <a routerLink="#"
                    class="w-3/5 md:w-1/6 h-44 p-7 flex flex-col items-center justify-around rounded-3xl bg-gray-50 bg-opacity-50">
                    <img src="/assets/icons/llamada.svg" alt="Llamada Imagen"
                        class="w-14 h-14 p-1 bg-secondary rounded-full">
                    <p class="font-semibold text-2xl md:text-lg">Preinscripción {{inscription.telefono}}</p>
                </a>
                <a *ngFor="let archive of AtachedFilesLinks; let i = index"
                    class="w-3/5 md:w-1/6 h-44 p-7 flex flex-col items-center justify-around rounded-3xl bg-gray-50 bg-opacity-50">
                    <a [href]="AtachedFilesLinks[i]" target="_blank">
                        <img src="/assets/icons/running.svg" alt="Descarga Imagen"
                            class="w-14 h-14 p-1 bg-secondary rounded-full">
                        <p class="font-semibold text-2xl md:text-lg">
                            {{inscription?.archivos_adjuntos[i]?.meta?.description}}</p>
                    </a>
                </a>
                <a routerLink="#"
                    class="w-3/5 md:w-1/6 h-44 p-7 flex flex-col  items-center justify-around rounded-3xl bg-gray-50 bg-opacity-50">
                    <img src="/assets/icons/info.svg" alt="Información Imagen" class="w-16 h-16">
                    <p class="font-semibold text-2xl md:text-lg">Información</p>
                </a>
            </article>
            <footer class="w-full text-center text-white mt-14 text-lg">
                <h3 class="text-4xl font-bold"><br>Atención</h3>
                <p [innerHTML]="inscription.llamada_de_atencion.value"></p>
            </footer>
        </aside>
    </section>
</main>