<article class="p-1 border border-gray-200">
    <div id="title-container" class="flex w-full cursor-pointer items-center p-2 hover:bg-gray-100 space-x-2.5" (click)="open=!open">
      <img src="/assets/icons/flecha.svg" alt="Flecha" class="w-6 transition-all ease-in" [ngClass]="open?'rotate-90':'rotate-0'">
      <div *ngFor="let ruta of rutas">
        <h5 class="font-bold">{{ruta.title | uppercase}}</h5>
      </div>
    </div>
    <div class="w-full overflow-hidden transition-all ease-in " [ngClass]="open?'h-full pb-2 px-3.5':'h-0'">
      <div *ngFor="let ruta of rutas">
        <p *ngIf="ruta?.descripcion" [innerHtml]="ruta?.descripcion" class="mt-4"></p>
      </div>
      <div class="mt-4">
        <h6 class="text-xl font-semibold">Rutas</h6>
        <p></p>
      </div>
    </div>
  </article>
  