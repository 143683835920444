<section [ngClass]="{'modal-open': modalOpen}">
  <aside>
    <header>
      <h2>Accesibilidad</h2>
    </header>
    <summary>
      <label>Tamaño de fuente</label>
      <app-horizontal-range-slider 
      (valueChange)="modifyFontSize($event)"
      />
    </summary>
    <summary>
      <label>Alto contraste</label>
      <app-slide-checkbox [checked]="highContrast" (change)="toggleHighContrast()"></app-slide-checkbox>
    </summary>
    <summary>
      <label>Estilos</label>
      <app-slide-checkbox [checked]="stylesEnabled" (change)="toggleStyles()"></app-slide-checkbox>
    </summary>
    <footer>
      <a routerLink="/accesibilidad">
        <h2>Informacion de accesibilidad</h2>
      </a>
    </footer>
    <app-menu-curtain 
    [navOpen]="modalOpen"
    (modalOpenChange)="onModalOpenChange($event)"
    />
  </aside>
  <main id="accessible-btn-toggler" (click)="toggleModal()">
    <footer>
      <app-icon 
        src="accessibility"
        fill="var(--wine-red)"
        hoverFill="black"
        customClass="accessibility-icon"
      />
      <article>
        Accesibilidad
      </article>
    </footer>
  </main>
</section>