<app-menu [open]="menuOpen"></app-menu>
<app-modal-busqueda *ngIf="modalSearchOpen" (closeModal)="closeModal()" [search]="search"></app-modal-busqueda>
<header>
  <main>
    <aside>
      <div class="flex flex-row items-center justify-between md:justify-start md:space-x-8">
        <div class="w-10">
          <button id="menuButton" #menuButton
            class="w-full m-auto flex flex-col justify-center items-center text-center text-white text-xs"
            (click)="openMenu($event)">
            <app-icon src="menu" fill="var(--header-color)" />
            <span>MENU</span>
          </button>
        </div>
        <button id="button-search-mobile"
          class="h-8 relative whitespace-nowrap px-3 rounded-full md:hidden block overflow-hidden"
          (click)="modalSearchOpen = true">
        </button>
      </div>
      <app-icon id="mainMenu" src="logo" fill="var(--header-color)" (click)="navigateToHome()" />
    </aside>
    <aside>
      <span class="h-8 relative whitespace-nowrap bg-white px-3 rounded-full hidden md:block overflow-hidden">
        <i>
          <app-icon src="lupa" fill="var(--header-color)" customClass="inline-block h-8 bg-white cursor-pointer "
            (click)="modalSearchOpen = true" id="button-search-bar" />
          <input type="text" name="search" id="search-md" placeholder="Búsqueda" autocomplete="off" [(ngModel)]="search"
            (keyup.enter)="modalSearchOpen = true"
            class="h-8 rounded-2xl bg-white placeholder:text-secondary text-primary text-center inline-block focus:outline-none focus:border-0 active:outline-none active:border-0 focus:placeholder-transparent" />
        </i>
      </span>
      <app-user-burger-menu *ngIf="requiredPrivateConnection" />
      <app-user-profile-switch />
    </aside>
  </main>
</header>