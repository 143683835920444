<main>
  <app-loader [Show]="showloader">
    <section *ngFor="let resource of resources">
      <app-activity-details
        classList="btn btn-secondary activity-button"
        [activity]="resource.Descripcion"
        [activityDescription]="resource.DescripcionAmpliada"
        [IdActividad]="resource.Id"
      />
    </section>
    <app-button label="Volver" classList="btn btn-primary btn-block btn-back" (click)="getBack()"/>
  </app-loader>
</main>
