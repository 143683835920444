import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { catchError, of, tap, timeout } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiStatus } from '../../interfaces';
import { primaryColor } from '../../utils/globalColors';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  apiStatus: ApiStatus = { loading: false, error: false };

  constructor(private authService: AuthService, private route: Router, private fb: FormBuilder) {}

  formularioLogin = this.fb.group({
    username: [null, Validators.required],
    password: [null, Validators.required],
    grant_type: ['password'],
    client_secret: ['mwd_qraneos'],
    client_id: ['logged_user'],
  });

  ngOnInit() {
    this.authService.isLoggedIn$
      .pipe(
        tap(isLogin => {
          if (isLogin) {
            this.route.navigate(['/user-profile']);
          }
        })
      )
      .subscribe();
  }

  onSubmit() {
    this.apiStatus = { loading: true, error: false };
    this.authService
      .login(this.formularioLogin.value)
      .pipe(
        timeout(5000),
        catchError(error => {
          this.apiStatus = { loading: false, error: error };
          return of();
        }),
        tap(_ => {
          this.apiStatus = { loading: false, error: false };
          this.authService.isLoggedIn$
            .pipe(
              tap(isLogin => {
                if (isLogin) {
                  this.route.navigate([this.authService.redirectUrl]);
                }
              })
            )
            .subscribe();
        })
      )
      .subscribe();
  }

  protected readonly primaryColor = primaryColor;
}
