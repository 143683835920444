import { Component, Input } from '@angular/core';
import { RutinaApi } from '../../converters/tablas/tablas.converter.service';
import { bgColors } from '../../utils/colors';

@Component({
  selector: 'app-planning-routine-line',
  templateUrl: './planning-routine-line.component.html',
  styleUrls: ['./planning-routine-line.component.css'],
})
export class PlanningRoutineLineComponent {
  @Input() routine: any;
  @Input() index: number;

  getColor() {
    return bgColors[this.index % bgColors.length];
  }
}
