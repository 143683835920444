import { FeatureCollection, Geometry, GeoJsonProperties } from 'geojson';
import { RutasComponent } from '../rutas/pages/rutas/rutas.component';
export interface RutasResponse {
  jsonapi: Jsonapi;
  data: Ruta[];
  meta: WelcomeMeta;
  links: ImgLinks;
}

export interface Ruta {
  changed: Date;
  created: Date;
  descripcion: string;
  sombra: string;
  bancos: string;
  trafico: string;
  dificultad: string;
  fuentes: string;
  final: string;
  inicio: string;
  tipo_recorrido: string;
  tipo_suelo: string;
  geojson: FeatureCollection<Geometry, GeoJsonProperties> | undefined;
  id: string;
  img?: Img;
  links: ImgLinks;
  logo?: Logo;
  orden: number;
  title: string;
  titleslug: string;
  validado: boolean;
  length?: number;
}

export const rutaToCSVConverter = (rutas: Ruta[]) => {
  return rutas.map(ruta => ({
    'Nombre Ruta': ruta.title,
    Descripcion: ruta.descripcion || '',
    Sombra: ruta.sombra || '',
    Bancos: ruta.bancos || '',
    Trafico: ruta.trafico || '',
    Dificultad: ruta.dificultad || '',
    Fuentes: ruta.fuentes || '',
    Final: ruta.final || '',
    Inicio: ruta.inicio || '',
    'Tipo de recorrido': ruta.tipo_recorrido || '',
    'Tipo de suelo': ruta.tipo_suelo || '',
  }));
};

export interface Img {
  type: ImgType;
  id: string;
  links: ImgLinks;
  drupal_internal__fid: number;
  langcode: Langcode;
  filename: string;
  uri: URI;
  filemime: Filemime;
  filesize: number;
  status: boolean;
  created: Date;
  changed: Date;
  meta: ImgMeta;
  uid: Uid;
}

export enum Filemime {
  ImageJPEG = 'image/jpeg',
  ImagePNG = 'image/png',
}

export enum Langcode {
  Es = 'es',
}

export interface ImgLinks {
  self: Self;
}

export interface Self {
  href: string;
}

export interface ImgMeta {
  alt: string;
  title: string;
  width: number;
  height: number;
  drupal_internal__target_id: number;
}

export enum ImgType {
  FileFile = 'file--file',
}

export interface Uid {
  type: UidType;
  id: string;
  meta: UidMeta;
}

export interface UidMeta {
  drupal_internal__target_id: number;
}

export enum UidType {
  UserUser = 'user--user',
}

export interface URI {
  value: string;
  url: string;
}

export interface Logo {
  data: null;
  links: LogoLinks;
}

export interface LogoLinks {
  related: Self;
  self: Self;
}

export enum DatumType {
  Rutas = 'rutas',
}

export interface Jsonapi {
  version: string;
  meta: JsonapiMeta;
  parsed: boolean;
}

export interface JsonapiMeta {
  links: ImgLinks;
}

export interface WelcomeMeta {
  count: number;
}
