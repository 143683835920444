<div class="busqueda-usuario-container">
  <div class="input-busqueda">
    <div class="busqueda-input-container">
      <img src="/assets/icons/lupa.svg" alt="Busqueda" id="button-search-bar" />
      <input
        type="text"
        spellcheck="false"
        name="search"
        id="search-md"
        class="busqueda-input"
        placeholder="Búsqueda"
        autocomplete="off"
        [(ngModel)]="search"
      />
    </div>
  </div>
  <div
    class="list-users"
    cdkDropList
    id="users"
    #users="cdkDropList"
    [cdkDropListConnectedTo]="['usersAdded']"
    [cdkDropListData]="usuariosFiltrados"
    (cdkDropListDropped)="drop($event)"
    cdkDropListSortingDisabled
  >
    <ng-container *ngFor="let usuario of usuariosFiltrados">
      <div cdkDrag [cdkDragData]="usuario" (cdkDragEnded)="onDragEnd(usuario)">
        <app-agregar-usuarios-linea-usuario
          (selectElement)="addUser($event)"
          [usuario]="usuario"
          [isAdd]="true"
        />
        <app-agregar-usuarios-linea-usuario
          *cdkDragPreview
          [usuario]="usuario"
          [isAdd]="true"
          class="custom-drag-preview"
        />
        <app-agregar-usuarios-linea-usuario
          *cdkDragPlaceholder
          [usuario]="usuario"
          [isAdd]="true"
        />
      </div>
    </ng-container>
  </div>
</div>
