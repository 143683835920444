<div *ngIf="!image; else ImageCheckbox">
  <ng-template [ngIf]="title">
    <h4>{{ title }}</h4>
  </ng-template>
  <div class="checkbox-field">
    <ng-template [ngIf]="label">
      <label for="{{ id }}" (click)="updateParent()">{{ label }}</label>
    </ng-template>
    <div class="slider mt-1" (click)="updateParent()" [ngClass]="value ? 'slider-checked' : ''">
      <div class="slider__circle" [ngClass]="value ? 'checked' : 'unchecked'"></div>
      <input
        type="checkbox"
        [id]="id"
        [(ngModel)]="value"
        (blur)="onTouch()"
        [disabled]="disabled"
      />
    </div>
  </div>
  <ng-template [ngIf]="errores">
    <label class="text-red-700">El campo "{{ label | titlecase }}" es requerido</label>
  </ng-template>
</div>
<ng-template #ImageCheckbox>
  <div ngClass="imageCheckbox" class="flex flex-col items-center" (click)="updateParent()">
    <img [src]="image" [alt]="label" />
    <label for="{{ id }}" (click)="updateParent()">{{ label }}</label>
    <input
      type="checkbox"
      [id]="id"
      [(ngModel)]="value"
      (blur)="onTouch()"
      [disabled]="disabled"
      style="display: none"
    />
  </div>
</ng-template>
