import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { InstalacionesRoutingModule } from './instalaciones-routing.module';
import { InstalacionService } from './services/instalacion.service';
import { InstalacionesComponent } from './pages/instalaciones/instalaciones.component';
import { FormsModule } from '@angular/forms';
import { BusquedaComponent } from './components/busqueda/busqueda.component';
import { ListadoComponent } from './components/listado/listado.component';
import { GeolocalizacionComponent } from './components/geolocalizacion/geolocalizacion.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { MapaComponent } from './components/mapa/mapa.component';
import { InstalacionComponent } from './pages/instalacion/instalacion.component';
import { SharedModule } from '../shared/shared.module';
import { NoticiasModule } from '../noticias/noticias.module';
import { SlideCheckboxModule } from '../form/slide-checkbox/slide-checkbox.module';
import { LayoutsModule } from '../layouts/layouts.module';
import { AcordeonModule } from './components/acordeon/acordeon.module';
import { SelectInputModule } from '../form/select-input/select-input.module';
import { InputModule } from '../form/input/input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ListModule } from '../components/shared/list/list.module';

@NgModule({
  declarations: [
    InstalacionesComponent,
    BusquedaComponent,
    ListadoComponent,
    GeolocalizacionComponent,
    MapaComponent,
    InstalacionComponent,
  ],
  exports: [MapaComponent, GeolocalizacionComponent],
  providers: [InstalacionService],
  imports: [
    CommonModule,
    HttpClientModule,
    InstalacionesRoutingModule,
    FormsModule,
    NgxMapboxGLModule,
    SharedModule,
    NoticiasModule,
    SlideCheckboxModule,
    LayoutsModule,
    AcordeonModule,
    SelectInputModule,
    InputModule,
    ReactiveFormsModule,
    ListModule,
  ],
})
export class InstalacionesModule {}
