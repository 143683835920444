import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { InstalacionesResponse } from '../../interfaces/InstalacionesResponse';

@Injectable({
  providedIn: 'root',
})
export class InstalacionService {
  instalaciones?: InstalacionesResponse;

  constructor(private http: HttpClient) {}

  getInstalaciones(
    limit: number = 200,
    url: string = `${environment.apiBackGetUrl}/api/v1/drupal/instalaciones-deportivas`
  ): Observable<InstalacionesResponse> {
    let params = new HttpParams();
    params = params.append('page[limit]', limit);
    if (this.instalaciones) {
      return of(this.instalaciones);
    } else {
      params = params.append('page[limit]', 200);
      return this.http.get<InstalacionesResponse>(url, { params }).pipe(
        tap(data => {
          data.data.map(instalacion => {
            if (instalacion.deportes![0]?.title == 'NO DEPORTE') instalacion.deportes = undefined;
            if (instalacion.escuela![0]?.title == 'NO ESCUELA') instalacion.escuela = undefined;
          });
          this.instalaciones = data;
        })
      );
    }
  }

  filtradoInstalaciones(
    params: HttpParams,
    limit: number = 200
  ): Observable<InstalacionesResponse> {
    params = params.append('page[limit]', limit);
    return this.http
      .get<InstalacionesResponse>(
        `${environment.apiBackGetUrl}/api/v1/drupal/instalaciones-deportivas`,
        { params }
      )
      .pipe(
        tap((data: InstalacionesResponse) => {
          data.data.map(instalacion => {
            if (instalacion.deportes![0]?.title == 'NO DEPORTE') instalacion.deportes = undefined;
            if (instalacion.escuela![0]?.title == 'NO ESCUELA') instalacion.escuela = undefined;
          });
        })
      );
  }

  getTiposInstalaciones() {
    return this.http.get<any>(`${environment.apiBackGetUrl}/api/v1/drupal/tipo_instalacion`);
  }
}
