import {
  trigger,
  transition,
  style,
  query,
  group,
  animateChild,
  animate,
} from '@angular/animations';

export const animation = trigger('routeAnimations', [transition('* => *', slideTo())]);

function slideTo() {
  const optional = { optional: true };
  return [
    query(
      ':enter, :leave',
      [
        style({
          position: 'fixed',
          left: 0,
          width: '100%',
        }),
      ],
      optional
    ),
    query(':enter', [style({ left: '-100%' })], optional),
    group([
      query(':leave', [animate('200ms ease', style({ left: '100%' }))], optional),
      query(':enter', [animate('200ms ease', style({ right: '0%' }))], optional),
    ]),
    query(':leave', animateChild(), optional),
    query(':enter', animateChild(), optional),
  ];
}
