import { Component } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent {
  @Input() title = 'Cargando datos';
  @Input() Show: boolean = false;
  showcontent: boolean = true;
}
