import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IncidenciasResponse } from '../../interfaces/IncidenciasResponse';
import { AuthService } from '../../services/auth.service';
import { InstalacionesResponse, Tipo } from '../../interfaces/InstalacionesResponse';

export enum TipoIncidencia {
  SIA = 'SIA',
  SAID = 'SAID',
}

@Injectable({
  providedIn: 'root',
})
export class IncidenciasService {
  auth_token = '';

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${this.auth_token}`,
  });

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this.checkUserToken();
  }

  checkUserToken() {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authService.getUser()?.token}`,
    });
  }

  getInstalacionesConsecionesAdministrativas() {
    return this.httpClient.get<InstalacionesResponse>(
      `${environment.apiBackGetUrl}/api/v1/drupal/instalaciones-deportivas?filter[concesion_administrativa]=true`
    );
  }
  getMotivosIncidencias() {
    return this.httpClient.get<any>(`${environment.apiBackGetUrl}/api/v1/drupal/motivo_incidencia`);
  }

  getTiposInstalaciones() {
    return this.httpClient.get<any>(`${environment.apiBackGetUrl}/api/v1/drupal/tipo_instalacion`);
  }

  getAllIncidencias() {
    return this.httpClient.get<IncidenciasResponse>(
      environment.apiBackGetUrl + '/api/v1/drupal/incidencias'
    );
  }

  putIncidencia(id: number, data: any) {
    this.checkUserToken();
    return this.httpClient.patch<IncidenciasResponse>(
      environment.apiBackGetUrl + `/api/v1/drupal/incidencias?id=${id}`,
      data
    );
  }

  postIncidencia(data: any) {
    this.checkUserToken();
    return this.httpClient.post<IncidenciasResponse>(
      environment.apiBackGetUrl + `/api/v1/drupal/incidencias`,
      data
    );
  }
}
