import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UsuarioRutina } from '../../models/ejercicios.model';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-busqueda-usuarios',
  templateUrl: './busqueda-usuarios.component.html',
  styleUrls: ['./busqueda-usuarios.component.css'],
})
export class BusquedaUsuariosComponent {
  @Input() set usuarios(usuarios: UsuarioRutina[]) {
    if (usuarios !== null) {
      const uniqueElements = usuarios.filter(
        addedUsuario =>
          !this.usuariosDisponibles.some(
            disponibleUsuario => disponibleUsuario.Id === addedUsuario.Id
          )
      );
      this.usuariosDisponibles = [...this.usuariosDisponibles, ...uniqueElements];
    }
  }

  @Output() addUsuario: EventEmitter<UsuarioRutina> = new EventEmitter<UsuarioRutina>();

  usuariosDisponibles: UsuarioRutina[] = [];

  search: string = '';

  get usuariosFiltrados(): UsuarioRutina[] {
    const searchTerm = this.search.trim().toLowerCase();
    return this.usuariosDisponibles.filter(usuario => {
      const namePredicate =
        usuario?.Nombre && usuario?.Nombre.trim().toLowerCase().includes(searchTerm);
      const apellido1Predicate =
        usuario?.Apellido1 && usuario?.Apellido1.trim().toLowerCase().includes(searchTerm);
      const apellido2Predicate =
        usuario?.Apellido2 && usuario?.Apellido2.trim().toLowerCase().includes(searchTerm);
      return namePredicate || apellido1Predicate || apellido2Predicate;
    });
  }

  onDragEnd(usuarioDeleted: UsuarioRutina) {
    this.usuariosDisponibles = this.usuariosDisponibles.filter(
      usuario => usuario?.Id !== usuarioDeleted.Id
    );
  }

  drop(event: CdkDragDrop<UsuarioRutina[]>) {
    if (event.previousContainer !== event.container) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      const uniqueElements = event.container.data.filter(
        addedUsuario =>
          !this.usuariosDisponibles.some(
            disponibleUsuario => disponibleUsuario.Id === addedUsuario.Id
          )
      );
      this.usuariosDisponibles = [...this.usuariosDisponibles, ...uniqueElements];
    }
  }

  addUser(usuario: UsuarioRutina) {
    this.usuariosDisponibles = this.usuariosDisponibles.filter(user => user?.Id !== usuario?.Id);
    this.addUsuario.emit(usuario);
  }
}
