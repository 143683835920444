<app-page-with-breadcrumb>
  <div id="instalacion" *ngIf="instalacion" class="flex flex-col space-y-6">
    <h1 class="text-4xl font-aldo text-primary">Instalación Deportiva</h1>
    <h2 class="text-2xl font-bold">{{ instalacion.title }}</h2>
    <!--    Campos de Texto -->
    <div class="grid grid-cols-1 md:grid-cols-3 gap-6 items-start w-full">
      <!--    Campo  Tipo de Acceso    -->
      <div class="flex space-x-2 items-center h-full w-full">
        <img
          alt="Icono de Acceso"
          src="/assets/icons/entrada.svg"
          class="w-10 h-10 bg-primary p-1.5 rounded-full"
        />
        <div>
          <h3 class="font-semibold">Tipos de acceso</h3>
          <p>{{ instalacion.permite_alquiler ? 'Con reserva previa' : 'Libre Acceso' }}</p>
        </div>
      </div>
      <!-- Campo Lineas de transporte-->
      <div *ngIf="instalacion.lineas" class="flex space-x-2 items-center h-full w-full">
        <img
          alt="Icono de Transporte"
          src="/assets/icons/bus.svg"
          class="w-10 h-10 bg-primary p-1.5 rounded-full"
        />
        <div>
          <h3 class="font-semibold">Líneas de transporte</h3>
          <p>{{ instalacion.lineas }}</p>
        </div>
      </div>
      <!--      Campo de Telefonos-->
      <div *ngIf="instalacion.telefono" class="flex space-x-2 items-center h-full w-full">
        <img
          alt="Icono de Telefono"
          src="/assets/icons/telefono.svg"
          class="w-10 h-10 bg-primary p-1.5 rounded-full"
        />
        <div>
          <h3 class="font-semibold">Teléfonos</h3>
          <p>{{ instalacion.telefono }}</p>
        </div>
      </div>
      <!--      Campo de Direccion-->
      <div *ngIf="instalacion.direccion" class="flex space-x-2 items-center h-full w-full">
        <img
          alt="Icono de direccion"
          src="/assets/icons/marker.svg"
          class="w-10 h-10 bg-primary p-1.5 rounded-full"
        />
        <div>
          <h3 class="font-semibold">Dirección</h3>
          <p>{{ instalacion.direccion }}</p>
        </div>
      </div>
      <!--      Campo de Horario-->
      <div *ngIf="instalacion.horario" class="flex space-x-2 items-center h-full w-full">
        <img
          alt="Icono de horario"
          src="/assets/icons/horario.svg"
          class="w-10 h-10 bg-primary p-1.5 rounded-full"
        />
        <div>
          <h3 class="font-semibold">Horario</h3>
          <p>{{ instalacion.horario }}</p>
        </div>
      </div>
      <!--      Campo de URL-->
      <div *ngIf="instalacion.url" class="flex space-x-2 items-center h-full w-full">
        <img
          alt="Icono de URL"
          src="/assets/icons/horario.svg"
          class="w-10 h-10 bg-primary p-1.5 rounded-full"
        />
        <div>
          <h3 class="font-semibold">Web</h3>
          <a [href]="instalacion.url" target="_blank" class="hover:text-primary">{{
            instalacion.url
          }}</a>
        </div>
      </div>
      <!--      Campo de Deportes que admite-->
      <div
        *ngIf="!instalacion.deportes?.hasOwnProperty('data') && instalacion.escuela"
        class="flex space-x-2 items-center md:col-span-2 md:row-span-2 h-full w-full"
      >
        <img
          alt="Icono de Deportes"
          src="/assets/icons/deportes.svg"
          class="w-10 h-10 bg-primary p-1.5 rounded-full"
        />
        <div>
          <h3 class="font-semibold">Deportes que admite</h3>
          <section>
            <a
              *ngFor="let deporte of instalacion.deportes index as i"
              [routerLink]="'/deportes/'"
              [fragment]="deporte.id"
              (click)="onSportClick(deporte.id)"
            >
              <h5>{{ deporte.title | titlecase }}</h5>
            </a>
          </section>
        </div>
      </div>
      
      <!--      Campo de Escuelas Asociadas-->
      <div
        *ngIf="!instalacion.escuela?.hasOwnProperty('data') && instalacion.escuela?.hasOwnProperty('title')"
        class="flex space-x-2 items-center md:col-span-2 h-full w-full"
      >
        <img
          alt="Icono de Deportes"
          src="/assets/icons/escuela.svg"
          class="w-10 h-10 bg-primary p-1.5 rounded-full"
        />
        <div>
          <h3 class="font-semibold">Escuelas Deportivas</h3>
          <div class="grid grid-cols-3">
            <a
              [id]="'escuela-' + i"
              *ngFor="let escuela of instalacion.escuela; let i = index"
              [routerLink]="'/escuelas/' + escuela.id"
              class="flex flex-row md:items-center items-start mr-3 hover:scale-105 transition-all hover:text-secondary"
            >
              <img alt="flecha" src="assets/icons/flecha.svg" class="h-4 w-4 mt-1 md:mt-0" />
              <h5>{{ escuela.title }}</h5>
            </a>
          </div>
        </div>
      </div>
    </div>
    <app-accordion
            title="¿Cómo me inscribo?"
            [isOpen]="false">
      <app-information-view/>
    </app-accordion>
    <!--    Galeria de imagenes-->
    <div
      *ngIf="
        instalacion.img?.hasOwnProperty('type') ||
        instalacion.img2?.hasOwnProperty('type') ||
        instalacion.img_orig?.hasOwnProperty('type') ||
        instalacion.img2_orig?.hasOwnProperty('type')
      "
      class="mt-6 md:grid md:grid-cols-4 md:gap-3 md:place-content-center md:h-auto h-56 md:overflow-clip w-full overflow-x-scroll whitespace-nowrap snap snap-x snap-mandatory space-x-4 mb-6"
    >
      <img
        *ngIf="instalacion.img?.hasOwnProperty('type')"
        [src]="environment.imgUrl + instalacion.img?.uri?.url"
        [alt]="instalacion.img?.id"
        (click)="abrirModalImagen(instalacion.img?.id!)"
        class="inline-block w-11/12 h-full snap-center cursor-pointer m-auto border border-primary rounded-md"
      />
      <img
        *ngIf="instalacion.img_orig?.hasOwnProperty('type')"
        [src]="environment.imgUrl + instalacion.img_orig?.uri?.url"
        [alt]="instalacion.img_orig?.id"
        (click)="abrirModalImagen(instalacion.img_orig?.id!)"
        class="inline-block w-11/12 h-full snap-center cursor-pointer m-auto border border-primary rounded-md"
      />
      <img
        *ngIf="instalacion.img2?.hasOwnProperty('type')"
        [src]="environment.imgUrl + instalacion.img2?.uri?.url"
        [alt]="instalacion.img2?.id"
        (click)="abrirModalImagen(instalacion.img2?.id!)"
        class="inline-block w-11/12 h-full snap-center cursor-pointer m-auto border border-primary rounded-md"
      />
      <img
        *ngIf="instalacion.img2_orig?.hasOwnProperty('type')"
        [src]="environment.imgUrl + instalacion.img2_orig?.uri?.url"
        [alt]="instalacion.img2_orig?.id"
        (click)="abrirModalImagen(instalacion.img2_orig?.id!)"
        class="inline-block w-11/12 h-full snap-center cursor-pointer m-auto border border-primary rounded-md"
      />
      <app-imagen-modal
        *ngIf="openModalImg"
        [instalacion]="instalacion"
        [selected]="imgSelected"
        (close)="closeModalImagen($event)"
      ></app-imagen-modal>
    </div>
    <!--  Mapa -->
    <div *ngIf="instalacion?.longitud && instalacion?.latitud" class="w-full h-[550px]">
      <app-mapa
        *ngIf="instalacion"
        [data]="[instalacion]"
        [center]="[instalacion.longitud, instalacion.latitud]"
        [zoom]="16"
      ></app-mapa>
    </div>
    <ng-container>
      <app-accordion *ngIf="instalacion.instalaciones.length > 1" [title]="instalacion.title">
          <app-list-lister  [data]="instalacion.instalaciones" [cardColumns]="cardColumns" [listColumns]="listColumns" [isCardView]="true"/>
      </app-accordion>
    </ng-container>
  </div>
  <!--  Mostrar cuando está cargando-->
  <div id="cargando" *ngIf="!instalacion && apiStatus.loading" class="w-1/2 h-32 m-auto">
    <h1 class="text-4xl text-center text-primary mt-6">Cargando datos</h1>
    <svg
      class="h-full w-full mt-8"
      id="L7"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <path
        fill="#e51818"
        d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
      c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="2s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
        />
      </path>
      <path
        fill="#e51818"
        d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
      c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 50 50"
          to="-360 50 50"
          repeatCount="indefinite"
        />
      </path>
      <path
        fill="#e51818"
        d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
      L82,35.7z"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="2s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>
  <!--  Mostrar si no hay datos-->
  <div
    id="no-data"
    *ngIf="!instalacion && !apiStatus.loading"
    class="w-full md:w-1/2 h-full m-auto"
  >
    <h1 class="text-4xl text-center text-primary mt-6">
      Lo sentimos no existe ninguna instalación con ese nombre o no podemos recuperar la información
    </h1>
    <img alt="Imagen No Datos" src="/assets/icons/no-data.svg" />
  </div>
  <app-button
  *ngIf="instalacion?.reference_id !== null && requiredPrivateConnection"
    type="button"
    classList="btn btn-primary"
    label="Reservar"
    (click)="onBookFacility()"
  />
</app-page-with-breadcrumb>
