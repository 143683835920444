<div>
  <div id="avisos" *ngIf="avisos.length != 0 && !apiStatus?.loading">
    <div class="my-14">
      <h1 class="md:text-start text-center text-5xl font-aldo text-primary">Avisos</h1>
    </div>
    <div class="w-full flex flex-col space-y-20">
      
      <app-pagination-container [data]="avisos" (emitData)="handleEmitData($event)" [pageSize]="5">
        <article *ngFor="let aviso of currentPageData" class="w-full flex flex-col shadow-2xl">
          <div class="md:w-3/4 flex flex-col md:flex-row p-3 md:p-8 pb-0 m-auto">
            <div class="md:w-1/4 overflow-hidden flex items-center justify-center">
              <img
                [src]="aviso.orden == 0 ? 'assets/icons/alerta.svg' : 'assets/icons/info.svg'"
                alt="icono Informacion"
                class="w-14 h-14"
              />
            </div>
            <div
              class="md:w-3/4 h-full flex flex-col space-y-2 p-3 my-auto text-center md:text-start"
            >
              <h3 class="text-xl md:text-3xl font-bold">{{ aviso.title }}</h3>
              <p
                *ngIf="aviso.descripcion"
                class="md:text-lg line-clamp-4"
                [innerHtml]="getDescripcion(aviso.descripcion) | safeHtml"
              ></p>
            </div>
          </div>
          <div class="w-full flex justify-center md:justify-end">
            <a
              [routerLink]="'/noticias/' + aviso.id"
              class="bg-primary hover:bg-secondary w-1/3 md:w-[20%] text-sm md:text-lg button-keep-reading text-white text-center font-semibold rounded-t-2xl p-1.5 md:mr-20"
            >
              Seguir leyendo
            </a>
          </div>
        </article>
      </app-pagination-container>
    </div>
  </div>
  <div
    id="noData-avisos"
    *ngIf="!apiStatus?.loading && avisos.length == 0"
    class="text-5xl text-primary text-center w-full"
  >
    No se encontro ningún aviso
  </div>
</div>
