import { Component } from '@angular/core';
import { TablasService } from '../../services/tablas/tablas.service';
import { ClasesService } from '../../services/clases/clases.service';
import { UsuariosService } from '../../services/usuarios/usuarios.service';

@Component({
  selector: 'app-perfil-table',
  templateUrl: './perfil-table.component.html',
  styleUrls: ['./perfil-table.component.css'],
})
export class PerfilTableComponent {
  selectedIndex = 0;
  tabs = ['MIS CLASES', 'MIS TABLAS', 'MIS USUARIOS'];
  rutinas = [];
  clases = [];
  users = [];

  constructor(
    private tablasService: TablasService,
    private clasesService: ClasesService,
    private usuariosService: UsuariosService
  ) {}

  ngOnInit() {
    this.tablasService.getRutinasByMonitorId(61).subscribe((rutinas: any) => {
      this.rutinas = rutinas;
    });

    this.clasesService.getClasesByMonitorId(61).subscribe((clases: any) => {
      this.clases = clases.data;
    });

    this.usuariosService.getUsersByMonitorId(61).subscribe((users: any) => {
      this.users = users;
    });
  }

  setSelectedIndex(index: number) {
    this.selectedIndex = index;
  }
}
