import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {
  RutinaApi,
  TablasConverterService,
} from '../../converters/tablas/tablas.converter.service';
import { EjerciciosService } from '../ejercicios/ejercicios.service';

@Injectable({
  providedIn: 'root',
})
export class TablasService {
  constructor(
    private http: HttpClient,
    private tablaConverterService: TablasConverterService,
    private ejercicioService: EjerciciosService
  ) {}

  saveRutina(rutinaToSave: any): Observable<any> {
    let newRutinaToSave = JSON.stringify(rutinaToSave, null, 2);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const request = this.http
      .post(environment.apiBackGetUrl + '/api/v1/drupal/rutinas', newRutinaToSave, {
        headers: headers,
      })
      .pipe(
        catchError(error => {
          console.error('Error al realizar la solicitud:', error);
          return throwError(error);
        })
      );

    return request.pipe(
      catchError(error => {
        console.error('Error al guardar tabla con usuarios asociados:', error);
        return throwError(error);
      })
    );
  }

  updateRutina(rutinaToUpdate: any): Observable<any> {
    let newRutinaToUpdate = JSON.stringify(rutinaToUpdate, null, 2);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const request = this.http
      .patch(environment.apiBackGetUrl + '/api/v1/drupal/rutinas', newRutinaToUpdate, {
        headers: headers,
      })
      .pipe(
        catchError(error => {
          console.error('Error al realizar la solicitud:', error);
          return throwError(error);
        })
      );

    return request.pipe(
      catchError(error => {
        console.error('Error al actualizar tabla con usuarios asociados:', error);
        return throwError(error);
      })
    );
  }

  getRutinasByTableId(idTabla: number): Observable<RutinaApi> {
    return this.http
      .get(environment.apiBackGetUrl + '/api/v1/drupal/rutinas?tabla=' + idTabla)
      .pipe(
        switchMap(rutinas => {
          return this.ejercicioService
            .getAllEjercicios()
            .pipe(map(ejercicios => this.tablaConverterService.fromDto(rutinas, ejercicios)));
        })
      );
  }

  getPlaningsByUserId(userId: number): Observable<any> {
    return this.http.get(
      environment.apiBackGetUrl + '/api/v1/drupal/planificacion_rutinas?usuario=' + userId
    );
  }

  updatePlannings(plannings: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const request = this.http
      .patch(environment.apiBackGetUrl + '/api/v1/drupal/planificacion_rutinas', plannings, {
        headers: headers,
      })
      .pipe(
        catchError(error => {
          console.error('Error al realizar la solicitud:', error);
          return throwError(error);
        })
      );

    return request.pipe(
      catchError(error => {
        console.error('Error al actualizar tabla con usuarios asociados:', error);
        return throwError(error);
      })
    );
  }

  getRutinasByMonitorId(idMonitor: number): Observable<RutinaApi[]> {
    return this.http
      .get<Array<any>>(environment.apiBackGetUrl + '/api/v1/drupal/rutinas?id_monitor=' + idMonitor)
      .pipe(
        switchMap(rutinas => {
          const groupedRoutines = [];

          rutinas.forEach(rutina => {
            const found = groupedRoutines.find(gr => gr.id === rutina.tabla.id);
            if (found) {
              found.rutinas.push(rutina);
            } else {
              groupedRoutines.push({
                id: rutina.tabla.id,
                rutinas: [rutina],
              });
            }
          });

          return this.ejercicioService.getAllEjercicios().pipe(
            map(ejercicios => {
              return groupedRoutines.map(gr => {
                return this.tablaConverterService.fromDto(gr.rutinas, ejercicios);
              });
            })
          );
        })
      );
  }

  getPdfFromRutinaId(idTabla: number): Observable<void> {
    const url = `${environment.apiBackGetUrl}/api/v1/drupal/rutinas/toPdf/${idTabla}`;
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((blob: Blob) => {
        const fileName = 'tabla.pdf';
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
      })
    );
  }
}
