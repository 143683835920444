import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private loggedUser: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  private isDeporwinConnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private adminUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {
    this.readLoggedUser();
  }

  private readLoggedUser() {
    const loggedUserString = localStorage.getItem('loggedUser');
    this.loggedUser.next(loggedUserString ? (JSON.parse(loggedUserString) as User) : null);
  }

  private writeLoggedUser(user: User) {
    if (this.loggedUser) {
      localStorage.setItem('loggedUser', JSON.stringify(user));
    }
  }

  removeLoggedUser() {
    localStorage.removeItem('mwd-user');
    localStorage.removeItem('loggedUser');
    this.loggedUser.next(null);
  }

  getDeporwinStatus() {
    return this.isDeporwinConnected.asObservable();
  }

  setLoggedUser(user: any) {
    this.writeLoggedUser(user);
    this.loggedUser.next(user);
  }

  setDeporwinStatus(status: boolean) {
    this.isDeporwinConnected.next(status);
  }

  getLoggedUser() {
    return this.loggedUser.asObservable();
  }

  getAdminUser() {
    return this.adminUser.asObservable();
  }

  setAdminUser(status: boolean) {
    this.adminUser.next(status);
  }
}
