import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Info, InfoResponse } from '../../interfaces/InfoInstitucionalResponse';
import { MainService } from '../../services/main.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css'],
})
export class InfoComponent implements OnInit {
  info?: Info;
  loading: boolean = false;

  constructor(private route: ActivatedRoute, private mainService: MainService) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      const httpp = new HttpParams().set('filter[id]', params['id']);
      this.loading = true;
      this.mainService.getInfoById(httpp).subscribe(
        (data: InfoResponse) => {
          this.info = data.data[0];
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    });
  }

  protected readonly environment = environment;
}
