import { Instalacion } from './InstalacionesResponse';

export interface DeportesResponse {
  jsonapi: Jsonapi;
  data: Deporte[];
  meta: DeportesResponseMeta;
  links: DeportesResponseLinks;
}

export interface Deporte {
  type: string;
  id: string;
  links: DatumLinks;
  title: string;
  created?: Date;
  changed?: Date;
  descripcion?: string | null;
  edad_maxima?: number;
  edad_minima?: number;
  solo_concesion_administrativa?: boolean | false;
  mas_informacion?: null | string;
  orden?: number;
  titleslug?: string;
  validado?: boolean;
  competiciones?: Competiciones;
  img?: Competiciones;
  img_bn?: Competiciones;
  instalacion_practicar?: Instalacion[] | [];
  precios?: Precio[] | [];
  contacto?: string;
  telefono?: string;
}

export const deportesForDownload = (deportes: Deporte[]) => {
  return deportes.map(deporte => ({
    Titulo: deporte.title,
    Descripción: deporte.descripcion || '',
    Edad_maxima: deporte.edad_maxima || '',
    Edad_minima: deporte.edad_minima || '',
    Mas_informacion: deporte.mas_informacion || '',
    Competiciones:
      deporte.competiciones && deporte.competiciones.data && deporte.competiciones.data.length > 0
        ? deporte.competiciones.data.map(competiciones => competiciones.title).join(', ')
        : '',
    Instalaciones:
      deporte.instalacion_practicar && deporte.instalacion_practicar.length > 0
        ? deporte.instalacion_practicar
            .map(instalacion_practicar => instalacion_practicar.title)
            .join(', ')
        : '',
    Precios:
      deporte.precios && deporte.precios.length > 0
        ? deporte.precios.map(precio => precio.title).join(', ')
        : '',
    Contacto: deporte.contacto || '',
    Telefono: deporte.telefono || '',
  }));
};
export interface Precio {
  type: string;
  id: string;
  links: DatumLinks;
  title: string;
  orden: number;
  precio: number;
  validado: boolean;
  meta: DeportesResponseMeta;
}

export interface Competiciones {
  data: any[] | null;
  links: CompeticionesLinks;
}

export interface CompeticionesLinks {
  related: Last;
  self: Last;
}

export interface Last {
  href: string;
}

export interface DatumLinks {
  self: Last;
}

export interface Jsonapi {
  version: string;
  meta: JsonapiMeta;
  parsed: boolean;
}

export interface JsonapiMeta {
  links: DatumLinks;
}

export interface DeportesResponseLinks {
  last: Last;
  next: Last;
  self: Last;
}

export interface DeportesResponseMeta {
  count: number;
}
