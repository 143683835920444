<app-page-with-breadcrumb>
  <app-pages-loader *ngIf="apiStatus.loading " [Apierror]="apiStatus.error"></app-pages-loader>
  <main>
    <h1>Rutas</h1>
    <article>
      <aside ngClass="infoRutas">
        <form [formGroup]="filterForm">
          <div ngClass="filtroRutas">
            <app-slide-checkbox label="Urbano" [id]="'urbano'" formControlName="urbano" [checkedValue]="type.urbano"
              image="/assets/icons/ruta-urbana.svg" />
            <app-slide-checkbox label="Monte" [id]="'monte'" formControlName="monte" [checkedValue]="type.monte"
              image="/assets/icons/ruta-monte.svg" />

            <app-slide-checkbox label="Huerta" [id]="'huerta'" formControlName="huerta" [checkedValue]="type.huerta"
              image="/assets/icons/ruta-huerta.svg" />

            <app-slide-checkbox label="Otro" [id]="'otro'" formControlName="otro" [checkedValue]="type.otro"
              image="/assets/icons/ruta-huerta.svg" />
          </div>
          <div>
            <h2>Ruta</h2>
            <input name="input-search" id="input-search" type="text" placeholder="Ruta | Nombre de la ruta"
              formControlName="route" class="w-full bg-footer mt-1 p-2 rounded-3xl outline-none" />
          </div>
          <app-select-input name="distance" id="distance" type="number" formControlName="distance" label="KM"
            firstOptionLabel="Todas las distancias" [size]="4" [options]="distancias" />
          <div class="inputs">
            <h2>DIFICULTAD</h2>
            <app-radio-input [options]="radioOptions" formControlName="difficulty" />
          </div>
        </form>
        <section>
          <h2>INFORMACION</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, veritatis laudantium! Id nisi earum
            voluptas repellat nemo vel eveniet reiciendis eligendi. Minus commodi aut dicta quis illum odio architecto
            impedit.
            Doloribus laboriosam, rerum magni voluptates dicta molestiae sunt accusantium veritatis sapiente unde cum
            harum ab dolorem illo facere itaque rem adipisci, nulla numquam dolores sit optio quos commodi facilis!
            Quis!
          </p>
        </section>
        <div ngClass="buttons">
          <app-button label="Limpiar Filtros" type="button" classList="btn btn-primary btn-block" (click)="clearSelection()"/>
          <app-button (click)="CSVdownload()" label="Descargar en CSV" type="button" classList="btn btn-secondary btn-block"/>
        </div>
      </aside>
      <aside ngClass="mapa">
        <app-mapa [center]="center" [zoom]="zoom"></app-mapa>
      </aside>
    </article>
    <footer>
      <app-pagination-container [data]="routeList"
      (emitData)="handleEmitData($event)" [pageSize]="4">
      <app-routes-list [rutas]="currentPageData" [cargando]="apiStatus.loading" />
    </app-pagination-container>
    </footer>
  </main>
</app-page-with-breadcrumb>