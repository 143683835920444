<nav class="w-auto mb-4">
  <ul class="">
    <li *ngFor="let breadcrumb of breadcrumbs; let i = index" class="hover:text-primary inline">
      <p class="inline">{{ '»' }}</p>
      <span *ngIf="breadcrumb.url == currentUrl; else linkBreadcrumb">
        <b>{{ breadcrumb.label | titlecase }}</b>
      </span>
      <ng-template #linkBreadcrumb>
        <a
          [routerLink]="decode(breadcrumb.url)"
          [queryParams]="breadcrumb.params"
          class="underline-offset-1 underline inline mx-2"
          (click)="removeFutureBreadcrumbs(i)"
          >{{ breadcrumb.label | titlecase }}
        </a>
      </ng-template>
    </li>
  </ul>
</nav>
