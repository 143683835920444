import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListCardComponent } from './list-card/list-card.component';
import { ListListerComponent } from './list-lister/list-lister.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { IconListComponent } from './icon-list/icon-list.component';
import { MiniCardViewComponent } from '../mini-card-view/mini-card-view.component';
import { LayoutsModule } from 'src/app/layouts/layouts.module';

@NgModule({
  declarations: [ListCardComponent, ListListerComponent, IconListComponent, MiniCardViewComponent],
  imports: [CommonModule, SharedModule, LayoutsModule],
  exports: [ListCardComponent, ListListerComponent, IconListComponent, MiniCardViewComponent],
})
export class ListModule {}
