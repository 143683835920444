<main>
  <ng-template [ngIf]="label">
    <label [for]="id">{{ label }}</label>
  </ng-template>
  <input #searchInput type="text" [placeholder]="placeholder" [value]="inputValue" (click)="onInputClick()" (input)="onFilterChange($event)" />
  <span>
    <article *ngIf="isDropdownOpen">
      <ul>
        <li *ngIf="defaultOptionLabel" (click)="onOptionClick({label: '', value: ''})">
          {{ defaultOptionLabel | titlecase }}
        <li *ngFor="let option of filteredOptions" (click)="onOptionClick(option)">
          {{ option.label | titlecase }}
        </li>
      </ul>
    </article>
  </span>
  <ng-template [ngIf]="errores">
    <label>El campo "{{ label | titlecase }}" es requerido</label>
  </ng-template>
</main>