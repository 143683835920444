import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface ArchivoEnlazado {
  id: string;
  url: string;
  filename: string;
  description: string;
  extension: string;
  filemime: string;
}

export interface Convocatoria {
  titulo: string;
  desde: string;
  hasta: string;
  descripcion: {
    value: string;
    format: string;
    processed: string;
  };
  archivos_enlazados: {
    uri: {
      url: string;
    };
    meta: {
      description: string | null;
    };
    filename: string;
  }[];
  activo: boolean;
}

export interface CompeticionData {
  id: string;
  titulo: string;
  deporte: string;
  activo: boolean;
  archivos_enlazados: ArchivoEnlazado[];
}

export interface Competicion {
  deporte: string;
  data: CompeticionData[];
}

export interface Memoria {
  id: string;
  titulo: string;
  descripcion: string;
  archivos: ArchivoEnlazado[];
}

export interface Descarga {
  data: DescargaData[];
  meta: Meta;
  links: Links;
}

export interface DescargaData {
  id: string;
  titulo: string;
  descripcion: string;
  archivos: ArchivoEnlazado[];
}

interface Meta {
  count: number;
}

interface Links {
  self: Self;
}

interface Self {
  href: string;
}

@Injectable({
  providedIn: 'root',
})
export class DeporteEscolarService {
  constructor(private httpCliente: HttpClient) {}

  getConvocatorias() {
    return this.httpCliente.get(
      environment.apiBackGetUrl + '/api/v1/drupal/deporte_escolar/convocatorias'
    );
  }

  getCompeticiones() {
    return this.httpCliente.get(
      environment.apiBackGetUrl + '/api/v1/drupal/deporte_escolar/competiciones'
    );
  }

  getMemoria(): Observable<Memoria[]> {
    return this.httpCliente.get<Memoria[]>(
      environment.apiBackGetUrl + '/api/v1/drupal/deporte_escolar/memoria'
    );
  }

  getEscuelasDeportivas() {
    return this.httpCliente.get(
      environment.apiBackGetUrl + '/api/v1/drupal/deporte_escolar/escuelas'
    );
  }

  getEscuelaDeportiva(id: string) {
    return this.httpCliente.get(
      environment.apiBackGetUrl + '/api/v1/drupal/deporte_escolar/escuela?id=' + id
    );
  }
  getDescargas(): Observable<Descarga> {
    return this.httpCliente.get<Descarga>(
      environment.apiBackGetUrl + '/api/v1/drupal/deporte_escolar/descargas'
    );
  }
}
