<form [formGroup]="lineaEjercicioFormGroup">
  <div class="linea-container" [ngClass]="isPreview ? 'preview' : 'normal'">
    <div class="info">
      <div class="draggable" cdkDragHandle>
        <app-icon src="drag" [fill]="'var(--grey-medium)'" />
      </div>
      <div class="imagen">
        <app-icon [src]="icon" fill="white" />
      </div>
      <label>{{ ejercicioTitle }}</label>
    </div>
    <div class="field-label-rep">
      <ng-container *ngIf="typeRepet == 1; else repet">
        <input
          id="time"
          name="time"
          type="text"
          [autocomplete]="false"
          mask="00:50"
          [patterns]="patern"
          [dropSpecialCharacters]="false"
          placeholder="mm:ss"
          formControlName="time"
          class="editable-field"
        />
      </ng-container>
      <ng-template #repet>
        <ng-container *ngIf="typeRepet == 2; else noRep">
          <input type="number" step="1" formControlName="repeat" class="editable-field" min="0" />
        </ng-container>
      </ng-template>
      <ng-template #noRep>
        <input type="number" formControlName="repeat" class="editable-field" />
      </ng-template>
      <select
        *ngIf="ejercicio?.admiteRepeticiones || ejercicio?.admiteTiempo"
        formControlName="tipoRepeticion"
      >
        <option *ngFor="let rep of tiempoRepetOpt" [value]="rep?.value">{{ rep?.label }}</option>
      </select>
    </div>
    <input type="number" formControlName="series" class="editable-field" min="0" />
    <div class="field-label-load">
      <ng-container *ngIf="ejercicio?.admiteCarga">
        <input
          [id]="'load-' + index"
          [name]="'load-' + index"
          type="number"
          step="0.5"
          formControlName="load"
          class="editable-field"
          min="0"
        />
        <label [for]="'load-' + index">Kg</label>
      </ng-container>
    </div>
    <div class="buttons">
      <div class="add-observation">
        <app-button
          icon="mas"
          (onClick)="addComment()"
          classList="btn-rounded"
          [strokeColors]="'var(--wine-red)'"
          type="button"
        />
      </div>
      <div class="play-button">
        <app-button
          icon="play"
          (onClick)="showExercise()"
          classList="btn-rounded"
          [fillColors]="['white', 'white']"
          type="button"
        />
      </div>
      <div class="divider"></div>
      <div class="delete-button">
        <app-button
          icon="trash"
          (onClick)="deleteExercise()"
          type="button"
          [fillColors]="['var(--grey-dark)', 'var(--grey-dark)']"
        />
      </div>
    </div>
  </div>
</form>
