import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { InscriptionsService } from 'src/app/services/inscriptions.service';
import { getTitle } from 'src/app/utils/breadcrumbs';

@Component({
  selector: 'app-fee-information-view',
  templateUrl: './fee-information-view.component.html',
  styleUrls: ['./fee-information-view.component.css'],
})
export class FeeInformationViewComponent {
  constructor(private breadCrumbs: BreadcrumbService, private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle(getTitle('Bonificaciones'));
    this.breadCrumbs.addCurrentBreadcrumb();
  }
}
