import { Component } from '@angular/core';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css'],
})
export class PerfilComponent {
  monitor = {
    name: 'John Doe',
    email: 'foo@test.com',
    number: '123456789',
    address: '1234 Main St',
    quote: 'I am a quote',
  };
}
