import { Component, Input } from '@angular/core';
import { DescargaData } from 'src/app/deporte-escolar/services/deporte-escolar.service';

@Component({
  selector: 'app-descargas-details',
  templateUrl: './descargas-details.component.html',
  styleUrls: ['./descargas-details.component.css'],
})
export class DescargasDetailsComponent {
  @Input() descarga!: DescargaData;
}
