import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css'],
})
export class AccordionComponent {
  @Input() isOpen: boolean = false;
  @Input() title: string = '';

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }
}
