import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import {
  ListListerColumn,
  ListListerColumnType,
} from 'src/app/components/shared/list/list-lister/list-lister.component';
import { DeporteEscolarService } from 'src/app/deporte-escolar/services/deporte-escolar.service';
import { SelectInputOptions } from 'src/app/form/select-input/select-input.component';
import { DeportesResponse } from 'src/app/interfaces/DeportesResponse';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MainService } from 'src/app/services/main.service';
import { getTitle } from 'src/app/utils/breadcrumbs';
import { Escuela } from 'src/app/interfaces/EscuelasResponse';

interface filterFormInterface {
  sport: FormControl<string>;
  searchInput: FormControl<string>;
  edadSelector: FormControl<number>;
}

@Component({
  selector: 'app-escuelas-deportivas',
  templateUrl: './escuelas-deportivas.component.html',
  styleUrls: ['./escuelas-deportivas.component.css'],
})
export class EscuelasDeportivasComponent {
  deportes: SelectInputOptions[] = [];
  ApiError: boolean = false;
  escuelas: Escuela[] = [];
  filteredEscuelas: Escuela[] = [];

  currentPageData: any[] = [];
  handleEmitData(data: any[]) {
    this.currentPageData = data;
  }

  cardColumns: ListListerColumn[] = [
    { name: 'Contacto', prop: 'contacto', type: ListListerColumnType.String },
    { name: 'Teléfono/Correo', prop: 'telefono', type: ListListerColumnType.String },
    { name: 'Deportes', prop: 'deportes', type: ListListerColumnType.Array },
  ];

  listColumns: ListListerColumn[] = [
    { name: 'Contacto', prop: 'contacto', type: ListListerColumnType.String },
    { name: 'Teléfono/Correo', prop: 'telefono', type: ListListerColumnType.String },
    { name: 'Instalación', prop: 'instalacion', type: ListListerColumnType.Array },
    { name: 'Deportes', prop: 'deportes', type: ListListerColumnType.Array },
  ];

  constructor(
    private deporteEscolarService: DeporteEscolarService,
    private mainService: MainService,
    private fb: FormBuilder,
    private breadCrumbService: BreadcrumbService,
    private titleService: Title
  ) {}

  filterForm: FormGroup<filterFormInterface> = this.fb.group({
    sport: [''],
    searchInput: [''],
    edadSelector: 0,
  }) as FormGroup<filterFormInterface>;

  ngOnInit() {
    this.titleService.setTitle(getTitle('Escuelas Deportivas'));
    this.breadCrumbService.addCurrentBreadcrumb();
    this.mainService.getDeportes(new HttpParams()).subscribe(
      (data: DeportesResponse) => {
        this.ApiError = false;
        this.deportes = data.data.map(deporte => ({
          label: deporte.title,
          value: deporte.titleslug,
        }));
      },
      () => this.onError()
    );

    this.deporteEscolarService.getEscuelasDeportivas().subscribe(
      (data: any) => {
        this.ApiError = false;
        this.escuelas = data;
        this.filteredEscuelas = data;
      },
      () => this.onError()
    );

    this.filterForm.valueChanges.subscribe(value => {
      this.filtrarEscuelas(this.escuelas);
    });
  }

  onError() {
    this.ApiError = true;
  }

  filtrarEscuelas(escuelas: Escuela[] | null) {
    const { sport = '', searchInput = '', edadSelector = 0 } = this.filterForm.value;
    this.filteredEscuelas = escuelas
      ? escuelas.filter(
          escuela =>
            (sport === '' || this.filterBySport(escuela, sport)) &&
            (searchInput === '' || this.filterBySearchInput(escuela, searchInput)) &&
            (edadSelector === 0 || this.filterByAge(escuela, edadSelector))
        )
      : [];
  }

  filterBySport(instalacion: any, sport: string) {
    return instalacion.deportes?.some((deporteEscuela: any) => deporteEscuela.titleslug === sport);
  }

  filterBySearchInput(instalacion: any, searchInput: string) {
    const reducedInstalacion = {
      titleslug: instalacion.titleslug,
      contacto: instalacion.contacto,
      deportes: instalacion.deportes,
      telefono: instalacion.telefono,
    };
    return JSON.stringify(reducedInstalacion).toLowerCase()?.includes(searchInput.toLowerCase());
  }
  filterByAge(escuela: Escuela, edad: number) {
    if (edad === 0) {
      return true;
    }
    const edadSelector = this.filterForm.value.edadSelector || 0;
    if (edadSelector !== undefined) {
      return escuela.deportes?.some(
        deporte =>
          (deporte.edad_minima ?? 0) <= edadSelector &&
          (deporte.edad_maxima ?? Infinity) >= edadSelector
      );
    }
    return false;
  }

  resetForm() {
    this.filterForm.reset({
      sport: '',
      edadSelector: 0,
      searchInput: '',
    });
    this.filteredEscuelas = this.escuelas;
  }
}
