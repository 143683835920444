import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button.component';
import { ImageRendererModule } from '../../components/globals/image-renderer/image-renderer.module';
import { IconosModule } from '../../iconos/iconos.module';

@NgModule({
  declarations: [ButtonComponent],
  imports: [CommonModule, ImageRendererModule, IconosModule],
  exports: [ButtonComponent],
})
export class ButtonModule {}
