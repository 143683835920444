import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';
import { Inject } from '@angular/core';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    @Inject(GlobalService) private globalService: GlobalService
  ) {}

  userRegistered = null;

  getUserId() {
    return JSON.parse(localStorage.getItem('mwd-user')!)?.userId;
  }

  getMe() {
    return this.http
      .get(environment.apiBackGetUrl + '/api/v1/deporwin/me', { withCredentials: true })
      .pipe(
        tap(data => {
          this.globalService.setLoggedUser(data as User);
        })
      );
  }

  getDeporwinStatus() {
    return this.http.get(environment.apiBackGetUrl + '/api/v1/deporwin/status');
  }

  registerUser(body: any) {
    return this.http
      .post(environment.apiBackGetUrl + '/api/v1/deporwin/user/register', body, {
        withCredentials: true,
      })
      .pipe(
        tap((res: any) => {
          if (res?.IdPersona) this.userRegistered = { ...body, ...res };
        })
      );
  }

  getTokenAutenticacion() {
    return this.http.post(
      environment.apiBackGetUrl + '/api/v1/deporwin/user/tokenAutenticacion',
      this.userRegistered,
      {
        withCredentials: true,
      }
    );
  }

  registerPasswordForUser(body: any) {
    return this.http.put(environment.apiBackGetUrl + '/api/v1/deporwin/user/password', body, {
      withCredentials: true,
    });
  }
}
