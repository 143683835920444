<main>
    <div>
        <article>
            <section>
                <img *ngIf="usuario.Imagen" alt="Foto perfil usuario" [src]="usuario?.Imagen" />
                <img *ngIf="!usuario.Imagen" alt="Foto perfil usuario" src="/assets/img/person%20placeholder.jpg" />
                <label>{{getFullName(usuario)}}</label>
            </section>
            <aside>
                <label>Nº Socio</label>
                <p>{{ usuario?.Id }}</p>
            </aside>
            <aside>
                <label>{{ ageCalculate(usuario?.FechaNacimiento) + ' años' }}</label>
                <p>1.82 m</p>
            </aside>
            <aside>
                <label>Peso Actual</label>
                <p>83 kg</p>
            </aside>
            <aside>
                <label>Inicio</label>
                <p>17-07-2024</p>
            </aside>
            <aside>
                <label>Fin</label>
                <p>18-09-2024</p>
            </aside>
            <aside class="comment">
                <p>{{usuario?.Comentario ? usuario?.Comentario : 'Ninguna Observacion'}}</p>
            </aside>
            <aside class="buttons-usuarios">
                <i class="calendar-button" (click)="goToUserInfoDetails(usuario)">
                    <app-icon src="infoIcon" fill="none" stroke="var(--wine-red)" />
                </i>
            </aside>
        </article>
    </div>
</main>
