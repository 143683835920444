<app-page-with-breadcrumb>
  <app-pages-loader
    *ngIf="noticias.length == 0 || avisos.length == 0 || convocatorias.length == 0"
    [Apierror]="apiStatus.error"
  ></app-pages-loader>
  <div
    *ngIf="noticias.length != 0 && avisos.length != 0 && convocatorias.length != 0"
  >
    <div class="w-full flex rounded-2xl m-auto justify-around border text-lg">
      <button
        id="noticia-button"
        routerLink="/noticias"
        class="p-2 w-full rounded-2xl bg-opacity-90"
        [class]="selected == 'noticia' ? 'bg-primary text-white' : 'bg-white text-primary'"
      >
        Noticias
      </button>
      <button
        id="aviso-button"
        routerLink="/avisos"
        class="p-2 w-full rounded-2xl bg-opacity-90"
        [class]="selected == 'aviso' ? 'bg-primary text-white' : 'bg-white text-primary'"
      >
        Avisos
      </button>
      <button
        id="convocatoria-button"
        routerLink="/convocatorias"
        class="p-2 w-full rounded-2xl bg-opacity-90"
        [class]="selected == 'convocatoria' ? 'bg-primary text-white' : 'bg-white text-primary'"
      >
        Convocatorias
      </button>
      <button
        id="incidencias-button"
        routerLink="/incidencias/listado"
        class="p-2 w-full rounded-2xl bg-opacity-90"
        [class]="selected == 'incidencias' ? 'bg-primary text-white' : 'bg-white text-primary'"
      >
        Incidencias
      </button>
    </div>
  
    <app-noticias *ngIf="selected == 'noticia'" [noticias]="noticias" [apiStatus]="apiStatus" />
    <app-avisos *ngIf="selected == 'aviso'" [avisos]="avisos" [apiStatus]="apiStatus" />
    <app-convocatorias
      *ngIf="selected == 'convocatoria'"
      [convocatorias]="convocatorias"
      [apiStatus]="apiStatus"
    />
  
    <div id="cargando-noticias" *ngIf="apiStatus.loading">
      <h3 class="text-primary text-4xl font-semibold w-full text-center">Cargando Datos</h3>
    </div>
  </div>
</app-page-with-breadcrumb>
