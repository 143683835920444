import {
  Ejercicio,
  LineaEjercicio,
  Planificacion,
  PlanificacionRutina,
  RutinaEjercicio,
  TablaEjercicio,
} from '../models/ejercicios.model';

export const USUARIO_UNO_ID = 'bcf03b7e-23c9-4eb1-986c-ea1afe1a1710';
export const USUARIO_DOS_ID = 'a7f0c4a9-1f9f-47fd-8089-9e166d3a818b';
export const USUARIO_TRES_ID = 'd7131cc5-12d9-44a2-89c4-92f13a8d7473';
export const MONITOR_ID = '1f5f896e-d47e-4d4f-9c65-9af956209feb';

export const RUTINA_EJERCICIO_ID = 'de3b40a2-3c34-4c39-a5be-c01e7fc73ea3';

export const TABLA_EJERCICIO_ID = 'a9060a2b-b0b5-4c02-ae84-28de6fa6cb95';

export const PRESS_BANCA_EJERCICIO = '8404c928-dd62-4aa3-aa87-9c294b8c7ca0';
export const BICEPS_PULL_EJERCICIO = '8404c928-dd62-4aa3-aa87-9c294b8cafer';
export const TRICEPS_PULL_EJERCICIO = '8404c928-dd62-4aa3-aa87-9c294b8bcfer';

export const PRESS_BANCA_LINEA_EJERCICIO = 'd0f12b30-67f1-4e9f-b34e-6265e8b25e68';
export const BICEPS_PULL_LINEA_EJERCICIO = '2036d85c-6c81-4441-be7f-7d1d7be76e86';
export const TRICEPS_PULL_LINEA_EJERCICIO = '3e9d3ed4-a664-4b2a-84b7-07770ee38e1b';

export const pressBancaEjercicio: Ejercicio = {
  id: PRESS_BANCA_EJERCICIO,
  title: 'Press de Banca',
  img: 'https://drupal.murciadeportes.qraneos.io/sites/default/files/2024-07/bench-press-800.png',
  descripcion: 'Ejercicio básico para el tren superior',
  video:
    'https://drupal.murciadeportes.qraneos.io/sites/default/files/2024-07/The%20Bench%20Press_0.mp4',
  grupoMuscular: ['TrenSuperior'],
  icon: 'mancuernas',
};

export const bicepsPullEjercicio: Ejercicio = {
  id: BICEPS_PULL_EJERCICIO,
  title: 'Biceps Pull',
  img: 'https://drupal.murciadeportes.qraneos.io/sites/default/files/2024-07/bench-press-800.png',
  descripcion: 'Ejercicio básico para Biceps',
  video:
    'https://drupal.murciadeportes.qraneos.io/sites/default/files/2024-07/The%20Bench%20Press_0.mp4',
  grupoMuscular: ['TrenSuperior'],
  icon: 'remo',
};

export const tricepsPullEjercicio: Ejercicio = {
  id: TRICEPS_PULL_EJERCICIO,
  title: 'Triceps Pull',
  img: 'https://drupal.murciadeportes.qraneos.io/sites/default/files/2024-07/bench-press-800.png',
  descripcion: 'Ejercicio básico para Triceps',
  video:
    'https://drupal.murciadeportes.qraneos.io/sites/default/files/2024-07/The%20Bench%20Press_0.mp4',
  grupoMuscular: ['TrenSuperior'],
  icon: 'sprint',
};

export const lineaPressBanca: LineaEjercicio = {
  id: PRESS_BANCA_LINEA_EJERCICIO,
  title: 'lineaPressBanca',
  peso: '10kg',
  ejercicio: pressBancaEjercicio,
  series: 3,
  repeticiones: 15,
  observaciones: 'Ejercicio para Pecho',
};

export const lineaBicepsPull: LineaEjercicio = {
  id: BICEPS_PULL_LINEA_EJERCICIO,
  title: 'lineaBicepsPull',
  peso: '15kg',
  ejercicio: bicepsPullEjercicio,
  series: 3,
  repeticiones: 15,
  observaciones: 'Ejercicio para Biceps',
};

export const lineaTricepsPull: LineaEjercicio = {
  id: TRICEPS_PULL_LINEA_EJERCICIO,
  title: 'lineaTricepsPull',
  peso: '5kg',
  ejercicio: tricepsPullEjercicio,
  series: 3,
  repeticiones: 15,
  observaciones: 'Ejercicio para Triceps',
};

export const TablaEjercicioMock: TablaEjercicio = {
  id: TABLA_EJERCICIO_ID,
  isPlantilla: false,
  nombre: 'Rutina Fuerza',
  descripcion: 'Debes hacer los ejercicios lentamente priorizando la técnica antes que la rapidez',
  monitorId: MONITOR_ID,
  ejercicios: [lineaPressBanca, lineaBicepsPull, lineaTricepsPull],
};

export const RutinaEjerciciosMock: RutinaEjercicio = {
  id: RUTINA_EJERCICIO_ID,
  monitorId: MONITOR_ID,
  usuarioId: USUARIO_UNO_ID,
  tablaEjercicio: TablaEjercicioMock,
};

export const PlanificacionMock: Planificacion = {
  fechaFin: '2024-07-20',
  fechaInicio: '2024-07-06',
  monitorId: MONITOR_ID,
  usuarioId: USUARIO_UNO_ID,
  objetivoNum: 68,
  typeObj: { value: 'Peso', label: 'peso' },
};

export const PlanificacionRutinaMock: PlanificacionRutina = {
  rutina: RutinaEjerciciosMock,
  planificacion: PlanificacionMock,
  fechas: [new Date(), new Date('2024-07-11')],
};

export const usuarios = [
  {
    Id: 234042,
    Nombre: 'FRANCISCO JESUS',
    Apellido1: 'QUEREDA',
    Apellido2: ' TORTOSA',
    Sexo: 1,
    FechaNacimiento: '1980-01-01',
    Imagen:
      'https://fastly.picsum.photos/id/933/200/200.jpg?hmac=OW5v0bUFqC97kOeYWLjXhU-5mkb6atERs7CrqdPlRfs',
  },
  {
    Id: 240366,
    Nombre: 'GLORIA',
    Apellido1: 'MONERRIS',
    Sexo: 2,
    FechaNacimiento: '1983-01-01',
    Imagen:
      'https://fastly.picsum.photos/id/1028/200/200.jpg?hmac=thf3cKyzvjBi3Rnf8-hvYRl8MmEPFPIq1G8nJVvoT4I',
  },
  {
    Id: 200815,
    Nombre: 'JUAN',
    Apellido1: 'GARCIA ',
    Apellido2: 'MURCIA ',
    Sexo: 1,
    FechaNacimiento: '1990-01-01',
    Imagen:
      'https://fastly.picsum.photos/id/492/200/200.jpg?hmac=XU2aUuiOp-6a1CXwWFsbEobU3xA-9upNSkJiJacygTM',
  },
  {
    Id: 240367,
    Nombre: 'LILIA',
    Apellido1: 'IZQUIERDO',
    Apellido2: 'AGUIRRE',
    Sexo: 2,
    FechaNacimiento: '1989-01-01',
    Imagen:
      'https://fastly.picsum.photos/id/129/200/200.jpg?hmac=Y7ERTUfFi4RdOFkUcoOnX_xjWnsy4PA7pJkkFmaQt8c',
  },
  {
    Id: 159884,
    Nombre: 'Mª ASUNCION',
    Apellido1: 'GALIAN',
    Apellido2: 'MARTINEZ',
    Sexo: 2,
    FechaNacimiento: '1997-01-01',
    Imagen:
      'https://fastly.picsum.photos/id/826/200/200.jpg?hmac=WlCuCjxEhXh_s4IkOpulPoB-LOoGjfZwP4GjNnkzTLA',
  },
  {
    Id: 164734,
    Nombre: 'MARIA DOLORES',
    Apellido1: 'ROS',
    Apellido2: 'FUENTES',
    Sexo: 2,
    FechaNacimiento: '1994-01-01',
    Imagen:
      'https://fastly.picsum.photos/id/25/200/200.jpg?hmac=G4ZRBi0qdWfQJQs_yxNQr_LJJlf0V1_Pdj8Tp41xsJU',
  },
  {
    Id: 240369,
    Nombre: 'MARIA TERESA',
    Apellido1: 'ILLAN',
    Apellido2: 'MANZANARES',
    Sexo: 2,
    FechaNacimiento: '1978-01-01',
    Imagen:
      'https://fastly.picsum.photos/id/1077/200/200.jpg?hmac=hiq7UCoz9ZFgr9HcMCpbnKhV-IMyOJqsQtVFyqmqohQ',
  },
  {
    Id: 240368,
    Nombre: 'PAULINO',
    Apellido1: 'GARCIA',
    Apellido2: 'TRISTANTE',
    Sexo: 1,
    FechaNacimiento: '1993-01-01',
    Imagen:
      'https://fastly.picsum.photos/id/140/200/200.jpg?hmac=EslZ18xRW6ewC17GDNdn7zvrEZUx37IhZq5iWo9b4g8',
  },
  {
    Id: 226954,
    Nombre: 'T-INNOVA',
    Apellido1: 'ADMINWEB',
    Apellido2: 'PADRE',
    Sexo: 1,
    FechaNacimiento: '1999-01-01',
    Imagen:
      'https://fastly.picsum.photos/id/351/400/200.jpg?hmac=IJK1-Udfbbdk7ykgNA_0AclI8T-AoZ1cKWEeLMiEGJ4',
  },
  {
    Id: 179628,
    Nombre: 'T-INNOVA',
    Apellido1: 'PRUEBA',
    Apellido2: 'SA',
    Sexo: 2,
    FechaNacimiento: '2003-01-01',
    // Imagen:
    //   'https://fastly.picsum.photos/id/954/200/200.jpg?hmac=U_V-b3xGbMM1KTSaB5KKqncFD4fmOVG5iK39Bjd9DoQ',
  },
];
