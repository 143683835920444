import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthConverterService } from './auth.converter.service';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import { GlobalService } from './global.service';

export interface User {
  userId: string;
  token: string;
  tokenRefresh?: {
    tokenLife: string;
    generationDate: Date;
  };
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loggedInUserSubject: BehaviorSubject<User | null>;
  public loggedInUser$: Observable<User | null>;
  public isLoggedIn$: Observable<boolean>;
  public redirectUrl: string = '/user-profile';

  constructor(
    private http: HttpClient,
    private authConverter: AuthConverterService,
    private userService: UserService,
    private globalService: GlobalService
  ) {
    const loggedInUser = this.loadUser();
    this.loggedInUserSubject = new BehaviorSubject<User | null>(loggedInUser);
    this.loggedInUser$ = this.loggedInUserSubject.asObservable();
    this.isLoggedIn$ = this.loggedInUser$.pipe(map(user => !!user));
  }

  login(dataUser: any) {
    return this.http
      .post(
        environment.apiBackGetUrl + '/login',
        { DocumentoIdentidad: dataUser.username, Contraseña: dataUser.password },
        { withCredentials: true }
      )
      .pipe(
        map((user: any) => {
          const usersession = this.authConverter.fromDto({
            userId: user.IdPersona,
            token: user.Token,
            tokenRefresh: {
              tokenLife: user.TiempoCaducidad,
            },
          });
          this.saveUser(usersession);
          this.userService.getMe().subscribe();
          return usersession;
        })
      );
  }

  removeUserInfo(): void {
    localStorage.removeItem('mwd-user');
    localStorage.removeItem('loggedUser');
    this.globalService.removeLoggedUser();
    this.loggedInUserSubject.next(null);
  }

  isLoggedIn(): boolean {
    return !!this.loggedInUserSubject.getValue();
  }

  getUser(): User | null {
    return this.loggedInUserSubject.getValue();
  }

  private saveUser(user: User): void {
    localStorage.setItem('mwd-user', JSON.stringify(user));
    this.loggedInUserSubject.next(user);
  }

  private loadUser(): User | null {
    if (localStorage.getItem('mwd-user'))
      return JSON.parse(localStorage.getItem('mwd-user')!) as User;
    else return null;
  }

  isUserAdmin(): Observable<boolean> {
    return this.http
      .get<boolean>(environment.apiBackGetUrl + `/api/v1/drupal/admin/${this.getUser()?.userId}`)
      .pipe(
        map((isAdmin: any) => {
          return isAdmin;
        })
      );
  }
}
