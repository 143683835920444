<div id="all-content" class="w-full"
     [ngClass]="mainService.accesibilidad==Accesibilidad.BN?'accesibilidad-bn':
     mainService.accesibilidad==Accesibilidad.TextoGrande?'accesibilidad-texto-grande':
     mainService.accesibilidad==Accesibilidad.NoCSS?'accesibilidad-no-css':''">
  <app-header id="header"></app-header>
  <div id="body"  [@routeAnimations]="prepareRoute(outlet)">
    <app-modal-success></app-modal-success>
    <router-outlet #outlet="outlet"></router-outlet>
    <app-fab></app-fab>
    <app-menu-accesibilidad></app-menu-accesibilidad>
  </div>
  <app-footer></app-footer>
</div>
