import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { InstalacionService } from '../../services/instalacion.service';
import { Deporte, DeportesResponse } from '../../../interfaces/DeportesResponse';
import { HttpParams } from '@angular/common/http';
import {
  Instalacion,
  InstalacionesResponse,
  Tipo,
} from '../../../interfaces/InstalacionesResponse';
import { environment } from '../../../../environments/environment';
import { MainService } from '../../../services/main.service';
import { SelectInputOptions } from '../../../form/select-input/select-input.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.css'],
})
export class BusquedaComponent implements OnInit, OnChanges {
  @Output() instalacionFiltrado = new EventEmitter<Instalacion[] | null>();
  @Input() deportes: Deporte[] = [];
  @Input() tiposInstalaciones: Tipo[] = [];

  deportesSelectOptions: SelectInputOptions[] = [];
  tipoInstalacionSelectOptions: SelectInputOptions[] = [];

  open: boolean = true;

  instalaciones: Instalacion[] = [];

  concesionAdministrativa: boolean = false;
  searchInput: string = '';
  instalacionInput: string = '';
  deporteSelector: string = '';
  tipoInstalacionSelector: string | null = null;
  cargandoDatos: boolean = false;
  hasData: boolean | null = null;

  constructor(
    private instalacionService: InstalacionService,
    private mainService: MainService,
    private location: Location
  ) {}

  ngOnInit() {
    this.mainService.getDeportes(new HttpParams()).subscribe((data: DeportesResponse) => {
      this.deportes = data.data;
    });
  }

  ngOnChanges() {
    this.tipoInstalacionSelectOptions = this.tiposInstalaciones.map(tipo => {
      return {
        label: tipo.name!,
        value: tipo.name,
      };
    });
    this.deportesSelectOptions = this.deportes.map(deporte => {
      return {
        label: deporte.title,
        value: deporte.titleslug,
      };
    });
  }

  appendForFilter = (filterObj: {
    type: string | null;
    params: HttpParams;
    operator: string | null;
    value: any;
    group: string | null;
    field: string | null;
    conjunction: string | null;
  }) => {
    if (filterObj.field)
      filterObj.params = filterObj.params.append(
        `filter[${filterObj.type}][condition][path]`,
        filterObj.field
      );
    if (filterObj.operator)
      filterObj.params = filterObj.params.append(
        `filter[${filterObj.type}][condition][operator]`,
        filterObj.operator
      );
    if (filterObj.value)
      filterObj.params = filterObj.params.append(
        `filter[${filterObj.type}][condition][value]`,
        filterObj.value
      );
    if (filterObj.group)
      filterObj.params = filterObj.params.append(
        `filter[${filterObj.type}][condition][memberOf]`,
        filterObj.group
      );
    if (filterObj.conjunction)
      filterObj.params = filterObj.params.append(
        `filter[${filterObj.type}][group][conjunction]`,
        filterObj.conjunction
      );

    return filterObj.params;
  };

  filtradoInstalaciones = () => {
    const instalacionFilter: string = this.instalacionInput
      .trim()
      .normalize('NFD')
      .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, '$1')
      .normalize();
    const searchFilter: string = this.searchInput
      .trim()
      .normalize('NFD')
      .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, '$1')
      .normalize();
    const deporteFilter: string = this.deporteSelector ? this.deporteSelector?.trim() : '';
    const tipoSelector = this.tipoInstalacionSelector ? this.tipoInstalacionSelector : '';

    let queryParam = '';
    this.instalaciones = [];
    this.hasData = false;
    if (
      instalacionFilter == '' &&
      searchFilter == '' &&
      deporteFilter == '' &&
      tipoSelector == '' &&
      !this.concesionAdministrativa
    ) {
      this.instalacionFiltrado.emit(null);
      this.hasData = true;
      return;
    } else {
      this.cargandoDatos = true;
      let params: HttpParams = new HttpParams();
      if (searchFilter != '') {
        params = this.appendForFilter({
          type: 'search-group',
          params: params,
          operator: null,
          value: null,
          group: null,
          field: null,
          conjunction: 'OR',
        });
        params = this.appendForFilter({
          type: 'titleslug-filter',
          params: params,
          operator: 'CONTAINS',
          value: searchFilter,
          group: 'search-group',
          field: 'titleslug',
          conjunction: null,
        });

        params = this.appendForFilter({
          type: 'direccionslug',
          params: params,
          operator: 'CONTAINS',
          value: searchFilter,
          group: 'search-group',
          field: 'direccionslug',
          conjunction: null,
        });

        params = this.appendForFilter({
          type: 'deportestitleslug',
          params: params,
          operator: 'CONTAINS',
          value: searchFilter,
          group: 'search-group',
          field: 'deportes.titleslug',
          conjunction: null,
        });
        if (queryParam == '') queryParam = `searchFilter=${searchFilter}`;
        else queryParam = `${queryParam}&searchFilter=${searchFilter}`;
      }
      if (instalacionFilter != '') {
        params = this.appendForFilter({
          type: 'instalacion-filter',
          params: params,
          operator: 'CONTAINS',
          value: instalacionFilter,
          group: null,
          field: 'titleslug',
          conjunction: null,
        });
        if (queryParam == '') queryParam = `instalacionFilter=${instalacionFilter}`;
        else queryParam = `${queryParam}&instalacionFilter=${instalacionFilter}`;
      }
      if (deporteFilter != '') {
        params = this.appendForFilter({
          type: 'deporte-filter',
          params: params,
          operator: 'CONTAINS',
          value: deporteFilter,
          group: null,
          field: 'deportes.titleslug',
          conjunction: null,
        });
        if (queryParam == '') queryParam = `deporteFilter=${deporteFilter}`;
        else queryParam = `${queryParam}&deporteFilter=${deporteFilter}`;
      }

      if (this.concesionAdministrativa) {
        params = this.appendForFilter({
          type: 'concesion_administrativa',
          params: params,
          operator: '=',
          value: '1',
          group: null,
          field: 'concesion_administrativa',
          conjunction: null,
        });
        if (queryParam == '') queryParam = `concesion=true`;
        else queryParam = `${queryParam}&concesion=true`;
      }

      if (tipoSelector != '') {
        params = this.appendForFilter({
          type: 'tipo-filter',
          params: params,
          operator: 'CONTAINS',
          value: this.tipoInstalacionSelector,
          group: null,
          field: 'tipo.name',
          conjunction: null,
        });
        if (queryParam == '') queryParam = `tipo=${this.tipoInstalacionSelector}`;
        else queryParam = `${queryParam}&tipo=${this.tipoInstalacionSelector}`;
      }
      this.location.go('/instalaciones', queryParam);

      this.instalacionService.filtradoInstalaciones(params).subscribe(
        (data: InstalacionesResponse) => {
          this.instalaciones = data.data;
          this.instalacionFiltrado.emit(this.instalaciones);
          this.hasData = this.instalaciones.length != 0;
          this.cargandoDatos = false;
        },
        () => {
          this.cargandoDatos = false;
        }
      );
    }
  };

  protected readonly environment = environment;
}
