import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RutasComponent } from './pages/rutas/rutas.component';
import { RutaComponent } from './pages/ruta/ruta.component';
import { SharedModule } from '../shared/shared.module';
import { RutasRoutingModule } from './rutas-routing.module';
import { ListaRutasComponent } from './components/lista-rutas/lista-rutas.component';
import { NoticiasModule } from '../noticias/noticias.module';
import { LayoutsModule } from '../layouts/layouts.module';
import { SelectInputModule } from '../form/select-input/select-input.module';
import { InstalacionesModule } from '../instalaciones/instalaciones.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RoutesModule } from '../components/routes/routes.module';
import { RadioInputModule } from '../form/radio-input/radio-input.module';

@NgModule({
  declarations: [RutasComponent, RutaComponent, ListaRutasComponent],
  imports: [
    CommonModule,
    RutasRoutingModule,
    SharedModule,
    NoticiasModule,
    LayoutsModule,
    SelectInputModule,
    InstalacionesModule,
    FormsModule,
    RoutesModule,
    ReactiveFormsModule,
    RadioInputModule,
  ],
})
export class RutasModule {}
