import { Component, Input } from '@angular/core';
import { CompeticionData } from '../../../services/deporte-escolar.service';

@Component({
  selector: 'app-competicion',
  templateUrl: './competicion.component.html',
  styleUrls: ['./competicion.component.css'],
})
export class CompeticionComponent {
  @Input() competicion!: CompeticionData[];
}
