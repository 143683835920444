<form [formGroup]="usuarioFormGroup">
  <div class="linea-usuario">
    <div class="nombre">
      <img *ngIf="usuario.imagen" alt="Foto perfil usuario" [src]="usuario?.imagen" />
      <img
        *ngIf="!usuario.imagen"
        alt="Foto perfil usuario"
        src="/assets/img/person%20placeholder.jpg"
      />
      <label>{{ usuario?.nombre + ' ' + usuario?.apellidos }}</label>
    </div>
    <div class="field-data">
      <label>Id Usuario</label>
      <p>{{ usuario?.id }}</p>
    </div>
    <div class="field-data">
      <label>{{ usuario?.edad ? usuario?.edad + ' años' : 'Edad: -' }}</label>
      <p>1.82 m</p>
      <!-- TODO: Esto se debe tiene que traer de backend -->
    </div>
    <div class="field-data">
      <label>Peso Actual</label>
      <p>83 kg</p>
      <!-- TODO: Esto se debe tiene que traer de backend -->
    </div>
    <div class="field-data">
      <label>Inicio</label>
      <p>17-07-2024</p>
      <!-- TODO: Esto de donde se coge?? -->
    </div>
    <div class="field-data">
      <label>Fin</label>
      <p>18-09-2024</p>
      <!-- TODO: Esto de donde se coge?? -->
    </div>
    <div class="field-data">
      <p>Ninguno</p>
      <!-- TODO: Esto se debe tiene que traer de backend -->
    </div>
    <div class="buttons-usuarios">
      <div class="calendar-button">
        <app-button
          icon="calendar"
          (onClick)="handleOpenCalendar()"
          classList="btn-rounded"
          [fillColors]="['var(--wine-red)', 'var(--pale-red)']"
          type="button"
        />
      </div>
      <div class="comment-button">
        <app-button
          icon="mas"
          (onClick)="addComentario()"
          classList="btn-rounded"
          [strokeColors]="['var(--wine-red)', 'var(--pale-red)']"
          type="button"
        />
      </div>
      <div class="divider"></div>
      <div class="delete-button">
        <app-button
          icon="trash"
          (onClick)="deletePerson()"
          type="button"
          [fillColors]="['var(--grey-dark)', 'var(--grey-dark)']"
        />
      </div>
    </div>
  </div>
</form>
