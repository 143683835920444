import { Component, EventEmitter, Input, Output, Type } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
export interface TabInterface {
  title: string;
  active?: boolean;
}

@Component({
  selector: 'app-tab-slider',
  templateUrl: './tab-slider.component.html',
  styleUrls: ['./tab-slider.component.css'],
})
export class TabSliderComponent {
  @Input() tabs: TabInterface[] = [];
  @Input() activeIndex: number = 0;
  @Output() activeIndexChange = new EventEmitter<number>();

  constructor(private router: Router, private route: ActivatedRoute) {}

  changeActiveIndex(newIndex: number) {
    this.activeIndexChange.emit(newIndex);
    this.handleChangeSection(newIndex);
  }

  handleChangeSection(newSection: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { section: newSection },
      queryParamsHandling: 'merge',
    });
  }
}
