import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  private stepSource = new BehaviorSubject<number>(1);
  currentStep = this.stepSource.asObservable();

  private activitySource = new BehaviorSubject<any>(null);
  currentActivity = this.activitySource.asObservable();

  private aforoSource = new BehaviorSubject<number>(1);
  currentAforo = this.aforoSource.asObservable();

  private extendedDescriptionSource = new BehaviorSubject<string>('');
  currentExtendedDescription = this.extendedDescriptionSource.asObservable();
  extendedDescription = new BehaviorSubject<string>('');

  private IdActividadSource = new BehaviorSubject<number>(0);
  private IdActividad = this.IdActividadSource.asObservable();

  constructor() {}

  changeStep(step: number) {
    step++;
    this.stepSource.next(step);
  }
  resetStep(step: number) {
    step--;
    this.stepSource.next(step);
  }
  changeActivity(activity: any) {
    this.activitySource.next(activity);
  }
  changeAforo(aforo: number) {
    this.aforoSource.next(aforo);
  }
  changeIdActividad(id: number) {
    this.IdActividadSource.next(id);
  }
  getIdActividad() {
    return this.IdActividad;
  }
  changeExtendedDescription(description: string) {
    this.extendedDescription.next(description);
  }
}
