<main>
    <header>
        <h1>INFORMACIÓN GENERAL</h1>
        <p>
            Con la intención de facilitar el acceso a estas actividades, hemos desarrollado un plan de información en el
            que se
            detallan todas las modalidades deportivas ofertadas, población a la que va dirigida, instalaciones en las
            cuales se
            pueden practicar y procedimiento que el usuario podrá seguir para recibir información e inscribirse en
            ellas. El
            periodo que abarca el curso, se divide en tres ciclos, comenzando en Septiembre y acabando en Junio. La
            reserva de
            plazas se realizará a través de los teléfonos, 968 980 628 o desde esta web.
        </p>
    </header>
    <section>
        <app-accordion title="¿CÓMO ME INSCRIBO EN UN CURSO?">
            <article>
                <p>
                    El máximo de reservas, tanto por teléfono 968 980 628 como en Internet, será de tres por D.N.I.
                    válida única y
                    exclusivamente para el curso seleccionado, siendo cada una de las reservas válida para una persona.
                    Una vez
                    efectuada la misma, se le asignará una cita previa para formalizar la inscripción en la oficina
                    correspondiente a la
                    instalación a la que desee acudir, comunicando al interesado que si no acude el día señalado,
                    perderá el derecho a
                    la reserva realizada, teniendo por tanto que volver a solicitarla. Las reservas no formalizadas,
                    quedan libres y se
                    vuelven a ofertar de nuevo.
                </p>
                <p>
                    Infórmate de las actividades, trámites, fechas, etc. visitando antes la sección INSCRIPCIONES Y
                    RESERVAS
                </p>
                <p>
                    Para la gestión online, ya sea ALTA o RENOVACION, debes disponer de usuario y contraseña.
                </p>
                <p>
                    Si eres o has sido usuario de instalaciones municipales, pero no dispones de acceso a la gestión
                    online, deberás activarlo.
                    ACTIVAR ACCESO GESTION ONLINE
                </p>
                <p>
                    En caso de ser usuario nuevo, podrás consultar las plazas disponibles según instalación y actividad
                    pinchando
                    <span>aquí</span>
                    Antes de inscribirte o reservar una actividad, deberás dar de alta tus datos.
                    IMPORTANTE!!! Los datos deben ser de la persona que vaya a realizar la actividad.
                </p>
            </article>
            <app-accordion title="Modalidades de inscripción o reserva de plaza">
                <app-accordion title="Inscripción">
                    <article>
                        <h2>Inscripción:</h2>
                        <p>Se refiere al pago inmediato, vía TPV, con tarjeta de débito o crédito, conectando con tu
                            entidad
                        </p>
                    </article>
                </app-accordion>
                <app-accordion title="Preinscripción">
                    <article>
                        <h2>Preinscripción</h2>
                        <p>
                            Indica una "reserva" de la plaza, asignándote una cita (día, hora e instalación) para
                            formalizarla, es decir abonar
                            el importe de la actividad. Ésta es la opción a elegir si se dispone de bonificación y no la
                            tenemos registrada y
                            PARA CAMBIOS DE CURSOS.
                        </p>
                    </article>
                </app-accordion>
            </app-accordion>
            <article>
                <h3>SI EL USUARIO TIENE DERECHO A BONIFICACIÓN, DEBERÁ PRESENTARLA PREVIO AL PAGO DE LA ACTIVIDAD</h3>

                <p>
                    En primer lugar deberás decidir la instalación donde quieres apuntarte. Puedes ver la oferta
                    deportiva y las plazas disponibles <span>aqui.</span>
                </p>
                <p>
                    En caso de haber sido usuario de alguna instalación(piscina, pabellón, etc..), prueba primero a "Soy
                    o he sido usuario. Activar mi cuenta Internet", así se evitarán duplicidades.
                </p>
                <h3>
                    <i>
                        El abono de la actividad se realizará exclusivamente con tarjeta bancaria
                    </i>
                </h3>
                <p>
                    Una vez formalizada la inscripción, se facilita una tarjeta Contactless, siendo esta imprescindible
                    para el acceso a
                    la instalación. Así mismo, junto con la tarjeta, se les entrega el recibo correspondiente al pago
                    efectuado,
                    debiendo conservarlo como justificante para cualquier reclamación.
                </p>

            </article>
        </app-accordion>
        <app-accordion title="¿QUÉ NECESITO?">
            <article>
                <h3>
                    En el momento de formalizar la inscripción, si es nuevo usuario, deberá cumplir los siguientes
                    requisitos:
                </h3>
                <ul>
                    <li>-Una fotografía reciente tamaño carné.</li>
                    <li>-D.N.I.</li>
                    <li>-Libro de familia, si el usuario es menor de edad.</li>
                    <li>-En caso de tener derecho a alguna Bonificación, el documento acreditativo.</li>
                    <li>-Deberá firmar la ficha de usuario que le entregarán en la Oficina de Inscripciones.</li>
                </ul>
                <p>
                    A los nuevos usuarios se les hará entrega de una tarjeta deportiva, personal e intransferible que
                    deberá utilizar
                    para el acceso a las instalaciones deportivas. No tire la tarjeta, le servirá para futuros cursos en
                    los que desee
                    apuntarse.
                </p>
            </article>
        </app-accordion>
        <app-accordion title="¿PUEDO CAMBIAR DE CURSO?">
            <article>
                <p>
                    Se podrán realizar cambios de actividad, horario, turno e instalación , siempre que dichas
                    instalaciones estén
                    gestionadas por la misma entidad,
                    y cuando se dé la circunstancia de que exista disponibilidad de plaza en el grupo al que se quiere
                    acceder. El
                    protocolo a seguir es coger la plaza a la que desea cambiar y seleccionar la modalidad"
                    PREINSCRIPCIÓN" y realizar
                    el cambio en su oficina de Inscripciones. Cuando el turno al que se desea cambiar tenga un precio
                    público inferior o
                    superior, se devolverá o se abonará, en su caso, la cantidad económica, todo ello de conformidad con
                    el artículo 22º
                    de la Ordenanza General de Gestión, Recaudación e Inspección de Tributos Locales.
                </p>
                <p>
                    En ningún caso procederá la devolución del precio público abonado cuando el
                    usuario de programas deportivos municipales renuncie por razones particulares
                    continuar la práctica deportiva en la que figura inscrito.
                </p>
                <h3>
                    Puede descargar el formulario para tal efecto <span>aqui.</span>
                </h3>
            </article>
        </app-accordion>
        <app-accordion title="¿PUEDO DARME DE BAJA?">
            <article>
                <p>
                    El usuario puede proceder a darse de baja en el curso en el que esté inscrito en cualquier momento,
                    pero, en ningún
                    caso, procederá la devolución del precio público abonado cuando el usuario de programas deportivos
                    municipales
                    renuncie por razones particulares a continuar la práctica deportiva en la que figura inscrito.
                </p>
                <p>
                    Cuando por causas ajenas al usuario, tales como averías, reparaciones, celebración de eventos
                    extraordinarios u otras de
                    naturaleza similar, no se pueda prestar el servicio o no sea posible hacer uso del espacio deportivo
                    reservado, y
                    siempre que no arbitre solución alternativa, el usuario podrá solicitar la devolución proporcional
                    que corresponda de la
                    tarifa del precio público abonado.
                </p>
                <h3>
                    Puede descargar el formulario para tal efecto <span>aqui.</span>
                </h3>
            </article>
        </app-accordion>
        <app-accordion title="CARNET DE USUARIO.">
            <article>
                <h3>
                    En caso de nueva alta el carnet de usuario será gratuito. En caso de pérdida o deterioro de este, se
                    aplicará una
                    tasa de 3euros para la reexpedición.
                </h3>
            </article>
        </app-accordion>
        <app-accordion title="RENOVACIONES (Actividades del programa anual)">
            <article>
                <p>
                    La actividad deportiva en la que se está inscrito, independientemente de la duración de la misma
                    (ciclos trimestral,
                    semestral o anual), puede ser renovada de forma presencial en su Oficina de Inscripción de
                    referencia y horario de
                    atención o las 24h Online.
                </p>
                <p>
                    De forma habitual, a principios del último mes del ciclo (o ciclos) contratado, se abre el periodo
                    habilitado para la
                    renovación de la actividad para el siguiente. En caso de corresponder con el último ciclo de la
                    temporada, la renovación
                    se realizará para el 1er. Ciclo de la siguiente temporada*.
                </p>
                <p>
                    Si al CUMPLIR AÑOS, el/la usuario/a debiese cambiar de curso al siguiente disponible, la renovación
                    deberá
                    realizarse EXCLUSIVAMENTE de forma PRESENCIAL.
                </p>
                <p>
                    En la medida de lo posible, se informará de las fechas de renovación en esta web, en la web de
                    usuarios, en Redes
                    Sociales o mediante carteles informativos en la instalación.
                </p>
                <h3>
                    * La temporada deportiva del Programa Deportivo Docente comienza en Septiembre y finaliza en Junio.
                    En los meses estivales se pueden establecer cursos y actividades específicas no sujetas a
                    renovaciones. En estos
                    cursos y actividades se realizarán inscripciones de forma habitual.
                </h3>
            </article>
        </app-accordion>
    </section>
</main>