import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-renderer',
  templateUrl: './image-renderer.component.html',
  styleUrls: ['./image-renderer.component.css'],
})
export class ImageRendererComponent {
  @Input() classList: string = '';
  @Input() id: string | null = null;
  @Input() altString: string | null = null;
  @Input() src: string | null = null;
  @Input() routeAddress: string | null = null;
  @Input() imgClass: string | null = null;
}
