<ng-container>
  <svg
    id="Capa_1"
    [class]="classList ? classList : 'w-full h-full'"
    image-rendering="optimizeQuality"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    (mouseenter)="color = hoverFill"
    (mouseleave)="color = fill"
    [attr.fill]="hover ? hoverFill : color"
  >
    <style type="text/css">
      .st0 {
        fill: none;
      }
    </style>
    <g>
      <path
        d="M404.4,442.1c-20.9-4.2-40.8-2.1-62.7,1c-1,0-3.1,0-5.2,1h-1c-17.8,2.1-33.4,11.5-49.1,18.8c-33.4,17.8-58.5,29.3-90.9,2.1
		c-28.2-23-61.7-28.2-97.2-19.9c-5.7,1.3-11.3,3.4-16.7,6v34.5c1.7-0.9,3.3-1.9,5.2-2.8c8.4-4.2,10.5-7.3,18.8-9.4
		c26.1-6.3,50.2-2.1,71.1,14.6c16.7,13.6,36.6,22,58.5,22c23,0,43.9-10.5,64.8-20.9c12.5-6.3,27.2-13.6,40.8-15.7c1,0,2.1-1,3.1-1
		c0,0,0,0,1,0c1,0,0,0,2.1,0c27.7-6.9,61.1-5.2,83.6,13.9v-35.2C422.6,447.2,413.9,444.2,404.4,442.1z"
      />
      <path
        class="st0"
        d="M180.7,297.8c5.2,3.1,9.4,6.3,14.6,10.5c32.4,27.2,57.5,15.7,90.9-2.1c14.6-7.3,29.3-15.7,44.9-17.8v-13.6
		H180.7V297.8z"
      />
      <rect x="180.7" y="201.6" class="st0" width="150.5" height="43.9" />
      <rect x="180.7" y="128.5" class="st0" width="150.5" height="43.9" />
      <path
        d="M430.6,69.9V51.1c0-27.2-23-49.1-49.1-49.1c-27.2,0-50.2,22-50.2,49.1v23v25.1H180.7V74.1v-23c0-10.5,9.4-19.9,19.9-19.9
		c10.5,0,19.9,9.4,19.9,19.9v18.8h29.3V51.1c0-27.2-23-49.1-49.1-49.1c-27.2,0-49.1,22-49.1,49.1v23v39.7V187v100.3
		c-16.7-4.2-34.5-3.1-53.3,1c-5.7,1.3-11.3,3.4-16.7,6v34.5c1.7-0.9,3.3-1.9,5.2-2.8c8.4-4.2,10.5-7.3,18.8-9.4
		c26.1-6.3,50.2-2.1,71.1,14.6c16.7,13.6,36.6,22,58.5,22c23,0,43.9-10.5,64.8-20.9c12.5-6.3,27.2-14.6,40.8-15.7c1,0,2.1-1,3.1-1
		c0,0,0,0,1,0c1,0,0,0,2.1,0c27.7-6.9,61.1-5.2,83.6,13.3v-35.1c-8-3.9-16.7-6.7-26.1-8.6c-14.6-3.1-29.3-2.1-43.9-1V187v-73.2V74.1
		v-23c0-11.5,9.4-19.9,20.9-19.9c10.5,0,19.9,9.4,19.9,19.9v18.8H430.6z M331.3,288.4c-15.7,2.1-30.3,10.5-44.9,17.8
		c-33.4,17.8-58.5,29.3-90.9,2.1c-5.2-4.2-9.4-7.3-14.6-10.5v-23h150.5V288.4z M331.3,245.5H180.7v-43.9h150.5V245.5z M331.3,172.4
		H180.7v-43.9h150.5V172.4z"
      />
      <path
        d="M404.4,363.7c-20.9-4.2-40.8-2.1-62.7,1c-1,0-3.1,0-5.2,1h-1c-17.8,2.1-33.4,11.5-49.1,18.8c-33.4,17.8-58.5,29.3-90.9,2.1
		c-28.2-23-61.7-28.2-97.2-19.9c-5.7,1.3-11.3,3.4-16.7,6v34.5c1.7-0.9,3.3-1.9,5.2-2.8c8.4-4.2,10.5-7.3,18.8-9.4
		c26.1-6.3,50.2-2.1,71.1,14.6c16.7,13.6,36.6,22,58.5,22c23,0,43.9-10.5,64.8-20.9c12.5-6.3,27.2-13.6,40.8-15.7c1,0,2.1-1,3.1-1
		c0,0,0,0,1,0c1,0,0,0,2.1,0c27.7-6.9,61.1-5.2,83.6,13.9v-35.2C422.6,368.8,413.9,365.8,404.4,363.7z"
      />
    </g>
  </svg>
</ng-container>
