import { Component, Input } from '@angular/core';
import { Convocatoria } from '../../interfaces/ConvocatoriaResponse';

@Component({
  selector: 'app-convocatorias',
  templateUrl: './convocatorias.component.html',
  styleUrls: ['./convocatorias.component.css'],
})
export class ConvocatoriasComponent {
  @Input() convocatorias: Convocatoria[] = [];

  currentPageData: any[] = [];
  handleEmitData(data: any[]) {
    this.currentPageData = data;
  }

  indexSelected: number = 0;

  indexChange(index: number) {
    this.indexSelected = index;
  }
}
