import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TablaFormService } from '../../services/tabla-form/tabla-form.service';
import { ModalService } from '../../../services/modal/modal.service';
import { AgregarObservacionComponent } from '../agregar-observacion/agregar-observacion.component';
import { tap } from 'rxjs';

@Component({
  selector: 'app-linea-usuario',
  templateUrl: './linea-usuario.component.html',
  styleUrls: ['./linea-usuario.component.css'],
})
export class LineaUsuarioComponent {
  @Input() usuarioFormGroup: UntypedFormGroup;
  @Input() indice: number;
  @Output() openCalendar: EventEmitter<any> = new EventEmitter();

  constructor(private tablaFormService: TablaFormService, private modalService: ModalService) {}

  deletePerson() {
    this.tablaFormService.deleteUsuario(this.indice);
  }

  handleOpenCalendar() {
    this.openCalendar.emit(this.usuario?.id);
  }

  addComentario() {
    this.modalService
      .showComponent(
        AgregarObservacionComponent,
        {
          observacion: this.usuario?.observaciones,
          title: 'Observación Individual',
        },
        ['observacionChange']
      )
      .pipe(
        tap(res => {
          if (res?.observacionChange) {
            this.usuarioFormGroup.get('observaciones').patchValue(res?.observacionChange);
          }
          this.modalService.removeComponent();
        })
      )
      .subscribe();
  }

  get usuario() {
    return this.usuarioFormGroup.getRawValue();
  }
}
