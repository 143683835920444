import { Component } from '@angular/core';
import { DeporteEscolarService, Memoria } from '../../../services/deporte-escolar.service';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { getTitle } from 'src/app/utils/breadcrumbs';

@Component({
  selector: 'app-memoria',
  templateUrl: './memoria.component.html',
  styleUrls: ['./memoria.component.css'],
})
export class MemoriaComponent {
  memorias: Memoria[] | null = null;
  activeTab: number = 3;

  constructor(
    private deporteEscolarService: DeporteEscolarService,
    private breadCrumbs: BreadcrumbService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(getTitle('Memoria'));
    this.breadCrumbs.addCurrentBreadcrumb();
    this.deporteEscolarService.getMemoria().subscribe((data: Memoria[]) => {
      this.memorias = data;
    });
  }
}
