<app-page-with-breadcrumb>
    <main>
        <article>
            <section>
                <h2 class="text-primary font-aldo">Perfil</h2>
            </section>
            <section class="profile-image">
                <app-image-renderer *ngIf="user?.profileImage; else noImage"
                    [classList]="'flex justify-center md:justify-start'" [src]="'/favicon.ico'" [altString]="'Logo'"
                    [imgClass]="'h-14'" [routeAddress]="'/'" />
                <ng-template #noImage>
                    <app-no-image [data]="{
                            letterOne: user?.name?.charAt(0),
                            letterTwo: user?.lastName1?.charAt(0)
                        }" size="150" [fontSize]="4" [borderColor]="borderColor" />
                </ng-template>
                <small>#{{user?.id}}</small>
            </section>
            <article>
                <p>{{user?.name}} {{user?.lastName1}} {{user?.lastName2}}</p>
                <p>{{user?.mobilePhone}}</p>
                <p>{{user?.email}}</p>
            </article>
        </article>
        <app-button label="Modificar perfil" />
    </main>
</app-page-with-breadcrumb>
<app-info-floating-button />