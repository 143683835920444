import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PrivateGuard implements CanActivate {
  constructor(private globalService: GlobalService, private router: Router) {}

  async canActivate(): Promise<boolean> {
    const requiredPrivateConnection = await this.globalService
      .getDeporwinStatus()
      .pipe(first())
      .toPromise();

    if (!requiredPrivateConnection) {
      await this.router.navigate(['']);
      return false;
    }

    return requiredPrivateConnection;
  }
}
