import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { InscripcionesComponent } from './pages/inscripciones/inscripciones.component';
import { AccesibilidadComponent } from './pages/accesibilidad/accesibilidad.component';
import { InfoComponent } from './pages/info/info.component';
import { LoginComponent } from './pages/login/login.component';
import { UserMainPageComponent } from './pages/user-main-page/user-main-page.component';
import { authGuard } from './guards/authGuard';
import { SigninComponent } from './pages/signin/signin.component';
import { RememberPasswordComponent } from './pages/remember-password/remember-password.component';
import { BookFacilityComponent } from './pages/booking/book-facility/book-facility.component';
import { PrivateGuard } from './guards/private.guard';
import { SuperuserModule } from './superuser/superuser.module';
const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: { animation: 'home' },
  },
  {
    path: 'instalaciones',
    loadChildren: () =>
      import('./instalaciones/instalaciones.module').then(m => m.InstalacionesModule),
    data: { animation: 'instalaciones' },
  },
  {
    path: 'deportes',
    loadChildren: () => import('./pages/deportes/deportes.module').then(m => m.DeportesModule),
    data: { animation: 'deportes' },
  },
  {
    path: 'noticias',
    loadChildren: () => import('./noticias/noticias.module').then(m => m.NoticiasModule),
    data: { animation: 'noticias' },
  },
  {
    path: 'deporte-escolar',
    loadChildren: () =>
      import('./deporte-escolar/deporte-escolar.module').then(m => m.DeporteEscolarModule),
    data: { animation: 'deporte-escolar' },
  },
  {
    path: 'avisos',
    loadChildren: () => import('./noticias/noticias.module').then(m => m.NoticiasModule),
    data: { animation: 'avisos' },
  },
  {
    path: 'convocatorias',
    loadChildren: () => import('./noticias/noticias.module').then(m => m.NoticiasModule),
    data: { animation: 'convocatorias' },
  },
  {
    path: 'escuelas',
    loadChildren: () => import('./escuelas/escuelas.module').then(m => m.EscuelasModule),
    data: { animation: 'escuelas' },
  },
  {
    path: 'inscripciones',
    component: InscripcionesComponent,
    data: { animation: 'inscripciones' },
  },
  {
    path: 'accesibilidad',
    component: AccesibilidadComponent,
    data: { animation: 'accesibilidad' },
  },
  {
    path: 'mapa-web',
    loadChildren: () => import('./mapa-web/mapa-web.module').then(m => m.MapaWebModule),
    data: { animation: 'mapa-web' },
  },
  {
    path: 'info/:id',
    component: InfoComponent,
    data: { animation: 'info' },
  },
  {
    path: 'rutas',
    loadChildren: () => import('./rutas/rutas.module').then(m => m.RutasModule),
    data: { animation: 'rutas' },
  },
  {
    path: 'precios-publicos',
    loadChildren: () =>
      import('./precios-publicos/precios-publicos.module').then(m => m.PreciosPublicosModule),
    data: { animation: 'precios-publicos' },
  },
  {
    path: 'incidencias',
    loadChildren: () => import('./incidencias/inidencias.module').then(m => m.InidenciasModule),
    data: { animation: 'incidencias' },
  },
  {
    path: 'admin',
    loadChildren: () => import('./superuser/superuser.module').then(m => m.SuperuserModule),
    data: { animation: 'admin' },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { animation: 'login' },
    canActivate: [PrivateGuard],
  },
  {
    path: 'register',
    component: SigninComponent,
    data: { animation: 'register' },
    canActivate: [PrivateGuard],
  },
  {
    path: 'remember-password',
    component: RememberPasswordComponent,
    data: { animation: 'remember-password' },
    canActivate: [PrivateGuard],
  },
  {
    path: 'user-profile',
    component: UserMainPageComponent,
    data: { animation: 'user-profile' },
    canActivate: [authGuard],
  },
  {
    path: 'monitor',
    loadChildren: () => import('./monitor/monitor.module').then(m => m.MonitorModule),
    data: { animation: 'monitor' },
    // canActivate: [authGuard], TODO: Debe activarse pero de momento para desarrollar más rápido no lo está
  },
  {
    path: 'reservar-instalacion/:id',
    component: BookFacilityComponent,
    data: { animation: 'reservar-instalacion' },
    canActivate: [authGuard],
  },
  {
    path: '**',
    redirectTo: '',
    data: { animation: 'isRight' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
