import { Component } from '@angular/core';
import { SharedDataService } from '../service/book-facility.service';
import { first } from 'rxjs/operators';
import { ServiceService } from 'src/app/services/service.service';
@Component({
  selector: 'app-book-facility-step-two',
  templateUrl: './book-facility-step-two.component.html',
  styleUrls: ['./book-facility-step-two.component.css'],
})
export class BookFacilityStepTwoComponent {
  resources: any[] = [];

  currentStep: number = 2;
  showloader: boolean = true;
  IdActividad: number = 0;

  constructor(
    private sharedDataService: SharedDataService,
    private ServiceService: ServiceService
  ) {
    this.sharedDataService.currentStep.subscribe();
    this.sharedDataService
      .getIdActividad()
      .pipe(first())
      .subscribe(id => {
        this.IdActividad = id;
        this.getActividadesFiltradas(this.IdActividad);
      });
  }

  getBack() {
    this.sharedDataService.resetStep(this.currentStep);
  }

  getActividadesFiltradas(id: number) {
    this.ServiceService.getActividadesFiltradas(id).subscribe((data: any) => {
      this.resources = data.Servicios;
      this.showloader = false;
    });
  }
}
