import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pool-icon',
  templateUrl: './pool-icon.component.html',
  styleUrls: ['./pool-icon.component.css'],
})
export class PoolIconComponent implements OnChanges {
  @Input() classList?: string = '';
  @Input() fill: string = '#e51818';
  @Input() hoverFill: string = 'white';
  @Input() hover: boolean = false;
  color: string = this.fill;

  ngOnChanges(changes: SimpleChanges) {
    this.color = this.fill;
  }
}
