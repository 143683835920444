import { Component, EventEmitter, Input, Output } from '@angular/core';
import dayjs from 'dayjs';
import { bgColors } from '../../utils/colors';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-calendar-selector',
  templateUrl: './calendar-selector.component.html',
  styleUrls: ['./calendar-selector.component.css'],
})
export class CalendarSelectorComponent {
  date = dayjs();
  today = this.date;
  @Input() dates: { year: number; month: number; date: number; colorIndex: number; data?: any }[] =
    [];
  @Output() clickEvent? = new EventEmitter<{
    year: number;
    month: number;
    date: number;
    data?: any;
  }>();
  firstDay = this.date.startOf('month').day() - 1;
  constructor(private changeDetector: ChangeDetectorRef) {}

  months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  days = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'];

  ngOnInit() {
    this.changeDetector.detectChanges();
  }

  prevMonth() {
    this.date = this.date.subtract(1, 'month');
    this.firstDay = this.date.date(0).day() % 7;
  }

  nextMonth() {
    this.date = this.date.add(1, 'month');
    this.firstDay = this.date.date(0).day() % 7;
  }

  getBGColor({ year, month, date }: { year: number; month: number; date: number }) {
    const datesToColorize = this.dates.filter(
      d => d.year === year && d.month === month && d.date === date
    );

    if (datesToColorize.length === 0) {
      return '';
    }

    return (
      'backgroundColor: ' +
      bgColors[datesToColorize[0].colorIndex % bgColors.length] +
      '; color: white'
    );
  }

  onClick({ year, month, date }: { year: number; month: number; date: number }) {
    const data = this.dates.find(
      d => d.year === year && d.month === month && d.date === date
    )?.data;
    this.clickEvent && this.clickEvent.emit({ year, month, date, data });
  }
}
