<app-page-with-breadcrumb>
  <span *ngIf="overTabsErrorMessages?.length > 0">
    <h6>Errores</h6>
    <div class="overTabsErrors" *ngFor="let message of overTabsErrorMessages">
      <label>{{ message?.message }}</label>
    </div>
  </span>
  <app-tabla-form-group
    (saveRutina)="saveRutina()"
    [rutinaFormGroup]="rutinaFormGroup"
    [allEjercicios]="allEjercicios"
    [isSavingOrLoading]="savingOrLoading"
  />
</app-page-with-breadcrumb>
