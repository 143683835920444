import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TypeInput } from '../../form/input/input.component';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { ApiStatus } from '../../interfaces';
import { DatetimeType } from '../../form/datetime-picker/datetime-picker.component';
import { RegisterService } from 'src/app/services/register.service';
import { ModalSuccessService } from 'src/app/services/modal-success.service';

export interface RegisterUserForm {
  Nombre: FormControl<string | null>;
  Apellido1: FormControl<string | null>;
  Apellido2: FormControl<string | null>;
  FechaNacimiento: FormControl<string | null>;
  Sexo: FormControl<Sexo | string | null>;
  AceptaCondiciones: FormControl<boolean | null>;
  DocumentoIdentidad: FormControl<string | null>;
  Email: FormControl<string | null>;
  TelefonoMovil: FormControl<string | null>;
  CodigoPostal: FormControl<string | null>;
  Telefono: FormControl<string | null>;
}

export interface PasswordForm {
  Token: FormControl<string | null>;
  Contrasena: FormControl<string | null>;
  ContrasenaRepetida: FormControl<string | null>;
}

export interface PINForm {
  PIN: FormControl<string | null>;
}

export enum Sexo {
  Hombre = 'Hombre',
  Mujer = 'Mujer',
}

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.css'],
})
export class RegisterFormComponent {
  stepForm: number = 1;
  errores: any = {};
  apiStatus: ApiStatus = { loading: false, error: false };
  isPinDisabled: boolean = false;

  sexOptions = [
    { value: Sexo.Hombre, label: Sexo.Hombre },
    { value: Sexo.Mujer, label: Sexo.Mujer },
  ];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private registerService: RegisterService,
    private modalService: ModalSuccessService
  ) {}

  /* TODO: Crear un componente de cada formGroup, componetizar el formulario
  completo de modo que cada step sea un componente */

  formRegisterUser: FormGroup<RegisterUserForm> = this.fb.group({
    Nombre: ['', { validators: Validators.required, updateOn: 'blur' }],
    Apellido1: ['', { validators: Validators.required, updateOn: 'blur' }],
    Apellido2: ['', { validators: Validators.required, updateOn: 'blur' }],
    Sexo: ['', Validators.required],
    Telefono: [
      '',
      {
        validators: [Validators.required, Validators.minLength(9), Validators.maxLength(9)],
        updateOn: 'blur',
      },
    ],
    TelefonoMovil: [
      '',
      {
        validators: [Validators.required, Validators.minLength(9), Validators.maxLength(9)],
        updateOn: 'blur',
      },
    ],
    FechaNacimiento: [dayjs().toISOString(), Validators.required],
    AceptaCondiciones: [false, [Validators.required, Validators.requiredTrue]],
    DocumentoIdentidad: ['', { validators: Validators.required, updateOn: 'blur' }],
    CodigoPostal: [
      '',
      { validators: [Validators.required, Validators.pattern('^[0-9]{5}$')], updateOn: 'blur' },
    ],
    Email: ['', { validators: [Validators.required, Validators.email], updateOn: 'blur' }],
  });

  formPassword: FormGroup<PasswordForm> = this.fb.group(
    {
      Token: ['', { validators: Validators.required, updateOn: 'blur' }],
      Contrasena: [
        '',
        {
          validators: [
            Validators.required,
            Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/),
          ],
          updateOn: 'blur',
        },
      ],
      ContrasenaRepetida: ['', { validators: Validators.required, updateOn: 'blur' }],
    },
    { validators: this.passwordMatchValidator, updateOn: 'blur' }
  );

  formPIN: FormGroup<PINForm> = this.fb.group({
    PIN: [
      '',
      {
        validators: [Validators.required, Validators.minLength(4), Validators.maxLength(4)],
        updateOn: 'blur',
      },
    ],
  });

  passwordMatchValidator(control: FormGroup): ValidationErrors {
    const password = control.get('contrasena')?.value;
    const confirmPassword = control.get('contrasenaRepetida')?.value;

    return password === confirmPassword ? {} : { passwordMismatch: true };
  }

  onSubmitForm(body: any) {
    this.registerService.registerUserStepOne(body).subscribe(res => {
      if (this.registerService.userRegistered)
        this.registerService.getTokenAutenticacion().subscribe((res: any) => {
          if (res?.Token) {
            this.formPassword.get('Token')?.setValue(res?.Token);
            this.stepForm = 3;
          }
        });
    });
  }

  onSubmitPIN() {
    const controlToCheck = ['PIN'];
    if (!this.checkErrors(controlToCheck, this.formPIN)) {
      this.isPinDisabled = true;
      this.registerService.registerUserStepTwo(this.formPIN.value).subscribe((response: any) => {
        if (response?.status === 200) {
          this.modalService.showModal('Registro completado', 2500);
          this.router.navigate(['/login']);
        } else {
          this.isPinDisabled = false;
        }
      });
    }
  }

  registerPassword() {
    const controlToCheck = ['Contrasena', 'ContrasenaRepetida'];
    if (!this.checkErrors(controlToCheck, this.formPassword)) {
      this.registerService
        .registerUserStepOne(
          this.convertDataForApi(this.formRegisterUser.value, this.formPassword.value)
        )
        .subscribe(
          (res: any) => {
            if (res?.status === 201) {
              this.stepForm = 4;
            }
          },
          error => {
            this.apiStatus = { loading: false, error: true };
          }
        );
    }
  }

  submit() {
    const controlsToCheck = [
      'DocumentoIdentidad',
      'CodigoPostal',
      'Email',
      'Telefono',
      'TelefonoMovil',
    ];
    if (!this.checkErrors(controlsToCheck, this.formRegisterUser)) {
      this.stepForm = 3;
    }
  }

  convertDataForApi(userData: any, passwordData: any) {
    return {
      Nombre: userData?.Nombre?.trim(),
      Apellido1: userData?.Apellido1?.trim(),
      Apellido2: userData?.Apellido2?.trim(),
      Sexo: userData?.Sexo,
      Telefono: userData?.Telefono?.trim(),
      TelefonoMovil: userData?.TelefonoMovil?.trim(),
      FechaNacimiento: dayjs(userData?.FechaNacimiento).format('YYYY-MM-DD'),
      DocumentoIdentidad: userData?.DocumentoIdentidad?.trim(),
      CodigoPostal: userData?.CodigoPostal?.trim(),
      Email: userData?.Email?.trim(),
      Contrasena: passwordData?.Contrasena?.trim(),
    };
  }

  onCancel() {
    this.router.navigate(['/login']);
  }

  goDatosContacto() {
    const controlsToCheck = [
      'Nombre',
      'Apellido1',
      'Apellido2',
      'FechaNacimiento',
      'AceptaCondiciones',
      'Sexo',
    ];
    if (!this.checkErrors(controlsToCheck, this.formRegisterUser)) {
      this.stepForm = 2;
    }
  }

  checkErrors(fields: string[], form: FormGroup) {
    this.errores = {};
    fields.forEach(key => {
      const controlError = form.get(key)?.errors;
      if (controlError != null) {
        this.errores[key] = controlError;
      }
    });
    return Object.keys(this.errores).length !== 0;
  }

  protected readonly TypeInput = TypeInput;
  protected readonly dayjs = dayjs;
  protected readonly DatetimeType = DatetimeType;
}
