import { AfterViewInit, Component, HostListener } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { animation } from './animations';
import { Accesibilidad, MainService } from './services/main.service';
import { UserService } from './services/user.service';
import { GlobalService } from './services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [animation],
})
export class AppComponent implements AfterViewInit {
  title: string = 'murcia-deportes';
  height: number = 0;

  constructor(
    public mainService: MainService,
    private userService: UserService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.userService.getDeporwinStatus().subscribe(
      (res: any) => {
        this.globalService.setDeporwinStatus(true);
      },
      (error: any) => {
        this.globalService.removeLoggedUser();
        this.globalService.setDeporwinStatus(false);
      }
    );
  }

  ngAfterViewInit() {
    this.resize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resize();
  }

  resize() {
    const footer = document.getElementsByTagName('footer')[0]?.offsetHeight;
    const header = document.getElementsByTagName('header')[0]?.offsetHeight;
    this.height = window.innerHeight - (footer + header);

    const body = document.getElementById('body');
    const fabUp = document.getElementById('fab-up');
    fabUp!.style.marginBottom = `${footer - footer / 1.5}px`;
    body!.style.minHeight = `${this.height}px`;
  }
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  protected readonly Accesibilidad = Accesibilidad;
}
