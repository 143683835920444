import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalSuccessService } from 'src/app/services/modal-success.service';

@Component({
  selector: 'app-modal-success',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit, OnDestroy {
  message: string = '';
  private subscription: Subscription = new Subscription();
  private timeoutId: any;
  private closeTimeout: number = 2500;

  constructor(public modalService: ModalSuccessService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.modalService.successMessage$.subscribe(message => {
        this.message = message;
        if (message) {
          this.closeModal();
        }
      })
    );
    this.subscription.add(
      this.modalService.modalVisible$.subscribe(visible => {
        if (visible) {
          this.closeModal();
        } else {
          clearTimeout(this.timeoutId);
        }
      })
    );
    this.subscription.add(
      this.modalService.closeTimeout$.subscribe(timeout => {
        this.closeTimeout = timeout;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    clearTimeout(this.timeoutId);
  }

  closed(): void {
    this.modalService.hideModal();
  }

  closeModal(): void {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.closed();
    }, this.closeTimeout);
  }
}
