<article class="p-1 border border-gray-200">
  <div
    id="title-container"
    class="flex w-full cursor-pointer items-center p-2 hover:bg-gray-100 space-x-2.5"
    (click)="open = !open"
  >
    <img
      src="/assets/icons/flecha.svg"
      alt="Flecha"
      class="w-6 transition-all ease-in"
      [ngClass]="open ? 'rotate-90' : 'rotate-0'"
    />
    <h5 class="font-bold">{{ instalacion?.title | uppercase }}</h5>
  </div>
  <div
    class="w-full overflow-hidden transition-all ease-in"
    [ngClass]="open ? 'h-full pb-2 px-3.5' : 'h-0'"
  >
    <div *ngIf="instalacion?.direccion" class="mt-4">
      <h6 class="text-xl font-semibold">Dirección</h6>
      <p>{{ instalacion?.direccion }}</p>
    </div>
    <div *ngIf="instalacion?.telefono" class="mt-4">
      <h6 class="text-xl font-semibold">Teléfono</h6>
      <p>{{ instalacion?.telefono }}</p>
    </div>
    <div *ngIf="instalacion?.lineas" class="mt-4">
      <h6 class="text-xl font-semibold">Líneas de Autobuses</h6>
      <p>{{ instalacion?.lineas }}</p>
    </div>
    <div *ngIf="instalacion?.horario" class="mt-4">
      <h6 class="text-xl font-semibold">Horario</h6>
      <p>{{ instalacion?.horario }}</p>
    </div>
    <div class="w-full flex justify-end mt-2">
      <a
        [routerLink]="'/instalaciones/' + instalacion?.id"
        class="p-2 bg-primary text-white rounded-2xl"
        >Ver más</a
      >
    </div>
  </div>
</article>
