import { Injectable } from '@angular/core';
import { Ejercicio } from '../../models/ejercicios.model';

@Injectable({
  providedIn: 'root',
})
export class EjerciciosConverterService {
  constructor() {}

  fromDto(ejercicio: any): Ejercicio {
    return {
      id: ejercicio?.id,
      title: ejercicio?.title,
      descripcion: ejercicio?.descripcion,
      img: ejercicio?.img,
      video: ejercicio?.video,
      grupoMuscular: ejercicio?.grupoMuscular ? ejercicio?.grupoMuscular : null,
      icon: ejercicio?.grupoMuscular[0]?.name?.toLowerCase() || null,
      admiteCarga: !!ejercicio?.admiteCarga,
      admiteTiempo: !!ejercicio?.admiteTiempo,
      admiteSeries: !!ejercicio?.admiteSeries,
      admiteRepeticiones: !!ejercicio?.admiteRepeticiones,
    } as Ejercicio;
  }
}
