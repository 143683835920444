import { Component, Input } from '@angular/core';
import { ModalService } from 'src/app/services/modal/modal.service';
import { MonitorProfileFormComponent } from '../monitor-profile-form/monitor-profile-form.component';
import { tap } from 'rxjs';

@Component({
  selector: 'app-perfil-resumen',
  templateUrl: './perfil-resumen.component.html',
  styleUrls: ['./perfil-resumen.component.css'],
})
export class PerfilResumenComponent {
  @Input() user: any;

  constructor(private modalService: ModalService) {}

  editProfile() {
    this.modalService
      .showComponent(
        MonitorProfileFormComponent,
        {
          user: this.user,
        },
        ['cerrar']
      )
      .pipe(
        tap((output: any) => {
          if (output.cerrar) {
            this.modalService.removeComponent();
          }
        })
      )
      .subscribe();
  }
}
