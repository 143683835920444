<div class="fixed z-30 w-full h-screen flex justify-around items-center bg-black bg-opacity-70 top-0 left-0">

  <button class="absolute right-0 top-0 text-2xl p-3 m-4 text-white border border-white rounded-2xl bg-gray-300 bg-opacity-20 hover:bg-opacity-40" (click)="close.emit(false)">
    Esc
  </button>

  <button class="text-white text-4xl w-1/5 h-2/3 z-40" (click)="anterior()">
    <
  </button>
  <div #modalImagen class="w-11/12 md:w-4/5  overflow-x-scroll overflow-y-hidden snap-mandatory snap-x">

    <div [id]="'imagen-'+i" *ngFor="let imagen of images, let i = index" class="inline-block w-full h-full snap-center align-middle m-auto">
      <img [src]="environment.imgUrl+imagen.url" [alt]="imagen.uid" class="h-full m-auto">
    </div>

  </div>
  <button class="text-white text-4xl w-1/5 h-2/3 z-40" (click)="siguiente()">
  >
  </button>

</div>

