import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Ejercicio } from '../../models/ejercicios.model';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { usuarios } from '../../mock/tabla.mock';

@Component({
  selector: 'app-tabla-form-group',
  templateUrl: './tabla-form-group.component.html',
  styleUrls: ['./tabla-form-group.component.css'],
})
export class TablaFormGroupComponent {
  @Input() rutinaFormGroup: UntypedFormGroup;
  @Input() allEjercicios: Ejercicio[];
  @Input() isSavingOrLoading: boolean = false;

  @Output() saveRutina = new EventEmitter();

  onSaveRutina() {
    this.saveRutina.emit();
  }

  get tablaFormGroup(): UntypedFormGroup {
    return this.rutinaFormGroup.get('tabla') as UntypedFormGroup;
  }

  get usuariosFormArray(): UntypedFormArray {
    return this.rutinaFormGroup.get('usuarios') as UntypedFormArray;
  }

  protected readonly usuarios = usuarios;
}
