import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { SharedDataService } from '../service/book-facility.service';
import { ServiceService } from 'src/app/services/service.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css'],
})
export class ActivityComponent implements OnInit {
  @Input() activity: any;
  @Input() activityDescription: any;
  @Input() IdActividad: any;

  showloader: boolean = true;
  currentDescription: string = '';
  currentExtendedDescription: string = '';
  currentAforo: any;

  currentActivity: any;
  currentStep: number = 1;

  @Output() activityClick = new EventEmitter<any>();
  @Output() aforoClick = new EventEmitter<any>();

  resources: any;
  IdFamilia = 0;
  static i = 0;
  @Input() step: number = 1;

  constructor(
    private sharedDataService: SharedDataService,
    private ServiceService: ServiceService,
    private route: ActivatedRoute
  ) {
    this.IdFamilia = this.route.snapshot.params['id'];
    this.sharedDataService.getIdActividad().subscribe(id => {
      this.IdActividad = id;
    });
  }
  ngOnInit() {
    this.ServiceService.getActivities(this.IdFamilia).subscribe((data: any) => {
      this.resources = data.SubFamilias;
      this.IdActividad = this.resources[ActivityComponent.i].Id;
      this.sharedDataService.changeActivity(this.IdActividad);

      if (ActivityComponent.i < this.resources.length) {
        this.currentDescription = this.resources[ActivityComponent.i].Descripcion;
        this.currentExtendedDescription = this.resources[ActivityComponent.i].DescripcionAmpliada;
        this.sharedDataService.changeExtendedDescription(this.currentExtendedDescription);

        ActivityComponent.i++;
      } else {
        this.showloader = false;
      }
    });
  }

  ngOnDestroy() {
    ActivityComponent.i = 0;
  }

  onActivityClick() {
    this.currentActivity = this.activity;
    this.sharedDataService.changeStep(this.currentStep);
    this.sharedDataService.changeIdActividad(this.IdActividad);
    this.sharedDataService.changeExtendedDescription(this.currentExtendedDescription);
  }
}
