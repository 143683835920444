
<main>
    <header>
        <app-icon src="grid" stroke="var(--wine-red)" customClass="icon-list-style" (click)="selectCardView()"/>
        <app-icon src="list" stroke="var(--wine-red)" customClass="icon-list-style" (click)="selectListView()"/>
    </header>
    <section *ngIf="isCardView; else listView">
        <ng-container *ngFor="let item of data">
            <app-list-card
            [data]="item"
            [isCardView]="isCardView"
            [cardColumns]="cardColumns"
            [listColumns]="listColumns"
            [title]="item.title"
            />
        </ng-container>
    </section>
    
    <ng-template #listView>
        <table >
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th *ngFor="let column of listColumns">{{column.name}}</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of data; trackBy: trackByFn">
                    <tr appListCard
                    [data]="item"
                    [isCardView]="isCardView"
                    [cardColumns]="cardColumns"
                    [listColumns]="listColumns"
                    [title]="item.title"
                    ></tr>
                </ng-container>
            </tbody>
        </table>
    </ng-template>
</main>