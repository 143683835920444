import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
class Indicator {
  constructor(public ref: number) {}
}

@Component({
  selector: 'app-dot-navigation',
  templateUrl: './dot-navigation.component.html',
  styleUrls: ['./dot-navigation.component.css'],
})
export class DotNavigationComponent implements DoCheck, OnChanges {
  @Input() estilos: string = '';
  @Input() current: number = 0;
  @Input() length: number = 0;
  @Input() tiempo: number = 4000;
  @Output() changeIndex = new EventEmitter<number>();
  intervalRef?: number;
  timeOutRef?: any;
  indicators: Indicator[] = [];

  private MAX_VISIBLE_INDICATORS = 2;
  private min = 0;
  private max = this.MAX_VISIBLE_INDICATORS;

  ngOnInit() {
    this.initTimeout();
  }

  initInterval() {
    // @ts-ignore
    this.intervalRef = setInterval(() => {
      if (this.current < this.length - 1) {
        this.change(++this.current);
      } else {
        this.change(0);
      }
    }, this.tiempo);
  }

  initTimeout() {
    clearTimeout(this.timeOutRef);
    clearInterval(this.intervalRef);
    this.timeOutRef = setTimeout(() => {
      this.initInterval();
    }, 6000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['current']) this.initTimeout();
    if (changes['length']) {
      for (let i = 0; i < this.length; i++) {
        this.indicators.push(new Indicator(i));
      }
    }
  }

  ngDoCheck(): void {
    if (this.current < this.min) {
      this.min = this.current;
      this.max = this.min + this.MAX_VISIBLE_INDICATORS;
      if (this.max > this.length) {
        this.max = this.length;
      }
    }
    if (this.current > this.max) {
      this.max = this.current;
      this.min = this.max - this.MAX_VISIBLE_INDICATORS;
      if (this.min < 0) {
        this.min = 0;
      }
    }
  }

  getIndicatorClass(ref: number): string {
    if (ref === this.current) {
      return 'active';
    }
    if (ref >= this.min && ref <= this.max) {
      return 'std';
    }
    if (ref === this.min - 1 || ref === this.max + 1) {
      return 'small';
    }
    if (ref === this.min - 2 || ref === this.max + 2) {
      return 'micro';
    }
    return 'hidden';
  }

  change(index: number) {
    this.current = index;
    this.changeIndex.emit(this.current);
  }

  clickChange(index: number) {
    this.change(index);
    this.initTimeout();
  }
}
