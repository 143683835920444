<main>
    <article>
        <em [style]="'background-color:' + bgColor">{{clase.title | toacronym | uppercase}}</em>
        <section>
            <span>{{clase.title}}</span>
            <div>
                <span>{{startingTime.format('HH:mm')}} - {{finishingTime.format('HH:mm')}}</span>
            </div>
        </section>
    </article>

    <article>
        <span>{{clase.alumnos.length}} PERSONAS</span>
        <app-icon src="checkcircle" stroke="black"/>
        <app-icon src="users" stroke="black" />

    </article>
</main>