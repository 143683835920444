<div class="page">
  <div id="title">
    <h1>Alta de Datos de Usuario</h1>
    <h4>
      Asegúrese que nunca ha sido usuario, pruebe primero a recordar contraseña El NIF/NIE es
      obligatorio, por lo que es caso de un menor, el tutor/a se registrará, si no lo ha hecho y una
      vez en el sistema, añadirá un “Nuevo miembro familiar”.
    </h4>
  </div>
  <app-register-form />
</div>
