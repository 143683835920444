import { Injectable } from '@angular/core';
import { read, readFile, utils, writeFileXLSX, writeXLSX } from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExportCsvService {
  generateXLSX(data: any[], fileName: string) {
    const rows = data.map(item => {
      let row: { [key: string]: any } = {};
      for (let key in item) {
        if (item.hasOwnProperty(key)) {
          row[key] = item[key];
        }
      }
      return row;
    });
    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, fileName);
    writeFileXLSX(workbook, fileName);
  }
}
