
  <app-page-with-breadcrumb>
    <h1 class="text-primary text-4xl font-aldo mb-4">Declaración de Accesibilidad</h1>
    <div>
      <p class="text-lg font-semibold my-2">Aviso legal, Contenido y Responsabilidad</p>
      Este dominio web, cualesquiera de los subdominios, sites o microsites dependientes del raíz murciadeportes.com (en adelante este sitio o esta web) y todos sus contenidos, incluyendo los textos, imágenes, sonido y cualquier otro material, son propiedad del Ayuntamiento de Murcia, y por tanto todos los derechos están reservados, salvo uso de terceros o sin derechos.
      Sólo está autorizado el uso personal no comercial. No está permitida la modificación de la dirección web ni de sus contenidos. La utilización indebida o no autorizada de la información contenida en todo el dominio web, así como los perjuicios que se puedan ocasionar en los derechos de la propiedad intelectual del Organismo dará lugar a las acciones que legalmente correspondan y, en su caso, a las responsabilidades que de dicho ejercicio se deriven.
      No se garantiza que la información facilitada a través de esta web, sus subdominios o microsites, reproduzca exactamente un texto adoptado oficialmente. Sólo los textos publicados en los respectivos boletines oficiales tienen carácter de autenticidad. Las disposiciones legislativas y demás documentos e informaciones contenidos en este sitio tienen únicamente carácter informativo. El acceso mediante buscadores puede mostrar información no actualizada. Rogamos que el acceso siempre sea mediante murciadeportes.com o uno de sus subdominios y microsites.
      El Ayuntamiento de Murcia no es responsable de la información que se pueda obtener a través de los enlaces a sistemas externos que no sean dependientes del propio Ayuntamiento de Murcia.
      Tanto el acceso a la web principal, sus subdominios y/o microsites dependientes, como el uso que pueda hacerse de la información contenida en la misma son de la exclusiva responsabilidad de quien lo realiza.
      El Ayuntamiento de Murcia no se hace responsable de ninguna consecuencia, daño o perjuicio que pudieran derivarse de dicho acceso o uso de información, con excepción de todas aquellas actuaciones que resulten de la aplicación de las disposiciones legales a las que deba someterse en el estricto ejercicio de sus competencias.
      El abono de actividades a través de la pasarela de pago tienen como titular al Excmo. Ayuntamiento de Murcia - Servicio de Deportes (P3003000A), Avda. del Rocío 7, 30007 - Murcia - España T: 968 35 86 00. Dicho abono no permite cancelación/devolución del mismo a través de esta vía. La política de devoluciones de precios públicos se encuentra en la correspondiente ordenanza.
    </div>
    <hr class="h-0.5 bg-primary">
    <div>
      <p class="text-lg font-semibold my-2">Accesibilidad</p>
      Este sitio y sus dependencias(del dominio principal) tienen la intención de ser accesibles para todos. Estamos interesados en facilitar su operatividad y en resolver cualquier dificultad que pueda hallar para acceder a su contenido. Si Ud. encuentra alguna dificultad para acceder a cualquier aspecto de su contenido, por favor colabore comunicándolo mediante correo electrónico enviándo sus sugerencias a aytomu@ayto-murcia.es.
      Esta página no está diseñada para ser compatible con ningún navegador concreto. Procuramos aplicar los estandares en apoyo a un modelo de WWW abierto, para ello diseñamos cada página teniendo en cuenta los estándares definidos por el W3C y por tanto cualquier navegador web que se ajuste a los estándares debe mostrarla de manera correcta.
      En concreto, esta página web está diseñada usando XHTML1.0 Strict válido y su presentación visual está basada en hojas de estilo CSS2 válidas.
      Separamos el contenido de la presentación, para ofrecer nuestros mensajes en un formato apropiado para la diversidad de dispositivos con que los ciudadanos acceden a la web. Todas las páginas se han etiquetado para transmitir adecuadamente su estructura. De esta forma, la navegación sin estilos, en lectores de pantalla, en navegadores de sólo texto o dispositivos móviles, resulta satisfactoria.
      Asimismo intentamos ser rigurosos con las pautas de accesibilidad propuestas por el W3C.
      Valid XHTML 1.0 Strict ¡CSS Válido! Icono de conformidad con el Nivel Triple-Ade las Directrices de Accesibilidad para el Contenido Web 1.0
      En cualquier caso y si aún así experimenta dificultad para la correcta visualización del contenido (pej. si usa un dispositivo móvil, un lector de pantalla, si las combinaciones de colores y tamaños dificultan su lectura, etc.) hemos diseñado algunas herramientas que podrán serle de ayuda:
      B/N: Versión accesible Blanco sobre Negro, para un mejor contraste entre texto y fondo.
      N/B: Versión accesible Negro sobre Blanco, para un mejor contraste entre texto y fondo.
      Texto Grande: Con independencia del modo de visualización en que se encuentre (normal, b/n o n/b) use este modo para aumentar el tamaño de las fuentes de letra y facilitar así la lectura de los textos.
      No CSS: Si la estructura y/o aspecto de la web dificulta su navegación por ella, considere usar este modo para deshabilitar los estilos gráficos. Conseguirá así visualizar la página de manera sencilla y estructurada. Este modo también es recomendable si navega con un dispositivo móvil cuya pantalla sea de reducidas dimensiones.
      En cada página, a través del menú lateral derecho, puede encontrar el enlace destinado a activar cada uno de los modos arriba mencionados.
      Asímismo puede usar los siguientes accesos directos de teclado para activarlos:
  
      1: Versión accesible Blanco sobre Negro. Pulse 1 de nuevo para volver a versión normal.
      2: Versión accesible Negro sobre Blanco. Pulse 2 de nuevo para volver a versión normal.
      3: Texto a tamaño mayor. Pulse 3 de nuevo para volver a texto a tamaño normal.
      4: Desactiva los estilos gráficos. Pulse 4 de nuevo para volver a versión normal.
      Las combinaciones de teclas a pulsar conjuntamente a las arriba citadas, varían según el navegador a usar (pej. Alt+TECLA en Mozilla Firefox).
      Consulte la documentación de su software.
  
      Un esfuerzo más del Servicio de Deportes en cuestión de Accesibilidad y elimnación de barreras ha sido la adaptación de parte de la información a la Lengua de Signos Española (LSE). El símbolo que identifica la parte adaptada es conocido por casi la totalidad de la Comunidad Sorda.
      Logo Lengua de Signos Española
  
      Su experiencia nos interesa. Atenderemos sus críticas, sugerencias y comentarios acerca de los inconvenientes / dificultades que haya detectado. Comuníquese con nosotros.
    </div>
    <hr class="h-0.5 bg-primary">
    <div>
      <p class="text-lg font-semibold my-2">Otras teclas de acceso directo</p>
      Para facilitar la navegación por esta web, además de las teclas de acceso directo antes mencionadas, para activar los modos de accesibilidad disponibles, existen otros accesos directos que permitirá acceder a las diferentes secciones de la web:
  
      0: Regresa a la página de inicio.
      m: Mapa Web.
      c: Contacto de correo electrónico del Ayuntamiento de Murcia.
      i: Página de información sobre esta web, el presente documento.
  
      Ayuda sobre herramientas adicionales
      En ocasiones se ponen a disposición de los usuarios diversos archivos y formularios en formato PDF.
  
  
      Es necesario disponer de un programa capaz de visualizarlos. Si usted no dispone de ninguno en su sistema siga las siguientes instrucciones:
      1. Descargue Adobe Reader de manera gratuita de la página web de Adobe.
      2. Una vez descargado proceda a la instalación del programa siguiendo las sencillas indicaciones que se le irán mostrando.
      3. Concluida la instalación, cualquier archivo con extensión .pdf podrá ser visualizado.
  
      Aviso: Para la correcta visualización de los documentos PDF que podrá encontrar en esta web, le aconsejamos disponer de una versión del lector Adobe Acrobat Reader igual o superior a la 6. Asimismo para completar los datos de los documentos PDF de la sección Impresos y Formularios le recomendamos descargar los archivos PDF a su PC en lugar de introducir los datos desde el navegador.
    </div>
    <hr class="h-0.5 bg-primary">
    <div>
      <p class="text-lg font-semibold my-2">Política de protección de datos</p>
  
      Responsable del Tratamiento de Datos personales	Ayuntamiento de Murcia / Glorieta de España, 1 ? Murcia
      Teléfono: 968 35 86 00
      Delegado de Protección de Datos personales	dpd@ayto-murcia.es
      Finalidad Principal	Gestión de servicios públicos. Los datos facilitados se conservarán mientras no se solicite su supresión por el interesado o hasta el plazo que indique la Ley.
      Legitimación para el empleo de datos personales	Consentimiento del interesado/a
      Consentimiento	Al prestar el consentimiento se considera informado/a de que sus datos personales aportados no serán objeto de cesión o comunicación salvo por obligación legal.
      Destinatarios de los datos personales	Las unidades y organismos implicados en el desempeño de la gestión o tramitación
      Derechos de los interesados/as	Derecho de acceso, rectificación y supresión, limitación del tratamiento o portabilidad de los datos.
      Más Info de Protección de Datos	Consultas en la web del Ayuntamiento de Murcia www.murcia.es en el portal de Transparencia y en el portal de la AEPD www.aepd.es
  
      Responsabilidad como usuario
      Al acceder como usuario/a, se te considera responsable de la veracidad, exactitud, vigencia y autenticidad de los datos que facilites por cualquier vía, incluida esta web y sus microsites, al SERVICIO MUNICIPAL DE DEPORTES del Ayuntamiento de Murcia
    </div>
  </app-page-with-breadcrumb>










