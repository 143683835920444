import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatetimePickerComponent } from './datetime-picker.component';
import { FormsModule } from '@angular/forms';
import { ImageRendererModule } from 'src/app/components/globals/image-renderer/image-renderer.module';

@NgModule({
  declarations: [DatetimePickerComponent],
  imports: [CommonModule, FormsModule, ImageRendererModule],
  exports: [DatetimePickerComponent],
})
export class DatetimePickerModule {}
