import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Ruta, RutasResponse } from '../../../interfaces/RutasResponse';
import { RutasService } from '../../service/rutas.service';
import { environment } from '../../../../environments/environment';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { Title } from '@angular/platform-browser';
import { getTitle } from 'src/app/utils/breadcrumbs';

@Component({
  selector: 'app-ruta',
  templateUrl: './ruta.component.html',
  styleUrls: ['./ruta.component.css'],
})
export class RutaComponent implements OnInit {
  ruta?: Ruta;
  loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private rutaService: RutasService,
    private breadCrumbService: BreadcrumbService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      const httpp = new HttpParams().set('filter[id]', params['id']);
      this.loading = true;
      this.rutaService.filtradoRutas(httpp, 1).subscribe(
        (data: RutasResponse) => {
          this.ruta = data.data[0];
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    });
  }

  getStyle(texto: string): string {
    return texto.replace('<a', '<a class="underline text-primary"');
  }

  protected readonly environment = environment;
}
