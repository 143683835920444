import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  userRegistered = null;
  constructor(private http: HttpClient) {}

  registerUserStepOne(body: any) {
    return this.http.post(
      environment.apiBackGetUrl + '/api/v1/deporwin/user/registerStepOne',
      body,
      {
        withCredentials: true,
      }
    );
  }

  registerUserStepTwo(body: any) {
    return this.http
      .post(environment.apiBackGetUrl + '/api/v1/deporwin/user/registerStepTwo', body, {
        withCredentials: true,
      })
      .pipe(
        tap((res: any) => {
          if (res?.userRegistered) this.userRegistered = { ...body, ...res };
        })
      );
  }

  getTokenAutenticacion() {
    return this.http.post(
      environment.apiBackGetUrl + '/api/v1/deporwin/user/tokenAutenticacion',
      this.userRegistered,
      {
        withCredentials: true,
      }
    );
  }

  registerPasswordForUser(body: any) {
    return this.http.put(environment.apiBackGetUrl + '/api/v1/deporwin/user/password', body, {
      withCredentials: true,
    });
  }
}
