import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { MapComponent as NgxMapboxGlMapComponent } from 'ngx-mapbox-gl';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
})
export class MapComponent implements OnChanges, AfterViewInit {
  @ViewChild(NgxMapboxGlMapComponent) mapComponent?: NgxMapboxGlMapComponent;

  @Input() data: any[] = [];
  @Input() zoom: number = 14;
  @Input() center: mapboxgl.LngLatLike = { lng: -1.13004, lat: 37.98704 };
  @Input() section: string = '';

  protected readonly environment: any = environment;

  ngOnChanges(changes: SimpleChanges) {
    if ('data' in changes) {
      this.adjustMapView();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.adjustMapView();
    }, 100);
  }
  adjustMapView() {
    if (this.data.length == 0) return;
    const bounds = new mapboxgl.LngLatBounds();
    this.data.forEach(data => {
      if (!data.geolocation.latitud || !data.geolocation.longitud) return;
      bounds.extend(new mapboxgl.LngLat(data.geolocation.longitud, data.geolocation.latitud));
    });

    this.mapComponent?.mapInstance.fitBounds(bounds, {
      padding: 50,
    });
  }
}
