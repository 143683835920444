import { Component, Input, OnInit } from '@angular/core';
import {
  Instalacion,
  InstalacionesResponse,
  InstalacionesResponseLinks,
} from '../../../interfaces/InstalacionesResponse';
import { InstalacionService } from '../../services/instalacion.service';

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.css'],
})
export class ListadoComponent implements OnInit {
  @Input() instalaciones: Instalacion[] = [];

  paginacion?: InstalacionesResponseLinks;
  open: boolean = true;
  limit: number = 18;
  vista: string = 'grid';

  constructor(private instalacionService: InstalacionService) {}

  ngOnInit() {
    // this.instalacionService.getInstalaciones(this.limit)
    //     .subscribe((data:InstalacionesResponse)=>{
    //         this.instalaciones = data.data
    //         this.paginacion = data.links
    //     })
  }

  nextInstalaciones() {
    this.instalacionService
      .getInstalaciones(this.limit, this.paginacion?.next?.href)
      .subscribe((data: InstalacionesResponse) => {
        this.instalaciones = data.data;
        this.paginacion = data.links;
      });
    document.getElementById('listado')?.scrollIntoView({ behavior: 'smooth' });
  }
  prevInstalaciones() {
    this.instalacionService
      .getInstalaciones(this.limit, this.paginacion?.prev?.href)
      .subscribe((data: InstalacionesResponse) => {
        this.instalaciones = data.data;
        this.paginacion = data.links;
      });
    document.getElementById('listado')?.scrollIntoView({ behavior: 'smooth' });
  }

  navigate(url: string) {
    window.open(url, '_blank');
  }
}
