<ng-container *ngIf="true; else notMonitor" >
    <section [ngClass]="switched ? 'switched' : ''">
        <article>
            <app-perfil />
        </article>
        <article>
            <app-user-profile />
        </article>
    </section>
</ng-container>
<ng-template #notMonitor>
    <app-user-profile />
</ng-template>