import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioInputComponent),
      multi: true,
    },
  ],
})
export class RadioInputComponent implements ControlValueAccessor, OnChanges {
  @Input() options: { label: string; value: any }[] = [];
  @Input() label: string | null = null;
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() errores: any = null;
  @Input() classList: string | null = null;
  @Input() optionClassList: string | null = null;
  value: any;
  disabled: boolean = false;

  onChange: any = (value: any) => {};
  onTouch: any = () => {};

  ngOnChanges(changes: SimpleChanges) {
    if (this.value)
      this.options.some(option => {
        return this.compareFn(this.value, option.value);
      })
        ? this.onChange(this.value)
        : this.onChange(null);
  }

  writeValue(value: any): void {
    this.value = value;
    this.onChange(value);
  }

  onSeletChange(value: any) {
    this.value = value;
    this.onChange(this.value);
  }

  compareFn(obj1: any, obj2: any) {
    if (obj1 && obj2) {
      if (typeof obj1 === 'object' && typeof obj2 === 'object') {
        return obj1?.id == obj2?.id;
      } else {
        return obj1 === obj2;
      }
    }
    return obj1 === obj2;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
