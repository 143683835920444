import { Component, Input } from '@angular/core';
import { ListListerColumn, ListListerColumnType } from '../list-lister/list-lister.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-card, [appListCard]',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.css'],
})
export class ListCardComponent {
  @Input() cardColumns!: ListListerColumn[];
  @Input() listColumns!: ListListerColumn[];
  @Input() data!: any;
  @Input() title!: string;
  @Input() isCardView: boolean = true;
  @Input() routeAccessor!: string;

  ListListerColumnType = ListListerColumnType;

  constructor(private router: Router) {}

  onCardClick(id: any) {
    this.router.navigate([this.data.type, id]);
  }
}
