import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '../../../../services/modal/modal.service';
import { tap } from 'rxjs';
import { PerfilModalComponent, User } from '../perfil-modal/perfil-modal.component';
import { calculateAge } from 'src/app/utils/time';

interface ModalResponse {
  observacionChange?: string;
}

@Component({
  selector: 'app-users-monitor-line',
  templateUrl: './users-monitor-line.component.html',
  styleUrls: ['./users-monitor-line.component.css'],
})
export class UsersMonitorLineComponent {
  @Input() usuario: User;
  @Output() observacionChange: EventEmitter<string | null> = new EventEmitter<string | null>();

  constructor(private modalService: ModalService) {}

  ageCalculate(birthDay: string): number {
    return calculateAge(new Date(birthDay));
  }

  goToUserInfoDetails(user: any) {
    this.modalService
      .showComponent(PerfilModalComponent, { user }, ['observacionChange'])
      .pipe(
        tap((res: ModalResponse) => {
          if (res?.observacionChange) {
            user.observaciones = res.observacionChange;
          }
          this.modalService.removeComponent();
        })
      )
      .subscribe();
  }
  getFullName(user: User): string {
    return (user?.Nombre ?? '') + ' ' + (user?.Apellido1 ?? '') + ' ' + (user?.Apellido2 ?? '');
  }
}
