<section>
    <header>
        <img src="https://picsum.photos/200" alt="Profile pic">
        <div>
            <app-icon
                stroke="white"
                src="camera"
                classList="camera"
            />
        </div>
    </header>

    <form [formGroup]="monitorFormGroup">
        <input type="text" name="name" placeholder="Nombre y apellidos">
        <input type="text" name="address" placeholder="Dirección">
        <input type="text" name="name" placeholder="000 000 000">
        <input type="text" name="name" placeholder="Correo electrónico">
        <input type="text" name="name" placeholder="Tu frase para tus clientes">
    </form>

    <footer>
        <app-button
            classList="button"
            label="Cancelar"
            (onClick)="onClose()"
        />
        <app-button
            classList="button accept"
            label="Aceptar"
            (onClick)="onClose()"
        />
    </footer>
</section>
<main (click)="onClose()">
</main>
