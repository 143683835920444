import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  private cache = new Map();
  private cacheFilteredActivities = new Map();

  constructor(private http: HttpClient) {}
  datosActividad: any[] = [];

  getHorarios(IdServicio: number) {
    return this.http.get(
      environment.apiBackGetUrl + `/api/v1/deporwin/servicios/${IdServicio}/horarios`
    );
  }

  getAforo(IdServicio: number) {
    return this.http.get(
      environment.apiBackGetUrl +
        `/api/v1/deporwin/servicios/${IdServicio}/componentescontratacion?IncluirDisponibilidad=true`
    );
  }
  getServicios(IdFamilia: number) {
    return this.http.get(
      environment.apiBackGetUrl + `/api/v1/deporwin/servicios?IdFamilia=${IdFamilia}`
    );
  }
  getActivities(IdFamilia: number) {
    const cacheKey = `activities_${IdFamilia}`;

    if (this.cache.has(cacheKey)) {
      return of(this.cache.get(cacheKey));
    } else {
      const url = `${environment.apiBackGetUrl}/api/v1/deporwin/servicios/subfamilias?IdFamilia=${IdFamilia}`;

      return this.http.get(url).pipe(tap(data => this.cache.set(cacheKey, data)));
    }
  }
  getActividadesFiltradas(IdSubFamilia: number) {
    const cacheKey = `actividadesFiltradas_${IdSubFamilia}`;

    if (this.cacheFilteredActivities.has(cacheKey)) {
      return of(this.cacheFilteredActivities.get(cacheKey));
    }

    return this.http
      .get(environment.apiBackGetUrl + `/api/v1/deporwin/servicios?IdSubFamilia=${IdSubFamilia}`)
      .pipe(
        tap(response => {
          this.cacheFilteredActivities.set(cacheKey, response);
        })
      );
  }
}
