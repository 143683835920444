import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Ejercicio } from '../../models/ejercicios.model';
import { getIcon } from '../../utils/icons';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-ejercicio-details',
  templateUrl: './ejercicio-details.component.html',
  styleUrls: ['./ejercicio-details.component.css'],
})
export class EjercicioDetailsComponent implements OnInit {
  @Input() ejercicios: any[];
  @Input() index: number = 0;

  @Output() cerrar: EventEmitter<boolean> = new EventEmitter();

  actualEjercicio: Ejercicio;
  actualIndex: number = 0;

  ngOnInit() {
    if (this.ejercicios.length > 0) {
      this.actualEjercicio = this.ejercicios[this.index];
      this.actualIndex = this.index;
    }
  }

  get isDisabledClickPrev(): boolean {
    return this.actualIndex === 0;
  }

  get isDisabledClickNext(): boolean {
    return this.ejercicios.length && this.actualIndex === this.ejercicios.length - 1;
  }

  onPrevClick() {
    this.actualIndex = this.actualIndex - 1;
    this.actualEjercicio = this.ejercicios[this.actualIndex];
  }

  onNextClick() {
    this.actualIndex = this.actualIndex + 1;
    this.actualEjercicio = this.ejercicios[this.actualIndex];
  }

  get srcVideo() {
    if (this.actualEjercicio?.video) return environment?.imgUrl + this.actualEjercicio?.video;
    else return null;
  }

  get srcImg() {
    if (this.actualEjercicio?.img) return environment?.imgUrl + this.actualEjercicio?.img;
    else return null;
  }

  protected readonly getIcon = getIcon;
}
