import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Ejercicio } from '../models/ejercicios.model';
import { forkJoin, Observable, take } from 'rxjs';
import { EjerciciosService } from '../services/ejercicios/ejercicios.service';

@Injectable({
  providedIn: 'root',
})
export class EjercicioDataResolver implements Resolve<any> {
  ejercicios: Ejercicio[] = [];

  constructor(private ejercicioService: EjerciciosService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin({
      ejercicios: this.getEjercicios(),
    });
  }

  private getEjercicios(): Observable<Ejercicio[]> {
    return this.ejercicioService.getAllEjercicios().pipe(take(1));
  }
}
