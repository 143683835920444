<main>
    <section>
        <p>TU PERFIL</p>
        <div>
            <img src="https://picsum.photos/200" alt="Profile pic">
        </div>
        <div>
            <p>{{ user.name }}</p>
            <p>{{ user.number }}</p>
            <p>{{ user.email }}</p>
            <p>{{user.quote}}</p>
        </div>
    </section>
    
    <app-button (click)="editProfile()" label="Editar perfil" classList="edit-profile-button" />
</main>
