<section>
    <div *ngFor="let item of singleViewData">
        <aside ngClass="data-icon">
            <app-icon [src]="item.icon" [fill]="item.fill" [stroke]="item.stroke"></app-icon>
        </aside>
        <footer>
            <h3>{{item.label}}</h3>
            <ng-container [ngSwitch]="item.type">
                <ng-container *ngSwitchCase="singleViewDataTypes.Number">
                    <p>{{data[item.valueAccessor] | number}}</p>
                </ng-container>
                <ng-container *ngSwitchCase="singleViewDataTypes.File">

                </ng-container>
                <ng-container *ngSwitchCase="singleViewDataTypes.Date">
                    <p>{{data[item.valueAccessor] | date:'dd/MM/yyyy'}}</p>
                </ng-container>
                <ng-container *ngSwitchCase="singleViewDataTypes.Boolean">
                    <p>{{data[item.valueAccessor] ? 'Sí' : 'No'}}</p>
                </ng-container>
                <ng-container *ngSwitchCase="singleViewDataTypes.Array">
                    <ng-container *ngIf="item.valueArrayAccessor; else noValueArray">
                        <em ngClass="custom-scrollbar">
                            <ng-container *ngFor="let value of data[item.valueAccessor]">
                                    <span (click)="onArrayElementClick(item.isClickable || '', value.id)">{{value[item.valueArrayAccessor]}}</span>
                            </ng-container>
                        </em>
                    </ng-container>
                    <ng-template #noValueArray>
                        <p>{{data[item.valueAccessor].join(', ')}}</p>
                    </ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="singleViewDataTypes.CombineString">
                    <p>{{combineString(item.combineString || '', data)}}</p>
                </ng-container>
                <ng-container *ngSwitchCase="singleViewDataTypes.innerHTML">
                    <p [innerHTML]="data[item.valueAccessor]"></p>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <p>{{data[item.valueAccessor]}}</p>
                </ng-container>
            </ng-container>
        </footer>
    </div>
</section>
