<div class="busqueda-ejercicio-container">
  <div class="input-busqueda">
    <span
      class="h-8 relative whitespace-nowrap bg-white px-3 rounded-full hidden md:block overflow-hidden"
    >
      <img
        src="/assets/icons/lupa.svg"
        alt="Busqueda"
        class="inline-block h-8 bg-white cursor-pointer"
        id="button-search-bar"
      />
      <input
        type="text"
        spellcheck="false"
        name="search"
        id="search-md"
        placeholder="Búsqueda"
        autocomplete="off"
        [(ngModel)]="search"
        (keyup)="filtrar()"
        class="h-8 p-2 w-full rounded-2xl bg-white inline-block focus:outline-none focus:border-0 active:outline-none active:border-0 focus:placeholder-transparent"
      />
    </span>
  </div>
  <div *ngIf="!grupoSeleccionado" class="group-table">
    <ng-container *ngFor="let grupo of Object.values(ejerciciosAgrupados); let i = index">
      <div class="elemento elemento-grupo" (click)="selectGrupoMuscular(grupo?.groupName)">
        <div class="imagen">
          <app-icon [src]="getIcon(grupo?.groupName?.toLowerCase())" fill="var(--grey-medium)" />
        </div>
        <p>{{ grupo?.groupName | titlecase }}</p>
      </div>
    </ng-container>
  </div>
  <div *ngIf="grupoSeleccionado" class="ejercicios-list">
    <div class="elemento elemento-grupo elemento-selected" (click)="deselectGrupo()">
      <div class="imagen">
        <app-icon
          [src]="getIcon(grupoSeleccionado?.groupName?.toLowerCase())"
          fill="var(--wine-red)"
        />
      </div>
      <p>{{ grupoSeleccionado?.groupName | titlecase }}</p>
    </div>
    <div
      cdkDropList
      id="exercises"
      #exercises="cdkDropList"
      [cdkDropListConnectedTo]="['exerciseTable']"
      [cdkDropListData]="ejerciciosFiltrados"
      cdkDropListSortingDisabled
    >
      <ng-container *ngFor="let ejercicio of grupoSeleccionado?.ejercicios; let i = index">
        <div
          class="elemento-ejercicio"
          cdkDrag
          (cdkDragStarted)="dragStarted(ejercicio, i)"
          (cdkDragEnded)="dragEnd()"
          [cdkDragData]="ejercicio"
        >
          <ng-container *ngIf="draggIndex !== i">
            <div class="imagen">
              <app-icon [src]="getIcon(ejercicio?.icon)" fill="var(--grey-medium)" />
            </div>
            <p>{{ ejercicio?.title | titlecase }}</p>
          </ng-container>

          <app-linea-ejercicio
            *cdkDragPreview
            [isPreview]="true"
            [lineaEjercicioFormGroup]="getLineaEjercicioFormGroup(ejercicio)"
          />
          <template *ngIf="draggIndex !== i">
            <app-linea-ejercicio
              *cdkDragPlaceholder
              [isPreview]="true"
              [lineaEjercicioFormGroup]="getLineaEjercicioFormGroup(ejercicio)"
            />
          </template>
        </div>
      </ng-container>
    </div>
  </div>
</div>
