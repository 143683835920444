import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Ejercicio, LineaEjercicio, UsuarioRutina } from '../../models/ejercicios.model';
import { UserService } from '../../../services/user.service';
import { RutinaApi } from '../tablas/tablas.converter.service';
import { tiempoRepet } from '../../components/linea-ejercicio/linea-ejercicio.component';
import {
  calculateAge,
  convertStringMinSecToTimestamp,
  convertTimestampToStringMinSec,
} from '../../../utils/time';

export const validatorTimeField = [Validators.required, Validators.pattern('^\\d{2}:[0-5]\\d$')];

@Injectable({
  providedIn: 'root',
})
export class TablaFormConverterService {
  constructor(private fb: UntypedFormBuilder, private userService: UserService) {}

  // @ts-ignore
  toRutina(rutinaForm: UntypedFormGroup): RutinaApi {
    const rutinaData = rutinaForm.getRawValue();
    try {
      return {
        tabla: this.buildTabla(rutinaData?.tabla),
        usuarios: this.buildUsuarios(rutinaData?.usuarios),
        usuariosDelete: this.buildUsuarios(rutinaData?.usuariosDelete),
      } as RutinaApi;
    } catch (e) {
      console.error('Error' + e);
    }
  }

  buildTabla(tabla: any): {
    id: string;
    title: string;
    observaciones: string;
    plantilla: boolean;
    idMonitor: string;
    lineasEntrada: LineaEjercicio[];
    lineasDelete: LineaEjercicio[];
  } {
    return {
      id: tabla?.id,
      observaciones: tabla?.observaciones,
      title: tabla?.title,
      plantilla: !!tabla?.plantilla,
      idMonitor: tabla?.idMonitor,
      lineasEntrada: tabla?.lineasEjercicio ? this.buildLineaEntrada(tabla?.lineasEjercicio) : [],
      lineasDelete: tabla?.lineasDelete ? this.buildLineaEntrada(tabla?.lineasDelete) : [],
    };
  }

  buildLineaEntrada(lineasEjercicio: any[]): LineaEjercicio[] {
    return lineasEjercicio
      ? lineasEjercicio?.map(linea => {
          const time = linea?.time ? convertStringMinSecToTimestamp(linea?.time) : null;
          return {
            id: linea?.id,
            title: linea?.title,
            observaciones: linea?.observaciones,
            tiempo: time,
            series: linea?.series,
            repeticiones: linea?.repeat,
            peso: linea?.load,
            ejercicio: this.buildEjercicio(linea?.ejercicio),
          };
        })
      : [];
  }

  buildEjercicio(ejercicio: any): Ejercicio {
    return {
      id: ejercicio?.id,
      title: ejercicio?.title,
      descripcion: ejercicio?.descripcion,
      img: ejercicio?.img,
      video: ejercicio?.video,
      grupoMuscular: ejercicio?.grupoMuscular,
      icon: ejercicio?.icon,
    };
  }

  buildUsuarios(usuarios: any[]): UsuarioRutina[] {
    return usuarios
      ? usuarios?.map(usuario => {
          return {
            idRutina: usuario?.idRutina,
            idUsuario: usuario?.id,
            fechas: usuario?.fechas,
            observaciones: usuario?.observaciones,
          };
        })
      : [];
  }

  fromRutina(rutina: any): UntypedFormGroup {
    return this.fb.group({
      tabla: this.buildTablaFormGroup(rutina?.tabla),
      usuarios: this.buildUsuariosFormArray(
        rutina?.usuarios || [
          {
            idUsuario: 240369,
            observaciones: 'Te hace falta bastante este ejercicio',
            fechas: [new Date()],
          }, //TODO: Eliminar 240369 que es hardcode
        ]
      ),
      usuariosDelete: this.fb.array([]),
    });
  }

  buildUsuariosFormArray(usuarios: any[]): UntypedFormArray {
    return usuarios
      ? this.fb.array(usuarios.map((usuario: any) => this.buildUsuarioFormGroup(usuario)))
      : this.fb.array([]);
  }

  buildUsuarioFormGroup(usuario: any): UntypedFormGroup {
    const apellidos =
      (usuario?.Apellido1 ? usuario?.Apellido1 + ' ' : '') +
      (usuario?.Apellido2 ? usuario?.Apellido2 : '');
    const sexo = usuario.Sexo === 1 ? 'Hombre' : 'Mujer';
    const edad = usuario?.FechaNacimiento ? calculateAge(new Date(usuario?.FechaNacimiento)) : null;
    return this.fb.group({
      id: [usuario?.Id || usuario?.id],
      idRutina: [usuario?.idRutina],
      nombre: [usuario?.Nombre || usuario?.nombre],
      apellidos: [apellidos || usuario?.apellidos],
      sexo: [(usuario?.Sexo ? sexo : null) || usuario?.sexo],
      edad: [(usuario?.FechaNacimiento ? edad : null) || usuario?.edad],
      imagen: [usuario?.Imagen || usuario?.imagen],
      observaciones: [usuario?.observaciones],
      fechas: [usuario?.fechas],
    });
  }

  buildTablaFormGroup(tabla: any): UntypedFormGroup {
    return this.fb.group({
      id: { value: tabla?.id, disabled: true },
      title: [tabla?.title, [Validators.required]],
      observaciones: [tabla?.observaciones, [Validators.required, Validators.maxLength(200)]],
      plantilla: [!!tabla?.plantilla],
      idMonitor: [tabla?.idMonitor || this.userService?.getUserId() || 61], //TODO: Eliminar 61 que es hardcode
      lineasEjercicio: this.buildLineaEjerciciosFormArray(tabla?.lineasEntrada),
      lineasDelete: this.fb.array([]),
    });
  }

  buildLineaEjerciciosFormArray(lineaEntrada: any[]): UntypedFormArray {
    return lineaEntrada
      ? this.fb.array(lineaEntrada.map((linea: any) => this.buildLineaEjercicioFormGroup(linea)))
      : this.fb.array([]);
  }

  buildLineaEjercicioFormGroup(lineaEjercicio: LineaEjercicio): UntypedFormGroup {
    const time = convertTimestampToStringMinSec(lineaEjercicio?.tiempo);
    return this.fb.group({
      id: [lineaEjercicio?.id],
      title: [lineaEjercicio?.title],
      ejercicio: this.buildEjercicioFormGroup(lineaEjercicio?.ejercicio),
      repeat: {
        value: lineaEjercicio?.repeticiones,
        disabled: !lineaEjercicio?.ejercicio?.admiteRepeticiones,
      },
      series: [
        {
          value: lineaEjercicio?.series,
          disabled: !lineaEjercicio?.ejercicio?.admiteSeries,
        },
        lineaEjercicio?.ejercicio?.admiteSeries ? [Validators.required] : null,
      ],
      time: {
        value: lineaEjercicio?.tiempo ? time : null,
        disabled: !lineaEjercicio?.ejercicio?.admiteTiempo,
      },
      load: [
        {
          value: lineaEjercicio?.peso || 0,
          disabled: !lineaEjercicio?.ejercicio?.admiteCarga,
        },
        lineaEjercicio?.ejercicio?.admiteCarga ? [Validators.required] : null,
      ],
      tipoRepeticion: {
        value: lineaEjercicio?.ejercicio?.admiteRepeticiones
          ? tiempoRepet[1]?.value
          : lineaEjercicio?.ejercicio?.admiteTiempo
          ? tiempoRepet[0]?.value
          : null,
        disabled: !(
          lineaEjercicio?.ejercicio?.admiteTiempo || lineaEjercicio?.ejercicio?.admiteRepeticiones
        ),
      },
      observaciones: [lineaEjercicio?.observaciones],
    }) as UntypedFormGroup;
  }

  buildEjercicioFormGroup(ejercicio: Ejercicio): UntypedFormGroup {
    return this.fb.group({
      id: { value: ejercicio?.id, disabled: true },
      title: { value: ejercicio?.title, disabled: true },
      descripcion: { value: ejercicio?.descripcion, disabled: true },
      img: { value: ejercicio?.img, disabled: true },
      video: { value: ejercicio?.video, disabled: true },
      grupoMuscular: {
        value: ejercicio?.grupoMuscular[0] ? ejercicio?.grupoMuscular[0]?.name : null,
        disabled: true,
      },
      icon: { value: ejercicio?.icon, disabled: true },
      admiteCarga: { value: ejercicio?.admiteCarga, disabled: true },
      admiteTiempo: { value: ejercicio?.admiteTiempo, disabled: true },
      admiteSeries: { value: ejercicio?.admiteSeries, disabled: true },
      admiteRepeticiones: { value: ejercicio?.admiteRepeticiones, disabled: true },
    });
  }
}
