
<div class="background-image">
</div>
<main>
  <h1>Usuario</h1>
  <form [formGroup]="formularioLogin" (ngSubmit)="onSubmit()">
    <fieldset>
      <article>
        <label for="username" class="font-bold">USUARIO</label>
        <input id="username" name="username" type="text" required formControlName="username" />
      </article>
      <article>
        <label for="password" class="font-bold">CONTRASEÑA</label>
        <input id="password" name="password" type="password" required formControlName="password" />
      </article>
      <label
        *ngIf="
          (apiStatus.error && apiStatus?.error.status == 401) || apiStatus?.error.status == 400
        "
        class="text-primary text-lg"
        >Revise usuario o contraseña</label
      >
      <label *ngIf="apiStatus.error && apiStatus?.error.status == 0" class="text-primary text-lg">
        Error en la conexión, inténtelo más tarde
      </label>
    </fieldset>
    <app-button
      class="btn-block"
      label="Entrar"
      classList="btn btn-primary btn-block"
      type="submit"
      [loading]="apiStatus.loading"
      fillColors="white"
    />
  </form>
  <aside><i></i>Otras opciones<i></i></aside>
  <footer>
    <app-button
      label="Olvidaste tu contraseña?"
      classList="btn btn-secondary"
      type="button"
      routerLink="/remember-password"
      fillColors="white"
    />
    <app-button
      label="Registrarse"
      classList="btn btn-secondary"
      type="button"
      routerLink="/register"
      fillColors="white"
    />
  </footer>
</main>
