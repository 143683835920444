import { Component, Input } from '@angular/core';
import { Convocatoria } from '../../../services/deporte-escolar.service';
import { environment } from 'src/environments/environment';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-convocatoria',
  templateUrl: './convocatoria.component.html',
  styleUrls: ['./convocatoria.component.css'],
})
export class ConvocatoriaComponent {
  @Input() convocatoria: Convocatoria | null = null;
  fromDate = dayjs(this.convocatoria?.desde);
  toDate = dayjs(this.convocatoria?.hasta);
  show = true;
  currentDate = dayjs();
  baseFilesUrl = environment.apiBackGetUrl;

  ngOnInit() {
    if (this.fromDate.isAfter(this.currentDate) || this.toDate.isBefore(this.currentDate))
      this.show = false;
  }
}
