import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { TablaFormService } from '../../services/tabla-form/tabla-form.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { PlanningCalendarModalComponent } from '../planning-calendar-modal/planning-calendar-modal.component';
import { tap } from 'rxjs';

@Component({
  selector: 'app-tabla-usuarios-form-group',
  templateUrl: './tabla-usuarios-form-group.component.html',
  styleUrls: ['./tabla-usuarios-form-group.component.css'],
})
export class TablaUsuariosFormGroupComponent {
  @Input() usuariosFormGroup: UntypedFormArray;

  constructor(private tablaFormService: TablaFormService, private modalService: ModalService) {}

  usuarioFormGroup(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  get nombreTabla(): string {
    const nombre = this.rutinaFormGroup.getRawValue()?.tabla?.title;
    return nombre ? nombre : '';
  }

  get rutinaFormGroup(): UntypedFormGroup {
    return this.tablaFormService.rutinaForm as UntypedFormGroup;
  }

  openCalendarModal(userId: number) {
    this.modalService
      .showComponent(
        PlanningCalendarModalComponent,
        {
          userId: userId,
        },
        ['cerrar']
      )
      .pipe(
        tap((output: any) => {
          if (output.cerrar) {
            this.modalService.removeComponent();
          }
        })
      )
      .subscribe();
  }

  handleOpenCalendar(userId: number) {
    this.openCalendarModal(userId);
  }
}
