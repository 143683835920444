import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {
  SingleViewData,
  SingleViewDataTypes,
} from 'src/app/components/shared/list/icon-list/icon-list.component';
import { ListListerColumnType } from 'src/app/components/shared/list/list-lister/list-lister.component';
import { Deporte } from 'src/app/interfaces/DeportesResponse';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-deporte',
  templateUrl: './deporte.component.html',
  styleUrls: ['./deporte.component.css'],
})
export class DeporteComponent {
  deporte: Deporte | null = null;
  id: string = '';
  singleViewDataTypes = SingleViewDataTypes;

  deporteData: SingleViewData[] = [
    // Commented for quick fix build error
    // {
    //   label: 'Edad',
    //   valueAccessor: 'edad_minima',
    //   type: SingleViewDataTypes.CombineString,
    //   icon: 'login-person',
    //   fill: 'white',
    //   stroke: 'none',
    //   combineString: '#edad_minima# - #edad_maxima#',
    // },
    // {
    //   label: 'Donde se puede practicar',
    //   isClickable: 'instalaciones',
    //   valueAccessor: 'instalacion_practicar',
    //   accessorForCardView:
    //     {
    //       listColumns: [
    //         { name: 'Dirección', prop: 'direccion', type: ListListerColumnType.String },
    //       ],
    //       cardColumns: [
    //         { name: 'Dirección', prop: 'direccion', type: ListListerColumnType.String },
    //
    //       ],
    //     },
    //   type: SingleViewDataTypes.isCardView,
    //   icon: 'instalaciones',
    //   fill: 'white',
    //   stroke: 'none',
    // },
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private mainService: MainService,
    private breadCrumbService: BreadcrumbService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.mainService.getDeporte(this.id).subscribe(data => {
        this.deporte = data;
        this.titleService.setTitle(this.deporte.title);
        this.breadCrumbService.addCurrentBreadcrumb();
      });
    });
  }
}
