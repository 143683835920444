<app-progress-bar [step]="stepForm" [numberSteps]="4  " />
<form *ngIf="stepForm != 3" [formGroup]="formRegisterUser" (ngSubmit)="submit()">
  <div>
    <div class="form" id="stepOne" *ngIf="stepForm == 1">
      <div>
        <app-input
          label="Nombre *"
          formControlName="Nombre"
          [errores]="errores?.Nombre"
        />
      </div>
      <div>
        <app-input
          label="Apellido 1 *"
          formControlName="Apellido1"
          [errores]="errores?.Apellido1"
        />
      </div>
      <div>
        <app-input
          label="Apellido 2 *"
          formControlName="Apellido2"
          [errores]="errores?.Apellido2"
        />
      </div>
      <div>
        <span id="inputFechaNacimiento">
          <app-datetime-picker
            name="FechaNacimiento"
            formControlName="FechaNacimiento"
            title="Fecha de Nacimiento *"
            [error]="errores?.FechaNacimiento"
            [yearsRange]="{ start: dayjs().year() - 1920, end: 0 }"
            [type]="DatetimeType.date"
          />
        </span>
      </div>
      <div id="sexoInput">
        <app-radio-input
          label="Sexo *"
          formControlName="Sexo"
          name="Sexo"
          [options]="sexOptions"
          [errores]="errores?.Sexo"
        />
      </div>
      <div id="condicionesInput">
        <app-slide-checkbox
          label="He leído y acepto condiciones *"
          formControlName="AceptaCondiciones"
          [errores]="errores?.AceptaCondiciones"
        />
      </div>
      <div class="buttons">
        <app-button
          type="button"
          classList="btn btn-secondary"
          label="Cancelar"
          (click)="onCancel()"
        />
        <app-button
          type="button"
          classList="btn btn-primary"
          label="Siguiente"
          (click)="goDatosContacto()"
        />
      </div>
    </div>
    <div class="form" id="stepTwo" *ngIf="stepForm == 2">
      <app-input
        label="Documento identidad *"
        formControlName="DocumentoIdentidad"
        [errores]="errores?.DocumentoIdentidad"
      />
      <app-input
        label="Correo Electrónico *"
        formControlName="Email"
        [type]="TypeInput.email"
        [errores]="errores?.Email"
      />
      <app-input
        label="Telefono *"
        formControlName="Telefono"
        [errores]="errores?.Telefono"
      />
      <app-input
        label="Telefono móvil *"
        formControlName="TelefonoMovil"
        [errores]="errores?.TelefonoMovil"
      />
      <app-input
        label="Código Postal *"
        formControlName="CodigoPostal"
        [errores]="errores?.CodigoPostal"
      />
      <div class="buttons">
        <app-button
          classList="btn btn-secondary"
          label="Atrás"
          type="button"
          (click)="stepForm = stepForm - 1"
        />
        <app-button classList="btn btn-primary" label="Siguiente" type="submit" />
      </div>
    </div>
  </div>
</form>
<form *ngIf="stepForm == 3" [formGroup]="formPassword" (ngSubmit)="registerPassword()">
  <div>
    <div class="form" id="stepThree">
      <div>
        <app-input
          label="Contraseña *"
          formControlName="Contrasena"
          [errores]="errores?.Contrasena"
        />
      </div>
      <div>
        <app-input
          label="Repita Contraseña *"
          formControlName="ContrasenaRepetida"
          [errores]="errores?.ContrasenaRepetida"
        />
      </div>
      <div class="buttons">
        <app-button
          classList="btn btn-secondary"
          label="Atrás"
          type="button"
          (click)="stepForm = stepForm - 1"
        />
        <app-button classList="btn btn-primary" label="Validar" type="submit" />
      </div>
    </div>
  </div>
</form>
<form *ngIf="stepForm == 4" [formGroup]="formPIN" (ngSubmit)="onSubmitPIN()">
  <div>
    <div class="form" id="stepFour">
      <div>
        <app-input
          label="PIN *"
          formControlName="PIN"
          id="pinInput"
          [errores]="errores?.PIN"
          [isDisabled]="isPinDisabled"
        />
      </div>
      <div class="buttons">
        <app-button classList="btn btn-primary" label="Finalizar" type="submit" />
      </div>
    </div>
  </div>
</form>

