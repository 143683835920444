import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toacronym',
})
export class ToacronymPipe implements PipeTransform {
  transform(value: string): string {
    const valuesToArr = value.split(' ');
    return valuesToArr
      .slice(0, 2)
      .map(word => word[0])
      .join('');
  }
}
