import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { usuarios } from '../../mock/tabla.mock';
import { UsuarioRutina } from '../../models/ejercicios.model';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-agregar-usuarios',
  templateUrl: './agregar-usuarios.component.html',
  styleUrls: ['./agregar-usuarios.component.css'],
})
export class AgregarUsuariosComponent implements OnInit {
  usuariosAdded: UsuarioRutina[] = [];

  usuariosMonitor: UsuarioRutina[] = [];

  @Input() usuariosAgregados: any[] = [];

  @Output() onSave: EventEmitter<UsuarioRutina[] | null> = new EventEmitter<
    UsuarioRutina[] | null
  >();

  ngOnInit() {
    // TODO: Get Usuarios Relacionados con este monitor
    if (this.usuariosAgregados !== null) {
      this.usuariosMonitor = usuarios.filter(
        user => !this.usuariosAgregados.some(us => us?.id === user?.Id)
      );

      this.usuariosAdded = usuarios.filter(user =>
        this.usuariosAgregados.some(us => us?.id === user?.Id)
      );
    }
  }

  saveUsers() {
    this.onSave.emit(this.usuariosAdded);
  }

  cancel() {
    this.onSave.emit(null);
  }

  drop(event: CdkDragDrop<UsuarioRutina[]>) {
    if (event.previousContainer !== event.container) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.usuariosAdded = [...event.container.data];
      this.usuariosMonitor = [...event.previousContainer.data];
    }
  }

  addUser(usuario: UsuarioRutina) {
    this.usuariosAdded = [...this.usuariosAdded, usuario];
    this.usuariosMonitor = this.usuariosMonitor.filter(user => user?.Id !== usuario?.Id);
  }

  removeUser(usuario: UsuarioRutina) {
    this.usuariosAdded = this.usuariosAdded.filter(user => user?.Id !== usuario?.Id);
    this.usuariosMonitor = [...this.usuariosMonitor, usuario];
  }
}
