<footer class="bottom-0 w-full flex items-center bg-footer shadow-lg">
    <div class="w-10/12 md:w-8/12  py-7 m-auto flex flex-col justify-around space-y-3">
        <div class="w-full flex flex-col space-y-3 items-center md:flex-row md:justify-between m-auto">
          <app-image-renderer
            [classList]= "'flex justify-center md:justify-start'"
            [src] = "'/favicon.ico'"
            [altString] = "'Logo'"
            [imgClass]="'h-14'"
            [routeAddress]="'/'">
          </app-image-renderer>
          <!-- Div de Avisos Legales y demas links de interes -->
          <section class="md:w-1/2 w-full flex flex-row flex-wrap space-x-1 justify-center text-gray-500 text-xs text-center px-2">
            <div>
              <a routerLink="/accesibilidad">Declaración de Accesibilidad</a>
            </div>
              <span>|</span>
            <div>
              <a href="https://www.murcia.es/web/portal/accesibilidad/#seccion-ayuda" target="_blank">Ayuda para el ciudadano</a>
            </div>
              <span>|</span>
            <div>
              <a href="https://www.murcia.es/web/portal/politica-de-privacidad" target="_blank">Política de Privacidad</a>
            </div>
              <span>|</span>
            <div>
              <a href="https://www.murcia.es/web/portal/aviso-legal" target="_blank">Aviso Legal</a>
            </div>
              <span>|</span>
            <div>
              <a href="https://murcia.es/web/portal/aviso-legal" target="_blank">© Copyright Ayuntamiento de Murcia</a>
            </div>
            <a href="https://maps.app.goo.gl/Tfngpfxk2e6ERwpr8" target="_blank">Glorieta de España 1, 30004 - Murcia - España T: 968358600</a>
            <div>
              <a routerLink="/mapa-web">Mapa web</a>
            </div>
          </section>
          <div class="mx-3 flex flex-col md:flex-row-reverse md:items-end md:justify-end">
            <!-- Links de redes sociales -->
            <div class="flex flex-row space-x-3 justify-center items-center">
<!--                <a href="#">-->
<!--                  <svg class="group h-8 hover:scale-125 m-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <title>Email</title>-->
<!--                    <g id="style=linear">-->
<!--                      <g id="email">-->
<!--                        <path class="group-hover:stroke-primary" id="vector" d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#000000" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                        <path class="group-hover:stroke-primary" id="vector_2" d="M18.7698 7.7688L13.2228 12.0551C12.5025 12.6116 11.4973 12.6116 10.777 12.0551L5.22998 7.7688" stroke="#000000" stroke-width="1.5" stroke-linecap="round"/>-->
<!--                      </g>-->
<!--                    </g>-->
<!--                  </svg>-->
<!--                </a>-->
<!--                <a href="#">-->
<!--                  <svg class="group h-8 hover:scale-125 m-1" viewBox="0 -2 20 20" xmlns="http://www.w3.org/2000/svg">-->

<!--                    <title>Twitter</title>-->
<!--                    <desc>Created with Sketch.</desc>-->
<!--                    <defs>-->

<!--                    </defs>-->
<!--                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
<!--                      <g id="Dribbble-Light-Preview" transform="translate(-60.000000, -7521.000000)" fill="#000000" class="group-hover:fill-primary">-->
<!--                        <g id="icons" transform="translate(56.000000, 160.000000)">-->
<!--                          <path d="M10.29,7377 C17.837,7377 21.965,7370.84365 21.965,7365.50546 C21.965,7365.33021 21.965,7365.15595 21.953,7364.98267 C22.756,7364.41163 23.449,7363.70276 24,7362.8915 C23.252,7363.21837 22.457,7363.433 21.644,7363.52751 C22.5,7363.02244 23.141,7362.2289 23.448,7361.2926 C22.642,7361.76321 21.761,7362.095 20.842,7362.27321 C19.288,7360.64674 16.689,7360.56798 15.036,7362.09796 C13.971,7363.08447 13.518,7364.55538 13.849,7365.95835 C10.55,7365.79492 7.476,7364.261 5.392,7361.73762 C4.303,7363.58363 4.86,7365.94457 6.663,7367.12996 C6.01,7367.11125 5.371,7366.93797 4.8,7366.62489 L4.8,7366.67608 C4.801,7368.5989 6.178,7370.2549 8.092,7370.63591 C7.488,7370.79836 6.854,7370.82199 6.24,7370.70483 C6.777,7372.35099 8.318,7373.47829 10.073,7373.51078 C8.62,7374.63513 6.825,7375.24554 4.977,7375.24358 C4.651,7375.24259 4.325,7375.22388 4,7375.18549 C5.877,7376.37088 8.06,7377 10.29,7376.99705" id="twitter-[#154]">-->

<!--                          </path>-->
<!--                        </g>-->
<!--                      </g>-->
<!--                    </g>-->
<!--                  </svg>-->
<!--                </a>-->
<!--                <a href="#">-->
<!--                  <svg class="group h-8 hover:scale-125" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <title>TikTok</title>-->
<!--                    <path d="M16.8218 5.1344C16.0887 4.29394 15.648 3.19805 15.648 2H14.7293C14.9659 3.3095 15.7454 4.43326 16.8218 5.1344Z" fill="#000000"/>-->
<!--                    <path d="M8.3218 11.9048C6.73038 11.9048 5.43591 13.2004 5.43591 14.7931C5.43591 15.903 6.06691 16.8688 6.98556 17.3517C6.64223 16.8781 6.43808 16.2977 6.43808 15.6661C6.43808 14.0734 7.73255 12.7778 9.324 12.7778C9.62093 12.7778 9.90856 12.8288 10.1777 12.9124V9.40192C9.89927 9.36473 9.61628 9.34149 9.324 9.34149C9.27294 9.34149 9.22654 9.34614 9.1755 9.34614V12.0394C8.90176 11.9558 8.61873 11.9048 8.3218 11.9048Z" fill="#000000"/>-->
<!--                    <path d="M19.4245 6.67608V9.34614C17.6429 9.34614 15.9912 8.77501 14.6456 7.80911V14.7977C14.6456 18.2851 11.8108 21.127 8.32172 21.127C6.97621 21.127 5.7235 20.6998 4.69812 19.98C5.8534 21.2198 7.50049 22 9.32392 22C12.8083 22 15.6478 19.1627 15.6478 15.6707V8.68211C16.9933 9.64801 18.645 10.2191 20.4267 10.2191V6.78293C20.0787 6.78293 19.7446 6.74574 19.4245 6.67608Z" fill="#000000"/>-->
<!--                    <path d="M14.6456 14.7977V7.80911C15.9912 8.77501 17.6429 9.34614 19.4245 9.34614V6.67608C18.3945 6.45788 17.4899 5.90063 16.8218 5.1344C15.7454 4.43326 14.9704 3.3095 14.7245 2H12.2098L12.2051 15.7775C12.1495 17.3192 10.8782 18.5591 9.32393 18.5591C8.35884 18.5591 7.50977 18.0808 6.98085 17.3564C6.06219 16.8688 5.4312 15.9076 5.4312 14.7977C5.4312 13.205 6.72567 11.9094 8.31708 11.9094C8.61402 11.9094 8.90168 11.9605 9.17079 12.0441V9.35079C5.75598 9.42509 3 12.2298 3 15.6707C3 17.3331 3.64492 18.847 4.69812 19.98C5.7235 20.6998 6.97621 21.127 8.32172 21.127C11.8061 21.127 14.6456 18.2851 14.6456 14.7977Z" fill="#000000" class="group-hover:fill-primary"/>-->
<!--                  </svg>-->
<!--                </a>-->
                <a href="https://www.instagram.com/murciadeportes/" target="_blank">
                  <svg class="group h-10 hover:scale-125" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <title>Instagram</title>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 5H9.5C7.29086 5 5.5 6.79086 5.5 9V15C5.5 17.2091 7.29086 19 9.5 19H15.5C17.7091 19 19.5 17.2091 19.5 15V9C19.5 6.79086 17.7091 5 15.5 5Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="group-hover:stroke-primary"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 15C10.8431 15 9.5 13.6569 9.5 12C9.5 10.3431 10.8431 9 12.5 9C14.1569 9 15.5 10.3431 15.5 12C15.5 12.7956 15.1839 13.5587 14.6213 14.1213C14.0587 14.6839 13.2956 15 12.5 15Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="group-hover:stroke-primary"/>
                    <rect x="15.5" y="9" width="2" height="2" rx="1" transform="rotate(-90 15.5 9)" fill="#000000"/>
                    <rect x="16" y="8.5" width="1" height="1" rx="0.5" transform="rotate(-90 16 8.5)" stroke="#000000" stroke-linecap="round" class="group-hover:stroke-primary"/>
                  </svg>
                </a>
                <a href="https://www.facebook.com/murciadeportes" target="_blank">
                  <svg fill="#000000" class="h-8 hover:fill-primary hover:scale-125" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <title>
                      Facebook
                    </title>
                    <path d="M21.95 5.005l-3.306-.004c-3.206 0-5.277 2.124-5.277 5.415v2.495H10.05v4.515h3.317l-.004 9.575h4.641l.004-9.575h3.806l-.003-4.514h-3.803v-2.117c0-1.018.241-1.533 1.566-1.533l2.366-.001.01-4.256z"/>
                  </svg>
                </a>
            </div>
          </div>
        </div>
    </div>
</footer>
