import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeportesComponent } from './deportes.component';
import { LayoutsModule } from 'src/app/layouts/layouts.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SlideCheckboxModule } from 'src/app/form/slide-checkbox/slide-checkbox.module';
import { AcordeonDeportesModule } from 'src/app/components/acordeon-deportes/acordeon-deportes.module';
import { FormsModule } from '@angular/forms';
import { DeporteComponent } from './deporte/deporte.component';
import { DeportesRoutingModule } from './deportes-routing.module';
import { IconListComponent } from 'src/app/components/shared/list/icon-list/icon-list.component';
import { ListModule } from 'src/app/components/shared/list/list.module';
import { SelectInputModule } from 'src/app/form/select-input/select-input.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DeportesComponent, DeporteComponent],
  imports: [
    CommonModule,
    LayoutsModule,
    SharedModule,
    SlideCheckboxModule,
    FormsModule,
    AcordeonDeportesModule,
    DeportesRoutingModule,
    ListModule,
    SelectInputModule,
    ReactiveFormsModule,
  ],
  exports: [DeportesComponent],
})
export class DeportesModule {}
