<div>
  <div id="convocatorias" *ngIf="convocatorias.length != 0 && !apiStatus?.loading">
    <div class="my-14">
      <h1 class="md:text-start text-center text-5xl font-aldo text-primary">Convocatorias</h1>
    </div>
    <div class="w-full flex flex-col space-y-20">
      <app-pagination-container [data]="convocatorias"
        (emitData)="handleEmitData($event)" [pageSize]="5">
        <article *ngFor="let convocatoria of currentPageData" class="w-full flex flex-col shadow-2xl">
          <div class="flex flex-col md:flex-row p-3 md:p-8 pb-0">
            <div class="w-full md:w-2/3 h-full flex flex-col space-y-2 p-3 m-auto">
              <h3 class="text-xl md:text-3xl font-bold">{{ convocatoria.title }}</h3>
              <p *ngIf="convocatoria.descripcion" class="md:text-lg line-clamp-4"
                [innerHtml]="getDescripcion(convocatoria.descripcion) | safeHtml"></p>
            </div>
          </div>
          <div class="w-full flex justify-center md:justify-end">
            <a [routerLink]="'/noticias/' + convocatoria.id"
              class="bg-primary hover:bg-secondary w-1/3 md:w-[20%] text-sm md:text-lg button-keep-reading text-white text-center font-semibold rounded-t-2xl p-1.5 md:mr-20">
              Seguir leyendo
            </a>
          </div>
        </article>
      </app-pagination-container>
    </div>
  </div>
  <div id="noData-convocatorias" *ngIf="!apiStatus?.loading && convocatorias.length == 0"
    class="text-5xl text-primary text-center w-full">
    No se encontro ninguna convocatoria
  </div>
</div>