import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsuariosService {
  constructor(private http: HttpClient) {}

  getUsersByMonitorId(monitorId: number): Observable<any> {
    return this.http
      .get(environment.apiBackGetUrl + '/api/v1/drupal/usuarios?id_monitor=' + monitorId)
      .pipe(map(response => response));
  }
}
