<main>
  <div>
    <section class="miniature">
      <app-icon
        class="icon-padding"
        src="pool"
        [fill]="contadorIncidencias.piscina > 0 ? 'orange' : 'green'"
        customClass="avisos-icon"
      />
      <app-icon
        class="icon-padding"
        src="tennis"
        [fill]="contadorIncidencias.pista > 0 ? 'orange' : 'green'"
        customClass="avisos-icon"
      />
    </section>
    <section routerLink="/incidencias/listado" [queryParams]="{ pool: false, court: true }">
      <app-icon
        class="icon-padding"
        [ngClass]="contadorIncidencias.piscina > 0 ? 'warning' : 'operative'"
        src="pool"
        fill="black"
        customClass="avisos-icon"
      />
      <span [ngClass]="contadorIncidencias.piscina > 0 ? 'ballot-warning' : ''" class="ballot">
        {{ contadorIncidencias.piscina }}</span
      >
    </section>
    <i></i>
    <section routerLink="/incidencias/listado" [queryParams]="{ pool: true, court: false }">
      <app-icon
        class="icon-padding"
        [ngClass]="contadorIncidencias.pista > 0 ? 'warning' : 'operative'"
        src="tennis"
        fill="black"
        customClass="avisos-icon"
      />
      <span [ngClass]="contadorIncidencias.pista > 0 ? 'ballot-warning' : ''" class="ballot">
        {{ contadorIncidencias.pista }}</span
      >
    </section>
  </div>
</main>
