export class Breadcrumb {
  label: string;
  url: string;
  params?: any;

  constructor(data: { label: string; url: string; params?: any }) {
    this.label = data.label;
    this.url = data.url;
    this.params = data.params;
  }
}
