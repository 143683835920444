import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ListListerColumn } from '../list-lister/list-lister.component';

export enum SingleViewDataTypes {
  String = 'string',
  CombineString = 'combineString',
  Date = 'date',
  File = 'file',
  Boolean = 'boolean',
  Number = 'number',
  Array = 'array',
  innerHTML = 'innerHTML',
}

export interface SingleViewData {
  label: string;
  valueAccessor: string;
  valueArrayAccessor?: string;
  type: SingleViewDataTypes;
  combineString?: string;
  geolocation?: { latitud: number; longitud: number };
  icon: string;
  fill: string;
  stroke: string;
  isClickable?: string;
}

@Component({
  selector: 'app-icon-list',
  templateUrl: './icon-list.component.html',
  styleUrls: ['./icon-list.component.css'],
})
export class IconListComponent {
  @Input() data!: any;
  @Input() singleViewData!: SingleViewData[];
  singleViewDataTypes = SingleViewDataTypes;

  constructor(private router: Router) {}

  onArrayElementClick(isClickable: string, id: string) {
    if (isClickable) {
      this.router.navigate([isClickable, id]);
    }
  }

  combineString(combineString: string, data: any) {
    const accessors = combineString
      .match(/#(.*?)#/g)
      ?.map((match: string) => match.replace(/#/g, ''));
    if (accessors) {
      accessors.forEach((accessor: string) => {
        combineString = combineString.replace(`#${accessor}#`, data[accessor]);
      });
    }
    return combineString;
  }
}

// Created for quick fix build error
export class AccessorForCardView {
  cardColumns: ListListerColumn[] = [];
  listColumns: ListListerColumn[] = [];
}
