import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { RutinaApi } from '../../converters/tablas/tablas.converter.service';
import { TablasService } from '../tablas/tablas.service';
import { TablaFormService } from '../tabla-form/tabla-form.service';

@Injectable({
  providedIn: 'root',
})
export class TablaLoaderService {
  constructor(private tablasService: TablasService, private tablaFormService: TablaFormService) {}

  loadTablaWithId(tablaId: number): Observable<any> {
    return this.getTablaById(tablaId).pipe(
      switchMap(rutina => {
        return this.tablaFormService.loadRutina(rutina);
      })
    );
  }

  private getTablaById(tablaId: number): Observable<RutinaApi> {
    return this.tablasService.getRutinasByTableId(tablaId);
  }
}
