import { Component, Input, OnDestroy } from '@angular/core';
import { SharedDataService } from '../service/book-facility.service';
import { ServiceService } from 'src/app/services/service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.css'],
})
export class ActivityDetailsComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];
  currentActivity: any;
  currentAforo: any;
  showSchedule = false;

  @Input() IdActividad: number = 0;
  @Input() activity: any;
  @Input() activityDescription: any;
  extendedDescription: any;

  aforo: number = 0;
  Idhorario: number = 0;
  horarios: any[] = [];

  constructor(
    private sharedDataService: SharedDataService,
    private ServiceService: ServiceService
  ) {}

  ngOnInit() {
    this.sharedDataService.extendedDescription.subscribe(description => {
      this.extendedDescription = description;
    });
    this.sharedDataService.currentAforo = this.currentAforo;
    const aforoSubscription = this.ServiceService.getAforo(this.IdActividad).subscribe(
      (data: any) => {
        this.aforo = data.Componentes[0].PlazasLibres;
        this.Idhorario = data.Componentes[0].IdServicio;
      }
    );
    this.subscriptions.push(aforoSubscription);
  }

  verHorario() {
    this.ServiceService.getHorarios(this.Idhorario).subscribe((data: any) => {
      this.horarios = data.Horarios;
      this.showSchedule = !this.showSchedule;
    });
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
