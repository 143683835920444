import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-acordeon-deportes',
  templateUrl: './acordeon-deportes.component.html',
  styleUrls: ['./acordeon-deportes.component.css'],
})
export class AcordeonDeportesComponent {
  @Input() deporte?: any;
  @Input() open?: boolean = false;
  @Input() id: string = '';
  prueba: any;

  constructor(private router: Router, private route: ActivatedRoute) {}

  onSeeMoreClick() {
    this.router.navigate([this.id], { relativeTo: this.route });
  }
}
