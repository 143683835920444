import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TablasEjerciciosPage } from './pages/tablas-ejercicios/tablas-ejercicios.page';
import { NuevaTablaEjerciciosPage } from './pages/nueva-tabla-ejercicios/nueva-tabla-ejercicios.page';
import { EjercicioDataResolver } from './resolvers/ejercicioResolver';
import { EditarTablaEjerciciosPage } from './pages/editar-tabla-ejercicios/editar-tabla-ejercicios.page';
import { PerfilComponent } from './pages/perfil/perfil.component';

const routes: Routes = [
  {
    path: 'tablas-ejercicios',
    component: TablasEjerciciosPage,
  },
  {
    path: 'nueva-tabla',
    component: NuevaTablaEjerciciosPage,
    resolve: {
      initialData: EjercicioDataResolver,
    },
  },
  {
    path: 'perfil',
    component: PerfilComponent,
  },
  {
    path: 'editar-tabla',
    component: EditarTablaEjerciciosPage,
    resolve: {
      initialData: EjercicioDataResolver,
    },
  },
  {
    path: '**',
    redirectTo: 'perfil',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MonitorRoutingModule {}
