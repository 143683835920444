import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css'],
})
export class ConfirmationModalComponent {
  @Input() title: string | null = null;
  @Input() message: string | null = null;
  @Input() confirm: string | null = null;
  @Input() cancel: string | null = null;
  @Input() confirmCallback: Function | null = null;
  @Input() cancelCallback: Function | null = null;
  @Input() isModalOpen: boolean = false;

  constructor() {}

  onCancel() {
    this.cancelCallback && this.cancelCallback();
    this.isModalOpen = false;
  }
  onConfirm() {
    this.confirmCallback && this.confirmCallback();
    this.isModalOpen = false;
  }
}
