<main *ngIf="show">
    <div>
        Usuario
    </div>
    <section (click)="switch()" [ngClass]="state ? '' : 'switched'">
        <article></article>
    </section>
    <div>
        Monitor
    </div>
</main>