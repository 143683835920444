import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Noticia } from '../../../interfaces/NoticiaResponse';
import { ApiStatus } from '../../../interfaces';
import { getDescripcion } from '../../../utils/text';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.css'],
})
export class NoticiasComponent {
  protected readonly environment = environment;
  @Input() noticias: Noticia[] = [];
  @Input() apiStatus?: ApiStatus;

  currentPageData: any[] = [];
  handleEmitData(data: any[]) {
    this.currentPageData = data;
  }
  protected readonly getDescripcion = getDescripcion;
}
