<ng-container *ngIf="rutina; else noRutina">
    <main>
        <section>
            <div class="imagen">
                <app-icon [src]="getIcon(rutina.tabla.icon)" fill="var(--grey-medium)" />
            </div>
            <p>{{ rutina.tabla.title | titlecase }}</p>
        </section>
        <section>
            <app-icon (click)="onEditClick()" src="table" stroke="black" />
            <app-icon (click)="onUsersClick()" src="users" stroke="black" />
            <app-file-downloader [forceType]="'pdf'" (click)="handleDownload(rutina.tabla.rutinaId)" />
        </section>
    </main>
</ng-container>

<ng-template #noRutina>
    <main (click)="onNewTableClick()">
        <section>
            <div class="newTable">
                <app-icon [src]="getIcon('plusIcon')" stroke="var(--grey-medium)" />
            </div>
            <p>Nueva tabla</p>
        </section>
    </main>
</ng-template>