<app-page-with-breadcrumb>
    <header>
        <h1>Escuela deportiva</h1>
        <h2>{{escuela.title}}</h2>
    </header>
    <app-icon-list [data]="escuela" [singleViewData]="escuelaData"/>

    <app-accordion title="Localizacións" [isOpen]="true">
        <main>
            <app-map [data]="[escuela]" section="deporte-escolar" />
        </main>
    </app-accordion>
</app-page-with-breadcrumb>
