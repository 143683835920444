<app-page-with-breadcrumb>
    <app-tab-slider [(activeIndex)]="activeIndex" [tabs]="tabs" />
    <ng-container [ngSwitch]="activeIndex">
        <main *ngSwitchCase="0">
            <app-inscriptions-info />
        </main>
        <main *ngSwitchCase="1">
            <app-information-view />
        </main>
        <main *ngSwitchCase="2">
            <app-fee-information-view />
        </main>

        <main *ngSwitchDefault>
            <app-inscriptions-info />     
        </main>
    </ng-container>
</app-page-with-breadcrumb>
