import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { InscriptionsService } from '../../services/inscriptions.service';
import { TabInterface } from 'src/app/components/shared/tab-slider/tab-slider.component';
import { ActivatedRoute } from '@angular/router';
import { InscriptionResponse } from 'src/app/interfaces/InscripcionResponse'; // TODO: Reformat the interface in order to type the inscriptionsList variable
import { getTitle } from 'src/app/utils/breadcrumbs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-inscripciones',
  templateUrl: './inscripciones.component.html',
  styleUrls: ['./inscripciones.component.css'],
})
export class InscripcionesComponent implements OnInit {
  inscriptionsList: any;
  attachedFiles: string[] = [];
  AtachedFilesLinks: string[] = [];

  tabs: TabInterface[] = [
    { title: 'Inscripciones', active: true },
    { title: 'Información', active: true },
    { title: 'Bonificaciones', active: true },
  ];
  activeIndex: number = 0;

  constructor(
    private breadCrumbs: BreadcrumbService,
    private inscriptionsService: InscriptionsService,
    private route: ActivatedRoute,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(getTitle('Inscripciones'));
    this.breadCrumbs.addCurrentBreadcrumb();
    this.route.queryParams.subscribe(params => {
      this.activeIndex = Number(params['section']) || 0;
    });

    this.inscriptionsService.getInscriptions().subscribe(inscriptions => {
      this.inscriptionsList = (inscriptions as any).data;
      for (let inscription of this.inscriptionsList) {
        for (let archivo of inscription.archivos_adjuntos) {
          this.attachedFiles.push(archivo.uri.url);
        }
      }
      this.AtachedFilesLinks = this.attachedFiles.map(path =>
        this.inscriptionsService.getAtachedFiles(path)
      );
    });
  }
}
