import { Component, Input } from '@angular/core';
import { Memoria } from '../../../services/deporte-escolar.service';

@Component({
  selector: 'app-memoria-detail',
  templateUrl: './memoria-detail.component.html',
  styleUrls: ['./memoria-detail.component.css'],
})
export class MemoriaDetailComponent {
  @Input() memoria!: Memoria;
}
