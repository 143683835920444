<main>
  <section>
    <div class="outer">
      <h1>Información de usuario</h1>
      <div class="parent">
        <div class="userMain">
          <aside>
            <div>
              <img *ngIf="user.Imagen" alt="Foto perfil usuario" [src]="user?.Imagen" />
              <img
                *ngIf="!user.Imagen"
                alt="Foto perfil usuario"
                src="/assets/img/person%20placeholder.jpg"
              />
            </div>
            <div class="contact">
              <h1>Datos de contacto</h1>
              <span><b>Nombre</b>{{ getFullName(user) }}</span>
              <span><b>Dirección</b>Calle Paseo de la Pelota, nº 40 - 5º h</span>
              <span><b>Móvil</b>600 000 000</span>
              <span><b>Email</b>albertorodri&#64;correo.com</span>
            </div>
          </aside>
          <i></i>
        </div>
        <div class="goalsPerPeriods">
          <h1>Objetivos por periodos</h1>
          <p>
            Poner qué objetivos tiene xxxxxxxxxxxxxxxxxxxxx xxxxxxxxxxxxxxxxxxxxxx xxx xxx xx x xx
            xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
          </p>
          <i></i>
        </div>
        <div class="story">
          <h1>Vida? Historial?</h1>
          <div>
            <div><b>Profesión</b> <span>Funcionario</span></div>
            <div><b>Deportes</b> <span>Padel, senderismo</span></div>
            <i></i>
          </div>
        </div>
        <div class="noticeOf">
          <h1>A tener en cuenta</h1>
          <div>
            <b
              >Lesión reciente de ligamento cruzado anterior de la pierna izquierda. Evitar forzar
              dicha pierna.</b
            >
            <span>Poca flexibilidad</span>
            <Span>Xxxxxxxxxxxxxxx</Span>
            <span
              >Xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</span
            >
          </div>
        </div>
        <div class="biometricalData">
          <h1>Datos biométrico</h1>
          <div>
            <span><b>Altura</b>1.82 m</span>
            <span><b>Peso Inicial</b>102 kg</span>
            <span><b>Peso Actual</b>83 kg</span>
            <span><b>IMC</b>25.1</span>
            <span><b>Medidas?</b></span>
            <span>Bíceps 00 cm</span>
            <span>Espalda 00 cm</span>
            <span>Pecho 00 cm</span>
            <span>Cintura 00 cm</span>
            <span>Glúteos 00 cm</span>
            <span>Cuádriceps 00 cm</span>
            <span>Tobillo 00 cm</span>
          </div>
          <span
            >Más
            datosxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</span
          >
        </div>
      </div>
    </div>
  </section>
  <div class="buttons">
    <app-button
      label="CERRAR"
      (onClick)="accept()"
      class="button-observacion-action button accept"
    ></app-button>
  </div>
</main>
