import { Component } from '@angular/core';
import { DescargaData } from 'src/app/deporte-escolar/services/deporte-escolar.service';
import { DeporteEscolarService } from 'src/app/deporte-escolar/services/deporte-escolar.service';

@Component({
  selector: 'app-descargas',
  templateUrl: './descargas.component.html',
  styleUrls: ['./descargas.component.css'],
})
export class DescargasComponent {
  descargas: DescargaData[] | null = null;

  constructor(private deporteEscolarService: DeporteEscolarService) {}

  ngOnInit() {
    this.deporteEscolarService.getDescargas().subscribe((data: any) => {
      this.descargas = data.data;
    });
  }
}
