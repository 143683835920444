<ng-container *ngIf="data.length">
    <mgl-map #mapComponent [style]="'mapbox://styles/estevez8891/clnefmm5i03rs01pbagxxdnec'" [zoom]="[zoom]" [center]="center" [maxZoom]="16"
    [attributionControl]="false" [cooperativeGestures]="true">
    <mgl-control mglNavigation></mgl-control>
    <mgl-control mglGeolocate [showUserLocation]="true" [trackUserLocation]="true"></mgl-control>
        <span *ngFor="let item of data">
            <ng-container *ngIf="item.geolocation.longitud">
                <mgl-marker #marker
                    [lngLat]="item.geolocation.longitud ? {lng: item.geolocation.longitud, lat: item.geolocation.latitud} : undefined">
                    <img alt="{{item.title}}" src="/assets/icons/map-marker.svg" />
                </mgl-marker>
                <mgl-popup [marker]="marker" [closeButton]="false">
                    <h3 [routerLink]="'/' + section + '/' + item.id">{{item.title}}</h3>
                    <p>{{item.direccion || ''}}</p>
                    <section *ngIf="item.deportes">
                        <ng-container *ngIf="!item.deportes?.hasOwnProperty('data')">
                            <p *ngFor="let depor of item.deportes">
                                {{depor.title}}
                            </p>
                        </ng-container>
                    </section>
                </mgl-popup>
            </ng-container>
        </span>
    </mgl-map>
</ng-container>