import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoticiasComponent } from './pages/noticias/noticias.component';
import { NoticiasRoutingModule } from './noticias-routing.module';
import { NoticiaComponent } from './pages/noticia/noticia.component';
import { SanitizeHtmlPipe } from '../pipes/safehtml.pipe';
import { SharedModule } from '../shared/shared.module';
import { NoticiasLayoutComponent } from './layouts/noticias-layout/noticias-layout.component';
import { ConvocatoriasComponent } from './pages/convocatorias/convocatorias.component';
import { AvisosComponent } from './pages/avisos/avisos.component';
import { LayoutsModule } from '../layouts/layouts.module';

@NgModule({
  declarations: [
    NoticiasComponent,
    NoticiaComponent,
    SanitizeHtmlPipe,
    NoticiasLayoutComponent,
    ConvocatoriasComponent,
    AvisosComponent,
  ],
  exports: [SanitizeHtmlPipe],
  imports: [CommonModule, NoticiasRoutingModule, SharedModule, LayoutsModule],
})
export class NoticiasModule {}
