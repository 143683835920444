import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { Title } from '@angular/platform-browser';
import { getTitle } from 'src/app/utils/breadcrumbs';

@Component({
  selector: 'app-accesibilidad',
  templateUrl: './accesibilidad.component.html',
  styleUrls: ['./accesibilidad.component.css'],
})
export class AccesibilidadComponent implements OnInit {
  constructor(private breadCrumbService: BreadcrumbService, private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle(getTitle('Accesibilidad'));
    this.breadCrumbService.addCurrentBreadcrumb();
  }
}
