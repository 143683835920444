<main>
    <header>
        <span (click)="prevMonth()"><</span>
        <b>{{months[date.month()]}}</b>
        <span (click)="nextMonth()">></span>
    </header>

    <section>
        <span *ngFor="let day of days">{{day}}</span>
        <ng-container *ngFor="let day of [].constructor(firstDay);">
            <span></span>
        </ng-container>
        <ng-container *ngFor="let day of [].constructor(date.daysInMonth()); let i = index;">
            <span (click)="onClick({year: date.year(), month: date.month(), date: i + 1})" [style]="getBGColor({year: date.year(), month: date.month(), date: i + 1})" [ngClass]="(today.year() === date.year() && today.month() === date.month() && today.date() === i + 1) ? 'today' : ''">
                {{i + 1}}
            </span>
        </ng-container>
    </section>
</main>