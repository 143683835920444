import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageWithBreadcrumbComponent } from './page-with-breadcrumb/page-with-breadcrumb.component';
import { BreadcrumbsModule } from '../shared/breadcrumbs/breadcrumbs.module';
import { MicrositesComponent } from './microsites/microsites/microsites.component';
import { BasicPageComponent } from './basic-page/basic-page.component';
import { PaginationContainerComponent } from './pagination-container/pagination-container.component';

@NgModule({
  declarations: [
    PageWithBreadcrumbComponent,
    MicrositesComponent,
    BasicPageComponent,
    PaginationContainerComponent,
  ],
  imports: [CommonModule, BreadcrumbsModule],
  exports: [
    PageWithBreadcrumbComponent,
    BasicPageComponent,
    MicrositesComponent,
    PaginationContainerComponent,
  ],
})
export class LayoutsModule {}
