import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { sanitize } from 'src/app/utils/text';

export interface SelectInputOptions {
  label: string;
  value: any;
}

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInputComponent),
      multi: true,
    },
  ],
})
export class SelectInputComponent implements ControlValueAccessor, OnChanges {
  @Input() options: { label: string; value: any }[] = [];
  @Input() label: string | null = null;
  @Input() id: string = '';
  @Input() placeholder: string = '';
  @Input() defaultOptionLabel: string = '';
  @Input() name: string = '';
  @Input() size: number = 1;
  @Input() errores: any = null;
  @Input() multiselect: boolean = false;
  @Input() classList: string | null = null;
  @Input() optionClassList: string | null = null;
  @Input() control: AbstractControl | null = null;
  @Input() controlName: string = '';
  @ViewChild('searchInput') searchInput: ElementRef | undefined;
  filteredOptions: { label: string; value: any }[] = [];
  value: any;
  inputValue: string = '';
  disabled: boolean = false;

  onChange: any = (value: any) => {};
  onTouch: any = () => {};

  isDropdownOpen: boolean = false;

  constructor(private eRef: ElementRef, private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this.filteredOptions = this.options;
    }
    if (changes['control'] && changes['control'].currentValue?.value) {
      const currentValue = changes['control'].currentValue?.value;
      this.inputValue = currentValue.title || currentValue.name || currentValue;
    }
  }

  ngOnInit() {
    this.control?.valueChanges.subscribe(value => {
      if (!value) return;
      this.value = value;
      this.inputValue =
        this.options.find(option => option.label == value?.[this.controlName])?.label ||
        value.title ||
        value.name ||
        value;
    });
  }

  @HostListener('document:click', ['$event'])
  clickout(event: { target: any }) {
    this.isDropdownOpen = this.eRef.nativeElement.contains(event.target);
    this.cd.detectChanges();
  }

  ngAfterViewInit() {
    this.filteredOptions = this.options;
  }

  writeValue(value: any): void {
    this.value = value;
    this.onChange(value);
    if (value === null || value === undefined) {
      this.inputValue = '';
      this.filteredOptions = this.options;
    }
  }

  onOptionClick(option: any) {
    this.onChange(option.value);
    this.isDropdownOpen = false;
    this.inputValue = option.label;
    this.cd.detectChanges();
  }

  onFilterChange(event: any) {
    this.filteredOptions = this.options.filter(option => {
      return sanitize(option.label).includes(sanitize(event.target.value));
    });
    this.inputValue = event.target.value;
  }

  onSeletChange(event: any) {
    this.onChange(this.value);
    this.isDropdownOpen = false;
    let selectedIndex = event.target.options.selectedIndex;
    this.inputValue = event.target.options[selectedIndex].text;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInputClick() {
    this.searchInput?.nativeElement.select();
  }
}
