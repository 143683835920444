import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { LngLatLike } from 'mapbox-gl';
import * as mapboxgl from 'mapbox-gl';
import { environment } from '../../../../environments/environment';
import { MapComponent } from 'ngx-mapbox-gl';
import { DeportesComponent } from 'src/app/pages/deportes/deportes.component';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css'],
})
export class MapaComponent implements OnChanges, AfterViewInit {
  @ViewChild(MapComponent) mapComp?: MapComponent;

  @Input() data: any[] = [];
  @Input() zoom: number = 14;
  @Input() center: LngLatLike = [-1.13004, 37.98704]; //Centro de Murcia segun Google

  protected readonly environment = environment;

  ngOnChanges(changes: SimpleChanges) {
    if ('data' in changes) {
      this.adjustMapView();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.adjustMapView();
    }, 100);
  }

  adjustMapView() {
    if (this.data.length == 0) return;

    const bounds = new mapboxgl.LngLatBounds();

    this.data.forEach(data => {
      if (!data.longitud || !data.latitud) return;
      bounds.extend(new mapboxgl.LngLat(data.longitud, data.latitud));
    });

    // Ajustar el mapa para que todos los marcadores estén en vista
    this.mapComp?.mapInstance.fitBounds(bounds, {
      padding: 50,
    });
  }

  getImagePath(marcador: string): string {
    if (!marcador) marcador = '';
    return `/assets/icons/map-marker-${marcador}.svg`;
  }
}
