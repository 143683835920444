<main>
  <header>
    <h2>{{ruta.title| titlecase}}</h2>
    <span [innerHTML]="ruta.descripcion"></span>
    <span>
      <b>Distancia: </b><span>{{ruta.length}}m</span>
    </span>
    <span>
      <b>5 km: </b><span>{{5000 / (ruta.length || 1) | number: '1.1-1'}} vueltas</span>
    </span>
    <span>
      <b>10 km: </b><span>{{10000 / (ruta.length || 1) | number: '1.1-1'}} vueltas</span>
    </span>
    <span>
      <b>Tipo de recorrido: </b> {{ruta.tipo_recorrido | titlecase}}
    </span>
    <span><b>Inicio del recorrido: </b> {{ruta.inicio| titlecase}}</span>
    <span *ngIf="ruta.final"><b>Final del recorrido: </b> {{ruta.final| titlecase}}</span>
    <span><b>Dificultad: </b>{{ruta.dificultad| titlecase}}</span>
    <span><b>Arbolado: </b> {{ruta.sombra| titlecase}}</span>
    <span><b>Bancos: </b> {{ruta.bancos| titlecase}}</span>
    <span><b>Fuentes: </b> {{ruta.fuentes| titlecase}}</span>
    <span><b>Tipo de suelo: </b> {{ruta.tipo_suelo| titlecase}}</span>
    <span><b>Cruces con tráfico: </b> {{ruta.trafico| titlecase}}</span>
  </header>
  <section>
    <app-route-map *ngIf="ruta.geojson" [geojson]="ruta.geojson" [zoom]="zoom"></app-route-map>
  </section>
</main>
