import { ChangeDetectorRef, Component, Input } from '@angular/core';

export enum ListListerColumnType {
  Array = 'array',
  String = 'string',
  Date = 'date',
  Number = 'number',
  Boolean = 'boolean',
}

export interface ListListerColumn {
  name: string;
  prop: string;
  type: ListListerColumnType;
}

@Component({
  selector: 'app-list-lister',
  templateUrl: './list-lister.component.html',
  styleUrls: ['./list-lister.component.css'],
})
export class ListListerComponent {
  @Input() data!: any[];
  @Input() cardColumns!: ListListerColumn[];
  @Input() listColumns!: ListListerColumn[];
  @Input() isCardView: boolean = true;

  constructor(private changeDetector: ChangeDetectorRef) {}

  selectCardView() {
    this.isCardView = true;
  }

  selectListView() {
    this.isCardView = false;
  }

  trackByFn(index: any, item: any) {
    return item.id;
  }
}
