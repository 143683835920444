import { Component, OnInit } from '@angular/core';
import {
  Instalacion,
  InstalacionesResponse,
  Tipo,
  instalacionesForDownloader,
} from '../../../interfaces/InstalacionesResponse';
import { InstalacionService } from '../../services/instalacion.service';
import { HttpParams } from '@angular/common/http';
import { DeportesResponse } from '../../../interfaces/DeportesResponse';
import { MainService } from '../../../services/main.service';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { Title } from '@angular/platform-browser';
import { getTitle } from 'src/app/utils/breadcrumbs';
import { catchError, of, switchMap } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SelectInputOptions } from 'src/app/form/select-input/select-input.component';
import { ExportCsvService } from 'src/app/services/export-csv.service';
import { ExportPdfService } from 'src/app/services/export-pdf.service';

interface filterFormInterface {
  sport: FormControl<string>;
  searchInput: FormControl<string>;
  administrativeConcession: FormControl<boolean>;
}
@Component({
  selector: 'app-instalaciones',
  templateUrl: './instalaciones.component.html',
  styleUrls: ['./instalaciones.component.css'],
})
export class InstalacionesComponent implements OnInit {
  instalaciones: Instalacion[] = [];
  tipoInstalaciones: Tipo[] = [];
  instalacionesFiltradas: Instalacion[] = [];
  deportes: SelectInputOptions[] = [];
  ApiError: boolean = false;
  currentPageData: any[] = [];

  constructor(
    private instalacionService: InstalacionService,
    private mainService: MainService,
    private titleService: Title,
    private breadCrumbService: BreadcrumbService,
    private fb: FormBuilder,
    private exportCsvService: ExportCsvService,
    private exportPdfService: ExportPdfService
  ) {}

  filterForm: FormGroup<filterFormInterface> = this.fb.group({
    sport: [''],
    searchInput: [''],
    administrativeConcession: [false],
  }) as FormGroup<filterFormInterface>;

  ngOnInit() {
    this.titleService.setTitle(getTitle('Instalaciones'));
    this.breadCrumbService.addCurrentBreadcrumb();
    this.instalacionService.getInstalaciones(100).subscribe(
      (data: InstalacionesResponse) => {
        this.ApiError = false;
        this.instalaciones = data.data;
        this.filtrarInstalaciones(this.instalaciones);
      },
      () => this.onError()
    );

    this.mainService.getDeportes(new HttpParams()).subscribe(
      (data: DeportesResponse) => {
        this.ApiError = false;
        this.deportes = data.data.map(deporte => ({
          label: deporte.title,
          value: deporte.titleslug,
        }));
      },
      () => this.onError()
    );

    this.instalacionService
      .getTiposInstalaciones()
      .pipe(
        catchError(_ => {
          this.onError();
          return of();
        }),
        switchMap(tipos => {
          this.tipoInstalaciones = tipos.data;
          return of();
        })
      )
      .subscribe();

    this.filterForm.valueChanges.subscribe(value => {
      this.filtrarInstalaciones(this.instalaciones);
    });
  }

  onError() {
    this.ApiError = true;
  }

  filtrarInstalaciones(instalaciones: Instalacion[] | null) {
    this.instalacionesFiltradas = this.instalaciones;
    const { sport, searchInput, administrativeConcession } = this.filterForm.value;
    this.instalacionesFiltradas =
      instalaciones?.filter(
        instalacion =>
          this.filterBySport(instalacion, sport as string) &&
          this.filterByAdministrativeConcession(instalacion, administrativeConcession as boolean) &&
          this.filterBySearchInput(instalacion, searchInput as string)
      ) || [];
  }

  filterBySport(instalacion: Instalacion, sport: string) {
    return (
      (instalacion.deportes?.length ?? 0) > 0 &&
      instalacion.deportes?.some(
        deporteInstalacion => sport == '' || deporteInstalacion.titleslug === sport
      )
    );
  }

  filterBySearchInput(instalacion: Instalacion, searchInput: string) {
    return instalacion.titleslug?.includes(searchInput);
  }

  filterByAdministrativeConcession(instalacion: Instalacion, administrativeConcession: boolean) {
    if (!administrativeConcession && !instalacion.concesion_administrativa) {
      instalacion.deportes = undefined;
      return true;
    }
    if (administrativeConcession === instalacion.concesion_administrativa) return true;
    return false;
  }

  handleEmitData(data: any[]) {
    this.currentPageData = data;
  }

  CSVdownload() {
    this.exportCsvService.generateXLSX(
      instalacionesForDownloader(this.instalaciones),
      'instalaciones.xlsx'
    );
  }
  PDFdownload() {
    this.exportPdfService.generatePdf(instalacionesForDownloader(this.instalaciones));
  }
}
