import { Component, OnDestroy, OnInit } from '@angular/core';
import { Ejercicio } from '../../models/ejercicios.model';
import { ActivatedRoute, Router } from '@angular/router';
import { TablaFormService } from '../../services/tabla-form/tabla-form.service';
import { catchError, filter, of, Subscription, switchMap, take, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { TablaLoaderService } from '../../services/tabla-form-loader/tabla-loader.service';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { Title } from '@angular/platform-browser';
import { ModalSuccessService } from '../../../services/modal-success.service';

const PARAM_TABLA_LABEL = 'tablaId';

@Component({
  selector: 'app-editar-tabla-ejercicios',
  templateUrl: './editar-tabla-ejercicios.page.html',
  styleUrls: ['./editar-tabla-ejercicios.page.css'],
  providers: [TablaFormService, TablaLoaderService],
})
export class EditarTablaEjerciciosPage implements OnInit, OnDestroy {
  allEjercicios: Ejercicio[] = [];

  overTabsErrorMessages: { message: string }[] = [];

  subscriptions: Subscription = new Subscription();

  savingOrLoading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private tablaFormService: TablaFormService,
    private tablaLoaderService: TablaLoaderService,
    private breadCrumbService: BreadcrumbService,
    private modalSuccessService: ModalSuccessService,
    private titleService: Title
  ) {
    this.tablaFormService.intialize();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ initialData }) => {
      this.allEjercicios = initialData?.ejercicios || [];
    });

    this.subscriptions.add(
      this.activatedRoute.queryParams
        .pipe(
          filter(params => !!params && !!params[PARAM_TABLA_LABEL]),
          take(1),
          switchMap(params => {
            this.titleService.setTitle('Editar Tabla - ' + params[PARAM_TABLA_LABEL]);
            this.breadCrumbService.addCurrentBreadcrumb();
            this.modalSuccessService.showModal('Cargando tabla...', 0);
            this.savingOrLoading = true;
            return this.loadTablaWithId(params[PARAM_TABLA_LABEL]);
          })
        )
        .subscribe({
          complete: () => {
            this.savingOrLoading = false;
            this.modalSuccessService.hideModal();
          },
        })
    );
  }

  loadTablaWithId(idTabla: number) {
    return this.tablaLoaderService.loadTablaWithId(idTabla);
  }

  updateRutina() {
    this.overTabsErrorMessages = [];

    this.modalSuccessService.showModal('Guardando...', 0);
    this.savingOrLoading = true;

    this.tablaFormService
      .updateRutina()
      .pipe(
        tap((value: any) => {
          if (Array.isArray(value)) {
            this.overTabsErrorMessages = value;
          } else {
            this.router.navigate(['/monitor/perfil']);
          }
        }),
        catchError((err: HttpErrorResponse) => {
          return this.handleCommonErrors(err);
        })
      )
      .subscribe({
        complete: () => {
          this.modalSuccessService.hideModal();
          this.savingOrLoading = false;
        },
      });
  }

  handleCommonErrors(error: HttpErrorResponse) {
    this.overTabsErrorMessages = [];
    if (this.ejercicioNotFound(error)) {
      this.overTabsErrorMessages.push({
        message: 'No existe uno de los ejercicios seleccionados.',
      });
    } else {
      this.overTabsErrorMessages.push({ message: 'Error inesperado del servidor' });
    }

    return of(null);
  }

  ejercicioNotFound(err: HttpErrorResponse): boolean {
    return err.status === 404 && err.error && err.error.message === 'Not Found';
  }

  get rutinaFormGroup(): UntypedFormGroup {
    return this.tablaFormService.rutinaForm as UntypedFormGroup;
  }
}
