<main>
  <h4 *ngIf="title" >{{ title }}</h4>
  <section>
    <label *ngIf="label" for="{{ id }}">{{ label }}</label>
    <ng-container *ngIf="isDisabled; else inputEnabled">
      <p>{{value}}</p>
    </ng-container>
    <ng-template #inputEnabled>
      <input
        [type]="type"
        [id]="id"
        [value]="value"
        (input)="updateValue($event)"
        (blur)="onTouched()"
        [placeholder]="placeholder"
        [ngClass]="{ 'input-error': errores }"
      />
    </ng-template>
  </section>
  <span *ngIf="errores" >El campo "{{ label | titlecase }}" es requerido</span>
</main>