import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ViewChild, ElementRef } from '@angular/core';
import { Geometry, Feature, FeatureCollection, GeoJsonProperties } from 'geojson';
import { LngLatLike } from 'mapbox-gl';
import { MapComponent } from 'ngx-mapbox-gl';

@Component({
  selector: 'app-route-map',
  templateUrl: './route-map.component.html',
  styleUrls: ['./route-map.component.css'],
})
export class RouteMapComponent {
  @ViewChild('mapComponent', { static: false }) mapComponent!: MapComponent;
  @Input() zoom: number = 14;
  @Input() geojson: FeatureCollection<Geometry, GeoJsonProperties> | undefined = undefined;

  length = 0;

  protected readonly environment = environment;
  center: LngLatLike = [-1.13004, 37.98704]; //Centro de Murcia segun Google
  uuid: string = crypto.randomUUID();

  ngOnInit() {
    const point = this.geojson?.features.filter(feature => feature.geometry.type === 'Point')[0];

    if (point?.geometry.type === 'Point') {
      this.center = point.geometry.coordinates as LngLatLike;
    }
  }

  ngAfterViewInit() {
    try {
      if (this.geojson) {
        this.loadGeoJson(this.geojson, this.uuid);
      }
    } catch (error) {
      console.error('Error loading GeoJSON:', error);
    }
  }

  loadGeoJson(geojson: any, layerId: string) {
    if (this.mapComponent) {
      this.mapComponent.mapLoad.subscribe(() => {
        const map = this.mapComponent.mapInstance;

        if (layerId) {
          if (!map.getSource(layerId)) {
            map.addSource(layerId, {
              type: 'geojson',
              data: geojson,
            });
          }

          // Add point layer
          if (!map.getLayer(layerId + '-point')) {
            map.addLayer({
              id: layerId + '-point',
              type: 'circle',
              source: layerId,
              filter: ['==', '$type', 'Point'],
              paint: {
                'circle-radius': 8,
                'circle-color': '#aa0400',
              },
            });
          }

          // Add line layer
          if (!map.getLayer(layerId + '-line')) {
            map.addLayer({
              id: layerId + '-line',
              type: 'line',
              source: layerId,
              filter: ['==', '$type', 'LineString'],
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': '#aa0400',
                'line-width': 8,
              },
            });
          }
        } else {
          console.error('layerId is not defined');
        }
      });
    }
  }
}
