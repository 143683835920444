<main>
  <h1>Recuperar contraseña</h1>
  <ng-container [ngSwitch]="currentStep">
    <form
      *ngSwitchCase="1"
      [formGroup]="formularioRecuperarContrasena"
      (ngSubmit)="submitRecuperarContrasena()"
    >
      <fieldset>
        <label for="DocumentoIdentidad">Documento de identidad</label>
        <input name="DocumentoIdentidad" formControlName="DocumentoIdentidad" type="text" />
        <label for="Email">Email</label>
        <input name="Email" formControlName="Email" type="email" />
        <label for="FechaNacimiento">Fecha de nacimiento</label>
        <app-datetime-picker
          formControlName="FechaNacimiento"
          name="FechaNacimiento"
          id="FechaNacimiento"
          [type]="DatetimeType.date"
          [yearsRange]="{ start: dayjs().year() - 1920, end: 0 }"
        />
      </fieldset>
      <app-button
        label="Siguiente"
        classList="btn btn-primary btn-block"
        type="submit"
        fillColors="white"
      ></app-button>
    </form>
    <form *ngSwitchCase="2" [formGroup]="formularioPIN" (ngSubmit)="submitPIN()">
      <fieldset>
        <label for="PIN">Código</label>
        <input name="PIN" formControlName="PIN" type="text" />
        <label for="NuevaContrasena">Nueva contraseña</label>
        <input name="NuevaContrasena" formControlName="NuevaContrasena" type="password" />
        <label for="ConfirmarContrasena">Confirmar contraseña</label>
        <input name="ConfirmarContrasena" formControlName="ConfirmarContrasena" type="password" />
      </fieldset>
      <app-button
        label="Recuperar contraseña"
        classList="btn btn-primary btn-block"
        type="submit"
        fillColors="white"
      ></app-button>
    </form>
  </ng-container>
</main>
