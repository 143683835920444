<app-page-with-breadcrumb>
  <app-pages-loader *ngIf="instalaciones.length == 0 || deportes.length == 0" [Apierror]="ApiError"></app-pages-loader>
  <div *ngIf="instalaciones.length != 0">
    <div id="content-instalaciones">
      <h1 class="text-5xl text-primary font-bold font-aldo text-center md:text-start">
        Instalaciones Deportivas
      </h1>
      <div class="w-full flex flex-col space-y-6 m-auto">
        <form [formGroup]="filterForm">
          <article>
            <app-input label="Por palabra" formControlName="searchInput" />
            <app-select-input placeholder="Busca un deporte" label="Por deporte" formControlName="sport"
              defaultOptionLabel="Cualquier deporte" [options]="deportes" />
            <app-slide-checkbox label="Instalaciones por concesión administrativa"
              formControlName="administrativeConcession" />
          </article>
        </form>
        <app-button (click)="CSVdownload()" label="Descargar en CSV" type="button" classList="btn btn-secondary btn-block"/>
        <app-button (click)="PDFdownload()" label="Descargar en PDF" type="button" classList="btn btn-secondary btn-block"/>
      </div>
    </div>
  </div>
  <app-geolocalizacion [data]="currentPageData"></app-geolocalizacion>
  <app-pagination-container [showUpperButtons]="true" [data]="instalacionesFiltradas"
    (emitData)="handleEmitData($event)" [pageSize]="18">
    <app-listado [instalaciones]="currentPageData"></app-listado>
  </app-pagination-container>
</app-page-with-breadcrumb>