import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as dayjs from 'dayjs';

export enum DatetimeType {
  date = 'date',
  time = 'time',
  datetime = 'datetime',
}

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimePickerComponent),
      multi: true,
    },
  ],
})
export class DatetimePickerComponent implements ControlValueAccessor, OnInit {
  Math = Math;
  @Input() type: DatetimeType = DatetimeType.datetime;
  @Input() datetime: string | Date | undefined = dayjs().toISOString();
  @Input() error?: any = null;
  @Input() name?: string;
  @Input() title?: string;
  @Input() id?: string;
  @Input() class?: string;
  @Input() yearsRange?: { start: number; end: number };
  @Input() stepper?: number = 1;

  @Input() minDate?: Date;

  currentYear: number;

  years: number[] = [];
  months: string[] = [];
  numberOfDays: { disabled: boolean }[] = [];
  weekdays: string[] = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
  emptyDays: any[] = [];

  today: dayjs.Dayjs;

  @ViewChildren('monthSpan') monthSpans!: QueryList<ElementRef>;
  disable: boolean = false;
  hideModal: boolean = true;
  yearSelector: boolean = false;

  onChange: any = (value: Date | undefined) => {};
  onTouch: any = () => {};

  constructor() {
    this.yearsRange = this.yearsRange || { start: 0, end: 0 };
    this.currentYear = dayjs().year();
    this.today = dayjs();
  }

  ngOnInit() {
    const dummyDate = this.datetime ? dayjs(this.datetime) : dayjs();
    setTimeout(() => {
      this.loadYearsAndMonths();
      this.loadEmptyDays(dayjs(dummyDate));
      this.controlTimeScroll();
      this.generateDaysInMonth(dummyDate);
    }, 10);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['minDate']) {
      const dummyDate = this.datetime ? dayjs(this.datetime) : dayjs();
      this.loadEmptyDays(dayjs(dummyDate));
      this.generateDaysInMonth(dummyDate);

      if (this.datetime && this.minDate && this.minDate > new Date(this.datetime)) {
        this.datetime = this.minDate.toISOString();
        this.onChange(this.datetime);
      }
    }
  }

  loadYearsAndMonths() {
    if (this.yearsRange) {
      for (
        let i = this.currentYear - this.yearsRange.start;
        i <= this.currentYear + this.yearsRange.end;
        i++
      ) {
        if (this.minDate && i < this.minDate.getFullYear()) continue;
        this.years.push(Math.floor(i));
      }
    }

    for (let i = 0; i < 12; i++) {
      const dummyDate = new Date(0);
      dummyDate.setMonth(i);
      const monthName = dummyDate.toLocaleString('default', { month: 'short' });
      this.months.push(monthName.charAt(0).toUpperCase() + monthName.slice(1));
    }
  }

  loadEmptyDays(date: dayjs.Dayjs) {
    const dummyDate = new Date(date.toISOString());
    const firstDay = new Date(dummyDate.getFullYear(), dummyDate.getMonth(), 1).getDay();
    this.emptyDays = new Array(firstDay - 1).fill(null);
  }

  generateDaysInMonth(date: dayjs.Dayjs) {
    this.numberOfDays = [];
    for (let i = 1; i <= date.daysInMonth(); i++) {
      this.numberOfDays.push({
        disabled:
          (this.minDate && this.minDate >= new Date(date.year(), date.month(), i + 1)) || false,
      });
    }
  }

  toggleModal() {
    if (!this.datetime) this.datetime = dayjs().toISOString();

    this.hideModal = !this.hideModal;
    setTimeout(() => {
      this.scrollToSelectedTime();
      this.scrollToSelectedMonth();
    }, 10);
    this.onChange(this.datetime);
  }

  enableYearSelector() {
    this.yearSelector = true;
  }

  controlTimeScroll() {
    const elements = document.querySelectorAll('main > div > nav');

    elements.forEach((element: Element) => {
      const nav = element as HTMLElement;

      nav.addEventListener(
        'wheel',
        function (e) {
          e.preventDefault();
          if (e.deltaY < 0) {
            nav.scrollBy(0, -50);
          } else {
            nav.scrollBy(0, 50);
          }
        },
        { passive: false }
      );
    });
  }

  onYearClick(event: any) {
    this.currentYear = event.target.innerText;
    this.yearSelector = false;
    this.scrollToSelectedYear(event);
  }

  onMonthClick(event: any) {
    const dummyDate = event.target.getAttribute('data-month').split('-');
    let clickedDate = dayjs();
    clickedDate = clickedDate.set('year', Number.parseInt(dummyDate[0]));
    clickedDate = clickedDate.set('month', Number.parseInt(dummyDate[1]) - 1);
    if (this.datetime) {
      const datetime = dayjs(this.datetime);
      if (clickedDate.daysInMonth() >= datetime.date()) {
        clickedDate = clickedDate.set('date', datetime.date());
      } else {
        clickedDate = clickedDate.set('date', clickedDate.daysInMonth());
      }
      clickedDate = clickedDate.set('hour', datetime.hour());
      clickedDate = clickedDate.set('minute', datetime.minute());
      clickedDate = clickedDate.set('second', datetime.second());
      clickedDate = clickedDate.set('millisecond', datetime.millisecond());
      this.datetime = clickedDate.toISOString();
    }
    this.scrollToSelectedMonth(event);
    this.generateDaysInMonth(clickedDate);
    this.loadEmptyDays(clickedDate);
    this.onChange(this.datetime);
  }

  onDayClick(event: any) {
    let datetime = dayjs(this.datetime);
    datetime = datetime.set('date', event.target.innerText);
    this.datetime = datetime.toISOString();
    this.onChange(this.datetime);
  }

  onHourClick(event: any) {
    let datetime = dayjs(this.datetime);
    datetime = datetime.set('hour', event.target.innerText);
    this.datetime = datetime.toISOString();
    this.scrollToSelectedTime(event);
    this.onChange(this.datetime);
  }

  onMinuteClick(event: any) {
    let datetime = dayjs(this.datetime);
    datetime = datetime.set('minute', event.target.innerText);
    this.datetime = datetime.toISOString();
    this.scrollToSelectedTime(event);
    this.onChange(this.datetime);
  }

  scrollToSelectedYear(event?: any) {
    const target = document.querySelector('.month-selected');
    const dataYear = target?.getAttribute('data-month');
    const newMonth = dataYear?.split('-');
    let joinedNewMonth = '';

    if (newMonth) {
      newMonth[0] = event.target.innerText;
      joinedNewMonth = newMonth.join('-');
    }

    const newTarget = document.querySelector(`[data-month="${joinedNewMonth}"]`) as HTMLElement;
    target?.classList.remove('month-selected');
    newTarget?.classList.add('month-selected');

    const aside = newTarget?.parentElement;

    if (newTarget && aside) {
      const spanPosition = newTarget.offsetTop - aside.offsetTop;
      aside.scrollTop = spanPosition - aside.clientHeight / 2 + newTarget.clientHeight / 2;
    }
  }

  scrollToSelectedMonth(event?: any) {
    const target = event?.target || document.querySelector('.month-selected');
    const dataMonth = target.getAttribute('data-month') || target?.getAttribute('data-month');

    const selectedSpan = this.monthSpans.find(
      span => span.nativeElement.getAttribute('data-month') === dataMonth
    );
    const aside = target.parentElement;

    if (selectedSpan && aside) {
      const spanPosition = selectedSpan.nativeElement.offsetTop - aside.offsetTop;
      aside.scrollTop =
        spanPosition - aside.clientHeight / 2 + selectedSpan.nativeElement.clientHeight / 2;
    }
  }

  scrollToSelectedTime(event?: any) {
    const target = event ? [event?.target] : document.querySelectorAll('.time-selected');
    target.forEach((element: any) => {
      const nav = element.parentElement;
      nav.scrollTop =
        element.offsetTop - nav.offsetTop - nav.clientHeight / 2 + element.clientHeight / 2;
    });
  }

  hightlightSelectedDay(day: number) {
    const datetime = dayjs(this.datetime);
    return datetime.date() === day;
  }

  hightlightSelectedHour(hour: number) {
    const datetime = dayjs(this.datetime);
    return datetime.hour() === hour;
  }

  hightlightSelectedMinute(minutes: number) {
    const datetime = dayjs(this.datetime);
    return this.roundedMinutes(datetime.minute()) === minutes;
  }

  roundedMinutes(minutes: number) {
    if (minutes % 10 <= 2.5) {
      return Math.floor(minutes / 10) * 10;
    } else {
      return Math.ceil(minutes / 10) * 10;
    }
  }

  writeValue(obj: any): void {
    if (obj) this.datetime = new Date(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disable = isDisabled;
  }

  protected readonly DatetimeType = DatetimeType;
}
