import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-burger-menu',
  templateUrl: './user-burger-menu.component.html',
  styleUrls: ['./user-burger-menu.component.css'],
})
export class UserBurgerMenuComponent implements OnInit {
  user: User | null = null;
  navOpen: boolean = false;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private globalService: GlobalService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.globalService.getLoggedUser().subscribe(user => {
      this.user = user;
      this.changeDetectorRef.detectChanges();
    });
  }

  toggleNav() {
    this.navOpen = !this.navOpen;
  }

  getRoute() {
    if (this.authService.isLoggedIn()) return '/user-profile';
    else return '/login';
  }

  logout() {
    this.toggleNav();
    this.authService.removeUserInfo();
    this.router.navigate(['']);
  }

  onNavOpenChange(_: null) {
    this.navOpen = !this.navOpen;
  }
}
