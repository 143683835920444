<ng-container *ngIf="condition; else conditionAccomplised">
  <div *ngIf='!Apierror' class="flex flex-col space-y-5 mx-auto mt-12 justify-center">
    <h1 class="text-3xl text-primary text-center w-full">Cargando Datos</h1>
    <img src="/assets/gif/letras%20cargando.gif" alt="Letras Loader" class="object-center object-cover m-auto">
    <img src="/assets/gif/imagen%20cargando.gif" alt="Imagen Loader" id="page-loader" class="object-center m-auto">
  </div>
  <div *ngIf='Apierror' class="md:w-8/12 w-11/12 flex flex-col space-y-5 mx-auto mt-16">
    <h1 class="text-2xl text-primary text-center w-full">
      No se puede recuperar la información. Inténtelo de nuevo.
    </h1>
    <img alt="Imagen No Datos" src="/assets/icons/no-data.svg" class='h-[300px]'/>
  </div>
</ng-container>
<ng-template #conditionAccomplised>
  <ng-content/>
</ng-template>