import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { getTitle } from 'src/app/utils/breadcrumbs';

@Component({
  selector: 'app-information-view',
  templateUrl: './information-view.component.html',
  styleUrls: ['./information-view.component.css'],
})
export class InformationViewComponent {
  infoInscripcion: any;

  constructor(private breadCrumbs: BreadcrumbService, private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle(getTitle('Información'));
    this.breadCrumbs.addCurrentBreadcrumb();
  }
}
