export const bgColors = [
  '#FF74D5',
  '#BF0988',
  '#6227C1',
  '#102FBE',
  '#1787E2',
  '#33D1CD',
  '#00BD2F',
  '#E4B301',
  '#F0561D',
  '#B4B4B4',
  '#2C2C2C',
];
