<main>
    <header>
        <h1>Matrícula</h1>
        
        <h3>
            Para las nuevas altas, el usuario deberá abonar un importe de 15 euros. La cuota de inscripción se aplicará
            por
            usuario y no por curso inscrito. Siempre que un usuario tenga un curso activo, no tendrá que efectuar de
            nuevo el
            pago.
        </h3>
        <i>
            "De conformidad con la normativa de precios públicos aprobada por acuerdo del Exmo. Ayuntamiento pleno de 26
            de
            octubre de 2012, la cuota de inscripción se aplica por usuario, independientemente de que se inscriba en más
            de una
            actividad que se desarrolle en instalaciones gestionadas por la misma entidad. Asimismo, le indicamos que
            esta cuota
            será abonada una sóla vez y de por vida, siempre que el usuario tenga un curso activo. Si un usuario
            quisiera
            cambiar de curso o de días, no tendría que abonar de nuevo la cuota indicada."
        </i>
        <h3>En ningun caso procederá la aplicación de bonificaciones en el cobro de esta cuota.</h3>
    </header>
    <section>
        <article>
            <h1>
                Para tener derecho a cualquier tipo de bonificación de actividades docentes el usuario debe estar
                empadronado en el municipio de Murcia.
            </h1>
            <table>
                <thead>
                    <tr>
                        <th>Concepto</th>
                        <th>Descuento</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Pago anticipado trimestral (sólo aplicable a cursos mensuales)</td>
                        <td>5%</td>
                    </tr>
                    <tr>
                        <td>Pago anticipado semestral</td>
                        <td>15%</td>
                    </tr>
                    <tr>
                        <td>Pago anticipado de curso completo</td>
                        <td>15%</td>
                    </tr>
                    <tr>
                        <td>Pago tarjeta MurciaTotal o tarjeta joven oficial</td>
                        <td>25%</td>
                    </tr>
                    <tr>
                        <td>Familia numerosa General</td>
                        <td>50%</td>
                    </tr>
                    <tr>
                        <td>Familia numerosa Especial</td>
                        <td>90%</td>
                    </tr>
                    <tr>
                        <td>Pensionistas y Jubilados con ingresos totales inferiores al Salario Mínimo
                            Interprofesional vigente que así lo acrediten</td>
                            <td>75%</td>
                        </tr>
                        <tr>
                            <td>Discapacitados con Grado de Minusvalía igual o superior al 65%, debidamente acreditado
                                por el IMAS</td>
                                <td>75%</td>
                            </tr>
                            <tr>
                                <td>A Discapacitados con Grado de Minusvalía igual o superior al 33% debidamente acreditado
                                    por el IMAS</td>
                                    <td>50%</td>
                                </tr>
                                <tr>
                                    <td>Actividades docentes menores de 14 años inclusive</td>
                                    <td>20%</td>
                                </tr>
                                <tr>
                                    <td>Libre Acceso *</td>
                                    <td>100%</td>
                                </tr>
                            </tbody>
                        </table>
                    </article>
        <footer>
            <h3>
                * El libre acceso se obtiene mediante solicitud que deberá rellenar y presentar junto con toda la
                documentación
                necesaria en el Registro General del Ayuntamiento de Murcia o a través de la Sede Electrónica. Puede
                descargarlo<span>aqui</span>
            </h3>
        </footer>
    </section>
</main>