<app-page-with-breadcrumb>
  <app-pages-loader [condition]="deportes.length == 0" [Apierror]="Apierror">
    <main>
      <h1>
        Actividades Deportivas
      </h1>
      <form [formGroup]="filterForm">
        <app-select-input label="Buscar por Deportes" placeholder="Busca un deporte" formControlName="sport"
          defaultOptionLabel="Cualquier deporte" [options]="deportesSelect" />
        <span>{{
          this.filterForm.value.edadSelector === 0 ? 'Todas las edades' : 'A partir de los ' +
          this.filterForm.value.edadSelector + ' años'
          }}
          <input id="range-edades" type="range" min="0" max="100" step="1" formControlName="edadSelector"
            class="bg-opacity-40 bg-footer accent-primary rounded-2xl text-center outline-none" />
        </span>
        <app-button label="Limpiar Filtros" type="button" classList="btn btn-primary" (click)="clearSelection()" />
      </form>
      <div>
        <app-button label="Limpiar Filtros" type="button" classList="btn btn-primary" (click)="clearSelection()" />
        <div>
          <app-button (click)="CSVdownload()" label="Crear CSV" type="button" classList="btn btn-secondary"/>
          <app-button (click)="PDFdownload()" label="Crear PDF" type="button" classList="btn btn-secondary"/>
        </div>
      </div>
      <app-pagination-container [data]="deportesFiltrados" (emitData)="handleEmitData($event)" [pageSize]="18">
        <ng-container *ngFor="let deporte of currentPageData; trackBy: trackByDeportes">
          <app-accordion [title]="deporte.title">
            <main>
              <section>
                <p *ngIf="deporte?.descripcion" [innerHtml]="deporte?.descripcion!"></p>
                <aside *ngIf="deporte?.contacto">
                  <h3>Contacto</h3>
                  <p>{{deporte?.contacto}}</p>
                </aside>
                <span *ngIf="deporte?.mas_informacion">
                  <h3>Telefono/Correo</h3>
                  <p>{{deporte?.mas_informacion}}</p>
                </span>
                <span>
                  <h3>Edades</h3>
                  <p>{{deporte?.edad_minima}}-{{deporte?.edad_maxima}}</p>
                </span>
                <span *ngIf="!deporte?.precios?.hasOwnProperty('data')">
                  <h3>Precios</h3>
                  <ul>
                    <li *ngFor="let precio of deporte?.precios">
                      {{precio.title}} - {{precio.precio | number:'1.2-2'}} euros/mes
                    </li>
                  </ul>
                </span>
                <footer>
                  <app-button (click)="onSeeMoreClick(deporte.id)" label="Más info" classList="btn btn-primary" />
                </footer>
              </section>
            </main>
          </app-accordion>
        </ng-container>
      </app-pagination-container>
    </main>
  </app-pages-loader>
</app-page-with-breadcrumb>