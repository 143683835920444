import { Component, Input } from '@angular/core';
import { Ruta } from 'src/app/interfaces/RutasResponse';

@Component({
  selector: 'app-routes-list',
  templateUrl: './routes-list.component.html',
  styleUrls: ['./routes-list.component.css'],
})
export class RoutesListComponent {
  @Input() cargando: boolean = false;
  @Input() rutas: Ruta[] = [];

  vista: string = 'grid';
}
