import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapForListComponent } from './map-for-list/map-for-list.component';
import { MapComponent } from './map/map.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [MapForListComponent, MapComponent],
  imports: [CommonModule, NgxMapboxGLModule, RouterLink],
  exports: [MapForListComponent, MapComponent],
})
export class MapsModule {}
