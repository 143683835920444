import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-reproductor-video',
  templateUrl: './reproductor-video.component.html',
  styleUrls: ['./reproductor-video.component.css'],
})
export class ReproductorVideoComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() src?: string;
  @Input() posterSrc?: string;

  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  playing: boolean = false;
  currentTime: number = 0;
  duration: number = 0;
  volume: number = 100;
  isMute: boolean = false;
  videoError: boolean = false;
  videoLoaded: boolean = false;
  hasValidSource: boolean = false;

  ngAfterViewInit() {
    this.setupVideoEventListeners();
    this.loadInitialSource();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['src'] && !changes['src'].firstChange) {
      this.changeSource(this.src);
    }

    // if (changes['posterSrc'] && !changes['posterSrc'].firstChange) {
    //   this.changePoster(this.posterSrc);
    // }
  }

  ngOnDestroy() {
    this.removeVideoEventListeners();
  }

  setupVideoEventListeners() {
    const video: HTMLVideoElement = this.videoPlayer?.nativeElement;

    video?.addEventListener('timeupdate', this.updateTime.bind(this));
    video?.addEventListener('error', this.handleError.bind(this));
    video?.addEventListener('loadeddata', this.handleLoaded.bind(this));
    video?.addEventListener('ended', this.handleEnded.bind(this));
  }

  removeVideoEventListeners() {
    const video: HTMLVideoElement = this.videoPlayer?.nativeElement;
    video.removeEventListener('timeupdate', this.updateTime);
    video.removeEventListener('error', this.handleError);
    video.removeEventListener('loadeddata', this.handleLoaded);
    video?.removeEventListener('ended', this.handleEnded);
  }

  handleEnded() {
    this.playing = false;
  }

  updateTime() {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    this.currentTime = video.currentTime;
    this.duration = video.duration;
  }

  handleError() {
    this.videoError = true;
    this.videoLoaded = false;
    this.hasValidSource = false;
  }

  handleLoaded() {
    const video: HTMLVideoElement = this.videoPlayer?.nativeElement;
    this.currentTime = 0;
    this.duration = video.duration;
    this.videoError = false;
    this.videoLoaded = true;
    this.hasValidSource = true;
  }

  playPause() {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    if (video.paused) {
      video.play();
      this.playing = true;
    } else {
      video.pause();
      this.playing = false;
    }
  }

  seek(progess: number) {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    video.currentTime = (progess / 100) * video.duration;
  }

  changeVolume(progress: number) {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    video.volume = progress / 100;
    this.volume = progress;
  }

  mute() {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    if (this.isMute) {
      video.volume = this.volume / 100;
      this.isMute = false;
    } else {
      video.volume = 0;
      this.isMute = true;
    }
  }

  changeSource(newSrc: string) {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;

    video.src = newSrc;
    video.load();
    this.currentTime = 0;
    this.volume = 100;
    if (newSrc) {
      this.removeVideoEventListeners();
      this.playing = false;
      this.videoError = false;
      this.videoLoaded = false;
      this.hasValidSource = true;
      this.setupVideoEventListeners();
    } else {
      this.playing = false;
      this.videoError = true;
      this.videoLoaded = false;
      this.hasValidSource = false;
    }
  }

  // changePoster(posterSrc: string) {
  //   const video: HTMLVideoElement = this.videoPlayer.nativeElement;
  //   video.poster = posterSrc;
  // }

  loadInitialSource() {
    if (this.src) {
      this.changeSource(this.src);
    } else {
      this.hasValidSource = false;
    }

    // if (this.posterSrc) {
    //   this.changePoster(this.posterSrc);
    // }
  }

  get currentTimeValue(): number {
    return (this.currentTime / this.duration) * 100;
  }
}
