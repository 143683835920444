<main>
    <i [class.expanded]="showSchedule"></i>
    <section>
        <aside ngClass="left-aside">
            <h2>{{activity}}</h2>
            <h3>{{extendedDescription}}</h3>
            <footer>
                <app-button [label]="showSchedule ? 'Cerrar horario' : 'Ver horario'" type="button" classList="btn btn-secondary btn-block" (click)="verHorario()"/>
                <ul *ngIf="showSchedule">
                    <li *ngFor="let horario of horarios">
                        <span>
                            {{ horario.DiaSemana }}
                        </span>
                        {{ horario.HoraInicio }} - {{ horario.HoraFin }}
                    </li>
                </ul>

            </footer>
        </aside>
        <aside ngClass="right-aside">
            <h2>Plazas {{aforo}} </h2>
            <app-button label="Inscribirme" type="button" classList="btn btn-primary btn-block"></app-button>

        </aside>
    </section>
</main>