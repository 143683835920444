import { Component, OnInit } from '@angular/core';
import { Noticia, NoticiaResponse } from '../../../interfaces/NoticiaResponse';
import { Aviso, AvisoResponse } from '../../../interfaces/AvisoResponse';
import { Convocatoria, ConvocatoriaResponse } from '../../../interfaces/ConvocatoriaResponse';
import { ApiStatus } from '../../../interfaces';
import { NoticiasService } from '../../services/noticias.service';
import { MainService } from '../../../services/main.service';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { catchError, forkJoin, of, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { getTitle } from 'src/app/utils/breadcrumbs';

@Component({
  selector: 'app-noticias-layout',
  templateUrl: './noticias-layout.component.html',
  styleUrls: ['./noticias-layout.component.css'],
})
export class NoticiasLayoutComponent implements OnInit {
  noticias: Noticia[] = [];
  avisos: Aviso[] = [];
  convocatorias: Convocatoria[] = [];
  selected: string = 'noticia';
  apiStatus: ApiStatus = { loading: false, error: false };

  constructor(
    private noticiaService: NoticiasService,
    private mainService: MainService,
    private breadCrumbService: BreadcrumbService,
    private titleService: Title,
    private route: Router
  ) {}

  ngOnInit() {
    this.checkRoute();
    this.apiStatus = { loading: true, error: false };
    forkJoin([
      this.noticiaService.getNoticias().pipe(
        catchError(_e => {
          this.apiStatus = { loading: false, error: true };
          return of();
        }),
        switchMap((data: NoticiaResponse) => {
          this.apiStatus = { loading: false, error: this.apiStatus.error };
          this.noticias = data.data;
          return of(data);
        })
      ),
      this.mainService.getAvisos().pipe(
        catchError(_ => {
          this.apiStatus = { loading: false, error: true };
          return of();
        }),
        switchMap((data: AvisoResponse) => {
          this.apiStatus = { loading: false, error: this.apiStatus.error };
          this.avisos = data.data;
          return of(data);
        })
      ),
      this.mainService.getConvocatorias().pipe(
        catchError(_ => {
          this.apiStatus = { loading: false, error: true };
          return of();
        }),
        switchMap((data: ConvocatoriaResponse) => {
          this.apiStatus = { loading: false, error: this.apiStatus.error };
          this.convocatorias = data.data;
          return of(data);
        })
      ),
    ]).subscribe();
  }

  checkRoute() {
    switch (this.route.url) {
      case '/noticias': {
        this.selected = 'noticia';
        this.titleService.setTitle(getTitle('Noticias'));
        break;
      }
      case '/avisos': {
        this.selected = 'aviso';
        this.titleService.setTitle(getTitle('Avisos'));
        break;
      }
      case '/convocatorias': {
        this.selected = 'convocatoria';
        this.titleService.setTitle(getTitle('Convocatorias'));
        break;
      }
    }
    this.breadCrumbService.addCurrentBreadcrumb();
  }
}
