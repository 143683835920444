<main>
    <section>
      <ng-container *ngFor="let clase of clases; let i = index">
        <app-clases-linea [index]="i" [clase]="clase" />
      </ng-container>
  </section>

  <aside>
    <app-calendar-selector [dates]="getDatesForCalendar()" />
  </aside>
</main>