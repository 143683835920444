import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { DotNavigationComponent } from './dot-navigation/dot-navigation.component';
import { MenuComponent } from './menu/menu.component';
import { FormsModule } from '@angular/forms';
import { ModalBusquedaComponent } from './modal-busqueda/modal-busqueda.component';
import { ImagenModalComponent } from './imagen-modal/imagen-modal.component';
import { FabComponent } from './fab/fab.component';
import { PagesLoaderComponent } from './pages-loader/pages-loader.component';
import { EnlacesInteresComponent } from '../components/enlaces-interes/enlaces-interes.component';
import { MenuAccesibilidadComponent } from './menu-accesibilidad/menu-accesibilidad.component';
import { ImageRendererModule } from '../components/globals/image-renderer/image-renderer.module';
import { SlideCheckboxModule } from '../form/slide-checkbox/slide-checkbox.module';
import { IconosModule } from '../iconos/iconos.module';
import { NoImageComponent } from '../components/user/no-image/no-image.component';
import { UserBurgerMenuComponent } from '../components/user-burger-menu/user-burger-menu.component';
import { MenuCurtainComponent } from '../components/menu-curtain/menu-curtain.component';
import { ButtonModule } from '../form/button/button.module';
import { ActivityComponent } from '../components/book-facility/activity/activity.component';
import { HorizontalRangeSliderComponent } from '../components/horizontal-range-slider/horizontal-range-slider.component';
import { TabSliderComponent } from '../components/shared/tab-slider/tab-slider.component';
import { AccordionComponent } from '../components/shared/accordion/accordion.component';
import { InfoFloatingButtonComponent } from '../components/shared/info-floating-button/info-floating-button.component';
import { MiniCardViewComponent } from '../components/shared/mini-card-view/mini-card-view.component';
import { LayoutsModule } from '../layouts/layouts.module';
import { InformationViewComponent } from '../pages/inscripciones/components/information-view/information-view.component';
import { UserProfileSwitchComponent } from '../components/user/user-profile-switch/user-profile-switch.component';
import { FileDownloaderComponent } from '../deporte-escolar/components/shared/file-downloader/file-downloader.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    DotNavigationComponent,
    MenuComponent,
    ModalBusquedaComponent,
    ImagenModalComponent,
    FabComponent,
    PagesLoaderComponent,
    EnlacesInteresComponent,
    MenuAccesibilidadComponent,
    NoImageComponent,
    MenuCurtainComponent,
    UserBurgerMenuComponent,
    ActivityComponent,
    HorizontalRangeSliderComponent,
    TabSliderComponent,
    AccordionComponent,
    InfoFloatingButtonComponent,
    InformationViewComponent,
    UserProfileSwitchComponent,
    FileDownloaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgOptimizedImage,
    FormsModule,
    ImageRendererModule,
    SlideCheckboxModule,
    IconosModule,
    ButtonModule,
    LayoutsModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    DotNavigationComponent,
    ImagenModalComponent,
    FabComponent,
    PagesLoaderComponent,
    EnlacesInteresComponent,
    MenuAccesibilidadComponent,
    SlideCheckboxModule,
    IconosModule,
    NoImageComponent,
    MenuCurtainComponent,
    UserBurgerMenuComponent,
    ButtonModule,
    ActivityComponent,
    HorizontalRangeSliderComponent,
    TabSliderComponent,
    AccordionComponent,
    InfoFloatingButtonComponent,
    InformationViewComponent,
    FileDownloaderComponent,
  ],
})
export class SharedModule {}
