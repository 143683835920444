<section class="w-full">
  <label *ngIf="title" [for]="id" class="font-semibold">{{ title }} </label>
  <article (click)="toggleModal()">
    <span>
      {{ datetime | date : 'yyyy/MM/dd' }}
    </span>
    <span *ngIf="type != DatetimeType.date">
      {{ datetime | date : 'HH:mm' }}
    </span>

    <input
      [id]="id"
      [name]="name || ''"
      type="datetime-local"
      [class]="class"
      [(ngModel)]="datetime"
    />
    <app-image-renderer
      *ngIf="hideModal; else modalOpen"
      src="../../../assets/icons/calendar.svg"
      imgClass="date-picker-input-icon"
    />
    <ng-template #modalOpen>
      <app-image-renderer
        src="../../../assets/icons/cerrar.svg"
        imgClass="date-picker-input-icon"
      />
    </ng-template>
  </article>
  <ng-template [ngIf]="error">
    <label *ngIf="error.required" class="text-red-700 w-full"
      >El campo "{{ title | titlecase }}" es requerido</label
    >
    <label *ngIf="error.invalidDate" class="text-red-700 w-full"
      >La fecha debe ser posterior a la fecha de cierre</label
    >
  </ng-template>
  <i [ngClass]="{ hidden: hideModal }" (click)="toggleModal()"></i>
  <footer [ngClass]="{ hidden: hideModal }">
    <aside>
      <ng-container *ngFor="let year of years">
        <b (click)="enableYearSelector()">{{ year }}</b>
        <ng-container *ngFor="let month of months; index as i">
          <span *ngIf="minDate && minDate.getMonth() <= i || minDate && minDate.getFullYear() < year, else disabled"
            #monthSpan
            [attr.data-month]="year + '-' + (i + 1)"
            (click)="onMonthClick($event)"
            [ngClass]="{
              'month-selected':
                year.toString() === (datetime | date : 'yyyy') &&
                (i + 1).toString() === (datetime | date : 'M')
            }"
            >{{ month }}</span
          >
          <ng-template #disabled>
            <span class="disabled">{{ month }}</span>
          </ng-template>
        </ng-container>
      </ng-container>
    </aside>
    <header></header>
    <main *ngIf="!yearSelector; else yearSelection">
      <div>
        <ng-container *ngFor="let weekday of weekdays">
          <b>{{ weekday }}</b>
        </ng-container>
        <ng-container *ngFor="let emptyDay of emptyDays">
          <span>{{ emptyDay }}</span>
        </ng-container>
        <ng-container *ngFor="let day of numberOfDays; index as i">
          <span *ngIf="!day.disabled, else disabled"
            [ngClass]="{ 'day-selected': hightlightSelectedDay(i + 1) }"
            (click)="onDayClick($event)"
            >{{ i + 1 }}</span
          >
          <ng-template #disabled>
            <span class="disabled">{{ i + 1 }}</span>
          </ng-template>
        </ng-container>
      </div>
      <div *ngIf="type != DatetimeType.date">
        <nav>
          <ng-container *ngFor="let hour of [].constructor(24); index as i">
            <span
              (click)="onHourClick($event)"
              [ngClass]="{ 'time-selected': hightlightSelectedHour(i) }"
              >{{ i | number : '2.0-0' }}</span
            >
          </ng-container>
        </nav>
        <span>:</span>
        <nav>
          <ng-container *ngFor="let minute of [].constructor(60 / (stepper || 1)); index as i">
            <span
              (click)="onMinuteClick($event)"
              [ngClass]="{ 'time-selected': hightlightSelectedMinute(i * (stepper || 1)) }"
              >{{ i * (stepper || 1) | number : '2.0-0' }}</span
            >
          </ng-container>
        </nav>
      </div>
    </main>
    <ng-template #yearSelection>
      <main id="yearSelector">
        <ng-container *ngFor="let year of years">
          <span (click)="onYearClick($event)">{{ year }}</span>
        </ng-container>
      </main>
    </ng-template>
  </footer>
</section>