<div>
  <ng-template [ngIf]="label">
    <label [for]="id" class="font-semibold">{{ label }}</label>
  </ng-template>
  <div id="options">
    <div *ngFor="let option of options">
      <input
        [name]="name"
        type="radio"
        [id]="option.value"
        [value]="option.value"
        [checked]="option.value === value"
        (change)="onSeletChange(option.value)"
        [disabled]="disabled"
      />
      <label [for]="option.value">{{ option.label | titlecase }}</label>
    </div>
  </div>
  <ng-template [ngIf]="errores">
    <label class="text-red-700">El campo "{{ label | titlecase }}" es requerido</label>
  </ng-template>
</div>
