export function getDescripcion(texto: string): string {
  return texto.replaceAll(/<img [^>]+\>/gi, '');
}

export function sanitize(text: string) {
  return text
    .trim()
    .normalize('NFD')
    .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, '$1')
    .toLowerCase();
}
