<button
  [type]="type"
  [ngClass]="[
    classList ? classList : 'border rounded-2xl border-primary p-2 mt-16 hover:bg-primary button',
    disabled ? 'disabled' : ''
  ]"
  (mouseenter)="isHover = true"
  (mouseleave)="isHover = false"
  (focusout)="isHover = false"
  (click)="click()"
>
  <div *ngIf="!loading; else elseCargando" class="flex flex-row justify-center text-center">
    <ng-container *ngIf="icon && iconPosition == 'left'">
      <app-icon
        [src]="icon"
        [fill]="fillColor"
        [hoverFill]="hoverFillColor"
        [stroke]="strokeColor ? strokeColor : undefined"
        [hoverStroke]="hoverStrokeColor ? hoverStrokeColor : undefined"
        [hover]="isHover"
        [classList]="'h-6 w-6 mr-3'"
      />
    </ng-container>
    <p *ngIf="label" class="my-auto text-center hover:cursor-pointer">
      {{ label }}
    </p>
    <ng-container *ngIf="icon && iconPosition == 'right'">
      <app-icon
        [src]="icon"
        [fill]="fillColor"
        [hoverFill]="hoverFillColor"
        [stroke]="strokeColor"
        [hoverStroke]="hoverStrokeColor"
        [hover]="isHover"
        [classList]="'h-6 w-6 ml-3'"
      />
    </ng-container>
  </div>
  <ng-template #elseCargando>
    <app-cargando-cirular-icon
      [classList]="'w-8 h-8 m-auto'"
      [fill]="fillColor"
      [hoverFill]="hoverFillColor"
    />
  </ng-template>
</button>
