import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TablasService } from '../../services/tablas/tablas.service';
import { bgColors } from '../../utils/colors';

@Component({
  selector: 'app-planning-calendar-modal',
  templateUrl: './planning-calendar-modal.component.html',
  styleUrls: ['./planning-calendar-modal.component.css'],
})
export class PlanningCalendarModalComponent implements OnInit {
  @Input() userId: number;
  @Output() cerrar = new EventEmitter<boolean>();
  planningsFromApiBackup: any[];
  planningsFromApi: any[];
  editedPlanningsFromApi: any[] = [];

  selectedPlanningIndex: number = null;

  constructor(private tablasService: TablasService) {}

  onClose() {
    this.cerrar.emit(true);
  }

  ngOnInit() {
    this.tablasService.getPlaningsByUserId(this.userId).subscribe(data => {
      this.planningsFromApi = data;
      this.planningsFromApiBackup = data;
    });
  }

  returnDatesFromPlannings(): { year: number; month: number; date: number; colorIndex: number }[] {
    let dates = [];
    this.planningsFromApi?.forEach((planning, index) => {
      const color = index % bgColors.length;

      dates.push(
        ...planning.fechas.map(fecha => {
          const parsedFecha = fecha.split('-');
          return {
            year: parseInt(parsedFecha[0]),
            month: parseInt(parsedFecha[1]) - 1,
            date: parseInt(parsedFecha[2]),
            colorIndex: color,
            data: planning,
          };
        })
      );
    });
    return dates;
  }

  setSelectedPlanningId(id: number) {
    this.selectedPlanningIndex = id;
  }

  findPlanningIndexById(id: number, plannings: any[]): number {
    return plannings.findIndex(planning => planning.id === id);
  }

  formatDate({ year, month, date }: { year: number; month: number; date: number }) {
    return `${year}-${(month + 1).toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}`;
  }

  handleDeleteDate({
    year,
    month,
    date,
    data,
  }: {
    year: number;
    month: number;
    date: number;
    data: any;
  }) {
    const editedPlanningIndex = this.findPlanningIndexById(
      this.planningsFromApi[this.findPlanningIndexById(data.id, this.planningsFromApi)].id,
      this.editedPlanningsFromApi
    );
    const planningFromApiIndex = this.findPlanningIndexById(data.id, this.planningsFromApi);

    const dateToRemove = this.formatDate({ year, month, date });

    this.planningsFromApi[planningFromApiIndex].fechas = this.planningsFromApi[
      planningFromApiIndex
    ].fechas.filter(fecha => fecha !== dateToRemove);

    if (editedPlanningIndex === -1) {
      this.editedPlanningsFromApi.push(this.planningsFromApi[planningFromApiIndex]);
    } else {
      this.editedPlanningsFromApi[editedPlanningIndex] =
        this.planningsFromApi[planningFromApiIndex];
    }
  }

  handleAddDate({ year, month, date }: { year: number; month: number; date: number }) {
    const editedPlanningIndex = this.findPlanningIndexById(
      this.planningsFromApi[this.selectedPlanningIndex].id,
      this.editedPlanningsFromApi
    );

    this.planningsFromApi[this.selectedPlanningIndex].fechas.push(
      this.formatDate({ year, month, date })
    );

    if (editedPlanningIndex === -1) {
      this.editedPlanningsFromApi.push(this.planningsFromApi[this.selectedPlanningIndex]);
    } else {
      this.editedPlanningsFromApi[editedPlanningIndex] =
        this.planningsFromApi[this.selectedPlanningIndex];
    }
  }

  onClickDay({
    year,
    month,
    date,
    data,
  }: {
    year: number;
    month: number;
    date: number;
    data?: any;
  }) {
    if (data) {
      this.handleDeleteDate({ year, month, date, data });
    } else {
      this.handleAddDate({ year, month, date });
    }
  }

  cancelChanges() {
    this.planningsFromApi = this.planningsFromApiBackup.slice();
    this.editedPlanningsFromApi = [];
    this.onClose();
  }

  saveChanges() {
    this.tablasService.updatePlannings(this.editedPlanningsFromApi).subscribe(() => {
      this.planningsFromApiBackup = this.planningsFromApi;
      this.editedPlanningsFromApi = [];
      this.onClose();
    });
  }
}
