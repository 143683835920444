import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface User {
  Id: number;
  Nombre: string;
  Apellido1: string;
  Apellido2: string;
  FechaNacimiento: string;
  Sexo: number;
  EsMonitor: boolean;
  Imagen: string;
  Comentario?: string;
}
// TODO poner este interface en un lugar común para todo el proyecto

@Component({
  selector: 'app-perfil-modal',
  templateUrl: './perfil-modal.component.html',
  styleUrls: ['./perfil-modal.component.css'],
})
export class PerfilModalComponent {
  @Input() user: User;
  @Output() observacionChange: EventEmitter<string | null> = new EventEmitter<string | null>();

  constructor() {}

  accept() {
    this.observacionChange.emit('observacion');
  }
  cancel() {
    this.observacionChange.emit(null);
  }
  getFullName(user: User): string {
    return (user?.Nombre ?? '') + ' ' + (user?.Apellido1 ?? '') + ' ' + (user?.Apellido2 ?? '');
  }
}
