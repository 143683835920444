import { Component, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';
import { Info, InfoResponse } from '../../interfaces/InfoInstitucionalResponse';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-enlaces-interes',
  templateUrl: './enlaces-interes.component.html',
  styleUrls: ['./enlaces-interes.component.css'],
})
export class EnlacesInteresComponent implements OnInit {
  banners: Info[] = [];
  constructor(private mainService: MainService) {}

  ngOnInit() {
    this.mainService.getBanners().subscribe((res: InfoResponse) => {
      this.banners = res.data;
    });
  }

  protected readonly environment = environment;
}
