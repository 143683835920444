import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Ruta } from '../../../interfaces/RutasResponse';

@Component({
  selector: 'app-lista-rutas',
  templateUrl: './lista-rutas.component.html',
  styleUrls: ['./lista-rutas.component.css'],
})
export class ListaRutasComponent {
  @Input() rutas: Ruta[] = [];
  @Input() logo?: string = '';
  @Input() tipo: string = 'OTRAS';
  @Output() filterChange = new EventEmitter<{ tipo: string; open: boolean }>();

  open: boolean = false;

  toggleOpen() {
    this.open = !this.open;
    this.filterChange.emit({ tipo: this.tipo, open: this.open });
  }
}
