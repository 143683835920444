import { ChangeDetectorRef, Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-fab',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.css'],
})
export class FabComponent {
  showFAB: boolean = false;

  constructor(private changeDect: ChangeDetectorRef) {}

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number: number =
      window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.showFAB = number > 180;
    this.changeDect.detectChanges();
  }

  up() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
