import { NgModule } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { InstalacionesModule } from './instalaciones/instalaciones.module';
import { NoticiasModule } from './noticias/noticias.module';
import { SharedModule } from './shared/shared.module';
import { MainService } from './services/main.service';

import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { AvisosComponent } from './components/avisos/avisos.component';
import { ConvocatoriasComponent } from './components/convocatorias/convocatorias.component';
import { InscripcionesComponent } from './pages/inscripciones/inscripciones.component';
import { DeportesModule } from './pages/deportes/deportes.module';
import { RutasModule } from './rutas/rutas.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccesibilidadModule } from './pages/accesibilidad/accesibilidad.module';
import { InfoComponent } from './pages/info/info.component';
import { BannerNoticiasComponent } from './components/banner-noticias/banner-noticias.component';
import { PreciosPublicosModule } from './precios-publicos/precios-publicos.module';
import { LoginComponent } from './pages/login/login.component';
import { AuthService } from './services/auth.service';
import { UserMainPageComponent } from './pages/user-main-page/user-main-page.component';
import { ImageRendererModule } from './components/globals/image-renderer/image-renderer.module';
import { SlideCheckboxModule } from './form/slide-checkbox/slide-checkbox.module';
import { BreadcrumbsModule } from './shared/breadcrumbs/breadcrumbs.module';
import { LayoutsModule } from './layouts/layouts.module';
import { ButtonModule } from './form/button/button.module';
import { IconosModule } from './iconos/iconos.module';
import { ConfirmationModalModule } from './components/globals/confirmation-modal/confirmation-modal.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './incidencias/interceptor/auth-interceptor.interceptor';
import { UserProfileComponent } from './components/user/user-profile/user-profile.component';
import { SigninComponent } from './pages/signin/signin.component';
import { RegisterFormComponent } from './components/register-form/register-form.component';
import { InputModule } from './form/input/input.module';
import { RadioInputModule } from './form/radio-input/radio-input.module';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { DatetimePickerModule } from './form/datetime-picker/datetime-picker.module';
import { RememberPasswordComponent } from './pages/remember-password/remember-password.component';
import { BookFacilityComponent } from './pages/booking/book-facility/book-facility.component';
import { BookFacilityStepOneComponent } from './components/book-facility/book-facility-step-one/book-facility-step-one.component';
import { BookFacilityStepTwoComponent } from './components/book-facility/book-facility-step-two/book-facility-step-two.component';
import { ActivityDetailsComponent } from './components/book-facility/activity-details/activity-details.component';
import { LoaderComponent } from './components/globals/loader/loader.component';
import { InscriptionsInfoComponent } from './pages/inscripciones/components/inscriptions-info/inscriptions-info.component';
import { FeeInformationViewComponent } from './pages/inscripciones/components/fee-information-view/fee-information-view.component';
import { ModalComponent } from './components/globals/modal/modal.component';
import { UserProfileSwitcherComponent } from './components/user/user-profile-switcher/user-profile-switcher.component';
import { MonitorModule } from './monitor/monitor.module';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AvisosComponent,
    ConvocatoriasComponent,
    InscripcionesComponent,
    InfoComponent,
    BannerNoticiasComponent,
    LoginComponent,
    UserMainPageComponent,
    SigninComponent,
    RegisterFormComponent,
    ProgressBarComponent,
    UserProfileComponent,
    RememberPasswordComponent,
    BookFacilityComponent,
    BookFacilityStepOneComponent,
    BookFacilityStepTwoComponent,
    ActivityDetailsComponent,
    LoaderComponent,
    InscriptionsInfoComponent,
    FeeInformationViewComponent,
    ModalComponent,
    UserProfileSwitcherComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxMapboxGLModule.withConfig({ accessToken: environment.mapBoxToken }),
    InstalacionesModule,
    NoticiasModule,
    RutasModule,
    PreciosPublicosModule,
    SharedModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    FormsModule,
    ImageRendererModule,
    SlideCheckboxModule,
    BreadcrumbsModule,
    LayoutsModule,
    AccesibilidadModule,
    DeportesModule,
    ButtonModule,
    IconosModule,
    ConfirmationModalModule,
    InputModule,
    RadioInputModule,
    DatetimePickerModule,
    MonitorModule,
  ],
  providers: [
    MainService,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  exports: [AvisosComponent, ConvocatoriasComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
