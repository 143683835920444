import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './pages/index/index.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { LayoutsModule } from '../layouts/layouts.module';
import { InfoComponent } from './components/convocatorias/info/info.component';
import { ConcursosComponent } from './components/concursos/concursos/concursos.component';
import { CompeticionesComponent } from './components/competiciones/competiciones/competiciones.component';
import { EscuelasDeportivasComponent } from './components/escuelas-deportivas/escuelas-deportivas/escuelas-deportivas.component';
import { MemoriaComponent } from './components/memoria/memoria/memoria.component';
import { DescargasComponent } from './components/descargas/descargas/descargas.component';
import { ConvocatoriaComponent } from './components/convocatorias/convocatoria/convocatoria.component';
import { CompeticionComponent } from './components/competiciones/competicion/competicion.component';
import { CompeticionDetailComponent } from './components/competiciones/competicion-detail/competicion-detail.component';
import { MemoriaDetailComponent } from './components/memoria/memoria-detail/memoria-detail.component';
import { SelectInputModule } from '../form/select-input/select-input.module';
import { InputModule } from '../form/input/input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ListModule } from '../components/shared/list/list.module';
import { MapsModule } from '../components/shared/maps/maps.module';
import { EscuelaComponent } from './components/escuelas-deportivas/escuela/escuela.component';
import { DescargasDetailsComponent } from './components/descargas/descargas-details/descargas-details.component';

const routes: Routes = [
  { path: '', component: IndexComponent },
  { path: ':id', component: EscuelaComponent },
];

@NgModule({
  declarations: [
    IndexComponent,
    InfoComponent,
    ConcursosComponent,
    CompeticionesComponent,
    EscuelasDeportivasComponent,
    MemoriaComponent,
    DescargasComponent,
    ConvocatoriaComponent,
    CompeticionComponent,
    CompeticionDetailComponent,
    MemoriaDetailComponent,
    EscuelaComponent,
    DescargasDetailsComponent,
  ],
  imports: [
    CommonModule,
    [RouterModule.forChild(routes)],
    SharedModule,
    LayoutsModule,
    SelectInputModule,
    InputModule,
    ReactiveFormsModule,
    ListModule,
    MapsModule,
  ],
  exports: [IndexComponent],
})
export class DeporteEscolarModule {}
