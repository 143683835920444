<div class="usuario">
  <div class="imagen-container" (click)="onSelectElement()">
    <img *ngIf="usuario?.Imagen" [src]="usuario?.Imagen" [alt]="'Perfil ' + usuario?.Nombre" />
    <img
      *ngIf="!usuario?.Imagen"
      alt="Foto perfil por defecto"
      src="/assets/img/person%20placeholder.jpg"
    />
    <div class="overlay" [ngClass]="isAdd ? 'add' : 'sub'">
      <p class="icon-plus">{{ isAdd ? '+' : '-' }}</p>
    </div>
  </div>
  <p>{{ nombreCompleto(usuario) }}</p>
</div>
