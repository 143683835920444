import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeporteEscolarService } from 'src/app/deporte-escolar/services/deporte-escolar.service';
import {
  SingleViewData,
  SingleViewDataTypes,
} from 'src/app/components/shared/list/icon-list/icon-list.component';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { Title } from '@angular/platform-browser';
import { getTitle } from 'src/app/utils/breadcrumbs';
import { ListListerColumnType } from 'src/app/components/shared/list/list-lister/list-lister.component';
DeporteEscolarService;

@Component({
  selector: 'app-escuela',
  templateUrl: './escuela.component.html',
  styleUrls: ['./escuela.component.css'],
})
export class EscuelaComponent {
  id: string | null = null;
  escuela: any = null;
  singleViewDataTypes = SingleViewDataTypes;

  escuelaData: SingleViewData[] = [
    // Commented for quick fix build error
    // { label: 'Contacto', valueAccessor: 'contacto', type: SingleViewDataTypes.String, icon: 'flecha', fill: 'white', stroke: 'none' },
    // { label: 'Teléfono', valueAccessor: 'telefono', type: SingleViewDataTypes.String, icon: 'flecha', fill: 'white', stroke: 'none' },
    // { label: 'Horarios', valueAccessor: 'turnos', type: SingleViewDataTypes.innerHTML, icon: 'flecha', fill: 'white', stroke: 'none' },
    // {
    //   label: 'Deportes que se pueden practicar',
    //   isClickable: 'deportes',
    //   valueAccessor: 'deportes',
    //   accessorForCardView: {
    //     listColumns: [
    //       { name: 'Edad minima', prop: 'edad_minima', type: ListListerColumnType.String },
    //       { name: 'Edad máxima', prop: 'edad_maxima', type: ListListerColumnType.String },
    //     ],
    //     cardColumns: [
    //     ],
    //   },
    //   type: SingleViewDataTypes.isCardView,
    //   icon: 'instalaciones',
    //   fill: 'white',
    //   stroke: 'none',
    // },
  ];

  constructor(
    private deporteEscolarService: DeporteEscolarService,
    private route: ActivatedRoute,
    private breadCrumbService: BreadcrumbService,
    private titleService: Title
  ) {}
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      if (this.id)
        this.deporteEscolarService.getEscuelaDeportiva(this.id).subscribe(escuela => {
          this.escuela = escuela;
          this.titleService.setTitle(getTitle(this.escuela.title));
          this.breadCrumbService.addCurrentBreadcrumb();
        });
    });
  }
}
