import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccesibilidadComponent } from './accesibilidad.component';
import { LayoutsModule } from 'src/app/layouts/layouts.module';

@NgModule({
  declarations: [AccesibilidadComponent],
  imports: [CommonModule, LayoutsModule],
  exports: [AccesibilidadComponent],
})
export class AccesibilidadModule {}
