<form class="container" [formGroup]="tablaFormGroup">
  <div class="none"></div>
  <div class="ejercicios">
    <app-busqueda-ejercicios [ejercicios]="allEjercicios" />
  </div>
  <span class="titulo-tabla">
    <input
      class="nombre-tabla"
      spellcheck="false"
      formControlName="title"
      type="text"
      placeholder="Nombre Tabla"
    />
  </span>
  <textarea
    formControlName="observaciones"
    class="observaciones"
    spellcheck="false"
    placeholder="Añadir observaciones de la tabla"
  ></textarea>
  <div class="section-ejercicios">
    <div class="title-ejercicios">
      <label>EJERCICIO</label>
      <label>TIEMPO / REPETICIONES</label>
      <label>SERIES</label>
      <label>CARGA</label>
      <div></div>
    </div>
    <div
      class="lista-ejercicios"
      cdkDropList
      id="exerciseTable"
      #exerciseTable="cdkDropList"
      [cdkDropListConnectedTo]="['exerciseTable']"
      [cdkDropListData]="allEjercicios"
      (cdkDropListDropped)="drop($event)"
    >
      <ng-container
        class="linea-ejercicio"
        *ngFor="let lineaEjercicio of lineasEjerciciosFormGroup.controls; let i = index"
      >
        <app-linea-ejercicio
          cdkDrag
          [lineaEjercicioFormGroup]="lineaEjercicioFormGroup(lineaEjercicio)"
          (showEjercicio)="showEjercicio($event)"
          [index]="i"
          (deleteLinea)="deleteLinea(i)"
        />
        <app-linea-ejercicio
          *cdkDragPreview
          [isPreview]="true"
          [lineaEjercicioFormGroup]="lineaEjercicioFormGroup(lineaEjercicio)"
        />
        <app-linea-ejercicio
          *cdkDragPlaceholder
          [isPreview]="true"
          [lineaEjercicioFormGroup]="lineaEjercicioFormGroup(lineaEjercicio)"
        />
      </ng-container>
    </div>
  </div>
  <div class="buttons-container" [formGroup]="tablaFormGroup">
    <div class="input-plantilla">
      <!--      <app-slide-checkbox label="Es Plantilla" formControlName="plantilla" id="plantilla" />-->
    </div>
    <div class="buttons">
      <app-button
        (onClick)="cancel()"
        classList="cancel button-action"
        label="Cancelar"
        [disabled]="isSavingOrLoading"
      ></app-button>
      <app-button
        classList="add-user button-action"
        label="Añadir usuarios"
        icon="mas"
        strokeColors="white"
        [disabled]="isSavingOrLoading"
        (onClick)="openModalUsers()"
      ></app-button>
      <app-button
        classList="save button-action"
        label="Aceptar"
        (onClick)="onClickSaveRutina()"
        [disabled]="isSavingOrLoading"
      ></app-button>
    </div>
  </div>
</form>
