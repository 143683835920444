import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UsuarioRutina } from '../../models/ejercicios.model';

@Component({
  selector: 'app-agregar-usuarios-linea-usuario',
  templateUrl: './agregar-usuarios-linea-usuario.component.html',
  styleUrls: ['./agregar-usuarios-linea-usuario.component.css'],
})
export class AgregarUsuariosLineaUsuarioComponent {
  @Input() usuario: UsuarioRutina;
  @Input() isAdd: boolean = true;

  @Output() selectElement: EventEmitter<UsuarioRutina> = new EventEmitter<UsuarioRutina>();

  onSelectElement() {
    this.selectElement.emit(this.usuario);
  }

  nombreCompleto(usuario: UsuarioRutina): string {
    let nombreCompleto = '';
    if (usuario?.Nombre) {
      nombreCompleto = usuario?.Nombre;
    }
    if (usuario?.Apellido1) {
      nombreCompleto = nombreCompleto + ' ' + usuario?.Apellido1;
    }
    if (usuario?.Apellido2) {
      nombreCompleto = nombreCompleto + ' ' + usuario?.Apellido2;
    }
    return nombreCompleto;
  }
}
