<app-accordion title="Búsqueda" [isOpen]="true">
    <form [formGroup]="filterForm">
        <article>
            <app-input label="Por palabra" formControlName="searchInput" />
            <app-select-input placeholder="Busca un deporte" label="Por deporte" formControlName="sport"
                defaultOptionLabel="Cualquier deporte" [options]="deportes" />
            <span>{{
                this.filterForm.value.edadSelector === 0 ? 'Todas las edades' : 'A partir de los ' +
                this.filterForm.value.edadSelector + ' años'
                }}
                <input id="range-edades" type="range" min="0" max="17" step="1" formControlName="edadSelector" />
            </span>
            <footer>
                <app-button classList="btn btn-primary btn-block" label="Limpiar filtros"
                    (click)="resetForm()"></app-button>
            </footer>
        </article>
    </form>
</app-accordion>
<app-accordion title="Geolocalización" [isOpen]="true">
    <app-map-for-list [data]="filteredEscuelas" section="deporte-escolar" />
</app-accordion>
<app-accordion title="Listado" [isOpen]="true">
    <app-pagination-container [showUpperButtons]="true" [data]="filteredEscuelas"
        (emitData)="handleEmitData($event)" [pageSize]="18">
        <app-list-lister [data]="currentPageData" [cardColumns]="cardColumns" [listColumns]="listColumns"
            [isCardView]="true" />
    </app-pagination-container>
</app-accordion>