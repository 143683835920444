import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-map-for-list',
  templateUrl: './map-for-list.component.html',
  styleUrls: ['./map-for-list.component.css'],
})
export class MapForListComponent {
  @Input() data: any[] = [];
  @Input() section: string = '';
}
